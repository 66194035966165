var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        card: "",
        "no-primary-button": "",
        closeButtonText: _vm._f("gettext")("Close"),
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceLogMessageViewer",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.item
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.currentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c("CodeMirror", {
                attrs: {
                  mode: "string",
                  options: _vm.b_1040167445267876_options
                },
                model: {
                  value: _vm.item.log_messages,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "log_messages", $$v)
                  },
                  expression: "item.log_messages"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item
        ? _c("UcFooter", {
            attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
            slot: "footer"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }