<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="FixedAssetBrowser"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="fixedAssets"
    :headerTitle="'Fixed assets'|gettext"
    :items="$fn.slickDataSource('integro_api/list_fixed_asset')"
    checkbox-row-selection
    footer-totals
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpButton
        :icon="$config.fixedAsset.createFixedAssetIcon"
        :text="'Create fixed asset...'|pgettext('FixedAssets')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/assets/fixed-assets.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "fixedAssets",
    path: "/fixed-assets",
    component: "FixedAssetBrowser"
  }
)

@Component()
export default class FixedAssetBrowser extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "name",
          name: this.$fn.pgettext("FixedAssetBrowser", "Name"),
          formatter: {
            name: "routeLink",
            to: "fixedAsset",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "asset_type",
          name: this.$fn.pgettext("FixedAssetBrowser", "Type"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "FixedAssetTypes",
              conversion: "StartCase",
              filterInput: true,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "description",
          name: this.$fn.pgettext("FixedAssetBrowser", "Description"),
        }
          ,
        {
          field: "inventory_no",
          name: this.$fn.pgettext("FixedAssetBrowser", "Inventory Number"),
        }
          ,
        {
          field: "sku",
          name: this.$fn.pgettext("FixedAssetBrowser", "SKU"),
        }
          ,
        {
          field: "quantity",
          name: this.$fn.pgettext("FixedAssetBrowser", "Quantity"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "acquisition_date",
          name: this.$fn.pgettext("FixedAssetBrowser", "Acquisition Date"),
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Fixed Assets"),
        icon: this.$config.fixedAsset.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1458_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1458, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_1460_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_1460, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_1462_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_1462, alias=undefined
    return {
      name: "MCreateFixedAsset",
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1458: actions.CloseModalAction;
    evh_7315092382398562_close_1457: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_1460: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_1459: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_showModal_1462: actions.ShowModalAction;
    evh_6466705138206980_click_1461: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1458 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1458_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1457 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1458,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1457],
      }
    );
    const act_2248226175642056_setData_1460 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_1460_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_1459 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_1460,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_1459],
      }
    );
    const act_6466705138206980_showModal_1462 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_1462_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_click_1461 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_1462,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_1461],
      }
    );
    return {
      act_7315092382398562_closeModal_1458,
      evh_7315092382398562_close_1457,
      evh_7315092382398562_close,
      act_2248226175642056_setData_1460,
      evh_2248226175642056_selectedRowsChanged_1459,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_showModal_1462,
      evh_6466705138206980_click_1461,
      evh_6466705138206980_click,
    }
  }
}

Vue.component("FixedAssetBrowser", FixedAssetBrowser);

</script>