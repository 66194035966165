var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "AddFixedAssetToRentalModal",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.currentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  staticClass: "row",
                  attrs: { name: "addFixedAssetToRental", id: _vm.formId },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpCol",
                    { attrs: { auto: _vm.b_5534025912102772_auto } },
                    [
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "3",
                            contentColsMd: "9",
                            name: "rental_id",
                            label: _vm._f("pgettext")(
                              "Rental",
                              "AddFixedAssetToRentalModal"
                            )
                          }
                        },
                        [
                          _c("ItpFormSelect2", {
                            attrs: {
                              name: "parent_id",
                              dataSource: _vm.b_5731695935601903_dataSource,
                              valueField: "id",
                              textField: "name",
                              "use-option-template-for-selected-option": "",
                              optionTemplate:
                                '<UcSuggestRentalOption :option="option"></UcSuggestRentalOption>'
                            },
                            model: {
                              value: _vm.item.rental_id,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "rental_id", $$v)
                              },
                              expression: "item.rental_id"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "3",
                            contentColsMd: "9",
                            name: "valid_from",
                            label: _vm._f("pgettext")(
                              "Valid From",
                              "AddFixedAssetToRentalModal"
                            )
                          }
                        },
                        [
                          _c("DatePicker", {
                            attrs: {
                              inputClass: "form-control form-control-md",
                              name: "valid_from",
                              format: "YYYY-MM-DD",
                              valueType: "format"
                            },
                            model: {
                              value: _vm.item.valid_from,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_from", $$v)
                              },
                              expression: "item.valid_from"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "3",
                            contentColsMd: "9",
                            name: "valid_to",
                            label: _vm._f("pgettext")(
                              "Valid To",
                              "AddFixedAssetToRentalModal"
                            )
                          }
                        },
                        [
                          _c("DatePicker", {
                            attrs: {
                              inputClass: "form-control form-control-md",
                              name: "valid_from",
                              format: "YYYY-MM-DD",
                              valueType: "format"
                            },
                            model: {
                              value: _vm.item.valid_to,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_to", $$v)
                              },
                              expression: "item.valid_to"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "3",
                            contentColsMd: "9",
                            name: "valid_to",
                            label: _vm._f("pgettext")(
                              "Notes",
                              "AddFixedAssetToRentalModal"
                            )
                          }
                        },
                        [
                          _c("ItpFormTextarea", {
                            attrs: { name: "notes" },
                            model: {
                              value: _vm.item.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "notes", $$v)
                              },
                              expression: "item.notes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  type: "submit",
                  text: _vm._f("pgettext")("Add", "AddFixedAssetToRentalModal"),
                  form: _vm.forms.addFixedAssetToRental,
                  spinning: _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Cancel"),
                  variant: "default"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }