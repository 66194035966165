<template>
<div>
  <legend
    class="text-muted"
  >
    {{ 'Advances'|pgettext('Contract') }}
  </legend>
  <ItpFormGroup
    name="rental_fee_advances_initial_months"
    :label="'Initial'|pgettext('Contract')"
    :appendText="'months'|pgettext('InputAddon')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      type="number"
      name="rental_fee_advances_initial_months"
      v-model="contract.invoicing.rental_fee_advances_initial_months"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="rental_fee_advances_reserve_months"
    :label="'Reserve'|pgettext('Contract')"
    :appendText="'months'|pgettext('InputAddon')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      type="number"
      name="rental_fee_advances_reserve_months"
      v-model="contract.invoicing.rental_fee_advances_reserve_months"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <hr>
  </hr>
  <legend>
    {{ 'Billing'|pgettext('Contract') }}
  </legend>
  <ItpFormGroup
    name="billing_currency"
    :label="'Billing currency'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="3"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="billing_currency"
      valueField="code"
      textField="code"
      required
      v-model="contract.invoicing.billing_currency"
      :options="currencies"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="billing_frequency"
    :label="'Billing frequency'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    contentClass="input-group"
    v-if="loaded"
  >
    <ItpFormInput
      type="number"
      name="billing_frequency_value"
      v-model="billing_frequency.value"
    >
    </ItpFormInput>
    <DIV
      class="input-group-append w-50"
    >
      <ItpFormSelect
        name="billing_frequency_unit"
        v-model="billing_frequency.unit"
        :options="billingFrequencyOptions"
      >
      </ItpFormSelect>
    </DIV>
  </ItpFormGroup>
  <ItpFormGroup
    name="invoice_notes"
    :label="'Notes on invoice'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="invoice_notes_value"
      v-model="contract.invoicing.invoice_notes"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
  <ItpFormGroup
    name="payment_terms"
    :label="'Payment period'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    contentClass="input-group"
    v-if="loaded && contract"
  >
    <ItpFormInput
      type="number"
      name="payment_terms_value"
      v-model="contract.invoicing.payment_terms.value"
    >
    </ItpFormInput>
    <DIV
      class="input-group-append w-50"
    >
      <ItpFormSelect
        name="payment_terms_type"
        v-model="contract.invoicing.payment_terms.type"
        :options="contractPaymentTermTypes"
      >
      </ItpFormSelect>
    </DIV>
  </ItpFormGroup>
</div>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetContractInvoicing extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
  }

  billing_frequency!: any;

  async $$load_billing_frequency() {
    return this.contract.invoicing.billing_cycle != null ?
      {
        value: this.contract.invoicing.billing_cycle.split(" ")[0],
        unit: this.contract.invoicing.billing_cycle.split(" ")[1]
      } : {
        value: null,
        unit: null
      }
  }

  billingFrequencyOptions!: any;
  contractPaymentTermTypes!: any;

  async $$load_contractPaymentTermTypes() {
    return this.$fn.getEnumValuesFromSchema('ContractPaymentTermTypes')
  }

  ux!: any;
  dataMembers = ['currencies', 'billing_frequency', 'billingFrequencyOptions', 'contractPaymentTermTypes', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        currencies: null,
        billing_frequency: null,
        billingFrequencyOptions: [{
          name: "day",
          text: this.$fn.gettext("day"),
        }
          ,
        {
          name: "month",
          text: this.$fn.gettext("month"),
        }
          ,
        ]
        ,
        contractPaymentTermTypes: null,
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("FieldsetContractInvoicing", FieldsetContractInvoicing);

</script>