<template>
<ItpModal
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WasteBinReadingModal"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="waste_bin_reading"
      :id="formId"
      class="row"
      ref="mainForm"
    >
      <ItpCol
        :auto="b_5534025912102772_auto"
      >
        <ItpFormGroup
          row
          label-cols-md="3"
          content-cols-md="9"
          name="waste_bin_number"
          :label="'Bin Number'|pgettext('WasteBinReadingModal')"
        >
          <ItpFormInput
            name="waste_bin_number"
            v-model="item.waste_bin_number"
            readonly
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          row
          label-cols-md="3"
          content-cols-md="9"
          name="timestamp"
          :label="'Time'|pgettext('WasteBinReadingModal')"
        >
          <ItpFormPlaintext
            id="timestamp"
          >
            {{ item.timestamp | datetime }}
          </ItpFormPlaintext>
        </ItpFormGroup>
        <ItpFormGroup
          row
          label-cols-md="3"
          content-cols-md="9"
          name="identity"
          :label="'Device'|pgettext('WasteBinReadingModal')"
        >
          <ItpFormPlaintext
            id="identity"
          >
            {{ item.identity }}
          </ItpFormPlaintext>
        </ItpFormGroup>
        <ItpFormGroup
          row
          label-cols-md="3"
          content-cols-md="9"
          name="state"
          :label="'State'|pgettext('WasteBinReadingModal')"
        >
          <ItpBox
            alignItems="center"
          >
            <ItpBadge
              :value="item.state"
              :variantMap="b_5430362791568289_variantMap"
              no-box-shadow
              class="mr-3"
            >
              {{ item.state | pgettext('WasteBinReadingStates', 'StartCase') }}

            </ItpBadge>
            <ItpButton
              :text="'Toggle state'|pgettext('WasteBinReadingModal')"
              variant="light"
              size="sm"
              @click.prevent.stop="evh_2286348834130966_clickPreventStop($event, {})"
            >
            </ItpButton>
          </ItpBox>
        </ItpFormGroup>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class WasteBinReadingModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
  })
  objectId!: int;

  @Watch('objectId')
  onObject_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("objectId")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('waste_bins/get_reading', { parameters: { id: this.objectId } }).then(d => new this.$fn.Resource(d))
  }

  ux!: any;
  b_5534025912102772_auto!: any;
  b_5430362791568289_variantMap!: any;
  dataMembers = ['formId', 'item', 'ux', 'b_5534025912102772_auto', 'b_5430362791568289_variantMap'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        item: null,
        ux: null,
        b_5534025912102772_auto: false,
        b_5430362791568289_variantMap: {
          empty: "success",
          full: "warning",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Waste bin reading"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1526_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1526, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_2286348834130966_setData_1532_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2286348834130966_setData_1532, alias=undefined
    return {
      path: "item",
      value: $event && $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_2286348834130966_request_1530_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_2286348834130966_request_1530, alias=toggleState
    return {
      operation: "waste_bins/update_reading",
      data: {
        id: this.item.id,
        state: this.item.state == 'full' ? 'empty' : 'full'
      },
    }
  }

  async evh_2286348834130966_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2286348834130966_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1526: actions.CloseModalAction;
    evh_7315092382398562_close_1525: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_1528: actions.CloseComponentAction;
    evh_2248226175642056_close_1527: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_2286348834130966_setData_1532: actions.SetDataAction;
    evh_2286348834130966_success_1531: actions.EventHandlerImpl;
    act_2286348834130966_request_1530: actions.RequestAction;
    evh_2286348834130966_clickPreventStop_1529: actions.EventHandlerImpl;
    evh_2286348834130966_clickPreventStop: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_1534: actions.CloseComponentAction;
    evh_1548630417156826_clickPreventStop_1533: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1526 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1526_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1525 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1526,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1525],
      }
    );
    const act_2248226175642056_closeComponent_1528 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1527 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_1528,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1527],
      }
    );
    const act_2286348834130966_setData_1532 = new actions.SetDataAction(
      {
        actionArgs: this.act_2286348834130966_setData_1532_getActionArgs,
        events: [],
      }
    );
    const evh_2286348834130966_success_1531 = new actions.EventHandlerImpl(
      {
        action: act_2286348834130966_setData_1532,
        event: "success",
        displayName: "setData",
      }
    );
    const act_2286348834130966_request_1530 = new actions.RequestAction(
      {
        actionArgs: this.act_2286348834130966_request_1530_getActionArgs,
        displayName: "toggleState",
        events: [evh_2286348834130966_success_1531],
      }
    );
    const evh_2286348834130966_clickPreventStop_1529 = new actions.EventHandlerImpl(
      {
        action: act_2286348834130966_request_1530,
        event: "click.prevent.stop",
        displayName: "toggleState",
      }
    );
    const evh_2286348834130966_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2286348834130966_clickPreventStop_1529],
      }
    );
    const act_1548630417156826_closeComponent_1534 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1548630417156826_clickPreventStop_1533 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_1534,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_1533],
      }
    );
    return {
      act_7315092382398562_closeModal_1526,
      evh_7315092382398562_close_1525,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_1528,
      evh_2248226175642056_close_1527,
      evh_2248226175642056_close,
      act_2286348834130966_setData_1532,
      evh_2286348834130966_success_1531,
      act_2286348834130966_request_1530,
      evh_2286348834130966_clickPreventStop_1529,
      evh_2286348834130966_clickPreventStop,
      act_1548630417156826_closeComponent_1534,
      evh_1548630417156826_clickPreventStop_1533,
      evh_1548630417156826_clickPreventStop,
      toggleState: act_2286348834130966_request_1530,
    }
  }
}

Vue.component("WasteBinReadingModal", WasteBinReadingModal);

</script>