<template>
<address>
  <ItpBox>
    <UcAddressTypeBadge
      :value="item.address_type"
    >
    </UcAddressTypeBadge>
    <ItpBadge
      :text="'Default'|gettext"
      variant="info"
      no-box-shadow
      class="ml-3"
      v-if="item.is_default && displayDefaultFlag"
    >
    </ItpBadge>
  </ItpBox>
  <DIV
    :key="index"
    v-if="item.lines && item.lines.length"
    v-for="(line, index) in item.lines"
  >
    {{ line }}
  </DIV>
  <DIV
    v-if="item.line_1"
  >
    {{ item.line_1 }}<br/>

  </DIV>
  <DIV
    v-if="item.line_2"
  >
    {{ item.line_2 }}<br/>

  </DIV>
  <DIV
    v-if="item.line_3"
  >
    {{ item.line_3 }}<br/>

  </DIV>
  <DIV
    v-if="item.street_address"
  >
    {{ item.street_address }}<br/>

  </DIV>
  <DIV>
    {{ item.city }} {{ item.region }} {{ item.postal_code }}

  </DIV>
  <DIV
    v-if="item.country"
  >
    {{ item.country }}

  </DIV>
  <ItpText
    :text="item.other_details"
    pre
    v-if="item.other_details"
  >
  </ItpText>
</address>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcAddressCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
  })
  displayDefaultFlag!: boolean;

  @Watch('displayDefaultFlag')
  onDisplay_default_flag(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("displayDefaultFlag")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcAddressCard", UcAddressCard);

</script>