<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Property"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <MaterialTabs
    v-if="loaded"
  >
    <ItpBox
      alignItems="center"
      class="mr-3"
      slot="tabs-start"
    >
      <b-breadcrumb
        class="m-0 bg-white"
      >
        <b-breadcrumb-item
          :text="'Properties'|gettext"
          :to="b_4615685607825208_to"
        >
        </b-breadcrumb-item>
        <b-breadcrumb-item
          :text="item.snapshot('name')"
          active
        >
        </b-breadcrumb-item>
      </b-breadcrumb>
      <ItpButton
        variant="default"
        icon="fa-sync-alt"
        :text="'Reload'|gettext"
        :spinning="actions.reload.isRunning"
        class="px-3"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <b-tab
      :title="'General'|gettext"
      class="container float-left"
    >
      <ItpRow>
        <ItpCol
          w="6"
        >
          <UcPropertyGeneralData
            :property="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_5477420070495986_reload($event, {})"
          >
          </UcPropertyGeneralData>
          <UcPropertyUnits
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_6337614691287098_reload($event, {})"
          >
          </UcPropertyUnits>
        </ItpCol>
        <ItpCol
          w="6"
        >
          <UcCommentsList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
          >
          </UcCommentsList>
          <UcAttachmentsList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
          >
          </UcAttachmentsList>
        </ItpCol>
      </ItpRow>
    </b-tab>
  </MaterialTabs>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "property",
    path: "/properties/:id",
    component: "Property",
    params: [{
      name: "id",
      prop: "propertyId",
    }
      ,
    ]

  }
)

@Component()
export default class Property extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  propertyId!: string;

  @Watch('propertyId')
  onProperty_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("propertyId")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_property', { asResource: true, parameters: { id: this.propertyId, include_units: true, include_subtree: true } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_4615685607825208_to!: any;
  dataMembers = ['item', 'ux', 'b_7315092382398562_modalBindings', 'b_4615685607825208_to'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_4615685607825208_to: {
          name: "app.property",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.item.snapshot('name')
          ,
        this.$fn.gettext("Property")
          ,
        ]
        ,
        icon: this.$config.property.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3136_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3136, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_5477420070495986_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5477420070495986_reload.executeFromDOM(this, event, scope);
  }

  async evh_6337614691287098_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6337614691287098_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3136: actions.CloseModalAction;
    evh_7315092382398562_close_3135: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_8320016629450276_reloadComponentData_3138: actions.ReloadComponentDataAction;
    evh_8320016629450276_clickPreventStop_3137: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_5477420070495986_reloadComponentData_3140: actions.ReloadComponentDataAction;
    evh_5477420070495986_reload_3139: actions.EventHandlerImpl;
    evh_5477420070495986_reload: actions.EventHandlerGroup;
    act_6337614691287098_reloadComponentData_3142: actions.ReloadComponentDataAction;
    evh_6337614691287098_reload_3141: actions.EventHandlerImpl;
    evh_6337614691287098_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3136 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3136_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3135 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3136,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3135],
      }
    );
    const act_8320016629450276_reloadComponentData_3138 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_8320016629450276_clickPreventStop_3137 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadComponentData_3138,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_3137],
      }
    );
    const act_5477420070495986_reloadComponentData_3140 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_5477420070495986_reload_3139 = new actions.EventHandlerImpl(
      {
        action: act_5477420070495986_reloadComponentData_3140,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_5477420070495986_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_5477420070495986_reload_3139],
      }
    );
    const act_6337614691287098_reloadComponentData_3142 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6337614691287098_reload_3141 = new actions.EventHandlerImpl(
      {
        action: act_6337614691287098_reloadComponentData_3142,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_6337614691287098_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_6337614691287098_reload_3141],
      }
    );
    return {
      act_7315092382398562_closeModal_3136,
      evh_7315092382398562_close_3135,
      evh_7315092382398562_close,
      act_8320016629450276_reloadComponentData_3138,
      evh_8320016629450276_clickPreventStop_3137,
      evh_8320016629450276_clickPreventStop,
      act_5477420070495986_reloadComponentData_3140,
      evh_5477420070495986_reload_3139,
      evh_5477420070495986_reload,
      act_6337614691287098_reloadComponentData_3142,
      evh_6337614691287098_reload_3141,
      evh_6337614691287098_reload,
      reload: act_8320016629450276_reloadComponentData_3138,
    }
  }
}

Vue.component("Property", Property);

</script>