<template>
<ItpModal
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ProductEditor"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="product"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <FieldsetProductGeneral
          :product="product"
          :formId="formId"
          :target="target"
        >
        </FieldsetProductGeneral>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      :disabled="!forms.product || !forms.product.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ProductEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  getPayload(...args: any[]) {
    const [data] = args

    if (!this.target) {
      return data;
    }

    const fieldsToSubmit = this.targetConfig[this.target].fieldsToSubmit;
    if (fieldsToSubmit.indexOf('*') > -1) {
      return data;
    }

    return _.pick(data, fieldsToSubmit);
  }

  @Prop({
    type: Object,
  })
  product!: object;

  @Watch('product')
  onProduct(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("product")
  }

  @Prop({
    type: String,
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  defaultTitle!: any;
  targetConfig!: any;
  ux!: any;
  dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        defaultTitle: this.$fn.pgettext("Product", "Edit General Information"),
        targetConfig: {
          general: {
            title: this.$fn.pgettext("Product", "Edit general data"),
            fieldsToSubmit: ["*"
              ,
            ]
            ,
          }
          ,
        }
        ,
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3042_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3042, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_3048_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_3048, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_3046_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_3046, alias=save
    return {
      objectType: "product",
      op: "upsert",
      data: this.getPayload(this.product),
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3042: actions.CloseModalAction;
    evh_7315092382398562_close_3041: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_3044: actions.CloseComponentAction;
    evh_2248226175642056_close_3043: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_3048: actions.CloseModalAction;
    evh_1040167445267876_success_3047: actions.EventHandlerImpl;
    act_1040167445267876_crud_3046: actions.CRUDAction;
    evh_1040167445267876_submit_3045: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_3050: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_3049: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3042 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3042_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3041 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3042,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3041],
      }
    );
    const act_2248226175642056_closeComponent_3044 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3043 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_3044,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3043],
      }
    );
    const act_1040167445267876_closeModal_3048 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_3048_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_3047 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_3048,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_crud_3046 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_3046_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_3047],
      }
    );
    const evh_1040167445267876_submit_3045 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_3046,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3045],
      }
    );
    const act_1419464017721962_closeComponent_3050 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_3049 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_3050,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_3049],
      }
    );
    return {
      act_7315092382398562_closeModal_3042,
      evh_7315092382398562_close_3041,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_3044,
      evh_2248226175642056_close_3043,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_3048,
      evh_1040167445267876_success_3047,
      act_1040167445267876_crud_3046,
      evh_1040167445267876_submit_3045,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_3050,
      evh_1419464017721962_clickPreventStop_3049,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_crud_3046,
    }
  }
}

Vue.component("ProductEditor", ProductEditor);

</script>