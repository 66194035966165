"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcFixedAssetWorkItemsPanel = exports.UcFixedAssetWasteBin = exports.UcFixedAssetRentalCard = exports.UcFixedAssetGeneral = exports.FieldsetFixedAssetWasteBin = exports.FieldsetFixedAssetGeneral = void 0;
var FieldsetFixedAssetGeneral_yaml_component_1 = require("./FieldsetFixedAssetGeneral.yaml?component");
exports.FieldsetFixedAssetGeneral = FieldsetFixedAssetGeneral_yaml_component_1.default;
var FieldsetFixedAssetWasteBin_yaml_component_1 = require("./FieldsetFixedAssetWasteBin.yaml?component");
exports.FieldsetFixedAssetWasteBin = FieldsetFixedAssetWasteBin_yaml_component_1.default;
var UcFixedAssetGeneral_yaml_component_1 = require("./UcFixedAssetGeneral.yaml?component");
exports.UcFixedAssetGeneral = UcFixedAssetGeneral_yaml_component_1.default;
var UcFixedAssetRentalCard_yaml_component_1 = require("./UcFixedAssetRentalCard.yaml?component");
exports.UcFixedAssetRentalCard = UcFixedAssetRentalCard_yaml_component_1.default;
var UcFixedAssetWasteBin_yaml_component_1 = require("./UcFixedAssetWasteBin.yaml?component");
exports.UcFixedAssetWasteBin = UcFixedAssetWasteBin_yaml_component_1.default;
var UcFixedAssetWorkItemsPanel_yaml_component_1 = require("./UcFixedAssetWorkItemsPanel.yaml?component");
exports.UcFixedAssetWorkItemsPanel = UcFixedAssetWorkItemsPanel_yaml_component_1.default;
