"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "dunningLetter",
    path: "/dunning-letters/:id",
    component: "DunningLetter"
});
var DunningLetter = (function (_super) {
    __extends(DunningLetter, _super);
    function DunningLetter() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "page",
        };
        _this.dataMembers = ['item', 'templates', 'emailTemplates', 'htmlTemplates', 'sendmail', 'printdoc', 'ux', 'b_7315092382398562_modalBindings', 'b_7495010493811270_style', 'b_6865942025371558_style', 'b_3273883411533724_options', 'b_2771751501651205_columns'];
        return _this;
    }
    DunningLetter.prototype.beforeCreate = function () {
    };
    DunningLetter.prototype.onId = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("id");
    };
    DunningLetter.prototype.onPayload = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payload");
    };
    DunningLetter.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    DunningLetter.prototype.$$load_item = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.payload || (this.id !== null
                        ? this.$fn.fetch('read_dunning_letter', { asResource: true, parameters: { id: this.id } })
                        : this.$fn.schemaDefaults('DunningLetter'))];
            });
        });
    };
    DunningLetter.prototype.$$load_templates = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_template')];
            });
        });
    };
    DunningLetter.prototype.$$load_emailTemplates = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, _.filter(this.templates, {
                        object_type: 'dunning_letter',
                        template_type: 'mjml'
                    })];
            });
        });
    };
    DunningLetter.prototype.$$load_htmlTemplates = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, _.filter(this.templates, {
                        object_type: 'dunning_letter',
                        template_type: 'html'
                    })];
            });
        });
    };
    DunningLetter.prototype.$$load_sendmail = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, {
                        email: _.isArray(this.item.customer_data.emails)
                            ? _.join(this.item.customer_data.emails, '; ')
                            : this.item.customer_data.emails,
                        template: _.get(_.first(this.emailTemplates), 'id')
                    }];
            });
        });
    };
    DunningLetter.prototype.$$load_printdoc = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, { template: _.get(_.first(this.htmlTemplates), 'id') }];
            });
        });
    };
    DunningLetter.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            templates: null,
            emailTemplates: null,
            htmlTemplates: null,
            sendmail: null,
            printdoc: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "xl",
            },
            b_7495010493811270_style: {
                minWidth: "250px",
            },
            b_6865942025371558_style: {
                minWidth: "250px",
            },
            b_3273883411533724_options: [{
                    value: null,
                    text: "-",
                },
                {
                    value: "sent",
                    text: this.$fn.pgettext("DunningLetterStatus", "Sent"),
                },
                {
                    value: "delivered",
                    text: this.$fn.pgettext("DunningLetterStatus", "Delivered"),
                },
                {
                    value: "error",
                    text: this.$fn.pgettext("DunningLetterStatus", "Error"),
                },
            ],
            b_2771751501651205_columns: [{
                    name: this.$fn.gettext("Invoice number"),
                    field: "invoice_number",
                },
                {
                    name: this.$fn.gettext("Completion date"),
                    field: "invoice_data.fulfillment_date",
                    textAlign: "right",
                    type: "date",
                },
                {
                    name: this.$fn.gettext("Due date"),
                    field: "invoice_data.due_date",
                    textAlign: "right",
                    type: "date",
                },
                {
                    name: this.$fn.gettext("Amount"),
                    field: "invoice_data.amount",
                    textAlign: "right",
                    type: "number",
                },
                {
                    name: this.$fn.gettext("Paid"),
                    field: "invoice_data.paid",
                    textAlign: "right",
                    type: "number",
                },
                {
                    name: this.$fn.gettext("Debt"),
                    field: "invoice_data.debt",
                    textAlign: "right",
                    type: "number",
                },
            ],
        });
    };
    Object.defineProperty(DunningLetter.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: ([this.item.snapshot('reference_number') || this.$fn.gettext('Dunning Letter')]),
                    icon: this.$config.dunningLetter.icon,
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    DunningLetter.prototype.act_7315092382398562_closeModal_4718_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    DunningLetter.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DunningLetter.prototype.evh_2248226175642056_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DunningLetter.prototype.evh_3943151158818434_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3943151158818434_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DunningLetter.prototype.act_8518026866742051_closeComponent_4726_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            close: true,
                        },
                    }];
            });
        });
    };
    DunningLetter.prototype.act_8518026866742051_reloadSlickgrid_4728_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "all",
                    }];
            });
        });
    };
    DunningLetter.prototype.act_8518026866742051_request_4724_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "delete_dunning_letter",
                        data: this.item,
                    }];
            });
        });
    };
    DunningLetter.prototype.act_8518026866742051_request_4724_getConfirm = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        message: this.$fn.gettext("Please confirm delete."),
                        btnOk: this.$fn.gettext("Delete"),
                    }];
            });
        });
    };
    DunningLetter.prototype.evh_8518026866742051_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8518026866742051_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DunningLetter.prototype.act_2492577004902623_script_4730_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.$refs.sendMailDropdown.hide(true);
                return [2];
            });
        });
    };
    DunningLetter.prototype.act_2492577004902623_script_4730_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_2492577004902623_script_4730_getActionArgs_value($event); },
                    }];
            });
        });
    };
    DunningLetter.prototype.act_2492577004902623_reloadSlickgrid_4734_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "all",
                    }];
            });
        });
    };
    DunningLetter.prototype.evh_2492577004902623_completed = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2492577004902623_completed.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DunningLetter.prototype.act_4810867692521497_script_4736_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.$refs.generatePdfDropdown.hide(true);
                return [2];
            });
        });
    };
    DunningLetter.prototype.act_4810867692521497_script_4736_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_4810867692521497_script_4736_getActionArgs_value($event); },
                    }];
            });
        });
    };
    DunningLetter.prototype.act_4810867692521497_reloadSlickgrid_4740_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "all",
                    }];
            });
        });
    };
    DunningLetter.prototype.evh_4810867692521497_completed = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4810867692521497_completed.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DunningLetter.prototype.act_7789867021953041_setData_4744_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item",
                        value: new this.$fn.Resource($event.data.response.data),
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    DunningLetter.prototype.evh_7789867021953041_success_4743_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data.response.data];
            });
        });
    };
    DunningLetter.prototype.act_7789867021953041_form_4746_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "dunningLetter",
                        reset: true,
                    }];
            });
        });
    };
    DunningLetter.prototype.act_7789867021953041_reloadSlickgrid_4748_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "all",
                    }];
            });
        });
    };
    DunningLetter.prototype.act_7789867021953041_request_4742_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "update_dunning_letter",
                        data: this.item,
                    }];
            });
        });
    };
    DunningLetter.prototype.evh_7789867021953041_submit = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7789867021953041_submit.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DunningLetter.prototype.evh_1548630417156826_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DunningLetter.prototype.getActions = function () {
        var act_7315092382398562_closeModal_4718 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4718_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_4717 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4718,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4717],
        });
        var act_2248226175642056_closeComponent_4720 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_2248226175642056_close_4719 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_4720,
            event: "close",
            displayName: "closeComponent",
        });
        var evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_4719],
        });
        var act_3943151158818434_reloadComponentData_4722 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reloadData",
            events: [],
        });
        var evh_3943151158818434_clickPreventStop_4721 = new core_1.actions.EventHandlerImpl({
            action: act_3943151158818434_reloadComponentData_4722,
            event: "click.prevent.stop",
            displayName: "reloadData",
        });
        var evh_3943151158818434_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3943151158818434_clickPreventStop_4721],
        });
        var act_8518026866742051_closeComponent_4726 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_8518026866742051_closeComponent_4726_getActionArgs,
            events: [],
        });
        var evh_8518026866742051_success_4725 = new core_1.actions.EventHandlerImpl({
            action: act_8518026866742051_closeComponent_4726,
            event: "success",
            displayName: "closeComponent",
        });
        var act_8518026866742051_reloadSlickgrid_4728 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8518026866742051_reloadSlickgrid_4728_getActionArgs,
            events: [],
        });
        var evh_8518026866742051_success_4727 = new core_1.actions.EventHandlerImpl({
            action: act_8518026866742051_reloadSlickgrid_4728,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        var act_8518026866742051_request_4724 = new core_1.actions.RequestAction({
            actionArgs: this.act_8518026866742051_request_4724_getActionArgs,
            confirm: this.act_8518026866742051_request_4724_getConfirm,
            displayName: "delete",
            events: [evh_8518026866742051_success_4725, evh_8518026866742051_success_4727],
        });
        var evh_8518026866742051_clickPreventStop_4723 = new core_1.actions.EventHandlerImpl({
            action: act_8518026866742051_request_4724,
            event: "click.prevent.stop",
            displayName: "delete",
        });
        var evh_8518026866742051_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8518026866742051_clickPreventStop_4723],
        });
        var act_2492577004902623_script_4730 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2492577004902623_script_4730_getActionArgs,
            events: [],
        });
        var evh_2492577004902623_completed_4729 = new core_1.actions.EventHandlerImpl({
            action: act_2492577004902623_script_4730,
            event: "completed",
            displayName: "script",
        });
        var act_2492577004902623_reloadComponentData_4732 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_2492577004902623_completed_4731 = new core_1.actions.EventHandlerImpl({
            action: act_2492577004902623_reloadComponentData_4732,
            event: "completed",
            displayName: "reloadComponentData",
        });
        var act_2492577004902623_reloadSlickgrid_4734 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2492577004902623_reloadSlickgrid_4734_getActionArgs,
            events: [],
        });
        var evh_2492577004902623_completed_4733 = new core_1.actions.EventHandlerImpl({
            action: act_2492577004902623_reloadSlickgrid_4734,
            event: "completed",
            displayName: "reloadSlickgrid",
        });
        var evh_2492577004902623_completed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2492577004902623_completed_4729, evh_2492577004902623_completed_4731, evh_2492577004902623_completed_4733],
        });
        var act_4810867692521497_script_4736 = new core_1.actions.ScriptAction({
            actionArgs: this.act_4810867692521497_script_4736_getActionArgs,
            events: [],
        });
        var evh_4810867692521497_completed_4735 = new core_1.actions.EventHandlerImpl({
            action: act_4810867692521497_script_4736,
            event: "completed",
            displayName: "script",
        });
        var act_4810867692521497_reloadComponentData_4738 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_4810867692521497_completed_4737 = new core_1.actions.EventHandlerImpl({
            action: act_4810867692521497_reloadComponentData_4738,
            event: "completed",
            displayName: "reloadComponentData",
        });
        var act_4810867692521497_reloadSlickgrid_4740 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_4810867692521497_reloadSlickgrid_4740_getActionArgs,
            events: [],
        });
        var evh_4810867692521497_completed_4739 = new core_1.actions.EventHandlerImpl({
            action: act_4810867692521497_reloadSlickgrid_4740,
            event: "completed",
            displayName: "reloadSlickgrid",
        });
        var evh_4810867692521497_completed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4810867692521497_completed_4735, evh_4810867692521497_completed_4737, evh_4810867692521497_completed_4739],
        });
        var act_7789867021953041_setData_4744 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_4744_getActionArgs,
            events: [],
        });
        var evh_7789867021953041_success_4743 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_4744,
            event: "success",
            when: this.evh_7789867021953041_success_4743_getWhen,
            displayName: "setData",
        });
        var act_7789867021953041_form_4746 = new core_1.actions.FormAction({
            actionArgs: this.act_7789867021953041_form_4746_getActionArgs,
            events: [],
        });
        var evh_7789867021953041_success_4745 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_form_4746,
            event: "success",
            displayName: "form",
        });
        var act_7789867021953041_reloadSlickgrid_4748 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_7789867021953041_reloadSlickgrid_4748_getActionArgs,
            events: [],
        });
        var evh_7789867021953041_success_4747 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_reloadSlickgrid_4748,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        var act_7789867021953041_request_4742 = new core_1.actions.RequestAction({
            actionArgs: this.act_7789867021953041_request_4742_getActionArgs,
            displayName: "save",
            events: [evh_7789867021953041_success_4743, evh_7789867021953041_success_4745, evh_7789867021953041_success_4747],
        });
        var evh_7789867021953041_submit_4741 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_request_4742,
            event: "submit",
            displayName: "save",
        });
        var evh_7789867021953041_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_submit_4741],
        });
        var act_1548630417156826_closeComponent_4750 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_1548630417156826_clickPreventStop_4749 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_4750,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        var evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_4749],
        });
        return {
            act_7315092382398562_closeModal_4718: act_7315092382398562_closeModal_4718,
            evh_7315092382398562_close_4717: evh_7315092382398562_close_4717,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_closeComponent_4720: act_2248226175642056_closeComponent_4720,
            evh_2248226175642056_close_4719: evh_2248226175642056_close_4719,
            evh_2248226175642056_close: evh_2248226175642056_close,
            act_3943151158818434_reloadComponentData_4722: act_3943151158818434_reloadComponentData_4722,
            evh_3943151158818434_clickPreventStop_4721: evh_3943151158818434_clickPreventStop_4721,
            evh_3943151158818434_clickPreventStop: evh_3943151158818434_clickPreventStop,
            act_8518026866742051_closeComponent_4726: act_8518026866742051_closeComponent_4726,
            evh_8518026866742051_success_4725: evh_8518026866742051_success_4725,
            act_8518026866742051_reloadSlickgrid_4728: act_8518026866742051_reloadSlickgrid_4728,
            evh_8518026866742051_success_4727: evh_8518026866742051_success_4727,
            act_8518026866742051_request_4724: act_8518026866742051_request_4724,
            evh_8518026866742051_clickPreventStop_4723: evh_8518026866742051_clickPreventStop_4723,
            evh_8518026866742051_clickPreventStop: evh_8518026866742051_clickPreventStop,
            act_2492577004902623_script_4730: act_2492577004902623_script_4730,
            evh_2492577004902623_completed_4729: evh_2492577004902623_completed_4729,
            act_2492577004902623_reloadComponentData_4732: act_2492577004902623_reloadComponentData_4732,
            evh_2492577004902623_completed_4731: evh_2492577004902623_completed_4731,
            act_2492577004902623_reloadSlickgrid_4734: act_2492577004902623_reloadSlickgrid_4734,
            evh_2492577004902623_completed_4733: evh_2492577004902623_completed_4733,
            evh_2492577004902623_completed: evh_2492577004902623_completed,
            act_4810867692521497_script_4736: act_4810867692521497_script_4736,
            evh_4810867692521497_completed_4735: evh_4810867692521497_completed_4735,
            act_4810867692521497_reloadComponentData_4738: act_4810867692521497_reloadComponentData_4738,
            evh_4810867692521497_completed_4737: evh_4810867692521497_completed_4737,
            act_4810867692521497_reloadSlickgrid_4740: act_4810867692521497_reloadSlickgrid_4740,
            evh_4810867692521497_completed_4739: evh_4810867692521497_completed_4739,
            evh_4810867692521497_completed: evh_4810867692521497_completed,
            act_7789867021953041_setData_4744: act_7789867021953041_setData_4744,
            evh_7789867021953041_success_4743: evh_7789867021953041_success_4743,
            act_7789867021953041_form_4746: act_7789867021953041_form_4746,
            evh_7789867021953041_success_4745: evh_7789867021953041_success_4745,
            act_7789867021953041_reloadSlickgrid_4748: act_7789867021953041_reloadSlickgrid_4748,
            evh_7789867021953041_success_4747: evh_7789867021953041_success_4747,
            act_7789867021953041_request_4742: act_7789867021953041_request_4742,
            evh_7789867021953041_submit_4741: evh_7789867021953041_submit_4741,
            evh_7789867021953041_submit: evh_7789867021953041_submit,
            act_1548630417156826_closeComponent_4750: act_1548630417156826_closeComponent_4750,
            evh_1548630417156826_clickPreventStop_4749: evh_1548630417156826_clickPreventStop_4749,
            evh_1548630417156826_clickPreventStop: evh_1548630417156826_clickPreventStop,
            reloadData: act_3943151158818434_reloadComponentData_4722,
            delete: act_8518026866742051_request_4724,
            save: act_7789867021953041_request_4742,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: String,
            default: null,
        }),
        __metadata("design:type", String)
    ], DunningLetter.prototype, "id", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('id'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], DunningLetter.prototype, "onId", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Object,
        }),
        __metadata("design:type", Object)
    ], DunningLetter.prototype, "payload", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('payload'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], DunningLetter.prototype, "onPayload", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "xl",
        }),
        __metadata("design:type", String)
    ], DunningLetter.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], DunningLetter.prototype, "onSize", null);
    DunningLetter = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], DunningLetter);
    return DunningLetter;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = DunningLetter;
vue_property_decorator_1.Vue.component("DunningLetter", DunningLetter);
