"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcSuggestInvoiceOption = exports.UcInvoiceTypeBadge = exports.UcInvoiceStatusBadge = exports.UcInvoiceMiniCard = exports.UcInvoiceKindBadge = void 0;
var UcInvoiceKindBadge_yaml_component_1 = require("./UcInvoiceKindBadge.yaml?component");
exports.UcInvoiceKindBadge = UcInvoiceKindBadge_yaml_component_1.default;
var UcInvoiceMiniCard_yaml_component_1 = require("./UcInvoiceMiniCard.yaml?component");
exports.UcInvoiceMiniCard = UcInvoiceMiniCard_yaml_component_1.default;
var UcInvoiceStatusBadge_yaml_component_1 = require("./UcInvoiceStatusBadge.yaml?component");
exports.UcInvoiceStatusBadge = UcInvoiceStatusBadge_yaml_component_1.default;
var UcInvoiceTypeBadge_yaml_component_1 = require("./UcInvoiceTypeBadge.yaml?component");
exports.UcInvoiceTypeBadge = UcInvoiceTypeBadge_yaml_component_1.default;
var UcSuggestInvoiceOption_yaml_component_1 = require("./UcSuggestInvoiceOption.yaml?component");
exports.UcSuggestInvoiceOption = UcSuggestInvoiceOption_yaml_component_1.default;
