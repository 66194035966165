<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Address"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="address"
      v-if="loaded"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetAddress
        :address="editor"
        :addressTypes="addressTypes"
      >
      </FieldsetAddress>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      type="submit"
      :text="'Save'|gettext"
      :form="forms.address"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Address extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  parent!: object;

  @Watch('parent')
  onParent(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parent")
  }

  @Prop({
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  resource!: any;

  async $$load_resource() {
    return this.item || this.$fn.schemaDefaults('Create' + _.upperFirst(this.parent.object) + 'AddressCommand')
  }

  editor!: any;

  async $$load_editor() {
    return this.resource.editor()
  }

  addressTypes!: any;

  async $$load_addressTypes() {
    return this.$fn.getEnumValuesFromSchema('AddressType')
  }

  createTitle!: any;
  editTitle!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['resource', 'editor', 'addressTypes', 'createTitle', 'editTitle', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        resource: null,
        editor: null,
        addressTypes: null,
        createTitle: this.$fn.pgettext("Addresses", "Add address"),
        editTitle: this.$fn.pgettext("Addresses", "Edit address"),
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.resource.id == null ? this.createTitle : this.editTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1348_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1348, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_1354_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_1354, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_closeComponent_1356_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_1356, alias=undefined
    return {
      result: {
        ok: true,
        value: "deleted",
      }
      ,
    }
  }

  async act_1040167445267876_rest_1352_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_1352, alias=submit
    return {
      method: "save",
      container: this.parent.addresses,
      resource: this.editor,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1348: actions.CloseModalAction;
    evh_7315092382398562_close_1347: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_1350: actions.CloseComponentAction;
    evh_2248226175642056_close_1349: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_1354: actions.CloseComponentAction;
    evh_1040167445267876_saved_1353: actions.EventHandlerImpl;
    act_1040167445267876_closeComponent_1356: actions.CloseComponentAction;
    evh_1040167445267876_deleted_1355: actions.EventHandlerImpl;
    act_1040167445267876_rest_1352: actions.RestAction;
    evh_1040167445267876_submit_1351: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_1358: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_1357: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1348 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1348_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1347 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1348,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1347],
      }
    );
    const act_2248226175642056_closeComponent_1350 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1349 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_1350,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1349],
      }
    );
    const act_1040167445267876_closeComponent_1354 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_1354_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_1353 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_1354,
        event: "saved",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_closeComponent_1356 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_1356_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_deleted_1355 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_1356,
        event: "deleted",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_rest_1352 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_1352_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_saved_1353, evh_1040167445267876_deleted_1355],
      }
    );
    const evh_1040167445267876_submit_1351 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_1352,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1351],
      }
    );
    const act_1419464017721962_closeComponent_1358 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1357 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_1358,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1357],
      }
    );
    return {
      act_7315092382398562_closeModal_1348,
      evh_7315092382398562_close_1347,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_1350,
      evh_2248226175642056_close_1349,
      evh_2248226175642056_close,
      act_1040167445267876_closeComponent_1354,
      evh_1040167445267876_saved_1353,
      act_1040167445267876_closeComponent_1356,
      evh_1040167445267876_deleted_1355,
      act_1040167445267876_rest_1352,
      evh_1040167445267876_submit_1351,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_1358,
      evh_1419464017721962_clickPreventStop_1357,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_rest_1352,
    }
  }
}

Vue.component("Address", Address);

</script>