<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    direction="column"
  >
    <ItpBox
      alignItems="center"
    >
      <ItpLink
        :to="{
  name: 'app.account',
  params: { id: payload.id }
}"
        class="d-inline-block"
      >
        {{ payload.name }}

      </ItpLink>
    </ItpBox>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcCompactAccountDisplay extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  payload!: object;

  @Watch('payload')
  onPayload(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payload")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4260: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4259: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4260 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4259 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4260,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4259],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4260,
      evh_7315092382398562_reload_4259,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcCompactAccountDisplay", UcCompactAccountDisplay);

</script>