"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var DateRangeSelector = (function (_super) {
    __extends(DateRangeSelector, _super);
    function DateRangeSelector() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['item', 'mode', 'options', 'ux'];
        return _this;
    }
    DateRangeSelector.prototype.beforeCreate = function () {
        this.$$cache_mode = new core_1.ComponentValueCache(this, "mode", "{{ name + '.mode' }}");
    };
    DateRangeSelector.prototype.getCurrentWeek = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var t0 = new Date();
        return [
            this.$fn.dateFns.startOfWeek(t0, { weekStartsOn: 1 }),
            null
        ];
    };
    DateRangeSelector.prototype.getCurrentMonth = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var t0 = new Date();
        return [
            this.$fn.dateFns.startOfMonth(t0),
            null
        ];
    };
    DateRangeSelector.prototype.getCurrentYear = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var t0 = new Date();
        return [
            this.$fn.dateFns.startOfYear(t0),
            null
        ];
    };
    DateRangeSelector.prototype.getPreviousWeek = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var prev_monday = this.$fn.dateFns.previousMonday(this.$fn.dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }));
        return [
            prev_monday,
            this.$fn.dateFns.endOfWeek(prev_monday)
        ];
    };
    DateRangeSelector.prototype.getPreviousMonth = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var start = this.$fn.dateFns.sub(this.getCurrentMonth()[0], { months: 1 });
        return [
            start,
            this.$fn.dateFns.endOfMonth(start),
        ];
    };
    DateRangeSelector.prototype.getPreviousYear = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var start = this.$fn.dateFns.sub(this.getCurrentYear()[0], { years: 1 });
        return [
            start,
            this.$fn.dateFns.endOfYear(start),
        ];
    };
    DateRangeSelector.prototype.getPreviousSevenDays = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var t0 = new Date();
        return [
            this.$fn.dateFns.sub(t0, { days: 7 }),
            null,
        ];
    };
    DateRangeSelector.prototype.getPreviousThirtyDays = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var t0 = new Date();
        return [
            this.$fn.dateFns.sub(t0, { days: 30 }),
            null,
        ];
    };
    DateRangeSelector.prototype.getPreviousSixtyDays = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var t0 = new Date();
        return [
            this.$fn.dateFns.sub(t0, { days: 60 }),
            null,
        ];
    };
    DateRangeSelector.prototype.onName = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("name");
    };
    DateRangeSelector.prototype.onFrom = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("from");
    };
    DateRangeSelector.prototype.onTo = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("to");
    };
    DateRangeSelector.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    DateRangeSelector.prototype.$$load_item = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, { start: this.from, end: this.to }];
            });
        });
    };
    DateRangeSelector.prototype.$$load_options = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, [{
                            key: "custom",
                            name: this.$fn.pgettext("DateRangeSelector", "Custom"),
                        },
                        {
                            key: "current_week",
                            name: this.$fn.pgettext("DateRangeSelector", "Current week"),
                            value: this.getCurrentWeek(),
                        },
                        {
                            key: "current_month",
                            name: this.$fn.pgettext("DateRangeSelector", "Current month"),
                            value: this.getCurrentMonth(),
                        },
                        {
                            key: "current_year",
                            name: this.$fn.pgettext("DateRangeSelector", "Current year"),
                            value: this.getCurrentYear(),
                        },
                        {
                            key: "previous_week",
                            name: this.$fn.pgettext("DateRangeSelector", "Previous week"),
                            value: this.getPreviousWeek(),
                        },
                        {
                            key: "previous_month",
                            name: this.$fn.pgettext("DateRangeSelector", "Previous month"),
                            value: this.getPreviousMonth(),
                        },
                        {
                            key: "previous_year",
                            name: this.$fn.pgettext("DateRangeSelector", "Previous year"),
                            value: this.getPreviousYear(),
                        },
                        {
                            key: "previous_seven_days",
                            name: this.$fn.pgettext("DateRangeSelector", "Previous seven days"),
                            value: this.getPreviousSevenDays(),
                        },
                        {
                            key: "previous_thirty_days",
                            name: this.$fn.pgettext("DateRangeSelector", "Previous thirty days"),
                            value: this.getPreviousThirtyDays(),
                        },
                        {
                            key: "previous_60_days",
                            name: this.$fn.pgettext("DateRangeSelector", "Previous sixty days"),
                            value: this.getPreviousSixtyDays(),
                        },
                    ]];
            });
        });
    };
    DateRangeSelector.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            mode: "custom",
            options: null,
            ux: null,
        });
    };
    DateRangeSelector.prototype.evh_7315092382398562_reload = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DateRangeSelector.prototype.act_6466705138206980_setData_1768_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.start",
                        value: $event.data.option.value
                            ? ($event.data.option.value[0] ? this.$fn.formatDate($event.data.option.value[0], 'yyyy-MM-dd') : null)
                            : this.item.start,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    DateRangeSelector.prototype.act_6466705138206980_setData_1770_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.end",
                        value: $event.data.option.value
                            ? ($event.data.option.value[1] ? this.$fn.formatDate($event.data.option.value[1], 'yyyy-MM-dd') : null)
                            : this.item.end,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    DateRangeSelector.prototype.evh_6466705138206980_optionSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_6466705138206980_optionSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DateRangeSelector.prototype.act_3943151158818434_emit_1772_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "changed",
                        value: [this.item.start, this.item.end],
                    }];
            });
        });
    };
    DateRangeSelector.prototype.evh_3943151158818434_input = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3943151158818434_input.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DateRangeSelector.prototype.act_4207795475682514_emit_1774_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "changed",
                        value: [this.item.start, this.item.end],
                    }];
            });
        });
    };
    DateRangeSelector.prototype.evh_4207795475682514_input = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4207795475682514_input.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    DateRangeSelector.prototype.getActions = function () {
        var act_7315092382398562_reloadComponentData_1766 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_7315092382398562_reload_1765 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_1766,
            event: "reload",
            displayName: "reloadComponentData",
        });
        var evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_1765],
        });
        var act_6466705138206980_setData_1768 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_1768_getActionArgs,
            events: [],
        });
        var evh_6466705138206980_optionSelected_1767 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_1768,
            event: "optionSelected",
            displayName: "setData",
        });
        var act_6466705138206980_setData_1770 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_1770_getActionArgs,
            events: [],
        });
        var evh_6466705138206980_optionSelected_1769 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_1770,
            event: "optionSelected",
            displayName: "setData",
        });
        var evh_6466705138206980_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_optionSelected_1767, evh_6466705138206980_optionSelected_1769],
        });
        var act_3943151158818434_emit_1772 = new core_1.actions.EmitAction({
            actionArgs: this.act_3943151158818434_emit_1772_getActionArgs,
            events: [],
        });
        var evh_3943151158818434_input_1771 = new core_1.actions.EventHandlerImpl({
            action: act_3943151158818434_emit_1772,
            event: "input",
            displayName: "emit",
        });
        var evh_3943151158818434_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3943151158818434_input_1771],
        });
        var act_4207795475682514_emit_1774 = new core_1.actions.EmitAction({
            actionArgs: this.act_4207795475682514_emit_1774_getActionArgs,
            events: [],
        });
        var evh_4207795475682514_input_1773 = new core_1.actions.EventHandlerImpl({
            action: act_4207795475682514_emit_1774,
            event: "input",
            displayName: "emit",
        });
        var evh_4207795475682514_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4207795475682514_input_1773],
        });
        return {
            act_7315092382398562_reloadComponentData_1766: act_7315092382398562_reloadComponentData_1766,
            evh_7315092382398562_reload_1765: evh_7315092382398562_reload_1765,
            evh_7315092382398562_reload: evh_7315092382398562_reload,
            act_6466705138206980_setData_1768: act_6466705138206980_setData_1768,
            evh_6466705138206980_optionSelected_1767: evh_6466705138206980_optionSelected_1767,
            act_6466705138206980_setData_1770: act_6466705138206980_setData_1770,
            evh_6466705138206980_optionSelected_1769: evh_6466705138206980_optionSelected_1769,
            evh_6466705138206980_optionSelected: evh_6466705138206980_optionSelected,
            act_3943151158818434_emit_1772: act_3943151158818434_emit_1772,
            evh_3943151158818434_input_1771: evh_3943151158818434_input_1771,
            evh_3943151158818434_input: evh_3943151158818434_input,
            act_4207795475682514_emit_1774: act_4207795475682514_emit_1774,
            evh_4207795475682514_input_1773: evh_4207795475682514_input_1773,
            evh_4207795475682514_input: evh_4207795475682514_input,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: String,
        }),
        __metadata("design:type", String)
    ], DateRangeSelector.prototype, "name", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('name'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], DateRangeSelector.prototype, "onName", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], DateRangeSelector.prototype, "from", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('from'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], DateRangeSelector.prototype, "onFrom", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], DateRangeSelector.prototype, "to", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('to'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], DateRangeSelector.prototype, "onTo", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], DateRangeSelector.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], DateRangeSelector.prototype, "onSize", null);
    DateRangeSelector = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], DateRangeSelector);
    return DateRangeSelector;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = DateRangeSelector;
vue_property_decorator_1.Vue.component("DateRangeSelector", DateRangeSelector);
