var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpRow",
        [
          _c(
            "ItpCol",
            { attrs: { w: "12" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "2",
                    contentColsMd: "5",
                    name: "account",
                    label: _vm._f("pgettext")("Account", "Payment"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormSelect2", {
                    attrs: {
                      name: "account",
                      valueField: "id",
                      textField: "name",
                      "bind-object": "",
                      options: _vm.accounts,
                      readonly: _vm.isReadOnly,
                      optionTemplate:
                        "<UcAccountSelectOption :payload=option></UcAccountSelectOption>",
                      "use-option-template-for-selected-option": ""
                    },
                    on: {
                      optionSelected: function($event) {
                        return _vm.evh_4615685607825208_optionSelected(
                          $event,
                          {}
                        )
                      }
                    },
                    model: {
                      value: _vm.payment.account,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "account", $$v)
                      },
                      expression: "payment.account"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "2",
                    contentColsMd: "10",
                    name: "payment_category",
                    label: _vm._f("pgettext")("Title", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormSelect2", {
                    attrs: {
                      name: "payment_category",
                      valueField: "name",
                      textField: "name",
                      options: _vm.categories,
                      taggable: "",
                      clearable: "",
                      readonly: _vm.isReadOnly
                    },
                    model: {
                      value: _vm.payment.payment_category,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "payment_category", $$v)
                      },
                      expression: "payment.payment_category"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "2",
                    contentColsMd: "10",
                    name: "client",
                    label: _vm._f("pgettext")("Client", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormSelect2", {
                    attrs: {
                      name: "client",
                      "bind-object": "",
                      dataSource: _vm.b_8697745105058001_dataSource,
                      optionTemplate:
                        "<UcSuggestClientOption :option=option></UcSuggestClientOption>",
                      "use-option-template-for-selected-option": "",
                      textField: "name",
                      clearable: "",
                      readonly: _vm.isReadOnly
                    },
                    model: {
                      value: _vm.payment.client,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "client", $$v)
                      },
                      expression: "payment.client"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("HR"),
      _vm._v(" "),
      _c(
        "ItpRow",
        [
          _c(
            "ItpCol",
            { attrs: { w: "5", form: "" } },
            [
              _vm.payment.account.account_type !== "cash_book"
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "5",
                        contentColsMd: "7",
                        name: "payment_number",
                        label: _vm._f("pgettext")("Number", "Payment"),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          autofocus: "",
                          name: "payment_number",
                          readonly: _vm.isReadOnly,
                          placeholder: _vm._f("pgettext")(
                            "Enter payment number",
                            "Payment/payment_number/placeholder"
                          ),
                          required: ""
                        },
                        model: {
                          value: _vm.payment.payment_number,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "payment_number", $$v)
                          },
                          expression: "payment.payment_number"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "5",
                    contentColsMd: "7",
                    name: "payment_date",
                    label: _vm._f("pgettext")("Payment Date", "Payment"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormDatetime", {
                    attrs: {
                      name: "payment_date",
                      readonly: _vm.isReadOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.payment.payment_date,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "payment_date", $$v)
                      },
                      expression: "payment.payment_date"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "5",
                    contentColsMd: "7",
                    name: "payment_method",
                    label: _vm._f("pgettext")("Method", "Payment"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormSelect", {
                    attrs: {
                      name: "payment_method",
                      options: _vm.paymentMethods,
                      readonly: _vm.isReadOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.payment.payment_method,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "payment_method", $$v)
                      },
                      expression: "payment.payment_method"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "5",
                    contentColsMd: "7",
                    name: "payment_type",
                    label: _vm._f("pgettext")("Payment type", "Payment"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormSelect", {
                    attrs: {
                      name: "payment_type",
                      options: _vm.cashBookPayment
                        ? _vm.cashPaymentTypes
                        : _vm.paymentTypes,
                      readonly: _vm.isReadOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.payment.payment_type,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "payment_type", $$v)
                      },
                      expression: "payment.payment_type"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "5",
                    contentColsMd: "7",
                    name: "amount",
                    label: _vm._f("pgettext")("Amount", "Payment"),
                    labelAlign: _vm.labelAlign,
                    appendText: _vm.payment.currency,
                    required: ""
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: {
                      name: "amount",
                      readonly: _vm.isReadOnly,
                      step: "any",
                      required: "",
                      min: "0",
                      mask: "number",
                      scale: "2"
                    },
                    model: {
                      value: _vm.payment.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "amount", $$v)
                      },
                      expression: "payment.amount"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "5",
                    contentColsMd: "5",
                    name: "exchange_rate",
                    label: _vm._f("pgettext")("Exchange Rate", "Payment"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: {
                      type: "number",
                      name: "exchange_rate",
                      readonly: _vm.isReadOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.payment.exchange_rate,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "exchange_rate", $$v)
                      },
                      expression: "payment.exchange_rate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpCol",
            { attrs: { w: "7" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "description",
                    label: _vm._f("pgettext")("Description", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "description", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.payment.description,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "description", $$v)
                      },
                      expression: "payment.description"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "reference_number",
                    label: _vm._f("pgettext")("Reference Number", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: {
                      name: "reference_number",
                      readonly: _vm.isReadOnly
                    },
                    model: {
                      value: _vm.payment.reference_number,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "reference_number", $$v)
                      },
                      expression: "payment.reference_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "file_number",
                    label: _vm._f("pgettext")("File Number", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "file_number", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.payment.file_number,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "file_number", $$v)
                      },
                      expression: "payment.file_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "notes",
                    label: _vm._f("pgettext")("Notes", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormTextarea", {
                    attrs: { name: "notes", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.payment.notes,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "notes", $$v)
                      },
                      expression: "payment.notes"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "internal_notes",
                    label: _vm._f("pgettext")("Internal Notes", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormTextarea", {
                    attrs: { name: "internal_notes", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.payment.internal_notes,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "internal_notes", $$v)
                      },
                      expression: "payment.internal_notes"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }