var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("pgettext")("Protocols and events", "Contract")
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "ml-auto",
                      attrs: {
                        size: "sm",
                        "toggle-class": "btn-light border",
                        text: _vm._f("pgettext")("New event", "Contract")
                      }
                    },
                    [
                      _c(
                        "b-dropdown-item-button",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.evh_4440454779651433_clickPreventStop(
                                $event,
                                {}
                              )
                            }
                          }
                        },
                        [
                          _c("ItpText", {
                            attrs: {
                              text: _vm._f("pgettext")(
                                "New handover protocol (moving in)...",
                                "Contract"
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item-button",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.evh_8643180786190723_clickPreventStop(
                                $event,
                                {}
                              )
                            }
                          }
                        },
                        [
                          _c("ItpText", {
                            attrs: {
                              text: _vm._f("pgettext")(
                                "New handover protocol (moving out)...",
                                "Contract"
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              !_vm.items || !_vm.items.length
                ? _c(
                    "BListGroupItem",
                    [
                      _c("ItpText", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "No events have been added yet.",
                                "Contract"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.items, function(item) {
                return _c(
                  "BListGroupItem",
                  { key: item.id },
                  [
                    _c(
                      "ItpBox",
                      { attrs: { alignItems: "center" } },
                      [
                        _c(
                          "DIV",
                          { staticClass: "pr-3" },
                          [
                            _c("ItpIcon", {
                              staticClass: "align-middle",
                              attrs: { name: "fluent:calendar-12-regular" }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "align-middle" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm._f("date")(item.handover_date)) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("ItpText", {
                          staticClass: "font-weight-bold px-3",
                          attrs: {
                            text: _vm._f("pgettext")(
                              item.handover_type,
                              "HandoverType",
                              "Sentence"
                            ),
                            strikethrough: item.protocol_status == "cancelled"
                          }
                        }),
                        _vm._v(" "),
                        _c("UcStatusBadge", {
                          staticClass: "px-3",
                          attrs: { status: item.protocol_status }
                        }),
                        _vm._v(" "),
                        item.locked
                          ? _c("ItpIcon", {
                              staticClass: "px-3",
                              attrs: {
                                title: _vm._f("gettext")("Locked"),
                                name: "fa-lock"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-dropdown",
                          {
                            staticClass: "ml-auto mb-auto",
                            attrs: {
                              size: "sm",
                              "toggle-class": "btn-default-borderless",
                              "no-caret": ""
                            }
                          },
                          [
                            _c("ItpIcon", {
                              staticClass: "mx-1",
                              attrs: {
                                slot: "button-content",
                                fa: "ellipsis-v"
                              },
                              slot: "button-content"
                            }),
                            _vm._v(" "),
                            _c(
                              "b-dropdown-item-button",
                              {
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.evh_2002045350107722_clickPreventStop(
                                      $event,
                                      { item: item }
                                    )
                                  }
                                }
                              },
                              [
                                _c("ItpText", {
                                  attrs: {
                                    text: _vm._f("pgettext")("Edit", "Command")
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    item.protocol_status == "completed" && item.meter_readings
                      ? _c(
                          "table",
                          {
                            staticClass:
                              "table table-condensed mt-2 mb-0 pb-0 mx-2"
                          },
                          _vm._l(item.meter_readings, function(reading) {
                            return _c("tr", { key: reading.serial_number }, [
                              _c(
                                "td",
                                [
                                  _c("UcMeterMiniCard", {
                                    attrs: { item: reading, "no-strong": "" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("number")(reading.reading_value)
                                    ) +
                                    " " +
                                    _vm._s(reading.reading_unit) +
                                    "\n\n            "
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }