"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcClientBankAccounts = exports.UcClientBankAccountFieldset = exports.UcClientBankAccountCard = exports.ClientBankAccount = void 0;
var ClientBankAccount_yaml_component_1 = require("./ClientBankAccount.yaml?component");
exports.ClientBankAccount = ClientBankAccount_yaml_component_1.default;
var UcClientBankAccountCard_yaml_component_1 = require("./UcClientBankAccountCard.yaml?component");
exports.UcClientBankAccountCard = UcClientBankAccountCard_yaml_component_1.default;
var UcClientBankAccountFieldset_yaml_component_1 = require("./UcClientBankAccountFieldset.yaml?component");
exports.UcClientBankAccountFieldset = UcClientBankAccountFieldset_yaml_component_1.default;
var UcClientBankAccounts_yaml_component_1 = require("./UcClientBankAccounts.yaml?component");
exports.UcClientBankAccounts = UcClientBankAccounts_yaml_component_1.default;
