"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var UcInvoiceHeader = (function (_super) {
    __extends(UcInvoiceHeader, _super);
    function UcInvoiceHeader() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['messages', 'ux'];
        return _this;
    }
    UcInvoiceHeader.prototype.beforeCreate = function () {
    };
    UcInvoiceHeader.prototype.onInvoice = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    };
    UcInvoiceHeader.prototype.onForm_id = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formId");
    };
    UcInvoiceHeader.prototype.onInvoice_number = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoiceNumber");
    };
    UcInvoiceHeader.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                newTransaction: this.$fn.pgettext("Invoice", "New Invoice"),
                invoiceKind: {
                    incoming: this.$fn.pgettext("InvoiceKind", "Incoming Invoice"),
                    outgoing: this.$fn.pgettext("InvoiceKind", "Outgoing Invoice"),
                },
                createCorrectorTooltip: this.$fn.pgettext("Invoice", "Create corrector invoice"),
                createStornoTooltip: this.$fn.pgettext("Invoice", "Create storno invoice"),
            },
            ux: null,
        });
    };
    Object.defineProperty(UcInvoiceHeader.prototype, "resourceTitle", {
        get: function () {
            if (this.loaded) {
                return this.messages.invoiceKind[this.invoice.invoice_kind];
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UcInvoiceHeader.prototype, "resourceKey", {
        get: function () {
            if (this.loaded) {
                return this.invoiceNumber || (this.invoice.isNew ? this.messages.newTransaction : this.invoice.invoice_number);
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UcInvoiceHeader.prototype, "createCorrectorTooltip", {
        get: function () {
            if (this.loaded) {
                return _.get(this.invoice, 'meta.modifiable.message') || this.messages.createCorrectorTooltip;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UcInvoiceHeader.prototype, "createStornoTooltip", {
        get: function () {
            if (this.loaded) {
                return _.get(this.invoice, 'meta.reversible.message') || this.messages.createStornoTooltip;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UcInvoiceHeader.prototype, "invoiceIsReversible", {
        get: function () {
            if (this.loaded) {
                return _.get(this.invoice, 'meta.reversible.result');
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UcInvoiceHeader.prototype, "invoiceIsModifiable", {
        get: function () {
            if (this.loaded) {
                return _.get(this.invoice, 'meta.modifiable.result');
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    UcInvoiceHeader.prototype.act_8680084583475136_emit_4544_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_8680084583475136_success = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8680084583475136_success.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_3282321863127091_emit_4546_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_3282321863127091_success = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3282321863127091_success.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_2386378572842701_emit_4550_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_2386378572842701_close_4549_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.modalResult.ok];
            });
        });
    };
    UcInvoiceHeader.prototype.act_2386378572842701_showModal_4548_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "InvoiceEditor",
                        props: {
                            invoice: _.cloneDeep(this.invoice),
                        },
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_2386378572842701_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2386378572842701_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_6305342467955186_emit_4552_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_6305342467955186_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_6305342467955186_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_6493473528837603_showModal_4554_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "InvoiceLogMessageViewer",
                        props: {
                            invoiceId: this.invoice.id,
                        },
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_6493473528837603_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_6493473528837603_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_5796712150329752_emit_4558_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "created",
                        value: $event.data.response.data,
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.act_5796712150329752_request_4556_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "create_invoice",
                        data: { invoice_id: this.invoice.id, invoice_type: 'copy' },
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_5796712150329752_click = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5796712150329752_click.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_4125470329773403_emit_4562_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "created",
                        value: $event.data.response.data,
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.act_4125470329773403_request_4560_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "create_invoice",
                        data: { invoice_id: this.invoice.id, invoice_type: 'storno' },
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_4125470329773403_click = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4125470329773403_click.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_3785918277944093_emit_4566_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "created",
                        value: $event.data.response.data,
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.act_3785918277944093_request_4564_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "create_invoice",
                        data: { invoice_id: this.invoice.id, invoice_type: 'correction' },
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_3785918277944093_click = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3785918277944093_click.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_2191919750581405_emit_4568_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_2191919750581405_success = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2191919750581405_success.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_3699289115122175_emit_4570_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "deleted",
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_3699289115122175_success = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3699289115122175_success.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.act_1247093655110851_showModal_4572_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "entityEvents",
                        props: {
                            entityId: this.invoice.id,
                        },
                    }];
            });
        });
    };
    UcInvoiceHeader.prototype.evh_1247093655110851_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1247093655110851_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceHeader.prototype.getActions = function () {
        var act_8680084583475136_emit_4544 = new core_1.actions.EmitAction({
            actionArgs: this.act_8680084583475136_emit_4544_getActionArgs,
            events: [],
        });
        var evh_8680084583475136_success_4543 = new core_1.actions.EventHandlerImpl({
            action: act_8680084583475136_emit_4544,
            event: "success",
            displayName: "emit",
        });
        var evh_8680084583475136_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8680084583475136_success_4543],
        });
        var act_3282321863127091_emit_4546 = new core_1.actions.EmitAction({
            actionArgs: this.act_3282321863127091_emit_4546_getActionArgs,
            events: [],
        });
        var evh_3282321863127091_success_4545 = new core_1.actions.EventHandlerImpl({
            action: act_3282321863127091_emit_4546,
            event: "success",
            displayName: "emit",
        });
        var evh_3282321863127091_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3282321863127091_success_4545],
        });
        var act_2386378572842701_emit_4550 = new core_1.actions.EmitAction({
            actionArgs: this.act_2386378572842701_emit_4550_getActionArgs,
            events: [],
        });
        var evh_2386378572842701_close_4549 = new core_1.actions.EventHandlerImpl({
            action: act_2386378572842701_emit_4550,
            event: "close",
            when: this.evh_2386378572842701_close_4549_getWhen,
            displayName: "emit",
        });
        var act_2386378572842701_showModal_4548 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2386378572842701_showModal_4548_getActionArgs,
            events: [evh_2386378572842701_close_4549],
        });
        var evh_2386378572842701_clickPreventStop_4547 = new core_1.actions.EventHandlerImpl({
            action: act_2386378572842701_showModal_4548,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_2386378572842701_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2386378572842701_clickPreventStop_4547],
        });
        var act_6305342467955186_emit_4552 = new core_1.actions.EmitAction({
            actionArgs: this.act_6305342467955186_emit_4552_getActionArgs,
            displayName: "reload",
            events: [],
        });
        var evh_6305342467955186_clickPreventStop_4551 = new core_1.actions.EventHandlerImpl({
            action: act_6305342467955186_emit_4552,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        var evh_6305342467955186_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6305342467955186_clickPreventStop_4551],
        });
        var act_6493473528837603_showModal_4554 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6493473528837603_showModal_4554_getActionArgs,
            events: [],
        });
        var evh_6493473528837603_clickPreventStop_4553 = new core_1.actions.EventHandlerImpl({
            action: act_6493473528837603_showModal_4554,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_6493473528837603_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6493473528837603_clickPreventStop_4553],
        });
        var act_5796712150329752_emit_4558 = new core_1.actions.EmitAction({
            actionArgs: this.act_5796712150329752_emit_4558_getActionArgs,
            events: [],
        });
        var evh_5796712150329752_success_4557 = new core_1.actions.EventHandlerImpl({
            action: act_5796712150329752_emit_4558,
            event: "success",
            displayName: "emit",
        });
        var act_5796712150329752_request_4556 = new core_1.actions.RequestAction({
            actionArgs: this.act_5796712150329752_request_4556_getActionArgs,
            displayName: "createInvoicecopy",
            events: [evh_5796712150329752_success_4557],
        });
        var evh_5796712150329752_click_4555 = new core_1.actions.EventHandlerImpl({
            action: act_5796712150329752_request_4556,
            event: "click",
            displayName: "createInvoicecopy",
        });
        var evh_5796712150329752_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5796712150329752_click_4555],
        });
        var act_4125470329773403_emit_4562 = new core_1.actions.EmitAction({
            actionArgs: this.act_4125470329773403_emit_4562_getActionArgs,
            events: [],
        });
        var evh_4125470329773403_success_4561 = new core_1.actions.EventHandlerImpl({
            action: act_4125470329773403_emit_4562,
            event: "success",
            displayName: "emit",
        });
        var act_4125470329773403_request_4560 = new core_1.actions.RequestAction({
            actionArgs: this.act_4125470329773403_request_4560_getActionArgs,
            displayName: "createStornoInvoice",
            events: [evh_4125470329773403_success_4561],
        });
        var evh_4125470329773403_click_4559 = new core_1.actions.EventHandlerImpl({
            action: act_4125470329773403_request_4560,
            event: "click",
            displayName: "createStornoInvoice",
        });
        var evh_4125470329773403_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4125470329773403_click_4559],
        });
        var act_3785918277944093_emit_4566 = new core_1.actions.EmitAction({
            actionArgs: this.act_3785918277944093_emit_4566_getActionArgs,
            events: [],
        });
        var evh_3785918277944093_success_4565 = new core_1.actions.EventHandlerImpl({
            action: act_3785918277944093_emit_4566,
            event: "success",
            displayName: "emit",
        });
        var act_3785918277944093_request_4564 = new core_1.actions.RequestAction({
            actionArgs: this.act_3785918277944093_request_4564_getActionArgs,
            displayName: "createCorrectorIvoice",
            events: [evh_3785918277944093_success_4565],
        });
        var evh_3785918277944093_click_4563 = new core_1.actions.EventHandlerImpl({
            action: act_3785918277944093_request_4564,
            event: "click",
            displayName: "createCorrectorIvoice",
        });
        var evh_3785918277944093_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3785918277944093_click_4563],
        });
        var act_2191919750581405_emit_4568 = new core_1.actions.EmitAction({
            actionArgs: this.act_2191919750581405_emit_4568_getActionArgs,
            events: [],
        });
        var evh_2191919750581405_success_4567 = new core_1.actions.EventHandlerImpl({
            action: act_2191919750581405_emit_4568,
            event: "success",
            displayName: "emit",
        });
        var evh_2191919750581405_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2191919750581405_success_4567],
        });
        var act_3699289115122175_emit_4570 = new core_1.actions.EmitAction({
            actionArgs: this.act_3699289115122175_emit_4570_getActionArgs,
            events: [],
        });
        var evh_3699289115122175_success_4569 = new core_1.actions.EventHandlerImpl({
            action: act_3699289115122175_emit_4570,
            event: "success",
            displayName: "emit",
        });
        var evh_3699289115122175_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3699289115122175_success_4569],
        });
        var act_1247093655110851_showModal_4572 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1247093655110851_showModal_4572_getActionArgs,
            events: [],
        });
        var evh_1247093655110851_clickPreventStop_4571 = new core_1.actions.EventHandlerImpl({
            action: act_1247093655110851_showModal_4572,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_1247093655110851_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1247093655110851_clickPreventStop_4571],
        });
        return {
            act_8680084583475136_emit_4544: act_8680084583475136_emit_4544,
            evh_8680084583475136_success_4543: evh_8680084583475136_success_4543,
            evh_8680084583475136_success: evh_8680084583475136_success,
            act_3282321863127091_emit_4546: act_3282321863127091_emit_4546,
            evh_3282321863127091_success_4545: evh_3282321863127091_success_4545,
            evh_3282321863127091_success: evh_3282321863127091_success,
            act_2386378572842701_emit_4550: act_2386378572842701_emit_4550,
            evh_2386378572842701_close_4549: evh_2386378572842701_close_4549,
            act_2386378572842701_showModal_4548: act_2386378572842701_showModal_4548,
            evh_2386378572842701_clickPreventStop_4547: evh_2386378572842701_clickPreventStop_4547,
            evh_2386378572842701_clickPreventStop: evh_2386378572842701_clickPreventStop,
            act_6305342467955186_emit_4552: act_6305342467955186_emit_4552,
            evh_6305342467955186_clickPreventStop_4551: evh_6305342467955186_clickPreventStop_4551,
            evh_6305342467955186_clickPreventStop: evh_6305342467955186_clickPreventStop,
            act_6493473528837603_showModal_4554: act_6493473528837603_showModal_4554,
            evh_6493473528837603_clickPreventStop_4553: evh_6493473528837603_clickPreventStop_4553,
            evh_6493473528837603_clickPreventStop: evh_6493473528837603_clickPreventStop,
            act_5796712150329752_emit_4558: act_5796712150329752_emit_4558,
            evh_5796712150329752_success_4557: evh_5796712150329752_success_4557,
            act_5796712150329752_request_4556: act_5796712150329752_request_4556,
            evh_5796712150329752_click_4555: evh_5796712150329752_click_4555,
            evh_5796712150329752_click: evh_5796712150329752_click,
            act_4125470329773403_emit_4562: act_4125470329773403_emit_4562,
            evh_4125470329773403_success_4561: evh_4125470329773403_success_4561,
            act_4125470329773403_request_4560: act_4125470329773403_request_4560,
            evh_4125470329773403_click_4559: evh_4125470329773403_click_4559,
            evh_4125470329773403_click: evh_4125470329773403_click,
            act_3785918277944093_emit_4566: act_3785918277944093_emit_4566,
            evh_3785918277944093_success_4565: evh_3785918277944093_success_4565,
            act_3785918277944093_request_4564: act_3785918277944093_request_4564,
            evh_3785918277944093_click_4563: evh_3785918277944093_click_4563,
            evh_3785918277944093_click: evh_3785918277944093_click,
            act_2191919750581405_emit_4568: act_2191919750581405_emit_4568,
            evh_2191919750581405_success_4567: evh_2191919750581405_success_4567,
            evh_2191919750581405_success: evh_2191919750581405_success,
            act_3699289115122175_emit_4570: act_3699289115122175_emit_4570,
            evh_3699289115122175_success_4569: evh_3699289115122175_success_4569,
            evh_3699289115122175_success: evh_3699289115122175_success,
            act_1247093655110851_showModal_4572: act_1247093655110851_showModal_4572,
            evh_1247093655110851_clickPreventStop_4571: evh_1247093655110851_clickPreventStop_4571,
            evh_1247093655110851_clickPreventStop: evh_1247093655110851_clickPreventStop,
            reload: act_6305342467955186_emit_4552,
            createInvoicecopy: act_5796712150329752_request_4556,
            createStornoInvoice: act_4125470329773403_request_4560,
            createCorrectorIvoice: act_3785918277944093_request_4564,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], UcInvoiceHeader.prototype, "invoice", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('invoice'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcInvoiceHeader.prototype, "onInvoice", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: function () {
                return this.$fn.randomElementId();
            },
        }),
        __metadata("design:type", String)
    ], UcInvoiceHeader.prototype, "formId", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('formId'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcInvoiceHeader.prototype, "onForm_id", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], UcInvoiceHeader.prototype, "invoiceNumber", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('invoiceNumber'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcInvoiceHeader.prototype, "onInvoice_number", null);
    UcInvoiceHeader = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], UcInvoiceHeader);
    return UcInvoiceHeader;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = UcInvoiceHeader;
vue_property_decorator_1.Vue.component("UcInvoiceHeader", UcInvoiceHeader);
