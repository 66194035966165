<template>
<ItpSelect2
  v-model="internalValue"
  :name="name"
  textField="description"
  :filterable="b_7315092382398562_filterable"
  :searchable="b_7315092382398562_searchable"
  :options="items"
  :readonly="readonly"
  @input="evh_7315092382398562_input($event, {})"
>
  <template
    slot="list-footer"
  >
    <HR>
    </HR>
    <ItpLink
      :text="'Add new...'|gettext"
      class="dropdown-item"
      @click="evh_3343864469950044_click($event, {})"
    >
    </ItpLink>
  </template>
</ItpSelect2>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class NumberingSelector extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  objectType!: string;

  @Watch('objectType')
  onObject_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("objectType")
  }

  @Prop({
    type: String,
  })
  name!: string;

  @Watch('name')
  onName(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("name")
  }

  @Prop({
    required: true,
  })
  value!: any;

  @Watch('value')
  onValue(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("value")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  internalValue!: any;

  async $$load_internalValue() {
    return this.value
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_numbering', { parameters: { resource_type: this.objectType } })
      .then(
        d => [{ id: null, description: this.$fn.gettext('Manual') }].concat(d)
      )
  }

  ux!: any;
  b_7315092382398562_filterable!: any;
  b_7315092382398562_searchable!: any;
  dataMembers = ['internalValue', 'items', 'ux', 'b_7315092382398562_filterable', 'b_7315092382398562_searchable'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        internalValue: null,
        items: null,
        ux: null,
        b_7315092382398562_filterable: false,
        b_7315092382398562_searchable: false,
      },
    }
  }

  async act_7315092382398562_emit_2890_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7315092382398562_emit_2890, alias=undefined
    return {
      event: "input",
      value: $event.originalEvent,
    }
  }

  async evh_7315092382398562_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_input.executeFromDOM(this, event, scope);
  }

  async act_3343864469950044_register_2894_getActionArgs($event: actions.ActionEvent): Promise<actions.RegisterActionArgs> {
    // parameterResolver name=act_3343864469950044_register_2894, alias=undefined
    return {
      name: "resultID",
      value: $event.data.modalResult && $event.data.modalResult.ok && $event.data.modalResult.value.id,
    }
  }

  async evh_3343864469950044_close_2895_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_2895, alias=undefined
    return $event.data.resultID
  }

  async act_3343864469950044_setData_2898_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_3343864469950044_setData_2898, alias=undefined
    return {
      path: "internalValue",
      value: this.resultID,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_3343864469950044_close_2897_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_2897, alias=undefined
    return $event.data.resultID
  }

  async act_3343864469950044_showModal_2892_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3343864469950044_showModal_2892, alias=undefined
    return {
      name: "Numbering",
      props: {
        objectType: this.objectType,
      }
      ,
    }
  }

  async evh_3343864469950044_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_emit_2890: actions.EmitAction;
    evh_7315092382398562_input_2889: actions.EventHandlerImpl;
    evh_7315092382398562_input: actions.EventHandlerGroup;
    act_3343864469950044_register_2894: actions.RegisterAction;
    evh_3343864469950044_close_2893: actions.EventHandlerImpl;
    act_3343864469950044_reloadComponentData_2896: actions.ReloadComponentDataAction;
    evh_3343864469950044_close_2895: actions.EventHandlerImpl;
    act_3343864469950044_setData_2898: actions.SetDataAction;
    evh_3343864469950044_close_2897: actions.EventHandlerImpl;
    act_3343864469950044_showModal_2892: actions.ShowModalAction;
    evh_3343864469950044_click_2891: actions.EventHandlerImpl;
    evh_3343864469950044_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_emit_2890 = new actions.EmitAction(
      {
        actionArgs: this.act_7315092382398562_emit_2890_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_input_2889 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_emit_2890,
        event: "input",
        displayName: "emit",
      }
    );
    const evh_7315092382398562_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_input_2889],
      }
    );
    const act_3343864469950044_register_2894 = new actions.RegisterAction(
      {
        actionArgs: this.act_3343864469950044_register_2894_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_close_2893 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_register_2894,
        event: "close",
        displayName: "register",
      }
    );
    const act_3343864469950044_reloadComponentData_2896 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_3343864469950044_close_2895 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_reloadComponentData_2896,
        event: "close",
        when: this.evh_3343864469950044_close_2895_getWhen,
        displayName: "reloadComponentData",
      }
    );
    const act_3343864469950044_setData_2898 = new actions.SetDataAction(
      {
        actionArgs: this.act_3343864469950044_setData_2898_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_close_2897 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_setData_2898,
        event: "close",
        when: this.evh_3343864469950044_close_2897_getWhen,
        displayName: "setData",
      }
    );
    const act_3343864469950044_showModal_2892 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3343864469950044_showModal_2892_getActionArgs,
        events: [evh_3343864469950044_close_2893, evh_3343864469950044_close_2895, evh_3343864469950044_close_2897],
      }
    );
    const evh_3343864469950044_click_2891 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_showModal_2892,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_3343864469950044_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_click_2891],
      }
    );
    return {
      act_7315092382398562_emit_2890,
      evh_7315092382398562_input_2889,
      evh_7315092382398562_input,
      act_3343864469950044_register_2894,
      evh_3343864469950044_close_2893,
      act_3343864469950044_reloadComponentData_2896,
      evh_3343864469950044_close_2895,
      act_3343864469950044_setData_2898,
      evh_3343864469950044_close_2897,
      act_3343864469950044_showModal_2892,
      evh_3343864469950044_click_2891,
      evh_3343864469950044_click,
    }
  }
}

Vue.component("NumberingSelector", NumberingSelector);

</script>