<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    alignItems="center"
  >
    <DIV>
      <ItpBox
        class="mb-2"
      >
        <ItpText
          strong
          :text="fixedAsset.rental.name"
          no-wrap
          v-if="fixedAsset.rental"
        >
        </ItpText>
        <ItpLink
          :to="{
    name: 'app.rental',
    params: { id: fixedAsset.rental.id }
  }"
          icon="fa-external-link-square-alt"
          class="ml-2"
          v-if="fixedAsset.rental"
        >
        </ItpLink>
      </ItpBox>
      <ItpBox>
        <ItpText
          strong
          :text="fixedAsset.rental.client_name"
          no-wrap
          v-if="fixedAsset.rental && fixedAsset.rental.client_id"
        >
        </ItpText>
        <ItpLink
          :to="{
    name: 'app.client',
    params: { id: fixedAsset.rental.client_id }
  }"
          icon="fa-external-link-square-alt"
          class="ml-2"
          v-if="fixedAsset.rental && fixedAsset.rental.client_id"
        >
        </ItpLink>
      </ItpBox>
    </DIV>
    <ItpButton
      :text="'Add to rental'|pgettext('FixedAsset')"
      variant="light"
      size="sm"
      class="ml-auto"
      v-if="!readonly"
      @click.prevent.stop="evh_3343864469950044_clickPreventStop($event, {})"
    >
    </ItpButton>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcFixedAssetRentalCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  fixedAsset!: object;

  @Watch('fixedAsset')
  onFixed_asset(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fixedAsset")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_3343864469950044_emit_4296_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3343864469950044_emit_4296, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3343864469950044_close_4295_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_4295, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_3343864469950044_showModal_4294_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3343864469950044_showModal_4294, alias=undefined
    return {
      name: "AddFixedAssetToRentalModal",
      props: {
        asset: this.fixedAsset,
      }
      ,
    }
  }

  async evh_3343864469950044_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4292: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4291: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_3343864469950044_emit_4296: actions.EmitAction;
    evh_3343864469950044_close_4295: actions.EventHandlerImpl;
    act_3343864469950044_showModal_4294: actions.ShowModalAction;
    evh_3343864469950044_clickPreventStop_4293: actions.EventHandlerImpl;
    evh_3343864469950044_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4292 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4291 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4292,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4291],
      }
    );
    const act_3343864469950044_emit_4296 = new actions.EmitAction(
      {
        actionArgs: this.act_3343864469950044_emit_4296_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_close_4295 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_emit_4296,
        event: "close",
        when: this.evh_3343864469950044_close_4295_getWhen,
        displayName: "emit",
      }
    );
    const act_3343864469950044_showModal_4294 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3343864469950044_showModal_4294_getActionArgs,
        events: [evh_3343864469950044_close_4295],
      }
    );
    const evh_3343864469950044_clickPreventStop_4293 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_showModal_4294,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_3343864469950044_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_clickPreventStop_4293],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4292,
      evh_7315092382398562_reload_4291,
      evh_7315092382398562_reload,
      act_3343864469950044_emit_4296,
      evh_3343864469950044_close_4295,
      act_3343864469950044_showModal_4294,
      evh_3343864469950044_clickPreventStop_4293,
      evh_3343864469950044_clickPreventStop,
    }
  }
}

Vue.component("UcFixedAssetRentalCard", UcFixedAssetRentalCard);

</script>