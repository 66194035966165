var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "2",
            contentColsMd: "3",
            name: "protocol_status",
            label: _vm._f("pgettext")("Status", "RentalHandover"),
            labelAlign: _vm.labelAlign
          }
        },
        [_c("UcStatusBadge", { attrs: { status: _vm.item.protocol_status } })],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "2",
            contentColsMd: "3",
            name: "handover_date",
            label: _vm._f("pgettext")("Handover date", "RentalHandover"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: {
              name: "handover_date",
              required: "",
              readonly: _vm.item.isReadonly
            },
            model: {
              value: _vm.item.handover_date,
              callback: function($$v) {
                _vm.$set(_vm.item, "handover_date", $$v)
              },
              expression: "item.handover_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded && _vm.item.paragraphs
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                labelColsMd: "2",
                contentColsMd: "10",
                name: "meter_readings",
                label: _vm._f("pgettext")("Paragraphs", "RentalHandover"),
                labelAlign: _vm.labelAlign
              }
            },
            _vm._l(_vm.item.paragraphs, function(paragraph, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c("ItpFormTextarea", {
                    staticClass: "w-100",
                    attrs: {
                      type: "number",
                      name: "paragraph_text",
                      required: "",
                      readonly: _vm.item.isReadonly
                    },
                    model: {
                      value: paragraph.text,
                      callback: function($$v) {
                        _vm.$set(paragraph, "text", $$v)
                      },
                      expression: "paragraph.text"
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded && _vm.item.meter_readings && _vm.item.meter_readings.length
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                labelColsMd: "2",
                contentColsMd: "10",
                name: "meter_readings",
                label: _vm._f("pgettext")("Meter reading", "RentalHandover"),
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("table", { staticClass: "table table-condensed" }, [
                _c(
                  "tbody",
                  _vm._l(_vm.item.meter_readings, function(reading) {
                    return _c("tr", { key: reading.serial_number }, [
                      _c(
                        "td",
                        [
                          _c("UcMeterTypeIcon", {
                            attrs: { type: reading.meter_type }
                          }),
                          _vm._v(" "),
                          _c("ItpText", {
                            attrs: { text: reading.serial_number }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "input-group" },
                          [
                            _c("ItpFormInput", {
                              attrs: {
                                type: "number",
                                name: "reading_value",
                                readonly: _vm.item.isReadonly
                              },
                              model: {
                                value: reading.reading_value,
                                callback: function($$v) {
                                  _vm.$set(reading, "reading_value", $$v)
                                },
                                expression: "reading.reading_value"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "DIV",
                              { staticClass: "input-group-append w-40" },
                              [
                                _c("ItpFormSelect", {
                                  attrs: {
                                    name: "reading_unit",
                                    options: _vm.units[reading.meter_type],
                                    readonly: _vm.item.isReadonly
                                  },
                                  model: {
                                    value: reading.reading_unit,
                                    callback: function($$v) {
                                      _vm.$set(reading, "reading_unit", $$v)
                                    },
                                    expression: "reading.reading_unit"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.isNew
        ? _c("UcAttachmentsList", {
            attrs: { parent: _vm.item, readonly: _vm.item.isReadonly }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }