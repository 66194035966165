"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var MainMenu = (function (_super) {
    __extends(MainMenu, _super);
    function MainMenu() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['ux'];
        return _this;
    }
    MainMenu.prototype.beforeCreate = function () {
    };
    MainMenu.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    };
    Object.defineProperty(MainMenu.prototype, "displayTestBadge", {
        get: function () {
            if (this.loaded) {
                return _.includes(this.$app.document.location.hostname, 'test');
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MainMenu.prototype, "displayDevBadge", {
        get: function () {
            if (this.loaded) {
                return _.includes(this.$app.document.location.hostname, 'local');
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    MainMenu.prototype.evh_7315092382398562_reload = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_5970709076725755_showModal_1228_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateInvoice",
                        props: {
                            invoiceKind: "outgoing",
                        },
                    }];
            });
        });
    };
    MainMenu.prototype.evh_5970709076725755_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5970709076725755_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_4190152961853837_showModal_1230_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateInvoice",
                        props: {
                            invoiceKind: "incoming",
                        },
                    }];
            });
        });
    };
    MainMenu.prototype.evh_4190152961853837_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4190152961853837_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_2424316887484278_showModal_1232_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "Payment",
                        props: {
                            transactionType: "deposit",
                        },
                    }];
            });
        });
    };
    MainMenu.prototype.evh_2424316887484278_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2424316887484278_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_7637021470970119_showModal_1234_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "Payment",
                        props: {
                            transactionType: "payment",
                        },
                    }];
            });
        });
    };
    MainMenu.prototype.evh_7637021470970119_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7637021470970119_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_2931973773564833_showModal_1236_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "Payment",
                        props: {
                            transactionType: "banking",
                        },
                    }];
            });
        });
    };
    MainMenu.prototype.evh_2931973773564833_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2931973773564833_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_2269742420845197_showModal_1238_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "AccountStatementReport",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_2269742420845197_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2269742420845197_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_1886107574314164_showModal_1240_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "MCreateFixedAsset",
                        props: {
                            assetType: "general",
                        },
                    }];
            });
        });
    };
    MainMenu.prototype.evh_1886107574314164_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1886107574314164_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_7260361660686930_showModal_1242_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "MCreateFixedAsset",
                        props: {
                            assetType: "waste_bin",
                        },
                    }];
            });
        });
    };
    MainMenu.prototype.evh_7260361660686930_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7260361660686930_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_5449511595610967_showModal_1244_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateMeter",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_5449511595610967_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5449511595610967_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_8050584411731670_showModal_1246_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "MeterDataExchange",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_8050584411731670_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8050584411731670_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_7807097906137782_showModal_1248_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "ReplaceMeter",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_7807097906137782_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7807097906137782_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_2338624680052783_showModal_1250_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateContract",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_2338624680052783_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2338624680052783_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_2596033535867385_showModal_1252_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateRental",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_2596033535867385_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2596033535867385_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_6794737306373342_showModal_1254_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateProperty",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_6794737306373342_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_6794737306373342_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_2660996057968802_showModal_1256_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateClient",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_2660996057968802_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2660996057968802_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_7567745526909371_showModal_1258_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateProduct",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_7567745526909371_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7567745526909371_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_7228767045058026_showModal_1260_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "Worksheet",
                        props: {
                            size: "lg",
                        },
                    }];
            });
        });
    };
    MainMenu.prototype.evh_7228767045058026_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7228767045058026_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_298118714321807_script_1262_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.$app.toggleConfirmPageClose($event);
                return [2];
            });
        });
    };
    MainMenu.prototype.act_298118714321807_script_1262_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_298118714321807_script_1262_getActionArgs_value($event); },
                    }];
            });
        });
    };
    MainMenu.prototype.evh_298118714321807_change = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_298118714321807_change.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_7106235911078350_logout_1264_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {}];
            });
        });
    };
    MainMenu.prototype.evh_7106235911078350_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7106235911078350_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.act_5361165673534032_showModal_1266_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "AboutModal",
                    }];
            });
        });
    };
    MainMenu.prototype.evh_5361165673534032_clickPrevent = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5361165673534032_clickPrevent.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    MainMenu.prototype.getActions = function () {
        var act_7315092382398562_reloadComponentData_1226 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_7315092382398562_reload_1225 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_1226,
            event: "reload",
            displayName: "reloadComponentData",
        });
        var evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_1225],
        });
        var act_5970709076725755_showModal_1228 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5970709076725755_showModal_1228_getActionArgs,
            events: [],
        });
        var evh_5970709076725755_clickPrevent_1227 = new core_1.actions.EventHandlerImpl({
            action: act_5970709076725755_showModal_1228,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_5970709076725755_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5970709076725755_clickPrevent_1227],
        });
        var act_4190152961853837_showModal_1230 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_4190152961853837_showModal_1230_getActionArgs,
            events: [],
        });
        var evh_4190152961853837_clickPrevent_1229 = new core_1.actions.EventHandlerImpl({
            action: act_4190152961853837_showModal_1230,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_4190152961853837_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4190152961853837_clickPrevent_1229],
        });
        var act_2424316887484278_showModal_1232 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2424316887484278_showModal_1232_getActionArgs,
            events: [],
        });
        var evh_2424316887484278_clickPrevent_1231 = new core_1.actions.EventHandlerImpl({
            action: act_2424316887484278_showModal_1232,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_2424316887484278_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2424316887484278_clickPrevent_1231],
        });
        var act_7637021470970119_showModal_1234 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7637021470970119_showModal_1234_getActionArgs,
            events: [],
        });
        var evh_7637021470970119_clickPrevent_1233 = new core_1.actions.EventHandlerImpl({
            action: act_7637021470970119_showModal_1234,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_7637021470970119_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7637021470970119_clickPrevent_1233],
        });
        var act_2931973773564833_showModal_1236 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2931973773564833_showModal_1236_getActionArgs,
            events: [],
        });
        var evh_2931973773564833_clickPrevent_1235 = new core_1.actions.EventHandlerImpl({
            action: act_2931973773564833_showModal_1236,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_2931973773564833_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2931973773564833_clickPrevent_1235],
        });
        var act_2269742420845197_showModal_1238 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2269742420845197_showModal_1238_getActionArgs,
            events: [],
        });
        var evh_2269742420845197_clickPrevent_1237 = new core_1.actions.EventHandlerImpl({
            action: act_2269742420845197_showModal_1238,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_2269742420845197_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2269742420845197_clickPrevent_1237],
        });
        var act_1886107574314164_showModal_1240 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1886107574314164_showModal_1240_getActionArgs,
            events: [],
        });
        var evh_1886107574314164_clickPrevent_1239 = new core_1.actions.EventHandlerImpl({
            action: act_1886107574314164_showModal_1240,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_1886107574314164_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1886107574314164_clickPrevent_1239],
        });
        var act_7260361660686930_showModal_1242 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7260361660686930_showModal_1242_getActionArgs,
            events: [],
        });
        var evh_7260361660686930_clickPrevent_1241 = new core_1.actions.EventHandlerImpl({
            action: act_7260361660686930_showModal_1242,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_7260361660686930_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7260361660686930_clickPrevent_1241],
        });
        var act_5449511595610967_showModal_1244 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5449511595610967_showModal_1244_getActionArgs,
            events: [],
        });
        var evh_5449511595610967_clickPrevent_1243 = new core_1.actions.EventHandlerImpl({
            action: act_5449511595610967_showModal_1244,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_5449511595610967_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5449511595610967_clickPrevent_1243],
        });
        var act_8050584411731670_showModal_1246 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8050584411731670_showModal_1246_getActionArgs,
            events: [],
        });
        var evh_8050584411731670_clickPrevent_1245 = new core_1.actions.EventHandlerImpl({
            action: act_8050584411731670_showModal_1246,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_8050584411731670_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8050584411731670_clickPrevent_1245],
        });
        var act_7807097906137782_showModal_1248 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7807097906137782_showModal_1248_getActionArgs,
            events: [],
        });
        var evh_7807097906137782_clickPrevent_1247 = new core_1.actions.EventHandlerImpl({
            action: act_7807097906137782_showModal_1248,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_7807097906137782_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7807097906137782_clickPrevent_1247],
        });
        var act_2338624680052783_showModal_1250 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2338624680052783_showModal_1250_getActionArgs,
            events: [],
        });
        var evh_2338624680052783_clickPrevent_1249 = new core_1.actions.EventHandlerImpl({
            action: act_2338624680052783_showModal_1250,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_2338624680052783_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2338624680052783_clickPrevent_1249],
        });
        var act_2596033535867385_showModal_1252 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2596033535867385_showModal_1252_getActionArgs,
            events: [],
        });
        var evh_2596033535867385_clickPrevent_1251 = new core_1.actions.EventHandlerImpl({
            action: act_2596033535867385_showModal_1252,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_2596033535867385_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2596033535867385_clickPrevent_1251],
        });
        var act_6794737306373342_showModal_1254 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6794737306373342_showModal_1254_getActionArgs,
            events: [],
        });
        var evh_6794737306373342_clickPrevent_1253 = new core_1.actions.EventHandlerImpl({
            action: act_6794737306373342_showModal_1254,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_6794737306373342_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6794737306373342_clickPrevent_1253],
        });
        var act_2660996057968802_showModal_1256 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2660996057968802_showModal_1256_getActionArgs,
            events: [],
        });
        var evh_2660996057968802_clickPrevent_1255 = new core_1.actions.EventHandlerImpl({
            action: act_2660996057968802_showModal_1256,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_2660996057968802_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2660996057968802_clickPrevent_1255],
        });
        var act_7567745526909371_showModal_1258 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7567745526909371_showModal_1258_getActionArgs,
            events: [],
        });
        var evh_7567745526909371_clickPrevent_1257 = new core_1.actions.EventHandlerImpl({
            action: act_7567745526909371_showModal_1258,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_7567745526909371_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7567745526909371_clickPrevent_1257],
        });
        var act_7228767045058026_showModal_1260 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7228767045058026_showModal_1260_getActionArgs,
            events: [],
        });
        var evh_7228767045058026_clickPrevent_1259 = new core_1.actions.EventHandlerImpl({
            action: act_7228767045058026_showModal_1260,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_7228767045058026_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7228767045058026_clickPrevent_1259],
        });
        var act_298118714321807_script_1262 = new core_1.actions.ScriptAction({
            actionArgs: this.act_298118714321807_script_1262_getActionArgs,
            events: [],
        });
        var evh_298118714321807_change_1261 = new core_1.actions.EventHandlerImpl({
            action: act_298118714321807_script_1262,
            event: "change",
            displayName: "script",
        });
        var evh_298118714321807_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_298118714321807_change_1261],
        });
        var act_7106235911078350_logout_1264 = new core_1.actions.LogoutAction({
            actionArgs: this.act_7106235911078350_logout_1264_getActionArgs,
            events: [],
        });
        var evh_7106235911078350_clickPrevent_1263 = new core_1.actions.EventHandlerImpl({
            action: act_7106235911078350_logout_1264,
            event: "click.prevent",
            displayName: "logout",
        });
        var evh_7106235911078350_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7106235911078350_clickPrevent_1263],
        });
        var act_5361165673534032_showModal_1266 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5361165673534032_showModal_1266_getActionArgs,
            events: [],
        });
        var evh_5361165673534032_clickPrevent_1265 = new core_1.actions.EventHandlerImpl({
            action: act_5361165673534032_showModal_1266,
            event: "click.prevent",
            displayName: "showModal",
        });
        var evh_5361165673534032_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5361165673534032_clickPrevent_1265],
        });
        return {
            act_7315092382398562_reloadComponentData_1226: act_7315092382398562_reloadComponentData_1226,
            evh_7315092382398562_reload_1225: evh_7315092382398562_reload_1225,
            evh_7315092382398562_reload: evh_7315092382398562_reload,
            act_5970709076725755_showModal_1228: act_5970709076725755_showModal_1228,
            evh_5970709076725755_clickPrevent_1227: evh_5970709076725755_clickPrevent_1227,
            evh_5970709076725755_clickPrevent: evh_5970709076725755_clickPrevent,
            act_4190152961853837_showModal_1230: act_4190152961853837_showModal_1230,
            evh_4190152961853837_clickPrevent_1229: evh_4190152961853837_clickPrevent_1229,
            evh_4190152961853837_clickPrevent: evh_4190152961853837_clickPrevent,
            act_2424316887484278_showModal_1232: act_2424316887484278_showModal_1232,
            evh_2424316887484278_clickPrevent_1231: evh_2424316887484278_clickPrevent_1231,
            evh_2424316887484278_clickPrevent: evh_2424316887484278_clickPrevent,
            act_7637021470970119_showModal_1234: act_7637021470970119_showModal_1234,
            evh_7637021470970119_clickPrevent_1233: evh_7637021470970119_clickPrevent_1233,
            evh_7637021470970119_clickPrevent: evh_7637021470970119_clickPrevent,
            act_2931973773564833_showModal_1236: act_2931973773564833_showModal_1236,
            evh_2931973773564833_clickPrevent_1235: evh_2931973773564833_clickPrevent_1235,
            evh_2931973773564833_clickPrevent: evh_2931973773564833_clickPrevent,
            act_2269742420845197_showModal_1238: act_2269742420845197_showModal_1238,
            evh_2269742420845197_clickPrevent_1237: evh_2269742420845197_clickPrevent_1237,
            evh_2269742420845197_clickPrevent: evh_2269742420845197_clickPrevent,
            act_1886107574314164_showModal_1240: act_1886107574314164_showModal_1240,
            evh_1886107574314164_clickPrevent_1239: evh_1886107574314164_clickPrevent_1239,
            evh_1886107574314164_clickPrevent: evh_1886107574314164_clickPrevent,
            act_7260361660686930_showModal_1242: act_7260361660686930_showModal_1242,
            evh_7260361660686930_clickPrevent_1241: evh_7260361660686930_clickPrevent_1241,
            evh_7260361660686930_clickPrevent: evh_7260361660686930_clickPrevent,
            act_5449511595610967_showModal_1244: act_5449511595610967_showModal_1244,
            evh_5449511595610967_clickPrevent_1243: evh_5449511595610967_clickPrevent_1243,
            evh_5449511595610967_clickPrevent: evh_5449511595610967_clickPrevent,
            act_8050584411731670_showModal_1246: act_8050584411731670_showModal_1246,
            evh_8050584411731670_clickPrevent_1245: evh_8050584411731670_clickPrevent_1245,
            evh_8050584411731670_clickPrevent: evh_8050584411731670_clickPrevent,
            act_7807097906137782_showModal_1248: act_7807097906137782_showModal_1248,
            evh_7807097906137782_clickPrevent_1247: evh_7807097906137782_clickPrevent_1247,
            evh_7807097906137782_clickPrevent: evh_7807097906137782_clickPrevent,
            act_2338624680052783_showModal_1250: act_2338624680052783_showModal_1250,
            evh_2338624680052783_clickPrevent_1249: evh_2338624680052783_clickPrevent_1249,
            evh_2338624680052783_clickPrevent: evh_2338624680052783_clickPrevent,
            act_2596033535867385_showModal_1252: act_2596033535867385_showModal_1252,
            evh_2596033535867385_clickPrevent_1251: evh_2596033535867385_clickPrevent_1251,
            evh_2596033535867385_clickPrevent: evh_2596033535867385_clickPrevent,
            act_6794737306373342_showModal_1254: act_6794737306373342_showModal_1254,
            evh_6794737306373342_clickPrevent_1253: evh_6794737306373342_clickPrevent_1253,
            evh_6794737306373342_clickPrevent: evh_6794737306373342_clickPrevent,
            act_2660996057968802_showModal_1256: act_2660996057968802_showModal_1256,
            evh_2660996057968802_clickPrevent_1255: evh_2660996057968802_clickPrevent_1255,
            evh_2660996057968802_clickPrevent: evh_2660996057968802_clickPrevent,
            act_7567745526909371_showModal_1258: act_7567745526909371_showModal_1258,
            evh_7567745526909371_clickPrevent_1257: evh_7567745526909371_clickPrevent_1257,
            evh_7567745526909371_clickPrevent: evh_7567745526909371_clickPrevent,
            act_7228767045058026_showModal_1260: act_7228767045058026_showModal_1260,
            evh_7228767045058026_clickPrevent_1259: evh_7228767045058026_clickPrevent_1259,
            evh_7228767045058026_clickPrevent: evh_7228767045058026_clickPrevent,
            act_298118714321807_script_1262: act_298118714321807_script_1262,
            evh_298118714321807_change_1261: evh_298118714321807_change_1261,
            evh_298118714321807_change: evh_298118714321807_change,
            act_7106235911078350_logout_1264: act_7106235911078350_logout_1264,
            evh_7106235911078350_clickPrevent_1263: evh_7106235911078350_clickPrevent_1263,
            evh_7106235911078350_clickPrevent: evh_7106235911078350_clickPrevent,
            act_5361165673534032_showModal_1266: act_5361165673534032_showModal_1266,
            evh_5361165673534032_clickPrevent_1265: evh_5361165673534032_clickPrevent_1265,
            evh_5361165673534032_clickPrevent: evh_5361165673534032_clickPrevent,
        };
    };
    MainMenu = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], MainMenu);
    return MainMenu;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = MainMenu;
vue_property_decorator_1.Vue.component("MainMenu", MainMenu);
