<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WasteBinReadings"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="wasteBinReadings"
    :headerTitle="'Waste Bin Readings'|gettext"
    :items="$fn.slickDataSource('list_waste_bin_readings', {parameters: dsParams})"
    export-to-csv
    :exportToCsvFilename="'waste-bin-readings'|pgettext('ExportFilename')"
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    :contextMenu="b_2248226175642056_contextMenu"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
    @contextmenu:remove="evh_2248226175642056_contextmenuRemove($event, {})"
    @command="evh_2248226175642056_command($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <DIV
        class="mx-2"
      >
        <ItpFormCheckbox
          name="skip_archived"
          :label="'Include handled readings'|pgettext('WasteBinReadings')"
          inline
          v-model="dsParams.include_worksheets"
        >
        </ItpFormCheckbox>
      </DIV>
      <ItpButton
        icon="fa-cog"
        :text="'Create Worksheets...'|gettext"
        size="sm"
        :disabled="!selectedItems || !selectedItems.length"
        variant="primary"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        icon="fa-trash"
        :text="'Delete'|gettext"
        size="sm"
        variant="danger"
        :disabled="!selectedItems || !selectedItems.length"
        :spinning="actions.deleteItems.isRunning"
        class="mx-2"
        @click="evh_6705964765214521_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/assets/fixed-assets.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "wasteBinReadings",
    path: "/waste-bin-readings",
    component: "WasteBinReadings"
  }
)

@Component()
export default class WasteBinReadings extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_dsParams = new ComponentValueCache(this, "dsParams", "WasteBinReadings.dsParams");
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  dsParams!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  b_2248226175642056_contextMenu!: any;
  dataMembers = ['selectedItems', 'dsParams', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns', 'b_2248226175642056_contextMenu'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        dsParams: {
          include_worksheets: false,
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "timestamp",
          name: this.$fn.pgettext("WasteBinReadings", "Reading Time"),
          type: "command",
          formatter: {
            name: "chain",
            formatters: [{
              name: "datetime",
            }
              ,
            {
              name: "link",
              href: "#",
              className: "command-link",
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "waste_bin_name",
          name: this.$fn.pgettext("WasteBinReadings", "Bin Name"),
          formatter: {
            name: "routeLink",
            to: "fixedAsset",
            params: {
              id: "waste_bin_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "waste_bin_number",
          name: this.$fn.pgettext("WasteBinReadings", "Bin Number"),
        }
          ,
        {
          field: "waste_bin_capacity",
          name: this.$fn.pgettext("WasteBinReadings", "Capacity (liters)"),
        }
          ,
        {
          field: "state",
          name: this.$fn.pgettext("WasteBinReadings", "State"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "WasteBinReadingStates",
              conversion: "StartCase",
            }
              ,
            {
              name: "badge",
              variantmap: {
                empty: "success",
                full: "warning",
              }
              ,
              variantMapSource: "fieldValue",
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "rental_name",
          name: this.$fn.pgettext("WasteBinReadings", "Rental"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "rental",
            params: {
              id: "rental_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "client_number",
          name: this.$fn.pgettext("WasteBinReadings", "Client Number"),
          formatter: {
            name: "routeLink",
            to: "client",
            params: {
              id: "client_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "client_name",
          name: this.$fn.pgettext("WasteBinReadings", "Client Name"),
          formatter: {
            name: "routeLink",
            to: "client",
            params: {
              id: "client_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "cycle",
          name: this.$fn.pgettext("WasteBinReadings", "Cycle"),
        }
          ,
        {
          field: "device_id",
          name: this.$fn.pgettext("WasteBinReadings", "Device ID"),
        }
          ,
        {
          field: "worksheets",
          name: this.$fn.pgettext("WasteBinReadings", "Worksheets"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            separator: ", ",
            formatterContext: "item",
            formatters: [{
              name: "getvalue",
              opts: {
                field: "number",
                source: "value",
              }
              ,
            }
              ,
            {
              name: "routeLink",
              to: "worksheet",
              params: {
                id: "id",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        ]
        ,
        b_2248226175642056_contextMenu: {
          items: [{
            label: this.$fn.gettext("Delete"),
            command: "remove",
          }
            ,
          ]
          ,
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Waste Bin Readings"),
        icon: this.$config.wasteBin.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1556_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1556, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_1558_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_1558, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_reloadSlickgrid_1562_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_1562, alias=undefined
    return {
      grid: "wasteBinReadings",
    }
  }

  async act_2248226175642056_request_1560_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_2248226175642056_request_1560, alias=undefined
    return {
      operation: "waste_bins/delete_reading",
      data: { identities: [$event.data.data.id] },
    }
  }

  async evh_2248226175642056_contextmenuRemove(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_contextmenuRemove.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_showModal_1564_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_1564, alias=showWasteBinReadingModal
    return {
      name: "WasteBinReadingModal",
      props: {
        objectId: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_1563_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_1563, alias=undefined
    return $event.data.column.id === 'timestamp'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_1566_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_1566, alias=undefined
    return {
      name: "CreateWorksheetsFromWasteBinReadingsModal",
      props: {
        readings: _.map(this.selectedItems, p => p.id),
      }
      ,
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  async act_6705964765214521_reloadSlickgrid_1570_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_6705964765214521_reloadSlickgrid_1570, alias=undefined
    return {
      grid: "wasteBinReadings",
    }
  }

  async act_6705964765214521_request_1568_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_6705964765214521_request_1568, alias=deleteItems
    return {
      operation: "waste_bins/delete_readings",
      data: { identities: this.selectedItems.map(p => p.id) },
    }
  }

  async act_6705964765214521_request_1568_getConfirm($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_6705964765214521_request_1568, alias=deleteItems
    return {
      header: "_(\"Delete the selected readings?\")",
      message: this.$fn.gettext("This action cannot be undone."),
      btnOk: this.$fn.gettext("Delete"),
    }
  }

  async evh_6705964765214521_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6705964765214521_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1556: actions.CloseModalAction;
    evh_7315092382398562_close_1555: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_1558: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_1557: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_2248226175642056_reloadSlickgrid_1562: actions.ReloadSlickgridAction;
    evh_2248226175642056_success_1561: actions.EventHandlerImpl;
    act_2248226175642056_request_1560: actions.RequestAction;
    evh_2248226175642056_contextmenuRemove_1559: actions.EventHandlerImpl;
    evh_2248226175642056_contextmenuRemove: actions.EventHandlerGroup;
    act_2248226175642056_showModal_1564: actions.ShowModalAction;
    evh_2248226175642056_command_1563: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_8320016629450276_showModal_1566: actions.ShowModalAction;
    evh_8320016629450276_click_1565: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
    act_6705964765214521_reloadSlickgrid_1570: actions.ReloadSlickgridAction;
    evh_6705964765214521_success_1569: actions.EventHandlerImpl;
    act_6705964765214521_request_1568: actions.RequestAction;
    evh_6705964765214521_click_1567: actions.EventHandlerImpl;
    evh_6705964765214521_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1556 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1556_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1555 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1556,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1555],
      }
    );
    const act_2248226175642056_setData_1558 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_1558_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_1557 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_1558,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_1557],
      }
    );
    const act_2248226175642056_reloadSlickgrid_1562 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_1562_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_success_1561 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_1562,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_request_1560 = new actions.RequestAction(
      {
        actionArgs: this.act_2248226175642056_request_1560_getActionArgs,
        events: [evh_2248226175642056_success_1561],
      }
    );
    const evh_2248226175642056_contextmenuRemove_1559 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_request_1560,
        event: "contextmenu:remove",
        displayName: "request",
      }
    );
    const evh_2248226175642056_contextmenuRemove = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_contextmenuRemove_1559],
      }
    );
    const act_2248226175642056_showModal_1564 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_1564_getActionArgs,
        displayName: "showWasteBinReadingModal",
        events: [],
      }
    );
    const evh_2248226175642056_command_1563 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_1564,
        event: "command",
        when: this.evh_2248226175642056_command_1563_getWhen,
        displayName: "showWasteBinReadingModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_1563],
      }
    );
    const act_8320016629450276_showModal_1566 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_1566_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_click_1565 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_1566,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_1565],
      }
    );
    const act_6705964765214521_reloadSlickgrid_1570 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_6705964765214521_reloadSlickgrid_1570_getActionArgs,
        events: [],
      }
    );
    const evh_6705964765214521_success_1569 = new actions.EventHandlerImpl(
      {
        action: act_6705964765214521_reloadSlickgrid_1570,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_6705964765214521_request_1568 = new actions.RequestAction(
      {
        actionArgs: this.act_6705964765214521_request_1568_getActionArgs,
        confirm: this.act_6705964765214521_request_1568_getConfirm,
        displayName: "deleteItems",
        events: [evh_6705964765214521_success_1569],
      }
    );
    const evh_6705964765214521_click_1567 = new actions.EventHandlerImpl(
      {
        action: act_6705964765214521_request_1568,
        event: "click",
        displayName: "deleteItems",
      }
    );
    const evh_6705964765214521_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6705964765214521_click_1567],
      }
    );
    return {
      act_7315092382398562_closeModal_1556,
      evh_7315092382398562_close_1555,
      evh_7315092382398562_close,
      act_2248226175642056_setData_1558,
      evh_2248226175642056_selectedRowsChanged_1557,
      evh_2248226175642056_selectedRowsChanged,
      act_2248226175642056_reloadSlickgrid_1562,
      evh_2248226175642056_success_1561,
      act_2248226175642056_request_1560,
      evh_2248226175642056_contextmenuRemove_1559,
      evh_2248226175642056_contextmenuRemove,
      act_2248226175642056_showModal_1564,
      evh_2248226175642056_command_1563,
      evh_2248226175642056_command,
      act_8320016629450276_showModal_1566,
      evh_8320016629450276_click_1565,
      evh_8320016629450276_click,
      act_6705964765214521_reloadSlickgrid_1570,
      evh_6705964765214521_success_1569,
      act_6705964765214521_request_1568,
      evh_6705964765214521_click_1567,
      evh_6705964765214521_click,
      showWasteBinReadingModal: act_2248226175642056_showModal_1564,
      deleteItems: act_6705964765214521_request_1568,
    }
  }
}

Vue.component("WasteBinReadings", WasteBinReadings);

</script>