<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="DunningLetters"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="dunning-letters"
    :headerTitle="'Dunning letters'|pgettext('DunningLettersView')"
    :items="$fn.slickDataSource('integro_api/list_dunning_letter')"
    checkbox-row-selection
    export-to-csv
    :exportToCsvFilename="'dunning-letters'|pgettext('filename')"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    :contextMenu="b_2248226175642056_contextMenu"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
    @contextmenu:remove="evh_2248226175642056_contextmenuRemove($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpButton
        icon="fa-envelope"
        :text="'Send emails...'|pgettext('DunningLettersView')"
        size="sm"
        variant="primary"
        :disabled="!selectedItems || !selectedItems.length"
        class="mx-2"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        icon="fa-trash"
        :text="'Delete'|pgettext('DunningLettersView')"
        size="sm"
        variant="danger"
        :disabled="!selectedItems || !selectedItems.length"
        :spinning="actions.deleteItems.isRunning"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/claims/dunning-letters.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "dunning-letters",
    path: "/dunning-letters",
    component: "DunningLetters"
  }
)

@Component()
export default class DunningLetters extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  b_2248226175642056_contextMenu!: any;
  dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns', 'b_2248226175642056_contextMenu'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "reference_number",
          name: this.$fn.pgettext("DunningLettersView", "Reference Number"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "dunningLetter",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "status",
          name: this.$fn.pgettext("DunningLettersView", "Status"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "DunningLetterStatus",
              conversion: "StartCase",
            }
              ,
            {
              name: "badge",
              variantmap: {
                sent: "light",
                delivered: "success",
                error: "danger",
              }
              ,
              variantMapSource: "fieldValue",
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "customer_name",
          name: this.$fn.pgettext("DunningLettersView", "Customer Name"),
          formatter: {
            name: "routeLink",
            to: "client",
            navigateOnIconClick: true,
            params: {
              id: "client_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "customer_email",
          name: this.$fn.pgettext("DunningLettersView", "Client Email"),
          formatter: {
            name: "join",
            separator: "; ",
          }
          ,
        }
          ,
        {
          field: "severity",
          name: this.$fn.pgettext("DunningLettersView", "Severity"),
        }
          ,
        {
          field: "created_at",
          name: this.$fn.pgettext("DunningLettersView", "Created at"),
          formatter: {
            name: "datetime",
          }
          ,
        }
          ,
        {
          field: "created_by",
          name: this.$fn.pgettext("DunningLettersView", "Created by"),
          formatter: {
            name: "user",
          }
          ,
        }
          ,
        ]
        ,
        b_2248226175642056_contextMenu: {
          items: [{
            label: "Delete",
            command: "remove",
          }
            ,
          ]
          ,
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("DunningLettersView", "Dunning Letters"),
        icon: this.$config.dunningLetter.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4816_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4816, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_4818_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_4818, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_reloadSlickgrid_4822_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_4822, alias=undefined
    return {
      grid: "all",
    }
  }

  async act_2248226175642056_request_4820_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_2248226175642056_request_4820, alias=undefined
    return {
      operation: "delete_dunning_letter",
      data: { id: $event.data.data.slug_id },
    }
  }

  async evh_2248226175642056_contextmenuRemove(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_contextmenuRemove.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_4824_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_4824, alias=undefined
    return {
      name: "SendMails",
      props: {
        items: this.selectedItems,
      }
      ,
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_script_4828_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8320016629450276_script_4828, alias=undefined
    this.$refs.grid.reload()
  }

  async act_8320016629450276_script_4828_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8320016629450276_script_4828, alias=undefined
    return {
      value: () => this.act_8320016629450276_script_4828_getActionArgs_value($event),
    }
  }

  async act_8320016629450276_request_4826_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8320016629450276_request_4826, alias=deleteItems
    return {
      operation: "bulk_delete_dunning_letter",
      data: this.selectedItems.map(p => ({ id: p.id, etag: null })),
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4816: actions.CloseModalAction;
    evh_7315092382398562_close_4815: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_4818: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_4817: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_2248226175642056_reloadSlickgrid_4822: actions.ReloadSlickgridAction;
    evh_2248226175642056_success_4821: actions.EventHandlerImpl;
    act_2248226175642056_request_4820: actions.RequestAction;
    evh_2248226175642056_contextmenuRemove_4819: actions.EventHandlerImpl;
    evh_2248226175642056_contextmenuRemove: actions.EventHandlerGroup;
    act_6466705138206980_showModal_4824: actions.ShowModalAction;
    evh_6466705138206980_click_4823: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
    act_8320016629450276_script_4828: actions.ScriptAction;
    evh_8320016629450276_success_4827: actions.EventHandlerImpl;
    act_8320016629450276_request_4826: actions.RequestAction;
    evh_8320016629450276_click_4825: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4816 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4816_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4815 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4816,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4815],
      }
    );
    const act_2248226175642056_setData_4818 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_4818_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_4817 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_4818,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_4817],
      }
    );
    const act_2248226175642056_reloadSlickgrid_4822 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_4822_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_success_4821 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_4822,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_request_4820 = new actions.RequestAction(
      {
        actionArgs: this.act_2248226175642056_request_4820_getActionArgs,
        events: [evh_2248226175642056_success_4821],
      }
    );
    const evh_2248226175642056_contextmenuRemove_4819 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_request_4820,
        event: "contextmenu:remove",
        displayName: "request",
      }
    );
    const evh_2248226175642056_contextmenuRemove = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_contextmenuRemove_4819],
      }
    );
    const act_6466705138206980_showModal_4824 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_4824_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_click_4823 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_4824,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_4823],
      }
    );
    const act_8320016629450276_script_4828 = new actions.ScriptAction(
      {
        actionArgs: this.act_8320016629450276_script_4828_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_success_4827 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_script_4828,
        event: "success",
        displayName: "script",
      }
    );
    const act_8320016629450276_request_4826 = new actions.RequestAction(
      {
        actionArgs: this.act_8320016629450276_request_4826_getActionArgs,
        displayName: "deleteItems",
        events: [evh_8320016629450276_success_4827],
      }
    );
    const evh_8320016629450276_click_4825 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_request_4826,
        event: "click",
        displayName: "deleteItems",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_4825],
      }
    );
    return {
      act_7315092382398562_closeModal_4816,
      evh_7315092382398562_close_4815,
      evh_7315092382398562_close,
      act_2248226175642056_setData_4818,
      evh_2248226175642056_selectedRowsChanged_4817,
      evh_2248226175642056_selectedRowsChanged,
      act_2248226175642056_reloadSlickgrid_4822,
      evh_2248226175642056_success_4821,
      act_2248226175642056_request_4820,
      evh_2248226175642056_contextmenuRemove_4819,
      evh_2248226175642056_contextmenuRemove,
      act_6466705138206980_showModal_4824,
      evh_6466705138206980_click_4823,
      evh_6466705138206980_click,
      act_8320016629450276_script_4828,
      evh_8320016629450276_success_4827,
      act_8320016629450276_request_4826,
      evh_8320016629450276_click_4825,
      evh_8320016629450276_click,
      deleteItems: act_8320016629450276_request_4826,
    }
  }
}

Vue.component("DunningLetters", DunningLetters);

</script>