<template>
<ItpPage
  card
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Invoice"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.invoice.color"
    slot="header"
    v-if="item"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcInvoiceHeader
      :invoice="item"
      :class="b_8564662037462133_class"
      @created="evh_8564662037462133_created($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
      @reload="evh_8564662037462133_reload($event, {})"
    >
    </UcInvoiceHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpPanel
      collapsible
      :caption="'Invoice data'|pgettext('Invoice')"
      carousel
      class="mb-4"
      v-if="!isNew"
    >
      <UcInvoiceData
        :invoice="item"
        :reload="lastLoaded"
        @reload="evh_5534025912102772_reload($event, {})"
      >
      </UcInvoiceData>
    </ItpPanel>
    <DIV
      class="overflow-hidden w-100"
      v-if="currency && item"
    >
      <UcInvoiceLines
        :invoice="item"
        :currency="currency"
        :defaultTaxCode="item.meta.default_tax_code"
        :defaultDiscount="item.meta.default_discount"
        :reload="lastLoaded"
        class="h-100 w-100"
        @reload="evh_1468474219929533_reload($event, {})"
      >
      </UcInvoiceLines>
    </DIV>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    :color="$config.invoice.color"
    slot="footer"
    v-if="item"
  >
    <UcInvoiceFooter
      :invoice="item"
      :currency="currency"
      :exchangedCurrency="exchangedCurrency"
      v-if="currency"
      @reload="evh_1548630417156826_reload($event, {})"
    >
    </UcInvoiceFooter>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "invoice",
    path: "/invoices/:id",
    component: "Invoice",
    params: [{
      name: "id",
      prop: "oid",
    }
      ,
    ]

  }
)

addRoute(
  {
    name: "incomingInvoiceCreate",
    path: "/invoices-in/create",
    component: "Invoice",
    componentProps: [{
      name: "newInvoiceKind",
      value: "incoming",
    }
      ,
    ]

  }
)

addRoute(
  {
    name: "outgoingInvoiceCreate",
    path: "/invoices-out/create",
    component: "Invoice",
    componentProps: [{
      name: "newInvoiceKind",
      value: "outgoing",
    }
      ,
    ]

  }
)

@Component()
export default class Invoice extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
  })
  invoiceRegisterEntry!: string;

  @Watch('invoiceRegisterEntry')
  onInvoice_register_entry(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceRegisterEntry")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
  })
  newInvoiceKind!: string;

  @Watch('newInvoiceKind')
  onNew_invoice_kind(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("newInvoiceKind")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  blankItem!: any;

  async $$load_blankItem() {
    return (this.newInvoiceKind == null && this.invoice_register_entry == null) ?
      null :
      this.$fn.fetch('get_invoice_template', {
        parameters: {
          invoice_kind: this.newInvoiceKind,
          invoice_register_entry: this.invoiceRegisterEntry
        },
        asResource: true
      })
  }

  item!: any;

  async $$load_item() {
    return this.blankItem ||
      this.$fn.fetch('read_invoice', { parameters: { id: this.oid } })
        .then(d => {

          if (d.billing_period == null) {
            d.billing_period = {
              start: null,
              end: null
            };
          }

          return new this.$fn.Resource(d);
        })
  }

  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
      .then(data => _.reduce(
        data,
        (m, v) => (m[v.code] = v, m),
        {}
      ))
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_8564662037462133_class!: any;

  async $$load_b_8564662037462133_class() {
    return {
      "bg-transparent": this.card,
      "shadow-sm": this.card,
    }
  }

  dataMembers = ['blankItem', 'item', 'currencies', 'ux', 'b_7315092382398562_modalBindings', 'b_8564662037462133_class'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        blankItem: null,
        item: null,
        currencies: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          noPrimaryButton: true,
          closeButtonText: this.$fn.gettext("Close"),
          size: "full-screen",
        }
        ,
        b_8564662037462133_class: null,
      },
    }
  }

  get isNew() {
    if (this.loaded) {

      return !this.item || !this.item.id
    }
    return null;
  }

  get invoiceTypeName() {
    if (this.loaded) {

      return this.item.invoice_type === "correction" ? this.$fn.pgettext("InvoiceType", "Correction") :
        (this.item.invoice_type === "storno" ? this.$fn.pgettext("InvoiceType", "Storno") :
          (this.item.invoice_type === "proforma" ? this.$fn.pgettext("InvoiceType", "Pro Forma") : "?"))
    }
    return null;
  }

  get currency() {
    if (this.loaded) {

      return this.currencies[this.item.currency]
    }
    return null;
  }

  get exchangedCurrency() {
    if (this.loaded) {

      return this.currencies[this.item.exchanged_currency]
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.item.id ? this.item.invoice_number : this.$fn.gettext('Create Invoice'),
        icon: this.$config.invoice.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2280_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2280, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_navigate_2284_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_8564662037462133_navigate_2284, alias=undefined
    return {
      location: {
        name: "app.invoice",
        params: {
          id: $event.data.id,
        }
        ,
      }
      ,
    }
  }

  async evh_8564662037462133_created(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_created.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async evh_5534025912102772_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_reload.executeFromDOM(this, event, scope);
  }

  async evh_1468474219929533_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1468474219929533_reload.executeFromDOM(this, event, scope);
  }

  async evh_1548630417156826_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2280: actions.CloseModalAction;
    evh_7315092382398562_close_2279: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_2282: actions.CloseModalAction;
    evh_2248226175642056_close_2281: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_navigate_2284: actions.NavigateAction;
    evh_8564662037462133_created_2283: actions.EventHandlerImpl;
    evh_8564662037462133_created: actions.EventHandlerGroup;
    act_8564662037462133_closeComponent_2286: actions.CloseComponentAction;
    evh_8564662037462133_deleted_2285: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_2288: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_2287: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_5534025912102772_reloadComponentData_2290: actions.ReloadComponentDataAction;
    evh_5534025912102772_reload_2289: actions.EventHandlerImpl;
    evh_5534025912102772_reload: actions.EventHandlerGroup;
    act_1468474219929533_reloadComponentData_2292: actions.ReloadComponentDataAction;
    evh_1468474219929533_reload_2291: actions.EventHandlerImpl;
    evh_1468474219929533_reload: actions.EventHandlerGroup;
    act_1548630417156826_reloadComponentData_2294: actions.ReloadComponentDataAction;
    evh_1548630417156826_reload_2293: actions.EventHandlerImpl;
    evh_1548630417156826_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2280 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2280_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2279 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2280,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2279],
      }
    );
    const act_2248226175642056_closeModal_2282 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2281 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_2282,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2281],
      }
    );
    const act_8564662037462133_navigate_2284 = new actions.NavigateAction(
      {
        actionArgs: this.act_8564662037462133_navigate_2284_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_created_2283 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_navigate_2284,
        event: "created",
        displayName: "navigate",
      }
    );
    const evh_8564662037462133_created = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_created_2283],
      }
    );
    const act_8564662037462133_closeComponent_2286 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_2285 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeComponent_2286,
        event: "deleted",
        displayName: "closeComponent",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_2285],
      }
    );
    const act_8564662037462133_reloadComponentData_2288 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_2287 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_2288,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_2287],
      }
    );
    const act_5534025912102772_reloadComponentData_2290 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_5534025912102772_reload_2289 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_reloadComponentData_2290,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_5534025912102772_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_reload_2289],
      }
    );
    const act_1468474219929533_reloadComponentData_2292 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_1468474219929533_reload_2291 = new actions.EventHandlerImpl(
      {
        action: act_1468474219929533_reloadComponentData_2292,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_1468474219929533_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_1468474219929533_reload_2291],
      }
    );
    const act_1548630417156826_reloadComponentData_2294 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_1548630417156826_reload_2293 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_reloadComponentData_2294,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_1548630417156826_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_reload_2293],
      }
    );
    return {
      act_7315092382398562_closeModal_2280,
      evh_7315092382398562_close_2279,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_2282,
      evh_2248226175642056_close_2281,
      evh_2248226175642056_close,
      act_8564662037462133_navigate_2284,
      evh_8564662037462133_created_2283,
      evh_8564662037462133_created,
      act_8564662037462133_closeComponent_2286,
      evh_8564662037462133_deleted_2285,
      evh_8564662037462133_deleted,
      act_8564662037462133_reloadComponentData_2288,
      evh_8564662037462133_reload_2287,
      evh_8564662037462133_reload,
      act_5534025912102772_reloadComponentData_2290,
      evh_5534025912102772_reload_2289,
      evh_5534025912102772_reload,
      act_1468474219929533_reloadComponentData_2292,
      evh_1468474219929533_reload_2291,
      evh_1468474219929533_reload,
      act_1548630417156826_reloadComponentData_2294,
      evh_1548630417156826_reload_2293,
      evh_1548630417156826_reload,
    }
  }
}

Vue.component("Invoice", Invoice);

</script>