<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    alignItems="center"
  >
    <ItpText
      :title="fromTooltip || defaultFromTooltip"
    >
      {{ from ? $options.filters.date(from) : (missingFromMessage || defaultMissingFromMessage) }}
    </ItpText>
    <span
      v-if="to || missingToMessage || defaultMissingToMessage"
    >
      &nbsp;-&nbsp;
    </span>
    <ItpText
      :title="toTooltip || defaultToTooltip"
      v-if="to || missingToMessage || defaultMissingToMessage"
    >
      {{ to ? $options.filters.date(to) : (missingToMessage || defaultMissingToMessage) }}
    </ItpText>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcValidityPeriod extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
  })
  from!: date;

  @Watch('from')
  onFrom(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("from")
  }

  @Prop({
  })
  to!: date;

  @Watch('to')
  onTo(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("to")
  }

  @Prop({
    type: String,
  })
  fromTooltip!: string;

  @Watch('fromTooltip')
  onFrom_tooltip(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fromTooltip")
  }

  @Prop({
    type: String,
  })
  toTooltip!: string;

  @Watch('toTooltip')
  onTo_tooltip(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("toTooltip")
  }

  @Prop({
    type: String,
  })
  missingFromMessage!: string;

  @Watch('missingFromMessage')
  onMissing_from_message(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("missingFromMessage")
  }

  @Prop({
    type: String,
  })
  missingToMessage!: string;

  @Watch('missingToMessage')
  onMissing_to_message(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("missingToMessage")
  }

  defaultFromTooltip!: any;
  defaultToTooltip!: any;
  defaultMissingFromMessage!: any;
  defaultMissingToMessage!: any;
  ux!: any;
  dataMembers = ['defaultFromTooltip', 'defaultToTooltip', 'defaultMissingFromMessage', 'defaultMissingToMessage', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        defaultFromTooltip: this.$fn.pgettext("ValidityPeriod", "Valid from"),
        defaultToTooltip: this.$fn.pgettext("ValidityPeriod", "Valid from"),
        defaultMissingFromMessage: this.$fn.pgettext("UcValidityPeriod", "N/A"),
        defaultMissingToMessage: this.$fn.pgettext("UcValidityPeriod", "Indefinite"),
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_1830: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_1829: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_1830 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_1829 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_1830,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_1829],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_1830,
      evh_7315092382398562_reload_1829,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcValidityPeriod", UcValidityPeriod);

</script>