"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var ReplaceMeter = (function (_super) {
    __extends(ReplaceMeter, _super);
    function ReplaceMeter() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "modal",
        };
        _this.dataMembers = ['formId', 'labelAlign', 'item', 'responseData', 'workflowStepsInfo', 'units', 'selectedOldMeter', 'old_reading_image', 'new_reading_image', 'ux', 'b_4963030162390099_dataSource', 'b_4246589045129675_dataSource'];
        return _this;
    }
    ReplaceMeter.prototype.beforeCreate = function () {
    };
    ReplaceMeter.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    ReplaceMeter.prototype.$$load_item = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.schemaDefaults('ReplaceMeter')];
            });
        });
    };
    ReplaceMeter.prototype.$$load_units = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('get_meter_type_units')];
            });
        });
    };
    ReplaceMeter.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            labelAlign: "right",
            item: null,
            responseData: null,
            workflowStepsInfo: {
                steps: ["rögzíti a leszerelt mérőóra állását",
                    "inaktiválja a leszerelt órát",
                    "inaktiválja a kapcsolódó bérleménynél beállított mért szolgáltatást",
                    "létrehoz egy új mérőórát a megadott sorozatszámmal",
                    "rögzíti az induló mérőállást",
                    "a kapcsolódó bérleménynél a leszerelt mérőóra helyett beállít egy mért szolgáltatást az új mérőórával",
                ],
            },
            units: null,
            selectedOldMeter: null,
            old_reading_image: null,
            new_reading_image: null,
            ux: null,
            b_4963030162390099_dataSource: {
                name: "suggest_meter",
                parameters: {
                    exclude_types: ["pulse",
                    ],
                },
            },
            b_4246589045129675_dataSource: {
                name: "suggest_meter",
                parameters: {
                    exclude_types: ["pulse",
                    ],
                },
            },
        });
    };
    Object.defineProperty(ReplaceMeter.prototype, "isReadOnly", {
        get: function () {
            if (this.loaded) {
                return !!(this.responseData || (this.actions.submit && this.actions.submit.isRunning));
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ReplaceMeter.prototype, "oldMeterReadingUnitOptions", {
        get: function () {
            if (this.loaded) {
                return this.selectedOldMeter ? this.units[this.selectedOldMeter.meter_type] : [];
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ReplaceMeter.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.$fn.pgettext("ReplaceMeterModal.Title", "Replace Meter"),
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    ReplaceMeter.prototype.act_7315092382398562_closeModal_2784_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    ReplaceMeter.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ReplaceMeter.prototype.evh_2248226175642056_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ReplaceMeter.prototype.act_1040167445267876_setData_2790_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "responseData",
                        value: $event.data.response.data,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ReplaceMeter.prototype.act_1040167445267876_request_2788_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "create_meter_operation",
                        data: this.item,
                        parameters: {
                            op: "replacement",
                        },
                    }];
            });
        });
    };
    ReplaceMeter.prototype.evh_1040167445267876_submit = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ReplaceMeter.prototype.act_4963030162390099_setData_2792_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.old_meter.unit",
                        value: $event.data.option.unit,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ReplaceMeter.prototype.act_4963030162390099_setData_2794_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "selectedOldMeter",
                        value: $event.data.option,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ReplaceMeter.prototype.evh_4963030162390099_optionSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4963030162390099_optionSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ReplaceMeter.prototype.act_3855552110905499_setData_2796_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.old_meter.image",
                        value: this.$fn.imageData($event.value),
                        resolve: true,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ReplaceMeter.prototype.evh_3855552110905499_changed = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3855552110905499_changed.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ReplaceMeter.prototype.act_4246589045129675_setData_2798_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.new_meter.serial",
                        value: $event.data.option.serial_number,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ReplaceMeter.prototype.act_4246589045129675_setData_2798_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.option];
            });
        });
    };
    ReplaceMeter.prototype.act_4246589045129675_setData_2800_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.new_meter.id",
                        value: $event.data.option.id,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ReplaceMeter.prototype.act_4246589045129675_setData_2800_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.option];
            });
        });
    };
    ReplaceMeter.prototype.act_4246589045129675_setData_2802_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.new_meter.unit",
                        value: $event.data.option.unit,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ReplaceMeter.prototype.act_4246589045129675_setData_2802_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.option];
            });
        });
    };
    ReplaceMeter.prototype.evh_4246589045129675_optionSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4246589045129675_optionSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ReplaceMeter.prototype.evh_353388685162594_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_353388685162594_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ReplaceMeter.prototype.evh_1548630417156826_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ReplaceMeter.prototype.getActions = function () {
        var act_7315092382398562_closeModal_2784 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2784_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_2783 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2784,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2783],
        });
        var act_2248226175642056_closeComponent_2786 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_2248226175642056_close_2785 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2786,
            event: "close",
            displayName: "closeComponent",
        });
        var evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2785],
        });
        var act_1040167445267876_setData_2790 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_2790_getActionArgs,
            events: [],
        });
        var evh_1040167445267876_success_2789 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_2790,
            event: "success",
            displayName: "setData",
        });
        var act_1040167445267876_request_2788 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_2788_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_2789],
        });
        var evh_1040167445267876_submit_2787 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_2788,
            event: "submit",
            displayName: "submit",
        });
        var evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_2787],
        });
        var act_4963030162390099_setData_2792 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4963030162390099_setData_2792_getActionArgs,
            events: [],
        });
        var evh_4963030162390099_optionSelected_2791 = new core_1.actions.EventHandlerImpl({
            action: act_4963030162390099_setData_2792,
            event: "optionSelected",
            displayName: "setData",
        });
        var act_4963030162390099_setData_2794 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4963030162390099_setData_2794_getActionArgs,
            events: [],
        });
        var evh_4963030162390099_optionSelected_2793 = new core_1.actions.EventHandlerImpl({
            action: act_4963030162390099_setData_2794,
            event: "optionSelected",
            displayName: "setData",
        });
        var evh_4963030162390099_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4963030162390099_optionSelected_2791, evh_4963030162390099_optionSelected_2793],
        });
        var act_3855552110905499_setData_2796 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3855552110905499_setData_2796_getActionArgs,
            events: [],
        });
        var evh_3855552110905499_changed_2795 = new core_1.actions.EventHandlerImpl({
            action: act_3855552110905499_setData_2796,
            event: "changed",
            displayName: "setData",
        });
        var evh_3855552110905499_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3855552110905499_changed_2795],
        });
        var act_4246589045129675_setData_2798 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4246589045129675_setData_2798_getActionArgs,
            when: this.act_4246589045129675_setData_2798_getWhen,
            events: [],
        });
        var evh_4246589045129675_optionSelected_2797 = new core_1.actions.EventHandlerImpl({
            action: act_4246589045129675_setData_2798,
            event: "optionSelected",
            displayName: "setData",
        });
        var act_4246589045129675_setData_2800 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4246589045129675_setData_2800_getActionArgs,
            when: this.act_4246589045129675_setData_2800_getWhen,
            events: [],
        });
        var evh_4246589045129675_optionSelected_2799 = new core_1.actions.EventHandlerImpl({
            action: act_4246589045129675_setData_2800,
            event: "optionSelected",
            displayName: "setData",
        });
        var act_4246589045129675_setData_2802 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4246589045129675_setData_2802_getActionArgs,
            when: this.act_4246589045129675_setData_2802_getWhen,
            events: [],
        });
        var evh_4246589045129675_optionSelected_2801 = new core_1.actions.EventHandlerImpl({
            action: act_4246589045129675_setData_2802,
            event: "optionSelected",
            displayName: "setData",
        });
        var evh_4246589045129675_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4246589045129675_optionSelected_2797, evh_4246589045129675_optionSelected_2799, evh_4246589045129675_optionSelected_2801],
        });
        var act_353388685162594_closeComponent_2804 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_353388685162594_clickPreventStop_2803 = new core_1.actions.EventHandlerImpl({
            action: act_353388685162594_closeComponent_2804,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        var evh_353388685162594_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_353388685162594_clickPreventStop_2803],
        });
        var act_1548630417156826_closeComponent_2806 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_1548630417156826_clickPreventStop_2805 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_2806,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        var evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_2805],
        });
        return {
            act_7315092382398562_closeModal_2784: act_7315092382398562_closeModal_2784,
            evh_7315092382398562_close_2783: evh_7315092382398562_close_2783,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2786: act_2248226175642056_closeComponent_2786,
            evh_2248226175642056_close_2785: evh_2248226175642056_close_2785,
            evh_2248226175642056_close: evh_2248226175642056_close,
            act_1040167445267876_setData_2790: act_1040167445267876_setData_2790,
            evh_1040167445267876_success_2789: evh_1040167445267876_success_2789,
            act_1040167445267876_request_2788: act_1040167445267876_request_2788,
            evh_1040167445267876_submit_2787: evh_1040167445267876_submit_2787,
            evh_1040167445267876_submit: evh_1040167445267876_submit,
            act_4963030162390099_setData_2792: act_4963030162390099_setData_2792,
            evh_4963030162390099_optionSelected_2791: evh_4963030162390099_optionSelected_2791,
            act_4963030162390099_setData_2794: act_4963030162390099_setData_2794,
            evh_4963030162390099_optionSelected_2793: evh_4963030162390099_optionSelected_2793,
            evh_4963030162390099_optionSelected: evh_4963030162390099_optionSelected,
            act_3855552110905499_setData_2796: act_3855552110905499_setData_2796,
            evh_3855552110905499_changed_2795: evh_3855552110905499_changed_2795,
            evh_3855552110905499_changed: evh_3855552110905499_changed,
            act_4246589045129675_setData_2798: act_4246589045129675_setData_2798,
            evh_4246589045129675_optionSelected_2797: evh_4246589045129675_optionSelected_2797,
            act_4246589045129675_setData_2800: act_4246589045129675_setData_2800,
            evh_4246589045129675_optionSelected_2799: evh_4246589045129675_optionSelected_2799,
            act_4246589045129675_setData_2802: act_4246589045129675_setData_2802,
            evh_4246589045129675_optionSelected_2801: evh_4246589045129675_optionSelected_2801,
            evh_4246589045129675_optionSelected: evh_4246589045129675_optionSelected,
            act_353388685162594_closeComponent_2804: act_353388685162594_closeComponent_2804,
            evh_353388685162594_clickPreventStop_2803: evh_353388685162594_clickPreventStop_2803,
            evh_353388685162594_clickPreventStop: evh_353388685162594_clickPreventStop,
            act_1548630417156826_closeComponent_2806: act_1548630417156826_closeComponent_2806,
            evh_1548630417156826_clickPreventStop_2805: evh_1548630417156826_clickPreventStop_2805,
            evh_1548630417156826_clickPreventStop: evh_1548630417156826_clickPreventStop,
            submit: act_1040167445267876_request_2788,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "lg",
        }),
        __metadata("design:type", String)
    ], ReplaceMeter.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], ReplaceMeter.prototype, "onSize", null);
    ReplaceMeter = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], ReplaceMeter);
    return ReplaceMeter;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = ReplaceMeter;
vue_property_decorator_1.Vue.component("ReplaceMeter", ReplaceMeter);
