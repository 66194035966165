var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "b-navbar",
        { attrs: { type: "light", variant: "default" } },
        [
          _c(
            "b-navbar-brand",
            { attrs: { href: "#/" } },
            [
              _vm._v("\n      " + _vm._s(_vm.$app.spec.brand) + "\n"),
              _vm.displayTestBadge
                ? _c(
                    "ItpBadge",
                    { staticClass: "mx-2", attrs: { variant: "danger" } },
                    [_vm._v("Test")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.displayDevBadge
                ? _c(
                    "ItpBadge",
                    { staticClass: "mx-2", attrs: { variant: "warning" } },
                    [_vm._v("Dev")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item-dropdown",
                    [
                      _c("template", { slot: "button-content" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("pgettext")("Finance", "MainMenu")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpDropdownText", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("pgettext")("Register", "MainMenu")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/letter-register",
                          icon: _vm.$config.letterRegister.icon,
                          text: _vm._f("pgettext")(
                            "Letter register",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/invoice-register",
                          icon: _vm.$config.invoiceRegister.icon,
                          text: _vm._f("pgettext")(
                            "Invoice register",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpDropdownDivider"),
                      _vm._v(" "),
                      _c("ItpDropdownText", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "Customer invoices",
                                "MainMenu"
                              )
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/invoices?kind=outgoing",
                          icon: _vm.$config.invoice.customerInvoiceIcon,
                          text: _vm._f("pgettext")(
                            "Customer invoices",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/draft-invoices",
                          icon: _vm.$config.invoice.draftInvoicesIcon,
                          text: _vm._f("pgettext")(
                            "Invoice Generation",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New Customer Invoice...",
                            "MainMenu"
                          ),
                          icon: _vm.$config.invoice.customerInvoiceIcon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_5970709076725755_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: _vm.$fn.urlFor(
                            "controlling_outgoing_invoice_lines",
                            { format: "csv" }
                          ),
                          target: "_blank",
                          icon: _vm.$config.downloadIcon,
                          text: _vm._f("pgettext")(
                            "Download outgoing invoice lines",
                            "MainMenu/Finance/Downloads"
                          ),
                          "dropdown-item": "",
                          "use-auth": "",
                          "show-spinner": "",
                          "force-download": "",
                          "download-file-name": "outgoing-invoice-lines.csv"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: _vm.$fn.urlFor(
                            "public_controlling_outgoing_invoice_lines",
                            { format: "csv" }
                          ),
                          target: "_blank",
                          icon: _vm.$config.downloadIcon,
                          text: _vm._f("pgettext")(
                            "Download outgoing invoice lines (exp)",
                            "MainMenu/Finance/Downloads"
                          ),
                          "dropdown-item": "",
                          "use-auth": "",
                          "show-spinner": "",
                          "force-download": "",
                          "download-file-name": "outgoing-invoice-lines-exp.csv"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpDropdownDivider"),
                      _vm._v(" "),
                      _c("ItpDropdownText", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "Incoming invoices",
                                "MainMenu"
                              )
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/invoices?kind=incoming",
                          icon: _vm.$config.invoice.vendorInvoiceIcon,
                          text: _vm._f("pgettext")(
                            "Incoming invoices",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New Incoming Invoice...",
                            "MainMenu"
                          ),
                          icon: _vm.$config.invoice.icon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_4190152961853837_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: _vm.$fn.urlFor(
                            "controlling_incoming_invoice_lines",
                            { format: "csv" }
                          ),
                          target: "_blank",
                          icon: _vm.$config.downloadIcon,
                          text: _vm._f("pgettext")(
                            "Download incoming invoice lines",
                            "MainMenu/Finance/Downloads"
                          ),
                          "dropdown-item": "",
                          "use-auth": "",
                          "show-spinner": "",
                          "force-download": "",
                          "download-file-name": "incoming-invoice-lines.csv"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpDropdownDivider"),
                      _vm._v(" "),
                      _c("ItpDropdownText", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("pgettext")("Payments", "MainMenu")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/payments",
                          icon: _vm.$config.payment.icon,
                          text: _vm._f("pgettext")("Payments", "PaymentsView"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/accounts",
                          icon: _vm.$config.account.icon,
                          text: _vm._f("pgettext")(
                            "Cash and Bank Accounts",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/accounts-daily-flow",
                          icon: _vm.$config.accountsDailyFlow.icon,
                          text: _vm._f("pgettext")(
                            "Accounts Daily Flow",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New Cash In...",
                            "Create Payment"
                          ),
                          icon: _vm.$config.payment.icon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_2424316887484278_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New Cash Out...",
                            "Create Payment"
                          ),
                          icon: _vm.$config.payment.icon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_7637021470970119_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New Banking...",
                            "Create Payment"
                          ),
                          icon: _vm.$config.payment.icon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_2931973773564833_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Account statement report...",
                            "MeinMenu"
                          ),
                          icon: _vm.$config.payment.icon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_2269742420845197_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: _vm.$fn.urlFor("controlling_payments", {
                            format: "xlsx"
                          }),
                          target: "_blank",
                          icon: _vm.$config.downloadIcon,
                          text: _vm._f("pgettext")(
                            "Download payments",
                            "MainMenu/Finance/Downloads"
                          ),
                          "dropdown-item": "",
                          "use-auth": "",
                          "show-spinner": "",
                          "force-download": "",
                          "download-file-name": "payments-data.xlsx"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: _vm.$fn.urlFor("controlling_invoice_payments", {
                            format: "xlsx"
                          }),
                          target: "_blank",
                          icon: _vm.$config.downloadIcon,
                          text: _vm._f("pgettext")(
                            "Download invoice payments",
                            "MainMenu/Finance/Downloads"
                          ),
                          "dropdown-item": "",
                          "use-auth": "",
                          "show-spinner": "",
                          "force-download": "",
                          "download-file-name": "invoice-payments-data.xlsx"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: _vm.$fn.urlFor("controlling_invoice_info", {
                            format: "xlsx"
                          }),
                          target: "_blank",
                          icon: _vm.$config.downloadIcon,
                          text: _vm._f("pgettext")(
                            "Download invoice info",
                            "MainMenu/Finance/Downloads"
                          ),
                          "dropdown-item": "",
                          "use-auth": "",
                          "show-spinner": "",
                          "force-download": "",
                          "download-file-name": "invoice-info-data.xlsx"
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpDropdownDivider"),
                      _vm._v(" "),
                      _c("ItpDropdownText", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "Claims management",
                                "MainMenu"
                              )
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/claims",
                          icon: _vm.$config.claim.icon,
                          text: _vm._f("pgettext")("Claims", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/dunning-letters",
                          icon: _vm.$config.dunningLetter.icon,
                          text: _vm._f("pgettext")(
                            "Dunning letters",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item-dropdown",
                    [
                      _c("template", { slot: "button-content" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("pgettext")("Assets", "MainMenu")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/fixed-assets",
                          icon: _vm.$config.fixedAsset.icon,
                          text: _vm._f("pgettext")("Fixed Assets", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Create Asset...",
                            "MainMenu"
                          ),
                          icon: _vm.$config.fixedAsset.icon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_1886107574314164_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpDropdownDivider"),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/waste-bins",
                          icon: _vm.$config.wasteBin.icon,
                          text: _vm._f("pgettext")("Waste Bins", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/waste-bin-readings",
                          icon: _vm.$config.wasteBin.icon,
                          text: _vm._f("pgettext")(
                            "Waste Bin Readings",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Create Waste Bin...",
                            "MainMenu"
                          ),
                          icon: _vm.$config.wasteBin.createWasteBinIcon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_7260361660686930_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpDropdownDivider"),
                      _vm._v(" "),
                      _c("ItpDropdownText", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("pgettext")("Meters", "MainMenu")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/meters",
                          icon: _vm.$config.meter.icon,
                          text: _vm._f("pgettext")("Meters", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")("Add Meter...", "MainMenu"),
                          icon: _vm.$config.meter.addMeterIcon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_5449511595610967_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/auto-readings",
                          icon: _vm.$config.meterAutoReading.icon,
                          text: _vm._f("pgettext")("Auto Readings", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: "#",
                          icon: "fa-upload",
                          text: _vm._f("pgettext")(
                            "Meter Data Exchange...",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_8050584411731670_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: "#",
                          icon: _vm.$config.meterReplacement.icon,
                          text: _vm._f("pgettext")(
                            "Replace meter...",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_7807097906137782_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: _vm.$fn.urlFor(
                            "controlling_meter_consumptions",
                            { format: "xlsx" }
                          ),
                          target: "_blank",
                          icon: _vm.$config.downloadIcon,
                          text: _vm._f("pgettext")(
                            "Download meter consumptions",
                            "MainMenu/Meters/Downloads"
                          ),
                          "dropdown-item": "",
                          "use-auth": "",
                          "show-spinner": "",
                          "force-download": "",
                          "download-file-name": "meter-consumptions.xlsx"
                        }
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item-dropdown",
                    [
                      _c("template", { slot: "button-content" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("pgettext")("Contracts", "MainMenu")
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/contracts",
                          icon: _vm.$config.contract.icon,
                          text: _vm._f("pgettext")("Contracts", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Create Contract...",
                            "MainMenu"
                          ),
                          icon: _vm.$config.contract.icon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_2338624680052783_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpDropdownDivider"),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/overlapping-revisions",
                          icon:
                            _vm.$config.contract.views.overlappingRevisions
                              .icon,
                          text: _vm._f("pgettext")(
                            "Overlapping Revisions",
                            "MainMenu"
                          ),
                          "dropdown-item": ""
                        }
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item-dropdown",
                    [
                      _c("template", { slot: "button-content" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("pgettext")("Rentals", "MainMenu")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/rentals",
                          icon: _vm.$config.rental.icon,
                          text: _vm._f("pgettext")("Rentals", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")("Add Rental...", "MainMenu"),
                          icon: _vm.$config.rental.addRentalIcon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_2596033535867385_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpDropdownDivider"),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/properties",
                          icon: _vm.$config.property.icon,
                          text: _vm._f("pgettext")("Locations", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Add Property...",
                            "MainMenu"
                          ),
                          icon: _vm.$config.property.addPropertyIcon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_6794737306373342_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item-dropdown",
                    [
                      _c("template", { slot: "button-content" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("pgettext")("Clients", "MainMenu")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/clients",
                          icon: _vm.$config.client.icon,
                          text: _vm._f("pgettext")("Clients", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")("New client...", "MainMenu"),
                          icon: _vm.$config.client.createClientIcon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_2660996057968802_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item-dropdown",
                    [
                      _c("template", { slot: "button-content" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("pgettext")("Products", "MainMenu")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/products",
                          icon: _vm.$config.product.icon,
                          text: _vm._f("pgettext")("Products", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New product/service...",
                            "MainMenu"
                          ),
                          icon: _vm.$config.product.addProductIcon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_7567745526909371_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item-dropdown",
                    [
                      _c("template", { slot: "button-content" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("pgettext")("Worksheets", "MainMenu")
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/worksheets",
                          icon: _vm.$config.worksheet.icon,
                          text: _vm._f("pgettext")("Worksheets", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New Worksheet...",
                            "MainMenu"
                          ),
                          icon: _vm.$config.worksheet.createWorksheetIcon,
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_7228767045058026_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          to: "/worksheets-work-summary",
                          icon: _vm.$config.worksheet.icon,
                          text: _vm._f("pgettext")("Work summary", "MainMenu"),
                          "dropdown-item": ""
                        }
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-item-dropdown",
                    { staticClass: "mx-3", attrs: { right: "" } },
                    [
                      _c(
                        "template",
                        { slot: "button-content" },
                        [
                          _c("ItpIcon", {
                            staticClass: "mr-1",
                            attrs: { name: "fa-user-circle" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$app.auth.user.fullName ||
                                    _vm.$app.auth.user.userName
                                ) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: _vm.$app.auth.accountUrl,
                          target: "_blank",
                          icon: "fa-user-circle",
                          text: _vm._f("pgettext")("Profile", "MainMenu"),
                          "dropdown-item": ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "ItpNavItem",
                        { attrs: { icon: "fa-globe" } },
                        [
                          _c("ItpLocaleSelector", {
                            staticClass: "nav-item-content"
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-dropdown-divider"),
                      _vm._v(" "),
                      _c(
                        "ItpNavItem",
                        { attrs: { icon: "line-md:confirm-circle" } },
                        [
                          _c(
                            "BFormCheckbox",
                            {
                              staticClass: "nav-item-content",
                              attrs: { switch: "" },
                              on: {
                                change: function($event) {
                                  return _vm.evh_298118714321807_change(
                                    $event,
                                    {}
                                  )
                                }
                              },
                              model: {
                                value: _vm.$app.confirmPageClose,
                                callback: function($$v) {
                                  _vm.$set(_vm.$app, "confirmPageClose", $$v)
                                },
                                expression: "$app.confirmPageClose"
                              }
                            },
                            [
                              _c("ItpText", {
                                staticClass: "user-select-none",
                                attrs: {
                                  text: _vm._f("pgettext")(
                                    "Confirm tab close",
                                    "MainMenu"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: "#",
                          icon: "fa-sign-out-alt",
                          text: _vm._f("pgettext")("Sign out", "MainMenu"),
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_7106235911078350_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item-dropdown",
                    { attrs: { right: "" } },
                    [
                      _c("ItpIcon", {
                        attrs: { slot: "button-content", name: "fa-bars" },
                        slot: "button-content"
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          "dropdown-item": "",
                          to: "/jobs",
                          icon: "fa-cogs",
                          text: _vm._f("pgettext")("Jobs", "MainMenu")
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          "dropdown-item": "",
                          to: "/jobs-v2",
                          icon: "fa-cogs",
                          text: _vm._f("pgettext")("Jobs (v2)", "MainMenu")
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          "dropdown-item": "",
                          to: "/templates",
                          icon: "fa-file-invoice",
                          text: _vm._f("pgettext")("Templates", "MainMenu")
                        }
                      }),
                      _vm._v(" "),
                      _c("b-dropdown-divider"),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          "dropdown-item": "",
                          to: "/settings",
                          icon: "fa-cog",
                          text: _vm._f("pgettext")("Settings", "MainMenu")
                        }
                      }),
                      _vm._v(" "),
                      _c("ItpNavItem", {
                        attrs: {
                          href: "#",
                          icon: "fa-question-circle",
                          text: _vm._f("pgettext")("About Integro", "MainMenu"),
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_5361165673534032_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }