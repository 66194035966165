<template>
<ItpUsercontrol
  loading-spinner
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Properties'|pgettext('RentalFormProperties') }}
      </strong>
      <ItpButton
        :text="'Add property...'|gettext"
        size="sm"
        variant="light"
        class="ml-auto"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup
      v-if="loaded"
    >
      <BListGroupItem
        class="card-body text-center"
        v-if="!items.length"
      >
        <span>
          {{ 'No properties.'|pgettext('RentalFormProperties') }}
        </span>
      </BListGroupItem>
      <BListGroupItem
        :key="item.id"
        class="d-flex flex-column"
        v-for="item in items"
      >
        <DIV
          class="w-100"
        >
          <DIV
            class="d-flex flex-row"
          >
            <DIV
              class="px-2"
            >
              {{ item.property_unit.full_name }}
            </DIV>
            <DIV
              :id="item.property_unit.unit_type"
              class="px-2"
            >
              {{ item.property_unit.unit_type }}
            </DIV>
          </DIV>
          <DIV
            class="d-flex flex-row my-1"
          >
            <DIV
              class="px-2"
            >
              {{ item.valid_from }}
            </DIV>
            <DIV
              class="px-2"
            >
              {{ item.valid_to }}
            </DIV>
          </DIV>
          <DIV
            class="d-flex flex-row"
          >
            <ItpBadge
              variant="secondary"
              class="mx-2 shadow-none"
              v-if="item.locked"
            >
              <ItpIcon
                fa="lock"
                class="px-2"
              >
              </ItpIcon>
              <SPAN>
                {{ 'Locked'|gettext }}
              </SPAN>
            </ItpBadge>
            <ItpBadge
              :text="'Inactive'|gettext"
              variant="warning"
              class="mx-2 shadow-none"
              v-if="item.inactive"
            >
            </ItpBadge>
          </DIV>
        </DIV>
        <DIV
          class="ml-2 d-flex flex-row"
        >
          <ItpButton
            variant="default"
            size="sm"
            icon="fa-edit"
            v-if="editable"
            @click.prevent.stop="evh_6853569216922577_clickPreventStop($event, {item})"
          >
          </ItpButton>
          <ItpButton
            variant="default"
            size="sm"
            icon="fa-trash"
            v-if="editable && !item.locked"
            @click.prevent.stop="evh_6492569393195423_clickPreventStop($event, {item})"
          >
          </ItpButton>
        </DIV>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcRentalFormProperties extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Boolean,
    default: true,
  })
  editable!: boolean;

  @Watch('editable')
  onEditable(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("editable")
  }

  @Prop({
    required: true,
    type: String,
  })
  rentalId!: string;

  @Watch('rentalId')
  onRental_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rentalId")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_rental_property', { parameters: { rental_id: this.rentalId } })
  }

  ux!: any;
  dataMembers = ['items', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async evh_8320016629450276_close_6727_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_6727, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_6726_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_6726, alias=undefined
    return {
      name: "RentalProperty",
      props: {
        rentalId: this.rentalId,
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_6853569216922577_close_6731_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_6853569216922577_close_6731, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_6853569216922577_showModal_6730_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6853569216922577_showModal_6730, alias=undefined
    return {
      name: "RentalProperty",
      props: {
        rentalId: this.rentalId,
        resourceId: $event.data.item.id,
      }
      ,
    }
  }

  async evh_6853569216922577_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6853569216922577_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6492569393195423_request_6734_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_6492569393195423_request_6734, alias=undefined
    return {
      operation: "delete_rental_property",
      data: { ...$event.data.prop, rental_id: this.rentalId },
    }
  }

  async act_6492569393195423_request_6734_getConfirm($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_6492569393195423_request_6734, alias=undefined
    return {
      message: this.$fn.gettext("Please confirm delete."),
      btnOk: this.$fn.gettext("Delete"),
    }
  }

  async evh_6492569393195423_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6492569393195423_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6724: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6723: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_reloadComponentData_6728: actions.ReloadComponentDataAction;
    evh_8320016629450276_close_6727: actions.EventHandlerImpl;
    act_8320016629450276_showModal_6726: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_6725: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_6853569216922577_reloadComponentData_6732: actions.ReloadComponentDataAction;
    evh_6853569216922577_close_6731: actions.EventHandlerImpl;
    act_6853569216922577_showModal_6730: actions.ShowModalAction;
    evh_6853569216922577_clickPreventStop_6729: actions.EventHandlerImpl;
    evh_6853569216922577_clickPreventStop: actions.EventHandlerGroup;
    act_6492569393195423_reloadComponentData_6736: actions.ReloadComponentDataAction;
    evh_6492569393195423_success_6735: actions.EventHandlerImpl;
    act_6492569393195423_request_6734: actions.RequestAction;
    evh_6492569393195423_clickPreventStop_6733: actions.EventHandlerImpl;
    evh_6492569393195423_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6724 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6723 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6724,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6723],
      }
    );
    const act_8320016629450276_reloadComponentData_6728 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8320016629450276_close_6727 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadComponentData_6728,
        event: "close",
        when: this.evh_8320016629450276_close_6727_getWhen,
        displayName: "reloadComponentData",
      }
    );
    const act_8320016629450276_showModal_6726 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_6726_getActionArgs,
        events: [evh_8320016629450276_close_6727],
      }
    );
    const evh_8320016629450276_clickPreventStop_6725 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_6726,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_6725],
      }
    );
    const act_6853569216922577_reloadComponentData_6732 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6853569216922577_close_6731 = new actions.EventHandlerImpl(
      {
        action: act_6853569216922577_reloadComponentData_6732,
        event: "close",
        when: this.evh_6853569216922577_close_6731_getWhen,
        displayName: "reloadComponentData",
      }
    );
    const act_6853569216922577_showModal_6730 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6853569216922577_showModal_6730_getActionArgs,
        events: [evh_6853569216922577_close_6731],
      }
    );
    const evh_6853569216922577_clickPreventStop_6729 = new actions.EventHandlerImpl(
      {
        action: act_6853569216922577_showModal_6730,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_6853569216922577_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6853569216922577_clickPreventStop_6729],
      }
    );
    const act_6492569393195423_reloadComponentData_6736 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6492569393195423_success_6735 = new actions.EventHandlerImpl(
      {
        action: act_6492569393195423_reloadComponentData_6736,
        event: "success",
        displayName: "reloadComponentData",
      }
    );
    const act_6492569393195423_request_6734 = new actions.RequestAction(
      {
        actionArgs: this.act_6492569393195423_request_6734_getActionArgs,
        confirm: this.act_6492569393195423_request_6734_getConfirm,
        events: [evh_6492569393195423_success_6735],
      }
    );
    const evh_6492569393195423_clickPreventStop_6733 = new actions.EventHandlerImpl(
      {
        action: act_6492569393195423_request_6734,
        event: "click.prevent.stop",
        displayName: "request",
      }
    );
    const evh_6492569393195423_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6492569393195423_clickPreventStop_6733],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6724,
      evh_7315092382398562_reload_6723,
      evh_7315092382398562_reload,
      act_8320016629450276_reloadComponentData_6728,
      evh_8320016629450276_close_6727,
      act_8320016629450276_showModal_6726,
      evh_8320016629450276_clickPreventStop_6725,
      evh_8320016629450276_clickPreventStop,
      act_6853569216922577_reloadComponentData_6732,
      evh_6853569216922577_close_6731,
      act_6853569216922577_showModal_6730,
      evh_6853569216922577_clickPreventStop_6729,
      evh_6853569216922577_clickPreventStop,
      act_6492569393195423_reloadComponentData_6736,
      evh_6492569393195423_success_6735,
      act_6492569393195423_request_6734,
      evh_6492569393195423_clickPreventStop_6733,
      evh_6492569393195423_clickPreventStop,
    }
  }
}

Vue.component("UcRentalFormProperties", UcRentalFormProperties);

</script>