<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="entry_date"
    :label="'Entry date'|pgettext('LetterRegisterEntry')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormDatetime
      name="entry_date"
      v-model="item.entry_date"
      required
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="delivery_modes"
    :label="'Delivery modes'|pgettext('LetterRegisterEntry')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect2
      name="delivery_modes"
      v-model="item.delivery_modes"
      :options="deliveryModes"
      valueField="name"
      textField="name"
      multiple
      required
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="categories"
    :label="'Categories'|pgettext('LetterRegisterEntry')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="categories"
      multiple
      taggable
      :options="categories"
      v-model="item.categories"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="client"
    :labelAlign="labelAlign"
    :label="'Client'|pgettext('LetterRegisterEntry')"
    required
  >
    <ItpFormSelect2
      name="contractor"
      v-model="item.client_name"
      :dataSource="b_6917646200545441_dataSource"
      valueField="name"
      textField="name"
      taggable
      required
      @optionSelected="evh_6917646200545441_optionSelected($event, {})"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="subject"
    :label="'Subject'|pgettext('LetterRegisterEntry')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="subject"
      v-model="item.subject"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="notes"
    :label="'Notes'|pgettext('LetterRegisterEntry')"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="notes"
      v-model="item.notes"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
  <hr>
  </hr>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="delivery_date"
    :label="'Delivery date'|pgettext('LetterRegisterEntry')"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="delivery_date"
      v-model="item.delivery_date"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="undelivered"
    :label="'Undelivered'|pgettext('LetterRegisterEntry')"
    :labelAlign="labelAlign"
  >
    <ItpFormCheckbox
      name="undelivered"
      v-model="item.undelivered"
    >
    </ItpFormCheckbox>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    v-if="item.isNew"
  >
    <ItpButton
      type="submit"
      :form="form"
      :text="'Create entry'|pgettext('LetterRegisterEntry/Button')"
      class="mr-2"
    >
    </ItpButton>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    v-if="!item.isNew && !item.isReadOnly"
  >
    <ItpButton
      type="submit"
      :form="form"
      :text="'Save changes'|pgettext('Button')"
      class="mr-2"
    >
    </ItpButton>
  </ItpFormGroup>
  <hr
    v-if="item.id"
  >
  </hr>
  <UcAttachmentsList
    :parent="item"
    v-if="item.id"
  >
  </UcAttachmentsList>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetLetterRegisterEntry extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    type: Object,
  })
  form!: object;

  @Watch('form')
  onForm(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("form")
  }

  deliveryModes!: any;

  async $$load_deliveryModes() {
    return this.$fn.fetch('list_letter_register_delivery_modes').then(d => _.orderBy(d, ['name']))
  }

  categories!: any;

  async $$load_categories() {
    return []
  }

  ux!: any;
  b_6917646200545441_dataSource!: any;
  dataMembers = ['deliveryModes', 'categories', 'ux', 'b_6917646200545441_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        deliveryModes: null,
        categories: null,
        ux: null,
        b_6917646200545441_dataSource: {
          name: "suggest_client",
        }
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_6917646200545441_setData_2584_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6917646200545441_setData_2584, alias=undefined
    return {
      path: "item.client_name",
      value: $event.data.option.name,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_6917646200545441_setData_2586_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6917646200545441_setData_2586, alias=undefined
    return {
      path: "item.client_id",
      value: $event.data.option.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6917646200545441_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6917646200545441_optionSelected.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2582: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2581: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_6917646200545441_setData_2584: actions.SetDataAction;
    evh_6917646200545441_optionSelected_2583: actions.EventHandlerImpl;
    act_6917646200545441_setData_2586: actions.SetDataAction;
    evh_6917646200545441_optionSelected_2585: actions.EventHandlerImpl;
    evh_6917646200545441_optionSelected: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2582 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2581 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2582,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2581],
      }
    );
    const act_6917646200545441_setData_2584 = new actions.SetDataAction(
      {
        actionArgs: this.act_6917646200545441_setData_2584_getActionArgs,
        events: [],
      }
    );
    const evh_6917646200545441_optionSelected_2583 = new actions.EventHandlerImpl(
      {
        action: act_6917646200545441_setData_2584,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_6917646200545441_setData_2586 = new actions.SetDataAction(
      {
        actionArgs: this.act_6917646200545441_setData_2586_getActionArgs,
        events: [],
      }
    );
    const evh_6917646200545441_optionSelected_2585 = new actions.EventHandlerImpl(
      {
        action: act_6917646200545441_setData_2586,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_6917646200545441_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_6917646200545441_optionSelected_2583, evh_6917646200545441_optionSelected_2585],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2582,
      evh_7315092382398562_reload_2581,
      evh_7315092382398562_reload,
      act_6917646200545441_setData_2584,
      evh_6917646200545441_optionSelected_2583,
      act_6917646200545441_setData_2586,
      evh_6917646200545441_optionSelected_2585,
      evh_6917646200545441_optionSelected,
    }
  }
}

Vue.component("FieldsetLetterRegisterEntry", FieldsetLetterRegisterEntry);

</script>