<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceRegister"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="invoiceRegister"
    :headerTitle="'Invoice register'|pgettext('Invoice register')"
    :items="$fn.slickDataSource('list_invoice_register_entries', {parameters: getQueryParameters})"
    export-to-csv
    :exportToCsvFilename="'invoice-register'|pgettext('filename')"
    footer-totals
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @command="evh_2248226175642056_command($event, {})"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpFormSelect2
        name="register_name"
        v-model="selectedRegister"
        bind-object
        valueField="id"
        textField="name"
        :options="registers"
        class="w-25 mx-3"
        @input="evh_6466705138206980_input($event, {})"
      >
      </ItpFormSelect2>
      <ItpButton
        :icon="$config.invoiceRegister.createEntry"
        :text="'Add entry...'|pgettext('InvoiceRegister')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <b-dropdown
        size="sm"
        variant="light"
        toggle-class="itp-button border"
        :tooltip="'Set status to selected items'|pgettext('InvoiceRegister')"
        :disabled="!selectedItems || !selectedItems.length || (
    actions.setStatus && actions.setStatus.isRunning
  )"
        class="mx-2"
        v-if="statuses && statuses.length"
      >
        <span
          slot="button-content"
        >
          <b-spinner
            small
            type="grow"
            class="mr-1"
            v-if="actions.setStatus.isRunning"
          >
          </b-spinner>
          <ItpText
            :text="'Set status'|pgettext('InvoiceRegister')"
            class="mx-1"
          >
          </ItpText>
        </span>
        <b-dropdown-item-button
          :disabled="!selectedItems || !selectedItems.length"
          :key="status"
          v-for="status in statuses"
          @click.prevent.stop="evh_5382094220310827_clickPreventStop($event, {status})"
        >
          <ItpText
            :text="status"
          >
          </ItpText>
        </b-dropdown-item-button>
        <hr>
        </hr>
        <b-dropdown-item-button
          :disabled="!selectedItems || !selectedItems.length"
          @click.prevent.stop="evh_8704926098283402_clickPreventStop($event, {})"
        >
          <ItpText
            :text="'Clear status'|pgettext('InvoiceRegister')"
          >
          </ItpText>
        </b-dropdown-item-button>
      </b-dropdown>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "invoiceRegister",
    path: "/invoice-register",
    component: "InvoiceRegister"
  }
)

@Component()
export default class InvoiceRegister extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_selectedRegisterId = new ComponentValueCache(this, "selectedRegisterId", "InvoiceRegister.selectedRegisterId");
  }

  getQueryParameters(...args: any[]) {
    return {
      sort_mode: 'desc',
      register_name: this.selectedRegister ? this.selectedRegister.id : this.registers[0].id
    }
  }

  invoiceTypeBadgeClass(...args: any[]) {
    const [row] = args;
    const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
    return `bg-${cc.bg} text-${cc.text}`
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  registers!: any;

  async $$load_registers() {
    return this.$fn.fetch('list_register')
      .then(data => _.filter(data, d => _.includes(d.register_type, 'incoming_invoice')))
  }

  selectedRegisterId!: any;
  selectedRegister!: any;

  async $$load_selectedRegister() {
    return _.find(this.registers, { id: this.selectedRegisterId }) || this.registers[0]
  }

  selectedItems!: any;
  statuses!: any;

  async $$load_statuses() {
    return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice-register-status' } }).then(data => _.map(data, p => p.name))
  }

  selectedStatus!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;

  async $$load_b_2248226175642056_columns() {
    return [{
      field: "entry_number",
      name: this.$fn.pgettext("Invoice register", "No."),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'font-weight-bold': true,
            'bg-yellow-200': !row.locked
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "ref_entry_number",
      name: this.$fn.pgettext("Invoice register", "Ref."),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "invoice_number",
      name: this.$fn.pgettext("Invoice register", "Invoice number"),
      formatter: [{
        name: "chain",
        formatters: [{
          name: "routeLink",
          to: "invoice",
          navigateOnIconClick: true,
          params: {
            id: "invoice_id",
          }
          ,
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'bg-yellow-200': row.invoice_id != null && row.external_key == null,
            'bg-red-200': row.invoice_id == null,
            'font-weight-bold': row.invoice_id != null
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "received_date",
      name: this.$fn.pgettext("Invoice register", "Received date"),
      type: "date",
    }
      ,
    {
      field: "invoice_type",
      name: this.$fn.pgettext("Invoice register", "Invoice type"),
      formatter: {
        name: "chain",
        formatters: [{
          name: "gettext",
          context: "InvoiceType",
          conversion: "StartCase",
          filterInput: true,
        }
          ,
        {
          name: "badge",
          dot: true,
          pill: true,
          badgeClass: this.invoiceTypeBadgeClass,
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "status",
      name: this.$fn.pgettext("Invoice register", "Status"),
    }
      ,
    {
      field: "categories",
      name: this.$fn.pgettext("Invoice register", "Category"),
    }
      ,
    {
      field: "client_name",
      name: this.$fn.pgettext("Invoice register", "Vendor"),
      formatter: {
        name: "routeLink",
        to: "client",
        navigateOnIconClick: true,
        params: {
          id: "client_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "description",
      name: this.$fn.pgettext("Invoice register", "Subject"),
    }
      ,
    {
      field: "notes",
      name: this.$fn.pgettext("Invoice register", "Notes"),
    }
      ,
    {
      field: "amount",
      name: this.$fn.pgettext("Invoice register", "Amount"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("Invoice register", "Currency"),
    }
      ,
    {
      field: "fulfillment_date",
      name: this.$fn.pgettext("Invoice register", "Fulfillment date"),
      type: "date",
    }
      ,
    {
      field: "due_date",
      name: this.$fn.pgettext("Invoice register", "Due date"),
      type: "date",
    }
      ,
    ]
  }

  dataMembers = ['registers', 'selectedRegisterId', 'selectedRegister', 'selectedItems', 'statuses', 'selectedStatus', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        registers: null,
        selectedRegisterId: null,
        selectedRegister: null,
        selectedItems: [
        ]
        ,
        statuses: null,
        selectedStatus: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Invoice register", "Invoice register"),
        icon: this.$config.invoiceRegister.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2398_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2398, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_reloadSlickgrid_2402_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2402, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_2248226175642056_reloadSlickgrid_2404_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2404, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_2248226175642056_close_2403_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_2403, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_2400_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_2400, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_2399_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_2399, alias=undefined
    return $event.data.column.id === 'entry_number'
  }

  async act_2248226175642056_reloadSlickgrid_2408_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2408, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_2248226175642056_reloadSlickgrid_2410_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2410, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_2248226175642056_close_2409_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_2409, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_2406_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_2406, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: $event.data.data.ref_entry_id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_2405_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_2405, alias=undefined
    return $event.data.column.id === 'ref_entry_number'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_2412_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_2412, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_reloadSlickgrid_2414_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_6466705138206980_reloadSlickgrid_2414, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_6466705138206980_setData_2416_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_2416, alias=updateSelectedRegisterId
    return {
      path: "selectedRegisterId",
      value: $event.data.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6466705138206980_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_input.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_reloadSlickgrid_2420_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_2420, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_8320016629450276_reloadSlickgrid_2422_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_2422, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_8320016629450276_close_2421_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_2421, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_8320016629450276_showModal_2418_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_2418, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        register: this.selectedRegister,
        companyId: this.selectedRegister.company_id,
      }
      ,
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  async act_5382094220310827_setData_2424_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5382094220310827_setData_2424, alias=undefined
    return {
      path: "selectedStatus",
      value: $event.scope.status,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_5382094220310827_reloadSlickgrid_2428_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_5382094220310827_reloadSlickgrid_2428, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_5382094220310827_request_2426_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_5382094220310827_request_2426, alias=setStatus
    return {
      operation: "bulk_update_invoice_register_entry",
      data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
    }
  }

  async evh_5382094220310827_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5382094220310827_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8704926098283402_setData_2430_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8704926098283402_setData_2430, alias=undefined
    return {
      path: "selectedStatus",
      value: null,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_8704926098283402_reloadSlickgrid_2434_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8704926098283402_reloadSlickgrid_2434, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_8704926098283402_request_2432_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8704926098283402_request_2432, alias=setStatus
    return {
      operation: "bulk_update_invoice_register_entry",
      data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
    }
  }

  async evh_8704926098283402_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8704926098283402_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2398: actions.CloseModalAction;
    evh_7315092382398562_close_2397: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_reloadSlickgrid_2402: actions.ReloadSlickgridAction;
    evh_2248226175642056_saved_2401: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2404: actions.ReloadSlickgridAction;
    evh_2248226175642056_close_2403: actions.EventHandlerImpl;
    act_2248226175642056_showModal_2400: actions.ShowModalAction;
    evh_2248226175642056_command_2399: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2408: actions.ReloadSlickgridAction;
    evh_2248226175642056_saved_2407: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2410: actions.ReloadSlickgridAction;
    evh_2248226175642056_close_2409: actions.EventHandlerImpl;
    act_2248226175642056_showModal_2406: actions.ShowModalAction;
    evh_2248226175642056_command_2405: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_2248226175642056_setData_2412: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_2411: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_reloadSlickgrid_2414: actions.ReloadSlickgridAction;
    evh_6466705138206980_input_2413: actions.EventHandlerImpl;
    act_6466705138206980_setData_2416: actions.SetDataAction;
    evh_6466705138206980_input_2415: actions.EventHandlerImpl;
    evh_6466705138206980_input: actions.EventHandlerGroup;
    act_8320016629450276_reloadSlickgrid_2420: actions.ReloadSlickgridAction;
    evh_8320016629450276_saved_2419: actions.EventHandlerImpl;
    act_8320016629450276_reloadSlickgrid_2422: actions.ReloadSlickgridAction;
    evh_8320016629450276_close_2421: actions.EventHandlerImpl;
    act_8320016629450276_showModal_2418: actions.ShowModalAction;
    evh_8320016629450276_click_2417: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
    act_5382094220310827_setData_2424: actions.SetDataAction;
    evh_5382094220310827_clickPreventStop_2423: actions.EventHandlerImpl;
    act_5382094220310827_reloadSlickgrid_2428: actions.ReloadSlickgridAction;
    evh_5382094220310827_success_2427: actions.EventHandlerImpl;
    act_5382094220310827_request_2426: actions.RequestAction;
    evh_5382094220310827_clickPreventStop_2425: actions.EventHandlerImpl;
    evh_5382094220310827_clickPreventStop: actions.EventHandlerGroup;
    act_8704926098283402_setData_2430: actions.SetDataAction;
    evh_8704926098283402_clickPreventStop_2429: actions.EventHandlerImpl;
    act_8704926098283402_reloadSlickgrid_2434: actions.ReloadSlickgridAction;
    evh_8704926098283402_success_2433: actions.EventHandlerImpl;
    act_8704926098283402_request_2432: actions.RequestAction;
    evh_8704926098283402_clickPreventStop_2431: actions.EventHandlerImpl;
    evh_8704926098283402_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2398 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2398_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2397 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2398,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2397],
      }
    );
    const act_2248226175642056_reloadSlickgrid_2402 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2402_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_saved_2401 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2402,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2404 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2404_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_close_2403 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2404,
        event: "close",
        when: this.evh_2248226175642056_close_2403_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_showModal_2400 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_2400_getActionArgs,
        events: [evh_2248226175642056_saved_2401, evh_2248226175642056_close_2403],
      }
    );
    const evh_2248226175642056_command_2399 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_2400,
        event: "command",
        when: this.evh_2248226175642056_command_2399_getWhen,
        displayName: "showModal",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2408 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2408_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_saved_2407 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2408,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2410 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2410_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_close_2409 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2410,
        event: "close",
        when: this.evh_2248226175642056_close_2409_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_showModal_2406 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_2406_getActionArgs,
        events: [evh_2248226175642056_saved_2407, evh_2248226175642056_close_2409],
      }
    );
    const evh_2248226175642056_command_2405 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_2406,
        event: "command",
        when: this.evh_2248226175642056_command_2405_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_2399, evh_2248226175642056_command_2405],
      }
    );
    const act_2248226175642056_setData_2412 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_2412_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_2411 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_2412,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_2411],
      }
    );
    const act_6466705138206980_reloadSlickgrid_2414 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_6466705138206980_reloadSlickgrid_2414_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_6466705138206980_input_2413 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_reloadSlickgrid_2414,
        event: "input",
        displayName: "reloadSlickgrid",
      }
    );
    const act_6466705138206980_setData_2416 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_2416_getActionArgs,
        displayName: "updateSelectedRegisterId",
        events: [],
      }
    );
    const evh_6466705138206980_input_2415 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_2416,
        event: "input",
        displayName: "updateSelectedRegisterId",
      }
    );
    const evh_6466705138206980_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_input_2413, evh_6466705138206980_input_2415],
      }
    );
    const act_8320016629450276_reloadSlickgrid_2420 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_2420_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8320016629450276_saved_2419 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_2420,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_reloadSlickgrid_2422 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_2422_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8320016629450276_close_2421 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_2422,
        event: "close",
        when: this.evh_8320016629450276_close_2421_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_showModal_2418 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_2418_getActionArgs,
        events: [evh_8320016629450276_saved_2419, evh_8320016629450276_close_2421],
      }
    );
    const evh_8320016629450276_click_2417 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_2418,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_2417],
      }
    );
    const act_5382094220310827_setData_2424 = new actions.SetDataAction(
      {
        actionArgs: this.act_5382094220310827_setData_2424_getActionArgs,
        events: [],
      }
    );
    const evh_5382094220310827_clickPreventStop_2423 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_setData_2424,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const act_5382094220310827_reloadSlickgrid_2428 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_5382094220310827_reloadSlickgrid_2428_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_5382094220310827_success_2427 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_reloadSlickgrid_2428,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_5382094220310827_request_2426 = new actions.RequestAction(
      {
        actionArgs: this.act_5382094220310827_request_2426_getActionArgs,
        displayName: "setStatus",
        events: [evh_5382094220310827_success_2427],
      }
    );
    const evh_5382094220310827_clickPreventStop_2425 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_request_2426,
        event: "click.prevent.stop",
        displayName: "setStatus",
      }
    );
    const evh_5382094220310827_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5382094220310827_clickPreventStop_2423, evh_5382094220310827_clickPreventStop_2425],
      }
    );
    const act_8704926098283402_setData_2430 = new actions.SetDataAction(
      {
        actionArgs: this.act_8704926098283402_setData_2430_getActionArgs,
        events: [],
      }
    );
    const evh_8704926098283402_clickPreventStop_2429 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_setData_2430,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const act_8704926098283402_reloadSlickgrid_2434 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8704926098283402_reloadSlickgrid_2434_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8704926098283402_success_2433 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_reloadSlickgrid_2434,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8704926098283402_request_2432 = new actions.RequestAction(
      {
        actionArgs: this.act_8704926098283402_request_2432_getActionArgs,
        displayName: "setStatus",
        events: [evh_8704926098283402_success_2433],
      }
    );
    const evh_8704926098283402_clickPreventStop_2431 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_request_2432,
        event: "click.prevent.stop",
        displayName: "setStatus",
      }
    );
    const evh_8704926098283402_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8704926098283402_clickPreventStop_2429, evh_8704926098283402_clickPreventStop_2431],
      }
    );
    return {
      act_7315092382398562_closeModal_2398,
      evh_7315092382398562_close_2397,
      evh_7315092382398562_close,
      act_2248226175642056_reloadSlickgrid_2402,
      evh_2248226175642056_saved_2401,
      act_2248226175642056_reloadSlickgrid_2404,
      evh_2248226175642056_close_2403,
      act_2248226175642056_showModal_2400,
      evh_2248226175642056_command_2399,
      act_2248226175642056_reloadSlickgrid_2408,
      evh_2248226175642056_saved_2407,
      act_2248226175642056_reloadSlickgrid_2410,
      evh_2248226175642056_close_2409,
      act_2248226175642056_showModal_2406,
      evh_2248226175642056_command_2405,
      evh_2248226175642056_command,
      act_2248226175642056_setData_2412,
      evh_2248226175642056_selectedRowsChanged_2411,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_reloadSlickgrid_2414,
      evh_6466705138206980_input_2413,
      act_6466705138206980_setData_2416,
      evh_6466705138206980_input_2415,
      evh_6466705138206980_input,
      act_8320016629450276_reloadSlickgrid_2420,
      evh_8320016629450276_saved_2419,
      act_8320016629450276_reloadSlickgrid_2422,
      evh_8320016629450276_close_2421,
      act_8320016629450276_showModal_2418,
      evh_8320016629450276_click_2417,
      evh_8320016629450276_click,
      act_5382094220310827_setData_2424,
      evh_5382094220310827_clickPreventStop_2423,
      act_5382094220310827_reloadSlickgrid_2428,
      evh_5382094220310827_success_2427,
      act_5382094220310827_request_2426,
      evh_5382094220310827_clickPreventStop_2425,
      evh_5382094220310827_clickPreventStop,
      act_8704926098283402_setData_2430,
      evh_8704926098283402_clickPreventStop_2429,
      act_8704926098283402_reloadSlickgrid_2434,
      evh_8704926098283402_success_2433,
      act_8704926098283402_request_2432,
      evh_8704926098283402_clickPreventStop_2431,
      evh_8704926098283402_clickPreventStop,
      reloadSlickgrid: act_8704926098283402_reloadSlickgrid_2434,
      updateSelectedRegisterId: act_6466705138206980_setData_2416,
      setStatus: act_8704926098283402_request_2432,
    }
  }
}

Vue.component("InvoiceRegister", InvoiceRegister);

</script>