var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ItpRow",
        [
          _c(
            "ItpCol",
            { attrs: { w: "6" } },
            [
              _c(
                "BListGroup",
                { staticClass: "list-group-alt-1", attrs: { flush: "" } },
                [
                  _c(
                    "ItpLabeledListGroupItem",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        label: _vm._f("pgettext")("Invoice For", "Invoice")
                      }
                    },
                    [
                      _c("UcClientMiniCard", {
                        attrs: { item: _vm.invoice.client }
                      }),
                      _vm._v(" "),
                      _c("UcAddressCard", {
                        staticClass: "mb-0",
                        attrs: { item: _vm.invoice.client.address }
                      }),
                      _vm._v(" "),
                      _c("ItpBox", [
                        _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.invoice.client.tax_number) +
                              "\n            "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.invoice.invoice_date
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")("Invoice date", "Invoice")
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("date")(_vm.invoice.invoice_date)
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.fulfillment_date
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Fulfillment date",
                              "Invoice"
                            )
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("date")(_vm.invoice.fulfillment_date)
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.billing_period &&
                  (_vm.invoice.billing_period.start ||
                    _vm.invoice.billing_period.end)
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Billing period",
                              "Invoice"
                            )
                          }
                        },
                        [
                          _c("DIV", { staticClass: "text-nowrap" }, [
                            _c("strong", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.invoice.billing_period.start
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("strong", [
                              _vm._v("\n              -\n            ")
                            ]),
                            _vm._v(" "),
                            _c("strong", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.invoice.billing_period.end
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.reference_date
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Reference date",
                              "Invoice"
                            )
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("date")(_vm.invoice.reference_date)
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.reference_number
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Reference Number",
                              "Invoice"
                            )
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.invoice.reference_number) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.order_number
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")("Order Number", "Invoice")
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.invoice.order_number) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.file_number
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")("File Number", "Invoice")
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.invoice.file_number) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpCol",
            { attrs: { w: "6" } },
            [
              _c(
                "BListGroup",
                { staticClass: "list-group-alt-1", attrs: { flush: "" } },
                [
                  _vm.invoice.currency
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")("Currency", "Invoice"),
                            strong: ""
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.invoice.currency) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.exchange_rate
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Exchange rate",
                              "Invoice"
                            ),
                            strong: ""
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("number")(_vm.invoice.exchange_rate)
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.due_date
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")("Due date", "Invoice")
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("date")(_vm.invoice.due_date)) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.payment_method
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Payment Method",
                              "Invoice"
                            )
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("pgettext")(
                                    _vm.invoice.payment_method,
                                    "PaymentMethod",
                                    "Sentence"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.expense_type
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")("Expense Type", "Invoice")
                          }
                        },
                        [
                          _c("ItpText", { attrs: { strong: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("pgettext")(
                                    _vm.invoice.expense_type,
                                    "ExpenseType",
                                    "Sentence"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invoice.register_entries.data &&
                  _vm.invoice.register_entries.data.length
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: _vm._f("pgettext")(
                              "Register entries",
                              "Invoice"
                            )
                          }
                        },
                        _vm._l(_vm.invoice.register_entries.data, function(
                          entry
                        ) {
                          return _c("UcInvoiceRegisterEntryMiniCard", {
                            key: entry.id,
                            attrs: { item: entry, "no-icon": "" }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ItpLabeledListGroupItem",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: {
                        label: _vm._f("pgettext")("References", "Invoice")
                      }
                    },
                    [
                      _c("UcInvoiceReferences", {
                        attrs: { invoice: _vm.invoice, reload: _vm.lastLoaded },
                        on: {
                          reload: function($event) {
                            return _vm.evh_4152404563918957_reload($event, {})
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.invoice.totals
                    ? _c(
                        "ItpLabeledListGroupItem",
                        {
                          staticClass: "mt-2 mb-2",
                          attrs: {
                            label: _vm._f("pgettext")("Payments", "Invoice")
                          }
                        },
                        [
                          _c("UcInvoicePayments", {
                            attrs: {
                              invoice: _vm.invoice,
                              reload: _vm.lastLoaded
                            },
                            on: {
                              reload: function($event) {
                                return _vm.evh_3855552110905499_reload(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.invoice.notes || _vm.invoice.internal_notes ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.invoice.notes || _vm.invoice.internal_notes
        ? _c(
            "ItpRow",
            [
              _vm.invoice.notes
                ? _c(
                    "ItpCol",
                    { attrs: { w: "6" } },
                    [
                      _c(
                        "BListGroup",
                        {
                          staticClass: "list-group-alt-1",
                          attrs: { flush: "" }
                        },
                        [
                          _c(
                            "ItpLabeledListGroupItem",
                            {
                              attrs: {
                                label: _vm._f("pgettext")("Notes", "Invoice")
                              }
                            },
                            [
                              _c("ItpText", {
                                attrs: { text: _vm.invoice.notes, pre: "" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.invoice.internal_notes
                ? _c(
                    "ItpCol",
                    { attrs: { w: "6" } },
                    [
                      _c(
                        "BListGroup",
                        {
                          staticClass: "list-group-alt-1",
                          attrs: { flush: "" }
                        },
                        [
                          _c(
                            "ItpLabeledListGroupItem",
                            {
                              attrs: {
                                label: _vm._f("pgettext")(
                                  "Internal Notes",
                                  "Invoice"
                                )
                              }
                            },
                            [
                              _c("ItpText", {
                                attrs: {
                                  text: _vm.invoice.internal_notes,
                                  pre: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }