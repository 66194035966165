"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var JobHandlerControl = (function (_super) {
    __extends(JobHandlerControl, _super);
    function JobHandlerControl() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['messages', 'jobData', 'jobPoller', 'status', 'ux'];
        return _this;
    }
    JobHandlerControl.prototype.beforeCreate = function () {
    };
    JobHandlerControl.prototype.beforeDestroy = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        {
            {
                this.jobPoller && this.jobPoller.stop();
            }
        }
    };
    JobHandlerControl.prototype.loadJob = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return this.$fn
            .fetch('read_a_job', { parameters: { id: this.jobData.id } })
            .then(function (data) {
            _this.setData('jobData', data);
            _this.setData('status', data.status);
            _this.maybeStopPoll();
        })
            .catch(function (error) { return _this.handleError(error); });
    };
    JobHandlerControl.prototype.onStatusReceived = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 1), status = _a[0].status;
        if (status && this.status != status) {
            this.setData('jobData.status', status);
            this.setData('status', status);
            this.$emit(status);
            if (status !== 'running') {
                return this.loadJob();
            }
        }
    };
    JobHandlerControl.prototype.onSubmit = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var val = _.isFunction(this.payload) ? this.payload() : this.payload;
        if (this.isRunning) {
            return;
        }
        Promise
            .resolve(val)
            .then(function (payload) {
            if (!payload || payload.cancel) {
                return;
            }
            _this.$fn.crud('a_job')
                .create(payload, { parameters: { job_type: _this.jobType } })
                .then(function (resource) {
                _this.setData('jobData', resource);
                _this.setData('status', resource.status);
            })
                .then(function () { return _this.maybeStartPoll(); })
                .catch(function (error) { return _this.handleError(error); });
        })
            .catch(function (error) { return _this.handleError(error); });
    };
    JobHandlerControl.prototype.checkStatus = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!this.jobData) {
            return false;
        }
        var status = this.jobData.status;
        if (status && this.status != status) {
            this.$emit(status);
            this.status = status;
        }
        if (status !== 'pending' && status !== 'running') {
            console.log("JobStatus is ".concat(status, ", stop polling."), this.jobPoller);
            this.jobPoller.stop();
            this.$emit('success');
            return;
        }
        return this.$fn
            .fetch('read_a_job_status', { parameters: { id: this.jobData.id } })
            .then(function (data) { _this.onStatusReceived(data); });
    };
    JobHandlerControl.prototype.maybeStartPoll = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var status = _.get(this, 'jobData.status');
        if (['completed', 'failed', 'cancelled', 'deleted'].indexOf(status) > -1) {
            return;
        }
        if (!this.jobPoller.isRunning) {
            return this.jobPoller.start();
        }
    };
    JobHandlerControl.prototype.maybeStopPoll = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var status = _.get(this, 'jobData.status');
        if (['pending', 'running'].indexOf(status) > -1) {
            return;
        }
        if (this.jobPoller.isRunning) {
            return this.jobPoller.stop();
        }
    };
    JobHandlerControl.prototype.onBlock = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("block");
    };
    JobHandlerControl.prototype.onJob_type = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("jobType");
    };
    JobHandlerControl.prototype.onText = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("text");
    };
    JobHandlerControl.prototype.onLoad_current = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("loadCurrent");
    };
    JobHandlerControl.prototype.onPayload = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payload");
    };
    JobHandlerControl.prototype.onJob_id = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("jobId");
    };
    JobHandlerControl.prototype.onIcon = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("icon");
    };
    JobHandlerControl.prototype.onVariant = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("variant");
    };
    JobHandlerControl.prototype.onDisabled = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("disabled");
    };
    JobHandlerControl.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    JobHandlerControl.prototype.onTooltip = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("tooltip");
    };
    JobHandlerControl.prototype.onPolling_interval = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("pollingInterval");
    };
    JobHandlerControl.prototype.$$load_jobData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.loadCurrent
                        ? this.$fn.fetch('read_a_job', { parameters: { id: this.jobID || 'current', job_type: this.jobType }, asResource: true })
                        : null];
            });
        });
    };
    JobHandlerControl.prototype.$$load_jobPoller = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.poller({
                        id: 'Job',
                        callback: this.checkStatus,
                        interval: this.pollingInterval,
                    })];
            });
        });
    };
    JobHandlerControl.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                showJobTooltip: this.$fn.pgettext("JobHandler|Command", "Open job details"),
                reloadJobData: this.$fn.pgettext("JobHandler|Command", "Reload job status"),
            },
            jobData: null,
            jobPoller: null,
            status: null,
            ux: null,
        });
    };
    Object.defineProperty(JobHandlerControl.prototype, "isRunning", {
        get: function () {
            if (this.loaded) {
                return this.jobData && this.jobData.status === 'running';
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(JobHandlerControl.prototype, "isPending", {
        get: function () {
            if (this.loaded) {
                return this.jobData && this.jobData.status === 'pending';
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(JobHandlerControl.prototype, "spinning", {
        get: function () {
            if (this.loaded) {
                return this.isRunning || this.isPending || (this.actions && this.actions.reloadJobData && this.actions.reloadJobData.isRunning);
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    JobHandlerControl.prototype.act_7315092382398562_script_5104_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onSubmit();
                return [2];
            });
        });
    };
    JobHandlerControl.prototype.act_7315092382398562_script_5104_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_7315092382398562_script_5104_getActionArgs_value($event); },
                    }];
            });
        });
    };
    JobHandlerControl.prototype.act_7315092382398562_script_5104_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !this.disabled];
            });
        });
    };
    JobHandlerControl.prototype.evh_7315092382398562_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    JobHandlerControl.prototype.act_96700093353179_script_5106_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onSubmit();
                return [2];
            });
        });
    };
    JobHandlerControl.prototype.act_96700093353179_script_5106_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_96700093353179_script_5106_getActionArgs_value($event); },
                    }];
            });
        });
    };
    JobHandlerControl.prototype.act_96700093353179_script_5106_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !this.disabled];
            });
        });
    };
    JobHandlerControl.prototype.evh_96700093353179_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_96700093353179_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    JobHandlerControl.prototype.act_96700093353179_script_5108_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $event.stop();
                return [2];
            });
        });
    };
    JobHandlerControl.prototype.act_96700093353179_script_5108_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_96700093353179_script_5108_getActionArgs_value($event); },
                    }];
            });
        });
    };
    JobHandlerControl.prototype.evh_96700093353179_bvDropdownHide = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_96700093353179_bvDropdownHide.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    JobHandlerControl.prototype.act_96700093353179_script_5110_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log($event);
                return [2];
            });
        });
    };
    JobHandlerControl.prototype.act_96700093353179_script_5110_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_96700093353179_script_5110_getActionArgs_value($event); },
                    }];
            });
        });
    };
    JobHandlerControl.prototype.evh_96700093353179_bvDropdownShow = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_96700093353179_bvDropdownShow.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    JobHandlerControl.prototype.act_302930856768608_showModal_5112_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "Job",
                        props: {
                            oid: this.jobData.id,
                        },
                    }];
            });
        });
    };
    JobHandlerControl.prototype.evh_302930856768608_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_302930856768608_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    JobHandlerControl.prototype.act_939987670462915_setData_5116_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "jobData",
                        value: $event.data.response.data,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    JobHandlerControl.prototype.act_939987670462915_setData_5116_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.response.data];
            });
        });
    };
    JobHandlerControl.prototype.act_939987670462915_request_5114_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "read_a_job",
                        parameters: {
                            id: this.jobData.id,
                        },
                    }];
            });
        });
    };
    JobHandlerControl.prototype.evh_939987670462915_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_939987670462915_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    JobHandlerControl.prototype.getActions = function () {
        var act_7315092382398562_script_5104 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_5104_getActionArgs,
            when: this.act_7315092382398562_script_5104_getWhen,
            displayName: "submit",
            events: [],
        });
        var evh_7315092382398562_clickPreventStop_5103 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_5104,
            event: "click.prevent.stop",
            displayName: "submit",
        });
        var evh_7315092382398562_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_clickPreventStop_5103],
        });
        var act_96700093353179_script_5106 = new core_1.actions.ScriptAction({
            actionArgs: this.act_96700093353179_script_5106_getActionArgs,
            when: this.act_96700093353179_script_5106_getWhen,
            displayName: "submit",
            events: [],
        });
        var evh_96700093353179_clickPreventStop_5105 = new core_1.actions.EventHandlerImpl({
            action: act_96700093353179_script_5106,
            event: "click.prevent.stop",
            displayName: "submit",
        });
        var evh_96700093353179_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_96700093353179_clickPreventStop_5105],
        });
        var act_96700093353179_script_5108 = new core_1.actions.ScriptAction({
            actionArgs: this.act_96700093353179_script_5108_getActionArgs,
            events: [],
        });
        var evh_96700093353179_bvDropdownHide_5107 = new core_1.actions.EventHandlerImpl({
            action: act_96700093353179_script_5108,
            event: "bv::dropdown::hide",
            displayName: "script",
        });
        var evh_96700093353179_bvDropdownHide = new core_1.actions.EventHandlerGroup({
            handlers: [evh_96700093353179_bvDropdownHide_5107],
        });
        var act_96700093353179_script_5110 = new core_1.actions.ScriptAction({
            actionArgs: this.act_96700093353179_script_5110_getActionArgs,
            events: [],
        });
        var evh_96700093353179_bvDropdownShow_5109 = new core_1.actions.EventHandlerImpl({
            action: act_96700093353179_script_5110,
            event: "bv::dropdown::show",
            displayName: "script",
        });
        var evh_96700093353179_bvDropdownShow = new core_1.actions.EventHandlerGroup({
            handlers: [evh_96700093353179_bvDropdownShow_5109],
        });
        var act_302930856768608_showModal_5112 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_302930856768608_showModal_5112_getActionArgs,
            displayName: "showJob",
            events: [],
        });
        var evh_302930856768608_clickPreventStop_5111 = new core_1.actions.EventHandlerImpl({
            action: act_302930856768608_showModal_5112,
            event: "click.prevent.stop",
            displayName: "showJob",
        });
        var evh_302930856768608_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_302930856768608_clickPreventStop_5111],
        });
        var act_939987670462915_setData_5116 = new core_1.actions.SetDataAction({
            actionArgs: this.act_939987670462915_setData_5116_getActionArgs,
            when: this.act_939987670462915_setData_5116_getWhen,
            events: [],
        });
        var evh_939987670462915_success_5115 = new core_1.actions.EventHandlerImpl({
            action: act_939987670462915_setData_5116,
            event: "success",
            displayName: "setData",
        });
        var act_939987670462915_request_5114 = new core_1.actions.RequestAction({
            actionArgs: this.act_939987670462915_request_5114_getActionArgs,
            displayName: "reloadJobData",
            events: [evh_939987670462915_success_5115],
        });
        var evh_939987670462915_clickPreventStop_5113 = new core_1.actions.EventHandlerImpl({
            action: act_939987670462915_request_5114,
            event: "click.prevent.stop",
            displayName: "reloadJobData",
        });
        var evh_939987670462915_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_939987670462915_clickPreventStop_5113],
        });
        return {
            act_7315092382398562_script_5104: act_7315092382398562_script_5104,
            evh_7315092382398562_clickPreventStop_5103: evh_7315092382398562_clickPreventStop_5103,
            evh_7315092382398562_clickPreventStop: evh_7315092382398562_clickPreventStop,
            act_96700093353179_script_5106: act_96700093353179_script_5106,
            evh_96700093353179_clickPreventStop_5105: evh_96700093353179_clickPreventStop_5105,
            evh_96700093353179_clickPreventStop: evh_96700093353179_clickPreventStop,
            act_96700093353179_script_5108: act_96700093353179_script_5108,
            evh_96700093353179_bvDropdownHide_5107: evh_96700093353179_bvDropdownHide_5107,
            evh_96700093353179_bvDropdownHide: evh_96700093353179_bvDropdownHide,
            act_96700093353179_script_5110: act_96700093353179_script_5110,
            evh_96700093353179_bvDropdownShow_5109: evh_96700093353179_bvDropdownShow_5109,
            evh_96700093353179_bvDropdownShow: evh_96700093353179_bvDropdownShow,
            act_302930856768608_showModal_5112: act_302930856768608_showModal_5112,
            evh_302930856768608_clickPreventStop_5111: evh_302930856768608_clickPreventStop_5111,
            evh_302930856768608_clickPreventStop: evh_302930856768608_clickPreventStop,
            act_939987670462915_setData_5116: act_939987670462915_setData_5116,
            evh_939987670462915_success_5115: evh_939987670462915_success_5115,
            act_939987670462915_request_5114: act_939987670462915_request_5114,
            evh_939987670462915_clickPreventStop_5113: evh_939987670462915_clickPreventStop_5113,
            evh_939987670462915_clickPreventStop: evh_939987670462915_clickPreventStop,
            submit: act_96700093353179_script_5106,
            showJob: act_302930856768608_showModal_5112,
            reloadJobData: act_939987670462915_request_5114,
        };
    };
    var _a;
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
            default: false,
        }),
        __metadata("design:type", Boolean)
    ], JobHandlerControl.prototype, "block", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('block'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onBlock", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: String,
        }),
        __metadata("design:type", String)
    ], JobHandlerControl.prototype, "jobType", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('jobType'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onJob_type", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: String,
        }),
        __metadata("design:type", String)
    ], JobHandlerControl.prototype, "text", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('text'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onText", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
            default: false,
        }),
        __metadata("design:type", Boolean)
    ], JobHandlerControl.prototype, "loadCurrent", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('loadCurrent'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onLoad_current", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
        }),
        __metadata("design:type", typeof (_a = typeof Function !== "undefined" && Function) === "function" ? _a : Object)
    ], JobHandlerControl.prototype, "payload", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('payload'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onPayload", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], JobHandlerControl.prototype, "jobId", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('jobId'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onJob_id", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], JobHandlerControl.prototype, "icon", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('icon'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onIcon", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "light",
        }),
        __metadata("design:type", String)
    ], JobHandlerControl.prototype, "variant", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('variant'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onVariant", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
        }),
        __metadata("design:type", Boolean)
    ], JobHandlerControl.prototype, "disabled", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('disabled'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onDisabled", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "md",
        }),
        __metadata("design:type", String)
    ], JobHandlerControl.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onSize", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], JobHandlerControl.prototype, "tooltip", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('tooltip'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onTooltip", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Number,
            default: 1000,
        }),
        __metadata("design:type", Number)
    ], JobHandlerControl.prototype, "pollingInterval", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('pollingInterval'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], JobHandlerControl.prototype, "onPolling_interval", null);
    JobHandlerControl = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], JobHandlerControl);
    return JobHandlerControl;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = JobHandlerControl;
vue_property_decorator_1.Vue.component("JobHandlerControl", JobHandlerControl);
