"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "letterRegister",
    path: "/letter-register",
    component: "LetterRegister"
});
var LetterRegister = (function (_super) {
    __extends(LetterRegister, _super);
    function LetterRegister() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "page",
        };
        _this.dataMembers = ['registers', 'selectedRegisterId', 'selectedRegister', 'selectedItems', 'companies', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_headerShowColumnSelectorButton', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
        return _this;
    }
    LetterRegister.prototype.beforeCreate = function () {
        this.$$cache_selectedRegisterId = new core_1.ComponentValueCache(this, "selectedRegisterId", "LetterRegister.selectedRegisterId");
    };
    LetterRegister.prototype.getQueryParameters = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return {
            sort_mode: 'desc',
            register_name: this.selectedRegister ? this.selectedRegister.id : this.registers[0].id
        };
    };
    LetterRegister.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    LetterRegister.prototype.$$load_registers = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_register')
                        .then(function (data) { return _.filter(data, function (d) { return _.includes(d.register_type, 'letters'); }); })];
            });
        });
    };
    LetterRegister.prototype.$$load_selectedRegister = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, _.find(this.registers, { id: this.selectedRegisterId }) || this.registers[0]];
            });
        });
    };
    LetterRegister.prototype.$$load_companies = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_company').then(function (resp) { return resp.data; })];
            });
        });
    };
    LetterRegister.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            registers: null,
            selectedRegisterId: null,
            selectedRegister: null,
            selectedItems: [],
            companies: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_headerShowColumnSelectorButton: false,
            b_2248226175642056_columnDefaults: {
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "entry_number",
                    name: this.$fn.pgettext("Letter register", "No."),
                    type: "command",
                    formatter: {
                        name: "link",
                        href: "#",
                    },
                },
                {
                    field: "entry_date",
                    name: this.$fn.pgettext("Letter register", "Date"),
                    type: "date",
                },
                {
                    field: "delivery_modes",
                    name: this.$fn.pgettext("Letter register", "Delivery mode"),
                    formatter: {
                        name: "join",
                        separator: ", ",
                    },
                },
                {
                    field: "categories",
                    name: this.$fn.pgettext("Letter register", "Category"),
                },
                {
                    field: "client_name",
                    name: this.$fn.pgettext("Letter register", "Client"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "subject",
                    name: this.$fn.pgettext("Letter register", "Subject"),
                },
                {
                    field: "notes",
                    name: this.$fn.pgettext("Letter register", "Notes"),
                },
                {
                    field: "delivery_date",
                    name: this.$fn.pgettext("Letter register", "Delivery date"),
                },
                {
                    field: "undelivered",
                    name: this.$fn.pgettext("Letter register", "Undelivered"),
                    type: "boolean",
                    formatter: {
                        name: "checkmark",
                    },
                },
            ],
        });
    };
    Object.defineProperty(LetterRegister.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.$fn.pgettext("Letter register", "Letter register"),
                    icon: this.$config.letterRegister.icon,
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    LetterRegister.prototype.act_7315092382398562_closeModal_2608_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    LetterRegister.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegister.prototype.act_2248226175642056_reloadSlickgrid_2612_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "letterRegister",
                    }];
            });
        });
    };
    LetterRegister.prototype.act_2248226175642056_reloadSlickgrid_2614_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "letterRegister",
                    }];
            });
        });
    };
    LetterRegister.prototype.evh_2248226175642056_close_2613_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data.modalResult.ok];
            });
        });
    };
    LetterRegister.prototype.act_2248226175642056_showModal_2610_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "LetterRegisterEntry",
                        props: {
                            register: this.selectedRegister,
                            oid: $event.data.data.id,
                        },
                    }];
            });
        });
    };
    LetterRegister.prototype.evh_2248226175642056_command_2609_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.column.id === 'entry_number'];
            });
        });
    };
    LetterRegister.prototype.evh_2248226175642056_command = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegister.prototype.act_2248226175642056_setData_2616_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "selectedItems",
                        value: this.$refs.grid.getSelectedItems(),
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    LetterRegister.prototype.evh_2248226175642056_selectedRowsChanged = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegister.prototype.act_6466705138206980_reloadSlickgrid_2618_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "letterRegister",
                    }];
            });
        });
    };
    LetterRegister.prototype.act_6466705138206980_setData_2620_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "selectedRegisterId",
                        value: $event.data.id,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    LetterRegister.prototype.evh_6466705138206980_input = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_6466705138206980_input.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegister.prototype.act_8320016629450276_reloadSlickgrid_2624_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "letterRegister",
                    }];
            });
        });
    };
    LetterRegister.prototype.act_8320016629450276_reloadSlickgrid_2626_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "letterRegister",
                    }];
            });
        });
    };
    LetterRegister.prototype.evh_8320016629450276_close_2625_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data.modalResult.ok];
            });
        });
    };
    LetterRegister.prototype.act_8320016629450276_showModal_2622_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "LetterRegisterEntry",
                        props: {
                            register: this.selectedRegister,
                        },
                    }];
            });
        });
    };
    LetterRegister.prototype.evh_8320016629450276_click = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegister.prototype.getActions = function () {
        var act_7315092382398562_closeModal_2608 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2608_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_2607 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2608,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2607],
        });
        var act_2248226175642056_reloadSlickgrid_2612 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_2612_getActionArgs,
            events: [],
        });
        var evh_2248226175642056_saved_2611 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_2612,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        var act_2248226175642056_reloadSlickgrid_2614 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_2614_getActionArgs,
            events: [],
        });
        var evh_2248226175642056_close_2613 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_2614,
            event: "close",
            when: this.evh_2248226175642056_close_2613_getWhen,
            displayName: "reloadSlickgrid",
        });
        var act_2248226175642056_showModal_2610 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_2610_getActionArgs,
            events: [evh_2248226175642056_saved_2611, evh_2248226175642056_close_2613],
        });
        var evh_2248226175642056_command_2609 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_2610,
            event: "command",
            when: this.evh_2248226175642056_command_2609_getWhen,
            displayName: "showModal",
        });
        var evh_2248226175642056_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_command_2609],
        });
        var act_2248226175642056_setData_2616 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_2616_getActionArgs,
            events: [],
        });
        var evh_2248226175642056_selectedRowsChanged_2615 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_2616,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        var evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_2615],
        });
        var act_6466705138206980_reloadSlickgrid_2618 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_6466705138206980_reloadSlickgrid_2618_getActionArgs,
            events: [],
        });
        var evh_6466705138206980_input_2617 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_reloadSlickgrid_2618,
            event: "input",
            displayName: "reloadSlickgrid",
        });
        var act_6466705138206980_setData_2620 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_2620_getActionArgs,
            displayName: "updateSelectedRegisterId",
            events: [],
        });
        var evh_6466705138206980_input_2619 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_2620,
            event: "input",
            displayName: "updateSelectedRegisterId",
        });
        var evh_6466705138206980_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_input_2617, evh_6466705138206980_input_2619],
        });
        var act_8320016629450276_reloadSlickgrid_2624 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8320016629450276_reloadSlickgrid_2624_getActionArgs,
            events: [],
        });
        var evh_8320016629450276_saved_2623 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadSlickgrid_2624,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        var act_8320016629450276_reloadSlickgrid_2626 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8320016629450276_reloadSlickgrid_2626_getActionArgs,
            events: [],
        });
        var evh_8320016629450276_close_2625 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadSlickgrid_2626,
            event: "close",
            when: this.evh_8320016629450276_close_2625_getWhen,
            displayName: "reloadSlickgrid",
        });
        var act_8320016629450276_showModal_2622 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_2622_getActionArgs,
            events: [evh_8320016629450276_saved_2623, evh_8320016629450276_close_2625],
        });
        var evh_8320016629450276_click_2621 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_2622,
            event: "click",
            displayName: "showModal",
        });
        var evh_8320016629450276_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_click_2621],
        });
        return {
            act_7315092382398562_closeModal_2608: act_7315092382398562_closeModal_2608,
            evh_7315092382398562_close_2607: evh_7315092382398562_close_2607,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_reloadSlickgrid_2612: act_2248226175642056_reloadSlickgrid_2612,
            evh_2248226175642056_saved_2611: evh_2248226175642056_saved_2611,
            act_2248226175642056_reloadSlickgrid_2614: act_2248226175642056_reloadSlickgrid_2614,
            evh_2248226175642056_close_2613: evh_2248226175642056_close_2613,
            act_2248226175642056_showModal_2610: act_2248226175642056_showModal_2610,
            evh_2248226175642056_command_2609: evh_2248226175642056_command_2609,
            evh_2248226175642056_command: evh_2248226175642056_command,
            act_2248226175642056_setData_2616: act_2248226175642056_setData_2616,
            evh_2248226175642056_selectedRowsChanged_2615: evh_2248226175642056_selectedRowsChanged_2615,
            evh_2248226175642056_selectedRowsChanged: evh_2248226175642056_selectedRowsChanged,
            act_6466705138206980_reloadSlickgrid_2618: act_6466705138206980_reloadSlickgrid_2618,
            evh_6466705138206980_input_2617: evh_6466705138206980_input_2617,
            act_6466705138206980_setData_2620: act_6466705138206980_setData_2620,
            evh_6466705138206980_input_2619: evh_6466705138206980_input_2619,
            evh_6466705138206980_input: evh_6466705138206980_input,
            act_8320016629450276_reloadSlickgrid_2624: act_8320016629450276_reloadSlickgrid_2624,
            evh_8320016629450276_saved_2623: evh_8320016629450276_saved_2623,
            act_8320016629450276_reloadSlickgrid_2626: act_8320016629450276_reloadSlickgrid_2626,
            evh_8320016629450276_close_2625: evh_8320016629450276_close_2625,
            act_8320016629450276_showModal_2622: act_8320016629450276_showModal_2622,
            evh_8320016629450276_click_2621: evh_8320016629450276_click_2621,
            evh_8320016629450276_click: evh_8320016629450276_click,
            updateSelectedRegisterId: act_6466705138206980_setData_2620,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "md",
        }),
        __metadata("design:type", String)
    ], LetterRegister.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], LetterRegister.prototype, "onSize", null);
    LetterRegister = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], LetterRegister);
    return LetterRegister;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = LetterRegister;
vue_property_decorator_1.Vue.component("LetterRegister", LetterRegister);
