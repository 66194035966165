<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpBox
    direction="column"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ resourceTitle }}
    </ItpText>
    <ItpText
      tag="h4"
      :text="resourceKey"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <UcInvoiceTypeBadge
    :value="invoice.invoice_type"
  >
  </UcInvoiceTypeBadge>
  <UcInvoiceStatusBadge
    :value="invoice.status"
  >
  </UcInvoiceStatusBadge>
  <DIV
    v-if="invoice.tags && invoice.tags.length"
  >
    <span
      class="badge badge-secondary mx-1 shadow-none"
      v-for="tag in invoice.tags"
    >
      {{ tag }}
    </span>
  </DIV>
  <DIV
    :title="'Closed'|gettext"
    v-if="invoice.isReadOnly"
  >
    <ItpIcon
      :icon="$config.lockIcon"
    >
    </ItpIcon>
  </DIV>
  <ItpBox
    direction="column"
    v-if="invoice.reference_invoice"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Reference invoice'|pgettext('Invoice') }}
    </ItpText>
    <UcInvoiceMiniCard
      :item="invoice.reference_invoice"
      no-icon
    >
    </UcInvoiceMiniCard>
  </ItpBox>
  <ItpBox
    direction="column"
    v-if="invoice.referencing_invoices && invoice.referencing_invoices.length"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Referencing invoices'|pgettext('Invoice') }}
    </ItpText>
    <ItpBox>
      <UcInvoiceMiniCard
        :key="obj.id"
        :item="obj"
        no-icon
        class="mr-2"
        v-for="obj in invoice.referencing_invoices"
      >
      </UcInvoiceMiniCard>
    </ItpBox>
  </ItpBox>
  <Icon
    :icon="'flagpack:' + invoice.language"
    :title="'Invoice Language'|pgettext('Invoice')"
    v-if="!invoice.isNew"
  >
  </Icon>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <BtnSyncInvoice
      :invoice="invoice"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_8680084583475136_success($event, {})"
    >
    </BtnSyncInvoice>
    <BtnSyncResource
      :resource="invoice"
      target="remote"
      confirmMessage="A számlát csak egyszer lehet felölteni a régi rendszerbe,
feltöltés után a számlát már nem lehet módosítani."
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_3282321863127091_success($event, {})"
    >
    </BtnSyncResource>
    <ItpButton
      :icon="$config.editIcon"
      :tooltip="'Edit'|gettext"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew && !invoice.isReadOnly"
      @click.prevent.stop="evh_2386378572842701_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @click.prevent.stop="evh_6305342467955186_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:printer-one"
      :href="invoice.print_url"
      :tooltip="'Open print document'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.print_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:preview-open"
      :href="invoice.preview_url"
      :tooltip="'Open preview'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.preview_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:log"
      :tooltip="'Show log messages'|pgettext('Invoice')"
      class="ml-auto mr-2"
      v-if="invoice.log_messages"
      @click.prevent.stop="evh_6493473528837603_clickPreventStop($event, {})"
    >
    </ItpButton>
    <b-dropdown
      right
      variant="light"
      no-caret
      class="border"
      v-if="!invoice.isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <ItpButton
        dropdown-item
        :text="'Copy'|pgettext('BtnCopyResource')"
        :tooltip="'Create new invoice from this one'|pgettext('Invoice')"
        variant="default"
        @click="evh_5796712150329752_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        :text="'Create storno'|pgettext('Invoice')"
        :tooltip="createStornoTooltip"
        variant="default"
        :disabled="!invoiceIsReversible"
        v-if="!invoice.isNew"
        @click="evh_4125470329773403_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        variant="default"
        :text="'Create corrector'|pgettext('Invoice')"
        :tooltip="createCorrectorTooltip"
        :disabled="!invoiceIsModifiable"
        v-if="!invoice.isNew"
        @click="evh_3785918277944093_click($event, {})"
      >
      </ItpButton>
      <BtnLockResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Lock invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_2191919750581405_success($event, {})"
      >
      </BtnLockResource>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Delete invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_3699289115122175_success($event, {})"
      >
      </BtnDeleteResource>
      <hr>
      </hr>
      <ItpButton
        dropdown-item
        :text="'History'|pgettext('BtnResourceHistory')"
        :tooltip="'Invoice history'|pgettext('Invoice')"
        variant="default"
        @click.prevent.stop="evh_1247093655110851_clickPreventStop($event, {})"
      >
      </ItpButton>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
  })
  invoiceNumber!: string;

  @Watch('invoiceNumber')
  onInvoice_number(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceNumber")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          newTransaction: this.$fn.pgettext("Invoice", "New Invoice"),
          invoiceKind: {
            incoming: this.$fn.pgettext("InvoiceKind", "Incoming Invoice"),
            outgoing: this.$fn.pgettext("InvoiceKind", "Outgoing Invoice"),
          }
          ,
          createCorrectorTooltip: this.$fn.pgettext("Invoice", "Create corrector invoice"),
          createStornoTooltip: this.$fn.pgettext("Invoice", "Create storno invoice"),
        }
        ,
        ux: null,
      },
    }
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.messages.invoiceKind[this.invoice.invoice_kind]
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.invoiceNumber || (this.invoice.isNew ? this.messages.newTransaction : this.invoice.invoice_number)
    }
    return null;
  }

  get createCorrectorTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.message') || this.messages.createCorrectorTooltip
    }
    return null;
  }

  get createStornoTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.message') || this.messages.createStornoTooltip
    }
    return null;
  }

  get invoiceIsReversible() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.result')
    }
    return null;
  }

  get invoiceIsModifiable() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.result')
    }
    return null;
  }

  async act_8680084583475136_emit_4544_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8680084583475136_emit_4544, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8680084583475136_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8680084583475136_success.executeFromDOM(this, event, scope);
  }

  async act_3282321863127091_emit_4546_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3282321863127091_emit_4546, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3282321863127091_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3282321863127091_success.executeFromDOM(this, event, scope);
  }

  async act_2386378572842701_emit_4550_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2386378572842701_emit_4550, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_2386378572842701_close_4549_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2386378572842701_close_4549, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_2386378572842701_showModal_4548_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2386378572842701_showModal_4548, alias=undefined
    return {
      name: "InvoiceEditor",
      props: {
        invoice: _.cloneDeep(this.invoice),
      }
      ,
    }
  }

  async evh_2386378572842701_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2386378572842701_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6305342467955186_emit_4552_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6305342467955186_emit_4552, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_6305342467955186_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6305342467955186_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6493473528837603_showModal_4554_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6493473528837603_showModal_4554, alias=undefined
    return {
      name: "InvoiceLogMessageViewer",
      props: {
        invoiceId: this.invoice.id,
      }
      ,
    }
  }

  async evh_6493473528837603_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6493473528837603_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5796712150329752_emit_4558_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5796712150329752_emit_4558, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_5796712150329752_request_4556_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_5796712150329752_request_4556, alias=createInvoicecopy
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'copy' },
    }
  }

  async evh_5796712150329752_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5796712150329752_click.executeFromDOM(this, event, scope);
  }

  async act_4125470329773403_emit_4562_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4125470329773403_emit_4562, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_4125470329773403_request_4560_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_4125470329773403_request_4560, alias=createStornoInvoice
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'storno' },
    }
  }

  async evh_4125470329773403_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4125470329773403_click.executeFromDOM(this, event, scope);
  }

  async act_3785918277944093_emit_4566_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3785918277944093_emit_4566, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_3785918277944093_request_4564_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_3785918277944093_request_4564, alias=createCorrectorIvoice
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'correction' },
    }
  }

  async evh_3785918277944093_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3785918277944093_click.executeFromDOM(this, event, scope);
  }

  async act_2191919750581405_emit_4568_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2191919750581405_emit_4568, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_2191919750581405_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2191919750581405_success.executeFromDOM(this, event, scope);
  }

  async act_3699289115122175_emit_4570_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3699289115122175_emit_4570, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_3699289115122175_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3699289115122175_success.executeFromDOM(this, event, scope);
  }

  async act_1247093655110851_showModal_4572_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_1247093655110851_showModal_4572, alias=undefined
    return {
      name: "entityEvents",
      props: {
        entityId: this.invoice.id,
      }
      ,
    }
  }

  async evh_1247093655110851_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1247093655110851_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_8680084583475136_emit_4544: actions.EmitAction;
    evh_8680084583475136_success_4543: actions.EventHandlerImpl;
    evh_8680084583475136_success: actions.EventHandlerGroup;
    act_3282321863127091_emit_4546: actions.EmitAction;
    evh_3282321863127091_success_4545: actions.EventHandlerImpl;
    evh_3282321863127091_success: actions.EventHandlerGroup;
    act_2386378572842701_emit_4550: actions.EmitAction;
    evh_2386378572842701_close_4549: actions.EventHandlerImpl;
    act_2386378572842701_showModal_4548: actions.ShowModalAction;
    evh_2386378572842701_clickPreventStop_4547: actions.EventHandlerImpl;
    evh_2386378572842701_clickPreventStop: actions.EventHandlerGroup;
    act_6305342467955186_emit_4552: actions.EmitAction;
    evh_6305342467955186_clickPreventStop_4551: actions.EventHandlerImpl;
    evh_6305342467955186_clickPreventStop: actions.EventHandlerGroup;
    act_6493473528837603_showModal_4554: actions.ShowModalAction;
    evh_6493473528837603_clickPreventStop_4553: actions.EventHandlerImpl;
    evh_6493473528837603_clickPreventStop: actions.EventHandlerGroup;
    act_5796712150329752_emit_4558: actions.EmitAction;
    evh_5796712150329752_success_4557: actions.EventHandlerImpl;
    act_5796712150329752_request_4556: actions.RequestAction;
    evh_5796712150329752_click_4555: actions.EventHandlerImpl;
    evh_5796712150329752_click: actions.EventHandlerGroup;
    act_4125470329773403_emit_4562: actions.EmitAction;
    evh_4125470329773403_success_4561: actions.EventHandlerImpl;
    act_4125470329773403_request_4560: actions.RequestAction;
    evh_4125470329773403_click_4559: actions.EventHandlerImpl;
    evh_4125470329773403_click: actions.EventHandlerGroup;
    act_3785918277944093_emit_4566: actions.EmitAction;
    evh_3785918277944093_success_4565: actions.EventHandlerImpl;
    act_3785918277944093_request_4564: actions.RequestAction;
    evh_3785918277944093_click_4563: actions.EventHandlerImpl;
    evh_3785918277944093_click: actions.EventHandlerGroup;
    act_2191919750581405_emit_4568: actions.EmitAction;
    evh_2191919750581405_success_4567: actions.EventHandlerImpl;
    evh_2191919750581405_success: actions.EventHandlerGroup;
    act_3699289115122175_emit_4570: actions.EmitAction;
    evh_3699289115122175_success_4569: actions.EventHandlerImpl;
    evh_3699289115122175_success: actions.EventHandlerGroup;
    act_1247093655110851_showModal_4572: actions.ShowModalAction;
    evh_1247093655110851_clickPreventStop_4571: actions.EventHandlerImpl;
    evh_1247093655110851_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_8680084583475136_emit_4544 = new actions.EmitAction(
      {
        actionArgs: this.act_8680084583475136_emit_4544_getActionArgs,
        events: [],
      }
    );
    const evh_8680084583475136_success_4543 = new actions.EventHandlerImpl(
      {
        action: act_8680084583475136_emit_4544,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_8680084583475136_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_8680084583475136_success_4543],
      }
    );
    const act_3282321863127091_emit_4546 = new actions.EmitAction(
      {
        actionArgs: this.act_3282321863127091_emit_4546_getActionArgs,
        events: [],
      }
    );
    const evh_3282321863127091_success_4545 = new actions.EventHandlerImpl(
      {
        action: act_3282321863127091_emit_4546,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3282321863127091_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3282321863127091_success_4545],
      }
    );
    const act_2386378572842701_emit_4550 = new actions.EmitAction(
      {
        actionArgs: this.act_2386378572842701_emit_4550_getActionArgs,
        events: [],
      }
    );
    const evh_2386378572842701_close_4549 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_emit_4550,
        event: "close",
        when: this.evh_2386378572842701_close_4549_getWhen,
        displayName: "emit",
      }
    );
    const act_2386378572842701_showModal_4548 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2386378572842701_showModal_4548_getActionArgs,
        events: [evh_2386378572842701_close_4549],
      }
    );
    const evh_2386378572842701_clickPreventStop_4547 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_showModal_4548,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2386378572842701_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2386378572842701_clickPreventStop_4547],
      }
    );
    const act_6305342467955186_emit_4552 = new actions.EmitAction(
      {
        actionArgs: this.act_6305342467955186_emit_4552_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_6305342467955186_clickPreventStop_4551 = new actions.EventHandlerImpl(
      {
        action: act_6305342467955186_emit_4552,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_6305342467955186_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6305342467955186_clickPreventStop_4551],
      }
    );
    const act_6493473528837603_showModal_4554 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6493473528837603_showModal_4554_getActionArgs,
        events: [],
      }
    );
    const evh_6493473528837603_clickPreventStop_4553 = new actions.EventHandlerImpl(
      {
        action: act_6493473528837603_showModal_4554,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_6493473528837603_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6493473528837603_clickPreventStop_4553],
      }
    );
    const act_5796712150329752_emit_4558 = new actions.EmitAction(
      {
        actionArgs: this.act_5796712150329752_emit_4558_getActionArgs,
        events: [],
      }
    );
    const evh_5796712150329752_success_4557 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_emit_4558,
        event: "success",
        displayName: "emit",
      }
    );
    const act_5796712150329752_request_4556 = new actions.RequestAction(
      {
        actionArgs: this.act_5796712150329752_request_4556_getActionArgs,
        displayName: "createInvoicecopy",
        events: [evh_5796712150329752_success_4557],
      }
    );
    const evh_5796712150329752_click_4555 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_request_4556,
        event: "click",
        displayName: "createInvoicecopy",
      }
    );
    const evh_5796712150329752_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_5796712150329752_click_4555],
      }
    );
    const act_4125470329773403_emit_4562 = new actions.EmitAction(
      {
        actionArgs: this.act_4125470329773403_emit_4562_getActionArgs,
        events: [],
      }
    );
    const evh_4125470329773403_success_4561 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_emit_4562,
        event: "success",
        displayName: "emit",
      }
    );
    const act_4125470329773403_request_4560 = new actions.RequestAction(
      {
        actionArgs: this.act_4125470329773403_request_4560_getActionArgs,
        displayName: "createStornoInvoice",
        events: [evh_4125470329773403_success_4561],
      }
    );
    const evh_4125470329773403_click_4559 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_request_4560,
        event: "click",
        displayName: "createStornoInvoice",
      }
    );
    const evh_4125470329773403_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4125470329773403_click_4559],
      }
    );
    const act_3785918277944093_emit_4566 = new actions.EmitAction(
      {
        actionArgs: this.act_3785918277944093_emit_4566_getActionArgs,
        events: [],
      }
    );
    const evh_3785918277944093_success_4565 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_emit_4566,
        event: "success",
        displayName: "emit",
      }
    );
    const act_3785918277944093_request_4564 = new actions.RequestAction(
      {
        actionArgs: this.act_3785918277944093_request_4564_getActionArgs,
        displayName: "createCorrectorIvoice",
        events: [evh_3785918277944093_success_4565],
      }
    );
    const evh_3785918277944093_click_4563 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_request_4564,
        event: "click",
        displayName: "createCorrectorIvoice",
      }
    );
    const evh_3785918277944093_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3785918277944093_click_4563],
      }
    );
    const act_2191919750581405_emit_4568 = new actions.EmitAction(
      {
        actionArgs: this.act_2191919750581405_emit_4568_getActionArgs,
        events: [],
      }
    );
    const evh_2191919750581405_success_4567 = new actions.EventHandlerImpl(
      {
        action: act_2191919750581405_emit_4568,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_2191919750581405_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_2191919750581405_success_4567],
      }
    );
    const act_3699289115122175_emit_4570 = new actions.EmitAction(
      {
        actionArgs: this.act_3699289115122175_emit_4570_getActionArgs,
        events: [],
      }
    );
    const evh_3699289115122175_success_4569 = new actions.EventHandlerImpl(
      {
        action: act_3699289115122175_emit_4570,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3699289115122175_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3699289115122175_success_4569],
      }
    );
    const act_1247093655110851_showModal_4572 = new actions.ShowModalAction(
      {
        actionArgs: this.act_1247093655110851_showModal_4572_getActionArgs,
        events: [],
      }
    );
    const evh_1247093655110851_clickPreventStop_4571 = new actions.EventHandlerImpl(
      {
        action: act_1247093655110851_showModal_4572,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_1247093655110851_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1247093655110851_clickPreventStop_4571],
      }
    );
    return {
      act_8680084583475136_emit_4544,
      evh_8680084583475136_success_4543,
      evh_8680084583475136_success,
      act_3282321863127091_emit_4546,
      evh_3282321863127091_success_4545,
      evh_3282321863127091_success,
      act_2386378572842701_emit_4550,
      evh_2386378572842701_close_4549,
      act_2386378572842701_showModal_4548,
      evh_2386378572842701_clickPreventStop_4547,
      evh_2386378572842701_clickPreventStop,
      act_6305342467955186_emit_4552,
      evh_6305342467955186_clickPreventStop_4551,
      evh_6305342467955186_clickPreventStop,
      act_6493473528837603_showModal_4554,
      evh_6493473528837603_clickPreventStop_4553,
      evh_6493473528837603_clickPreventStop,
      act_5796712150329752_emit_4558,
      evh_5796712150329752_success_4557,
      act_5796712150329752_request_4556,
      evh_5796712150329752_click_4555,
      evh_5796712150329752_click,
      act_4125470329773403_emit_4562,
      evh_4125470329773403_success_4561,
      act_4125470329773403_request_4560,
      evh_4125470329773403_click_4559,
      evh_4125470329773403_click,
      act_3785918277944093_emit_4566,
      evh_3785918277944093_success_4565,
      act_3785918277944093_request_4564,
      evh_3785918277944093_click_4563,
      evh_3785918277944093_click,
      act_2191919750581405_emit_4568,
      evh_2191919750581405_success_4567,
      evh_2191919750581405_success,
      act_3699289115122175_emit_4570,
      evh_3699289115122175_success_4569,
      evh_3699289115122175_success,
      act_1247093655110851_showModal_4572,
      evh_1247093655110851_clickPreventStop_4571,
      evh_1247093655110851_clickPreventStop,
      reload: act_6305342467955186_emit_4552,
      createInvoicecopy: act_5796712150329752_request_4556,
      createStornoInvoice: act_4125470329773403_request_4560,
      createCorrectorIvoice: act_3785918277944093_request_4564,
    }
  }
}

Vue.component("UcInvoiceHeader", UcInvoiceHeader);

</script>