<template>
<ItpModal
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WorksheetEditor"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.worksheet.color"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <DIV
    class="page d-flex flex-column"
  >
    <DIV
      class="page__content container-fluid pt-3 h-100"
    >
      <ItpForm
        :id="formId"
        name="worksheet"
        class="row"
        ref="mainForm"
        @submit="evh_5534025912102772_submit($event, {})"
      >
        <ItpCol>
          <UcWorksheetEditorFieldset
            :worksheet="worksheet"
            :formId="formId"
            :target="target"
          >
          </UcWorksheetEditorFieldset>
        </ItpCol>
      </ItpForm>
    </DIV>
  </DIV>
  <UcFooter
    :card="!modal"
    :modal="modal"
    :color="$config.worksheet.color"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save changes'|pgettext('Button')"
      type="submit"
      :form="forms.worksheet"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class WorksheetEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  getPayload(...args: any[]) {
    const [data] = args
    const fields = this.target && this.targetConfig[this.target].fieldsToSubmit
    return fields ? _.pick(data, fields) : data;
  }

  @Prop({
    type: Object,
  })
  worksheet!: object;

  @Watch('worksheet')
  onWorksheet(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("worksheet")
  }

  @Prop({
    type: String,
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  defaultTitle!: any;
  targetConfig!: any;
  ux!: any;
  dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        defaultTitle: this.$fn.pgettext("Worksheet", "Edit Worksheet"),
        targetConfig: {
          header: {
            title: this.$fn.pgettext("Worksheet", "Edit Worksheet Header"),
            fieldsToSubmit: ["id"
              ,
              "revision_id"
              ,
              "etag"
              ,
              "company"
              ,
            ]
            ,
          }
          ,
        }
        ,
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4204_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4204, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_closeComponent_4206_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_2248226175642056_closeComponent_4206, alias=undefined
    return {
      result: {
        cancel: true,
      }
      ,
    }
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_5534025912102772_closeModal_4210_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_5534025912102772_closeModal_4210, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_5534025912102772_crud_4208_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_5534025912102772_crud_4208, alias=save
    return {
      objectType: "worksheet",
      op: "upsert",
      data: this.getPayload(this.worksheet),
    }
  }

  async evh_5534025912102772_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeComponent_4212_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1419464017721962_closeComponent_4212, alias=undefined
    return {
      result: {
        cancel: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4204: actions.CloseModalAction;
    evh_7315092382398562_close_4203: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_4206: actions.CloseComponentAction;
    evh_2248226175642056_close_4205: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_5534025912102772_closeModal_4210: actions.CloseModalAction;
    evh_5534025912102772_success_4209: actions.EventHandlerImpl;
    act_5534025912102772_crud_4208: actions.CRUDAction;
    evh_5534025912102772_submit_4207: actions.EventHandlerImpl;
    evh_5534025912102772_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_4212: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_4211: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4204 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4204_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4203 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4204,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4203],
      }
    );
    const act_2248226175642056_closeComponent_4206 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_2248226175642056_closeComponent_4206_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_close_4205 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_4206,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_4205],
      }
    );
    const act_5534025912102772_closeModal_4210 = new actions.CloseModalAction(
      {
        actionArgs: this.act_5534025912102772_closeModal_4210_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_success_4209 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_closeModal_4210,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_5534025912102772_crud_4208 = new actions.CRUDAction(
      {
        actionArgs: this.act_5534025912102772_crud_4208_getActionArgs,
        displayName: "save",
        events: [evh_5534025912102772_success_4209],
      }
    );
    const evh_5534025912102772_submit_4207 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_crud_4208,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_5534025912102772_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_submit_4207],
      }
    );
    const act_1419464017721962_closeComponent_4212 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1419464017721962_closeComponent_4212_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_4211 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_4212,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_4211],
      }
    );
    return {
      act_7315092382398562_closeModal_4204,
      evh_7315092382398562_close_4203,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_4206,
      evh_2248226175642056_close_4205,
      evh_2248226175642056_close,
      act_5534025912102772_closeModal_4210,
      evh_5534025912102772_success_4209,
      act_5534025912102772_crud_4208,
      evh_5534025912102772_submit_4207,
      evh_5534025912102772_submit,
      act_1419464017721962_closeComponent_4212,
      evh_1419464017721962_clickPreventStop_4211,
      evh_1419464017721962_clickPreventStop,
      save: act_5534025912102772_crud_4208,
    }
  }
}

Vue.component("WorksheetEditor", WorksheetEditor);

</script>