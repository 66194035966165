import { render, staticRenderFns } from "./UcSuggestInvoiceRegisterEntryOption.yaml?vue&type=template&id=0fb32ab2&component"
import script from "./UcSuggestInvoiceRegisterEntryOption.yaml?vue&type=script&lang=ts&component"
export * from "./UcSuggestInvoiceRegisterEntryOption.yaml?vue&type=script&lang=ts&component"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/buildbot/integro-appbuilder/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0fb32ab2')) {
      api.createRecord('0fb32ab2', component.options)
    } else {
      api.reload('0fb32ab2', component.options)
    }
    module.hot.accept("./UcSuggestInvoiceRegisterEntryOption.yaml?vue&type=template&id=0fb32ab2&component", function () {
      api.rerender('0fb32ab2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/invoiceRegister/primitives/UcSuggestInvoiceRegisterEntryOption.yaml"
export default component.exports