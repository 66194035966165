"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var LetterRegisterEntry = (function (_super) {
    __extends(LetterRegisterEntry, _super);
    function LetterRegisterEntry() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "page",
        };
        _this.dataMembers = ['container', 'formId', 'formName', 'resource', 'editor', 'ux', 'b_7315092382398562_modalBindings'];
        return _this;
    }
    LetterRegisterEntry.prototype.beforeCreate = function () {
    };
    LetterRegisterEntry.prototype.onOid = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    };
    LetterRegisterEntry.prototype.onRegister = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("register");
    };
    LetterRegisterEntry.prototype.onCompany_id = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("companyId");
    };
    LetterRegisterEntry.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    LetterRegisterEntry.prototype.$$load_container = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, {
                        self: this.$fn.urlFor('create_letter_register_entry'),
                        object: 'list',
                        data: []
                    }];
            });
        });
    };
    LetterRegisterEntry.prototype.$$load_formId = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.randomElementId()];
            });
        });
    };
    LetterRegisterEntry.prototype.$$load_resource = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.oid
                        ? this.$fn.fetch('read_letter_register_entry', { parameters: { id: this.oid }, asResource: true })
                        : this.$fn.schemaDefaults('CreateLetterRegisterEntryCommand', {
                            id: null,
                            register_name: this.register.id,
                            company_id: this.companyId,
                            entry_date: this.$fn.today()
                        })];
            });
        });
    };
    LetterRegisterEntry.prototype.$$load_editor = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.resource.editor()];
            });
        });
    };
    LetterRegisterEntry.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            container: null,
            formId: null,
            formName: "letterRegisterEntry",
            resource: null,
            editor: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    };
    Object.defineProperty(LetterRegisterEntry.prototype, "isDeletable", {
        get: function () {
            if (this.loaded) {
                return this.oid != null;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LetterRegisterEntry.prototype, "creatingTitle", {
        get: function () {
            if (this.loaded) {
                return this.$fn.pgettext("LetterRegisterEntry", "Create new entry");
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LetterRegisterEntry.prototype, "editingTitle", {
        get: function () {
            if (this.loaded) {
                return '#' + this.resource.entry_number;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LetterRegisterEntry.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.resource.isNew ? this.creatingTitle : this.editingTitle,
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    LetterRegisterEntry.prototype.act_7315092382398562_closeModal_2660_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    LetterRegisterEntry.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegisterEntry.prototype.evh_2248226175642056_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegisterEntry.prototype.evh_8564662037462133_reload = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegisterEntry.prototype.act_8564662037462133_reloadSlickgrid_2668_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "letterRegister",
                    }];
            });
        });
    };
    LetterRegisterEntry.prototype.evh_8564662037462133_deleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegisterEntry.prototype.act_1040167445267876_emit_2672_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "saved",
                        value: $event.data,
                    }];
            });
        });
    };
    LetterRegisterEntry.prototype.act_1040167445267876_reloadSlickgrid_2674_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "letterRegister",
                    }];
            });
        });
    };
    LetterRegisterEntry.prototype.act_1040167445267876_rest_2670_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        method: "save",
                        container: this.container,
                        resource: this.editor,
                    }];
            });
        });
    };
    LetterRegisterEntry.prototype.evh_1040167445267876_submit = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegisterEntry.prototype.act_1548630417156826_script_2676_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                (this.setData('resource', this.resource.clone({
                    id: null,
                    rev: null,
                    entry_number: null,
                    subject: null,
                    links: null,
                    etag: null,
                    locked: null,
                })),
                    this.setData('editor', this.resource.editor()));
                return [2];
            });
        });
    };
    LetterRegisterEntry.prototype.act_1548630417156826_script_2676_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_1548630417156826_script_2676_getActionArgs_value($event); },
                    }];
            });
        });
    };
    LetterRegisterEntry.prototype.evh_1548630417156826_click = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegisterEntry.prototype.evh_1419464017721962_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    LetterRegisterEntry.prototype.getActions = function () {
        var act_7315092382398562_closeModal_2660 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2660_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_2659 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2660,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2659],
        });
        var act_2248226175642056_closeComponent_2662 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_2248226175642056_close_2661 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2662,
            event: "close",
            displayName: "closeComponent",
        });
        var evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2661],
        });
        var act_8564662037462133_reloadComponentData_2664 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_8564662037462133_reload_2663 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_2664,
            event: "reload",
            displayName: "reloadComponentData",
        });
        var evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_2663],
        });
        var act_8564662037462133_closeModal_2666 = new core_1.actions.CloseModalAction({
            events: [],
        });
        var evh_8564662037462133_deleted_2665 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeModal_2666,
            event: "deleted",
            displayName: "closeModal",
        });
        var act_8564662037462133_reloadSlickgrid_2668 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_2668_getActionArgs,
            events: [],
        });
        var evh_8564662037462133_deleted_2667 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_2668,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        var evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_2665, evh_8564662037462133_deleted_2667],
        });
        var act_1040167445267876_emit_2672 = new core_1.actions.EmitAction({
            actionArgs: this.act_1040167445267876_emit_2672_getActionArgs,
            events: [],
        });
        var evh_1040167445267876_saved_2671 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_emit_2672,
            event: "saved",
            displayName: "emit",
        });
        var act_1040167445267876_reloadSlickgrid_2674 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_2674_getActionArgs,
            events: [],
        });
        var evh_1040167445267876_saved_2673 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_2674,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        var act_1040167445267876_rest_2670 = new core_1.actions.RestAction({
            actionArgs: this.act_1040167445267876_rest_2670_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_saved_2671, evh_1040167445267876_saved_2673],
        });
        var evh_1040167445267876_submit_2669 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_rest_2670,
            event: "submit",
            displayName: "save",
        });
        var evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_2669],
        });
        var act_1548630417156826_script_2676 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1548630417156826_script_2676_getActionArgs,
            events: [],
        });
        var evh_1548630417156826_click_2675 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_script_2676,
            event: "click",
            displayName: "script",
        });
        var evh_1548630417156826_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_click_2675],
        });
        var act_1419464017721962_closeComponent_2678 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_1419464017721962_clickPreventStop_2677 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_2678,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        var evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_2677],
        });
        return {
            act_7315092382398562_closeModal_2660: act_7315092382398562_closeModal_2660,
            evh_7315092382398562_close_2659: evh_7315092382398562_close_2659,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2662: act_2248226175642056_closeComponent_2662,
            evh_2248226175642056_close_2661: evh_2248226175642056_close_2661,
            evh_2248226175642056_close: evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_2664: act_8564662037462133_reloadComponentData_2664,
            evh_8564662037462133_reload_2663: evh_8564662037462133_reload_2663,
            evh_8564662037462133_reload: evh_8564662037462133_reload,
            act_8564662037462133_closeModal_2666: act_8564662037462133_closeModal_2666,
            evh_8564662037462133_deleted_2665: evh_8564662037462133_deleted_2665,
            act_8564662037462133_reloadSlickgrid_2668: act_8564662037462133_reloadSlickgrid_2668,
            evh_8564662037462133_deleted_2667: evh_8564662037462133_deleted_2667,
            evh_8564662037462133_deleted: evh_8564662037462133_deleted,
            act_1040167445267876_emit_2672: act_1040167445267876_emit_2672,
            evh_1040167445267876_saved_2671: evh_1040167445267876_saved_2671,
            act_1040167445267876_reloadSlickgrid_2674: act_1040167445267876_reloadSlickgrid_2674,
            evh_1040167445267876_saved_2673: evh_1040167445267876_saved_2673,
            act_1040167445267876_rest_2670: act_1040167445267876_rest_2670,
            evh_1040167445267876_submit_2669: evh_1040167445267876_submit_2669,
            evh_1040167445267876_submit: evh_1040167445267876_submit,
            act_1548630417156826_script_2676: act_1548630417156826_script_2676,
            evh_1548630417156826_click_2675: evh_1548630417156826_click_2675,
            evh_1548630417156826_click: evh_1548630417156826_click,
            act_1419464017721962_closeComponent_2678: act_1419464017721962_closeComponent_2678,
            evh_1419464017721962_clickPreventStop_2677: evh_1419464017721962_clickPreventStop_2677,
            evh_1419464017721962_clickPreventStop: evh_1419464017721962_clickPreventStop,
            save: act_1040167445267876_rest_2670,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], LetterRegisterEntry.prototype, "oid", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('oid'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], LetterRegisterEntry.prototype, "onOid", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Object,
        }),
        __metadata("design:type", Object)
    ], LetterRegisterEntry.prototype, "register", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('register'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], LetterRegisterEntry.prototype, "onRegister", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], LetterRegisterEntry.prototype, "companyId", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('companyId'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], LetterRegisterEntry.prototype, "onCompany_id", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "md",
        }),
        __metadata("design:type", String)
    ], LetterRegisterEntry.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], LetterRegisterEntry.prototype, "onSize", null);
    LetterRegisterEntry = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], LetterRegisterEntry);
    return LetterRegisterEntry;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = LetterRegisterEntry;
vue_property_decorator_1.Vue.component("LetterRegisterEntry", LetterRegisterEntry);
