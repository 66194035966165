<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <legend
    v-if="!!noLegend"
  >
    {{ 'Rental'|pgettext('Contract') }}
  </legend>
  <ItpFormGroup
    name="rental"
    :label="'Rental'|pgettext('Rental')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    :required="rentalRequired"
  >
    <ItpFormSelect2
      name="rental"
      :required="rentalRequired"
      v-model="contract.rental"
      bind-object
      :dataSource="b_1040167445267876_dataSource"
      textField="number,name"
      clearable
      :template="b_1040167445267876_template"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="name"
    :label="'Name'|pgettext('Rental')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.rental"
  >
    <ItpFormInput
      name="name"
      v-model="contract.rental.name"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="name"
    :label="'Address'|pgettext('Rental')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.rental"
  >
    <ItpFormInput
      name="name"
      v-model="contract.rental.address"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="area"
    :label="'Area'|pgettext('Rental')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    v-if="contract.rental"
  >
    <ItpFormInput
      name="name"
      v-model="contract.rental.area"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="lot_number"
    :label="'LOT number'|pgettext('Rental')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    v-if="contract.rental"
  >
    <ItpFormInput
      name="name"
      v-model="contract.rental.lot_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="house_number"
    :label="'House number'|pgettext('Rental')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    v-if="contract.rental"
  >
    <ItpFormInput
      name="name"
      v-model="contract.rental.house_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="description"
    :label="'Description'|pgettext('Rental')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.rental"
  >
    <ItpFormTextarea
      name="name"
      v-model="contract.rental.description"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetContractRentalData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    type: Boolean,
  })
  noLegend!: boolean;

  @Watch('noLegend')
  onNo_legend(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noLegend")
  }

  ux!: any;
  b_1040167445267876_dataSource!: any;
  b_1040167445267876_template!: any;
  dataMembers = ['ux', 'b_1040167445267876_dataSource', 'b_1040167445267876_template'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_1040167445267876_dataSource: {
          name: "suggest_rental",
        }
        ,
        b_1040167445267876_template: "<div>\n\
  <span v-if=\"option.number\">{{ option.number }}</span>\n\
  <span v-if=\"option.name\">{{ option.name }}</span>\n\
</div>",
      },
    }
  }

  get rentalRequired() {
    if (this.loaded) {

      return ['rental', 'rental_agreement', 'parking'].indexOf(this.contract.contract_type) > -1
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6062: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6061: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6062 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6061 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6062,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6061],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6062,
      evh_7315092382398562_reload_6061,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetContractRentalData", FieldsetContractRentalData);

</script>