import { render, staticRenderFns } from "./UcProductGeneral.yaml?vue&type=template&id=d01b974e&component"
import script from "./UcProductGeneral.yaml?vue&type=script&lang=ts&component"
export * from "./UcProductGeneral.yaml?vue&type=script&lang=ts&component"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/buildbot/integro-appbuilder/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d01b974e')) {
      api.createRecord('d01b974e', component.options)
    } else {
      api.reload('d01b974e', component.options)
    }
    module.hot.accept("./UcProductGeneral.yaml?vue&type=template&id=d01b974e&component", function () {
      api.rerender('d01b974e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/product/form/UcProductGeneral.yaml"
export default component.exports