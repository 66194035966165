import { render, staticRenderFns } from "./JobHeader.yaml?vue&type=template&id=11c08bc8&component"
import script from "./JobHeader.yaml?vue&type=script&lang=ts&component"
export * from "./JobHeader.yaml?vue&type=script&lang=ts&component"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/buildbot/integro-appbuilder/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11c08bc8')) {
      api.createRecord('11c08bc8', component.options)
    } else {
      api.reload('11c08bc8', component.options)
    }
    module.hot.accept("./JobHeader.yaml?vue&type=template&id=11c08bc8&component", function () {
      api.rerender('11c08bc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/jobs/job/JobHeader.yaml"
export default component.exports