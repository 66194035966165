"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var RentalService = (function (_super) {
    __extends(RentalService, _super);
    function RentalService() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "page",
        };
        _this.dataMembers = ['item', 'units', 'formName', 'formId', 'labelAlign', 'isReadOnly', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_dataSource', 'b_2070266589801014_dataSource', 'b_5074589820235598_dataSource'];
        return _this;
    }
    RentalService.prototype.beforeCreate = function () {
    };
    RentalService.prototype.onOid = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    };
    RentalService.prototype.onRental = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rental");
    };
    RentalService.prototype.onMeter = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("meter");
    };
    RentalService.prototype.onPrevent_edit_rental = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("preventEditRental");
    };
    RentalService.prototype.onPrevent_edit_meter = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("preventEditMeter");
    };
    RentalService.prototype.onAssign_to_rental = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("assignToRental");
    };
    RentalService.prototype.onAssign_to_meter = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("assignToMeter");
    };
    RentalService.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    RentalService.prototype.$$load_item = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.oid
                        ? this.$fn.fetch('read_rental_service', { parameters: { id: this.oid } })
                        : this.$fn.fetch('get_rental_service_defaults', {
                            parameters: {
                                meter_id: this.meter ? this.meter.id : null,
                                rental_id: this.rental ? this.rental.id : null,
                            }
                        })];
            });
        });
    };
    RentalService.prototype.$$load_units = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_unit').then(function (d) { return _.orderBy(d, [function (p) { return p.symbol.toLowerCase(); }]); })];
            });
        });
    };
    RentalService.prototype.$$load_formId = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.randomElementId()];
            });
        });
    };
    RentalService.prototype.$$load_isReadOnly = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.item.locked];
            });
        });
    };
    RentalService.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            units: null,
            formName: "rentalService",
            formId: null,
            labelAlign: "right",
            isReadOnly: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
            b_5731695935601903_dataSource: {
                name: "suggest_rental",
            },
            b_2070266589801014_dataSource: {
                name: "suggest_meter",
            },
            b_5074589820235598_dataSource: {
                name: "suggest_product",
                parameters: {
                    product_types: "service",
                },
            },
        });
    };
    Object.defineProperty(RentalService.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.oid ? this.$fn.gettext('Edit Rental Service') : this.$fn.gettext('Add Rental Service'),
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    RentalService.prototype.act_7315092382398562_closeModal_6752_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    RentalService.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    RentalService.prototype.evh_2248226175642056_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    RentalService.prototype.act_1040167445267876_closeModal_6758_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            ok: true,
                            value: $event.data.response.data,
                        },
                    }];
            });
        });
    };
    RentalService.prototype.act_1040167445267876_request_6756_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: this.item.id ? 'update_rental_service' : 'create_rental_service',
                        data: this.item,
                    }];
            });
        });
    };
    RentalService.prototype.evh_1040167445267876_submit = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    RentalService.prototype.act_2070266589801014_script_6762_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                _.extend(this.item, $event.data.response.data);
                return [2];
            });
        });
    };
    RentalService.prototype.act_2070266589801014_script_6762_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_2070266589801014_script_6762_getActionArgs_value($event); },
                    }];
            });
        });
    };
    RentalService.prototype.act_2070266589801014_request_6760_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "get_rental_service_defaults",
                        data: {
                            rental_id: this.rental ? this.rental.id : this.item.rental_id,
                            meter_id: this.item.meter_id,
                        },
                    }];
            });
        });
    };
    RentalService.prototype.evh_2070266589801014_optionSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2070266589801014_optionSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    RentalService.prototype.act_5074589820235598_setData_6764_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.service_id",
                        value: this.item.service.id,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    RentalService.prototype.evh_5074589820235598_optionSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5074589820235598_optionSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    RentalService.prototype.act_2140198529698299_closeModal_6766_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            ok: true,
                        },
                    }];
            });
        });
    };
    RentalService.prototype.evh_2140198529698299_success = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2140198529698299_success.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    RentalService.prototype.act_2434514588044777_closeModal_6768_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            ok: true,
                        },
                    }];
            });
        });
    };
    RentalService.prototype.evh_2434514588044777_success = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2434514588044777_success.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    RentalService.prototype.act_2186679009640457_closeModal_6770_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            cancel: true,
                        },
                    }];
            });
        });
    };
    RentalService.prototype.evh_2186679009640457_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    RentalService.prototype.getActions = function () {
        var act_7315092382398562_closeModal_6752 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6752_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_6751 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6752,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6751],
        });
        var act_2248226175642056_closeComponent_6754 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_2248226175642056_close_6753 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6754,
            event: "close",
            displayName: "closeComponent",
        });
        var evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6753],
        });
        var act_1040167445267876_closeModal_6758 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_6758_getActionArgs,
            events: [],
        });
        var evh_1040167445267876_success_6757 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_6758,
            event: "success",
            displayName: "closeModal",
        });
        var act_1040167445267876_request_6756 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_6756_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_6757],
        });
        var evh_1040167445267876_submit_6755 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_6756,
            event: "submit",
            displayName: "submit",
        });
        var evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6755],
        });
        var act_2070266589801014_script_6762 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2070266589801014_script_6762_getActionArgs,
            events: [],
        });
        var evh_2070266589801014_success_6761 = new core_1.actions.EventHandlerImpl({
            action: act_2070266589801014_script_6762,
            event: "success",
            displayName: "script",
        });
        var act_2070266589801014_request_6760 = new core_1.actions.RequestAction({
            actionArgs: this.act_2070266589801014_request_6760_getActionArgs,
            displayName: "getDefaultsOnMeterSelected",
            events: [evh_2070266589801014_success_6761],
        });
        var evh_2070266589801014_optionSelected_6759 = new core_1.actions.EventHandlerImpl({
            action: act_2070266589801014_request_6760,
            event: "optionSelected",
            displayName: "getDefaultsOnMeterSelected",
        });
        var evh_2070266589801014_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2070266589801014_optionSelected_6759],
        });
        var act_5074589820235598_setData_6764 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5074589820235598_setData_6764_getActionArgs,
            events: [],
        });
        var evh_5074589820235598_optionSelected_6763 = new core_1.actions.EventHandlerImpl({
            action: act_5074589820235598_setData_6764,
            event: "optionSelected",
            displayName: "setData",
        });
        var evh_5074589820235598_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5074589820235598_optionSelected_6763],
        });
        var act_2140198529698299_closeModal_6766 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2140198529698299_closeModal_6766_getActionArgs,
            events: [],
        });
        var evh_2140198529698299_success_6765 = new core_1.actions.EventHandlerImpl({
            action: act_2140198529698299_closeModal_6766,
            event: "success",
            displayName: "closeModal",
        });
        var evh_2140198529698299_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2140198529698299_success_6765],
        });
        var act_2434514588044777_closeModal_6768 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2434514588044777_closeModal_6768_getActionArgs,
            events: [],
        });
        var evh_2434514588044777_success_6767 = new core_1.actions.EventHandlerImpl({
            action: act_2434514588044777_closeModal_6768,
            event: "success",
            displayName: "closeModal",
        });
        var evh_2434514588044777_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2434514588044777_success_6767],
        });
        var act_2186679009640457_closeModal_6770 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2186679009640457_closeModal_6770_getActionArgs,
            events: [],
        });
        var evh_2186679009640457_clickPreventStop_6769 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_closeModal_6770,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        var evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_6769],
        });
        return {
            act_7315092382398562_closeModal_6752: act_7315092382398562_closeModal_6752,
            evh_7315092382398562_close_6751: evh_7315092382398562_close_6751,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6754: act_2248226175642056_closeComponent_6754,
            evh_2248226175642056_close_6753: evh_2248226175642056_close_6753,
            evh_2248226175642056_close: evh_2248226175642056_close,
            act_1040167445267876_closeModal_6758: act_1040167445267876_closeModal_6758,
            evh_1040167445267876_success_6757: evh_1040167445267876_success_6757,
            act_1040167445267876_request_6756: act_1040167445267876_request_6756,
            evh_1040167445267876_submit_6755: evh_1040167445267876_submit_6755,
            evh_1040167445267876_submit: evh_1040167445267876_submit,
            act_2070266589801014_script_6762: act_2070266589801014_script_6762,
            evh_2070266589801014_success_6761: evh_2070266589801014_success_6761,
            act_2070266589801014_request_6760: act_2070266589801014_request_6760,
            evh_2070266589801014_optionSelected_6759: evh_2070266589801014_optionSelected_6759,
            evh_2070266589801014_optionSelected: evh_2070266589801014_optionSelected,
            act_5074589820235598_setData_6764: act_5074589820235598_setData_6764,
            evh_5074589820235598_optionSelected_6763: evh_5074589820235598_optionSelected_6763,
            evh_5074589820235598_optionSelected: evh_5074589820235598_optionSelected,
            act_2140198529698299_closeModal_6766: act_2140198529698299_closeModal_6766,
            evh_2140198529698299_success_6765: evh_2140198529698299_success_6765,
            evh_2140198529698299_success: evh_2140198529698299_success,
            act_2434514588044777_closeModal_6768: act_2434514588044777_closeModal_6768,
            evh_2434514588044777_success_6767: evh_2434514588044777_success_6767,
            evh_2434514588044777_success: evh_2434514588044777_success,
            act_2186679009640457_closeModal_6770: act_2186679009640457_closeModal_6770,
            evh_2186679009640457_clickPreventStop_6769: evh_2186679009640457_clickPreventStop_6769,
            evh_2186679009640457_clickPreventStop: evh_2186679009640457_clickPreventStop,
            submit: act_1040167445267876_request_6756,
            getDefaultsOnMeterSelected: act_2070266589801014_request_6760,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: null,
        }),
        __metadata("design:type", String)
    ], RentalService.prototype, "oid", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('oid'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], RentalService.prototype, "onOid", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Object,
            default: null,
        }),
        __metadata("design:type", Object)
    ], RentalService.prototype, "rental", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('rental'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], RentalService.prototype, "onRental", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Object,
            default: null,
        }),
        __metadata("design:type", Object)
    ], RentalService.prototype, "meter", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('meter'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], RentalService.prototype, "onMeter", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
            default: false,
        }),
        __metadata("design:type", Boolean)
    ], RentalService.prototype, "preventEditRental", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('preventEditRental'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], RentalService.prototype, "onPrevent_edit_rental", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
            default: false,
        }),
        __metadata("design:type", Boolean)
    ], RentalService.prototype, "preventEditMeter", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('preventEditMeter'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], RentalService.prototype, "onPrevent_edit_meter", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
            default: false,
        }),
        __metadata("design:type", Boolean)
    ], RentalService.prototype, "assignToRental", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('assignToRental'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], RentalService.prototype, "onAssign_to_rental", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
            default: false,
        }),
        __metadata("design:type", Boolean)
    ], RentalService.prototype, "assignToMeter", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('assignToMeter'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], RentalService.prototype, "onAssign_to_meter", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "md",
        }),
        __metadata("design:type", String)
    ], RentalService.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], RentalService.prototype, "onSize", null);
    RentalService = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], RentalService);
    return RentalService;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = RentalService;
vue_property_decorator_1.Vue.component("RentalService", RentalService);
