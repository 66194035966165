<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Waste Bin'|pgettext('FixedAsset') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <ItpLabeledListGroupItem
        :label="'Type'|pgettext('FixedAssetWasteBin')"
        v-if="fixedAsset.waste_bin.bin_type"
      >
        <span>
          {{ fixedAsset.waste_bin.bin_type | pgettext('BinTypes', 'StartCase')  }}
        </span>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Number'|pgettext('FixedAssetWasteBin')"
        :content="fixedAsset.waste_bin.bin_number|number"
        v-if="fixedAsset.waste_bin.bin_number != null"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Capacity'|pgettext('FixedAssetWasteBin')"
        v-if="fixedAsset.waste_bin.capacity != null"
      >
        <span>
          {{ fixedAsset.waste_bin.capacity.amount }} {{ fixedAsset.waste_bin.capacity.unit }}

        </span>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Label'|pgettext('FixedAssetWasteBin')"
      >
        <ItpButton
          :text="'Display label...'|pgettext('Button')"
          :href="$fn.urlFor('render_label', {id: fixedAsset.id})"
          use-auth
          size="sm"
          icon="ri:barcode-box-line"
        >
        </ItpButton>
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcFixedAssetWasteBin extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  fixedAsset!: object;

  @Watch('fixedAsset')
  onFixed_asset(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fixedAsset")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_4308_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_4308, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_4307_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_4307, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_4306_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_4306, alias=undefined
    return {
      name: "MFixedAssetEditor",
      props: {
        target: "wasteBin",
        fixedAsset: _.cloneDeep(this.fixedAsset),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4304: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4303: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_4308: actions.EmitAction;
    evh_8320016629450276_close_4307: actions.EventHandlerImpl;
    act_8320016629450276_showModal_4306: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_4305: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4304 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4303 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4304,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4303],
      }
    );
    const act_8320016629450276_emit_4308 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_4308_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_4307 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_4308,
        event: "close",
        when: this.evh_8320016629450276_close_4307_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_4306 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_4306_getActionArgs,
        events: [evh_8320016629450276_close_4307],
      }
    );
    const evh_8320016629450276_clickPreventStop_4305 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_4306,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_4305],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4304,
      evh_7315092382398562_reload_4303,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_4308,
      evh_8320016629450276_close_4307,
      act_8320016629450276_showModal_4306,
      evh_8320016629450276_clickPreventStop_4305,
      evh_8320016629450276_clickPreventStop,
    }
  }
}

Vue.component("UcFixedAssetWasteBin", UcFixedAssetWasteBin);

</script>