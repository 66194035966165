"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var FieldsetInvoiceHeader = (function (_super) {
    __extends(FieldsetInvoiceHeader, _super);
    function FieldsetInvoiceHeader() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['messages', 'duplicateInvoiceNumber', 'currencies', 'paymentMethods', 'expenseTypes', 'invoiceStatuses', 'incomingInvoiceStatuses', 'invoiceTypes', 'languages', 'tags', 'ux', 'b_4186377094414664_dataSource', 'b_6917646200545441_dataSource'];
        return _this;
    }
    FieldsetInvoiceHeader.prototype.beforeCreate = function () {
    };
    FieldsetInvoiceHeader.prototype.onReferenceInvoiceSelected = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = args[0], option = _a.option, value = _a.value;
        this.selectClient({ clientId: option.client.id });
    };
    FieldsetInvoiceHeader.prototype.selectClient = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var clientId = args[0].clientId;
        return this.$fn
            .fetch('get_invoice_party_details', { parameters: { id: clientId } })
            .then(function (data) { return _this.setData('invoice.client', data); })
            .catch(function (err) { return _this.onError(err); });
    };
    FieldsetInvoiceHeader.prototype.checkInvoiceNumber = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var invoiceNumber = args[0];
        return this.$fn
            .fetch('read_invoice', { parameters: { id: invoiceNumber, client: _.get(this.invoice, 'client.id') } })
            .then(function (d) {
            if (d.id != _this.invoice.id) {
                _this.duplicateInvoiceNumber = true;
            }
            else {
                _this.duplicateInvoiceNumber = false;
            }
        })
            .catch(function (err) {
            _this.duplicateInvoiceNumber = false;
        });
    };
    FieldsetInvoiceHeader.prototype.onInvoice = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    };
    FieldsetInvoiceHeader.prototype.onLabel_align = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("labelAlign");
    };
    FieldsetInvoiceHeader.prototype.$$load_currencies = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_currency')];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.$$load_paymentMethods = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_payment_method')
                        .then(function (data) { return _.map(data, function (p) { return ({
                        value: p.id,
                        text: _this.$fn.pgettext('PaymentMethod', p.name)
                    }); }); })];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.$$load_expenseTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } })];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.$$load_invoiceStatuses = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.getEnumValuesFromSchema(this.isIncomingInvoice ? 'IncomingInvoiceStatus' : 'OutgoingInvoiceStatus', { sort: false })];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.$$load_incomingInvoiceStatuses = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.getEnumValuesFromSchema('IncomingInvoiceStatus', { sort: false })];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.$$load_invoiceTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.getEnumValuesFromSchema('InvoiceType', { sort: false })];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.$$load_tags = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice' } }).then(function (data) { return _.map(data, function (p) { return p.name; }); })];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.$$load_b_4186377094414664_dataSource = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "suggest_invoice",
                        parameters: {
                            invoice_kind: this.invoice.invoice_kind,
                            parent_invoice_type: this.invoice.invoice_type,
                        },
                    }];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                invoiceTypes: {
                    correction: this.$fn.pgettext("InvoiceType", "Correction"),
                    storno: this.$fn.pgettext("InvoiceType", "Storno"),
                    proforma: this.$fn.pgettext("InvoiceType", "Pro Forma"),
                },
                invoiceTypeHelp: {
                    normal: this.$fn.pgettext("Invoice|Reference|description", "Normal invoices can reference Pro Forma invoices."),
                    correction: this.$fn.pgettext("Invoice|Reference|description", "A correction must reference the invoice being corrected."),
                    storno: this.$fn.pgettext("Invoice|Reference|description", "The reversing invoice must reference the invoice being reversed."),
                    proforma: this.$fn.pgettext("Invoice|Reference|description", "Pro Forma invoices can reference former Pro Forma invoices."),
                },
                duplicateInvoiceNumber: this.$fn.pgettext("Invoice", "The given invoice number already exists."),
            },
            duplicateInvoiceNumber: false,
            currencies: null,
            paymentMethods: null,
            expenseTypes: null,
            invoiceStatuses: null,
            incomingInvoiceStatuses: null,
            invoiceTypes: null,
            languages: [{
                    value: "hu",
                    text: this.$fn.pgettext("Language", "hungarian"),
                },
                {
                    value: "en",
                    text: this.$fn.pgettext("Language", "english"),
                },
            ],
            tags: null,
            ux: null,
            b_4186377094414664_dataSource: null,
            b_6917646200545441_dataSource: {
                name: "suggest_client",
            },
        });
    };
    Object.defineProperty(FieldsetInvoiceHeader.prototype, "isReadonly", {
        get: function () {
            if (this.loaded) {
                return false;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FieldsetInvoiceHeader.prototype, "isIncomingInvoice", {
        get: function () {
            if (this.loaded) {
                return this.invoice.invoice_kind == 'incoming';
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FieldsetInvoiceHeader.prototype, "isReferenceInvoiceRequired", {
        get: function () {
            if (this.loaded) {
                return ['storno', 'correction'].indexOf(this.invoice.invoice_type) > -1;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FieldsetInvoiceHeader.prototype, "preventChangeClient", {
        get: function () {
            if (this.loaded) {
                return !!this.invoice.reference_invoice;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    FieldsetInvoiceHeader.prototype.evh_7315092382398562_reload = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    FieldsetInvoiceHeader.prototype.act_4186377094414664_script_4436_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onReferenceInvoiceSelected($event.data);
                return [2];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.act_4186377094414664_script_4436_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_4186377094414664_script_4436_getActionArgs_value($event); },
                    }];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.evh_4186377094414664_optionSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4186377094414664_optionSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    FieldsetInvoiceHeader.prototype.act_6917646200545441_script_4438_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.selectClient({ clientId: this.invoice.client.id });
                return [2];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.act_6917646200545441_script_4438_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_6917646200545441_script_4438_getActionArgs_value($event); },
                    }];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.act_6917646200545441_script_4438_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!this.invoice.client];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.evh_6917646200545441_selected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_6917646200545441_selected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    FieldsetInvoiceHeader.prototype.act_7384593256854782_script_4440_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.checkInvoiceNumber(this.invoice.invoice_number);
                return [2];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.act_7384593256854782_script_4440_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_7384593256854782_script_4440_getActionArgs_value($event); },
                    }];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.evh_7384593256854782_blur_4439_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, this.invoice.isValueChanged('invoice_number')];
            });
        });
    };
    FieldsetInvoiceHeader.prototype.evh_7384593256854782_blur = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7384593256854782_blur.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    FieldsetInvoiceHeader.prototype.getActions = function () {
        var act_7315092382398562_reloadComponentData_4434 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_7315092382398562_reload_4433 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_4434,
            event: "reload",
            displayName: "reloadComponentData",
        });
        var evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_4433],
        });
        var act_4186377094414664_script_4436 = new core_1.actions.ScriptAction({
            actionArgs: this.act_4186377094414664_script_4436_getActionArgs,
            events: [],
        });
        var evh_4186377094414664_optionSelected_4435 = new core_1.actions.EventHandlerImpl({
            action: act_4186377094414664_script_4436,
            event: "optionSelected",
            displayName: "script",
        });
        var evh_4186377094414664_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4186377094414664_optionSelected_4435],
        });
        var act_6917646200545441_script_4438 = new core_1.actions.ScriptAction({
            actionArgs: this.act_6917646200545441_script_4438_getActionArgs,
            when: this.act_6917646200545441_script_4438_getWhen,
            events: [],
        });
        var evh_6917646200545441_selected_4437 = new core_1.actions.EventHandlerImpl({
            action: act_6917646200545441_script_4438,
            event: "selected",
            displayName: "script",
        });
        var evh_6917646200545441_selected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6917646200545441_selected_4437],
        });
        var act_7384593256854782_script_4440 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7384593256854782_script_4440_getActionArgs,
            events: [],
        });
        var evh_7384593256854782_blur_4439 = new core_1.actions.EventHandlerImpl({
            action: act_7384593256854782_script_4440,
            event: "blur",
            when: this.evh_7384593256854782_blur_4439_getWhen,
            displayName: "script",
        });
        var evh_7384593256854782_blur = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7384593256854782_blur_4439],
        });
        return {
            act_7315092382398562_reloadComponentData_4434: act_7315092382398562_reloadComponentData_4434,
            evh_7315092382398562_reload_4433: evh_7315092382398562_reload_4433,
            evh_7315092382398562_reload: evh_7315092382398562_reload,
            act_4186377094414664_script_4436: act_4186377094414664_script_4436,
            evh_4186377094414664_optionSelected_4435: evh_4186377094414664_optionSelected_4435,
            evh_4186377094414664_optionSelected: evh_4186377094414664_optionSelected,
            act_6917646200545441_script_4438: act_6917646200545441_script_4438,
            evh_6917646200545441_selected_4437: evh_6917646200545441_selected_4437,
            evh_6917646200545441_selected: evh_6917646200545441_selected,
            act_7384593256854782_script_4440: act_7384593256854782_script_4440,
            evh_7384593256854782_blur_4439: evh_7384593256854782_blur_4439,
            evh_7384593256854782_blur: evh_7384593256854782_blur,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], FieldsetInvoiceHeader.prototype, "invoice", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('invoice'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], FieldsetInvoiceHeader.prototype, "onInvoice", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "right",
        }),
        __metadata("design:type", String)
    ], FieldsetInvoiceHeader.prototype, "labelAlign", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('labelAlign'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], FieldsetInvoiceHeader.prototype, "onLabel_align", null);
    FieldsetInvoiceHeader = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], FieldsetInvoiceHeader);
    return FieldsetInvoiceHeader;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = FieldsetInvoiceHeader;
vue_property_decorator_1.Vue.component("FieldsetInvoiceHeader", FieldsetInvoiceHeader);
