<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <b-navbar
    type="light"
    variant="default"
  >
    <b-navbar-brand
      href="#/"
    >
      {{ $app.spec.brand }}
<ItpBadge v-if=displayTestBadge variant="danger" class="mx-2">Test</ItpBadge>
<ItpBadge v-if=displayDevBadge variant="warning" class="mx-2">Dev</ItpBadge>

    </b-navbar-brand>
    <b-collapse
      is-nav
    >
      <b-navbar-nav>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Finance'|pgettext('MainMenu') }}
          </template>
          <ItpDropdownText>
            {{ 'Register'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/letter-register"
            :icon="$config.letterRegister.icon"
            :text="'Letter register'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/invoice-register"
            :icon="$config.invoiceRegister.icon"
            :text="'Invoice register'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Customer invoices'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/invoices?kind=outgoing"
            :icon="$config.invoice.customerInvoiceIcon"
            :text="'Customer invoices'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/draft-invoices"
            :icon="$config.invoice.draftInvoicesIcon"
            :text="'Invoice Generation'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Customer Invoice...'|pgettext('MainMenu')"
            :icon="$config.invoice.customerInvoiceIcon"
            dropdown-item
            @click.prevent="evh_5970709076725755_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :href="$fn.urlFor('controlling_outgoing_invoice_lines', {format: 'csv'})"
            target="_blank"
            :icon="$config.downloadIcon"
            :text="'Download outgoing invoice lines'|pgettext('MainMenu/Finance/Downloads')"
            dropdown-item
            use-auth
            show-spinner
            force-download
            download-file-name="outgoing-invoice-lines.csv"
          >
          </ItpNavItem>
          <ItpNavItem
            :href="$fn.urlFor('public_controlling_outgoing_invoice_lines', {format: 'csv'})"
            target="_blank"
            :icon="$config.downloadIcon"
            :text="'Download outgoing invoice lines (exp)'|pgettext('MainMenu/Finance/Downloads')"
            dropdown-item
            use-auth
            show-spinner
            force-download
            download-file-name="outgoing-invoice-lines-exp.csv"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Incoming invoices'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/invoices?kind=incoming"
            :icon="$config.invoice.vendorInvoiceIcon"
            :text="'Incoming invoices'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Incoming Invoice...'|pgettext('MainMenu')"
            :icon="$config.invoice.icon"
            dropdown-item
            @click.prevent="evh_4190152961853837_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :href="$fn.urlFor('controlling_incoming_invoice_lines', {format: 'csv'})"
            target="_blank"
            :icon="$config.downloadIcon"
            :text="'Download incoming invoice lines'|pgettext('MainMenu/Finance/Downloads')"
            dropdown-item
            use-auth
            show-spinner
            force-download
            download-file-name="incoming-invoice-lines.csv"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Payments'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/payments"
            :icon="$config.payment.icon"
            :text="'Payments'|pgettext('PaymentsView')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/accounts"
            :icon="$config.account.icon"
            :text="'Cash and Bank Accounts'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/accounts-daily-flow"
            :icon="$config.accountsDailyFlow.icon"
            :text="'Accounts Daily Flow'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Cash In...'|pgettext('Create Payment')"
            :icon="$config.payment.icon"
            dropdown-item
            @click.prevent="evh_2424316887484278_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Cash Out...'|pgettext('Create Payment')"
            :icon="$config.payment.icon"
            dropdown-item
            @click.prevent="evh_7637021470970119_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Banking...'|pgettext('Create Payment')"
            :icon="$config.payment.icon"
            dropdown-item
            @click.prevent="evh_2931973773564833_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Account statement report...'|pgettext('MeinMenu')"
            :icon="$config.payment.icon"
            dropdown-item
            @click.prevent="evh_2269742420845197_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :href="$fn.urlFor('controlling_payments', {format: 'xlsx'})"
            target="_blank"
            :icon="$config.downloadIcon"
            :text="'Download payments'|pgettext('MainMenu/Finance/Downloads')"
            dropdown-item
            use-auth
            show-spinner
            force-download
            download-file-name="payments-data.xlsx"
          >
          </ItpNavItem>
          <ItpNavItem
            :href="$fn.urlFor('controlling_invoice_payments', {format: 'xlsx'})"
            target="_blank"
            :icon="$config.downloadIcon"
            :text="'Download invoice payments'|pgettext('MainMenu/Finance/Downloads')"
            dropdown-item
            use-auth
            show-spinner
            force-download
            download-file-name="invoice-payments-data.xlsx"
          >
          </ItpNavItem>
          <ItpNavItem
            :href="$fn.urlFor('controlling_invoice_info', {format: 'xlsx'})"
            target="_blank"
            :icon="$config.downloadIcon"
            :text="'Download invoice info'|pgettext('MainMenu/Finance/Downloads')"
            dropdown-item
            use-auth
            show-spinner
            force-download
            download-file-name="invoice-info-data.xlsx"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Claims management'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/claims"
            :icon="$config.claim.icon"
            :text="'Claims'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/dunning-letters"
            :icon="$config.dunningLetter.icon"
            :text="'Dunning letters'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Assets'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/fixed-assets"
            :icon="$config.fixedAsset.icon"
            :text="'Fixed Assets'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Create Asset...'|pgettext('MainMenu')"
            :icon="$config.fixedAsset.icon"
            dropdown-item
            @click.prevent="evh_1886107574314164_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpNavItem
            to="/waste-bins"
            :icon="$config.wasteBin.icon"
            :text="'Waste Bins'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/waste-bin-readings"
            :icon="$config.wasteBin.icon"
            :text="'Waste Bin Readings'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Create Waste Bin...'|pgettext('MainMenu')"
            :icon="$config.wasteBin.createWasteBinIcon"
            dropdown-item
            @click.prevent="evh_7260361660686930_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Meters'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/meters"
            :icon="$config.meter.icon"
            :text="'Meters'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Add Meter...'|pgettext('MainMenu')"
            :icon="$config.meter.addMeterIcon"
            dropdown-item
            @click.prevent="evh_5449511595610967_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            to="/auto-readings"
            :icon="$config.meterAutoReading.icon"
            :text="'Auto Readings'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            href="#"
            icon="fa-upload"
            :text="'Meter Data Exchange...'|pgettext('MainMenu')"
            dropdown-item
            @click.prevent="evh_8050584411731670_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            href="#"
            :icon="$config.meterReplacement.icon"
            :text="'Replace meter...'|pgettext('MainMenu')"
            dropdown-item
            @click.prevent="evh_7807097906137782_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :href="$fn.urlFor('controlling_meter_consumptions', {format: 'xlsx'})"
            target="_blank"
            :icon="$config.downloadIcon"
            :text="'Download meter consumptions'|pgettext('MainMenu/Meters/Downloads')"
            dropdown-item
            use-auth
            show-spinner
            force-download
            download-file-name="meter-consumptions.xlsx"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Contracts'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/contracts"
            :icon="$config.contract.icon"
            :text="'Contracts'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Create Contract...'|pgettext('MainMenu')"
            :icon="$config.contract.icon"
            dropdown-item
            @click.prevent="evh_2338624680052783_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpNavItem
            to="/overlapping-revisions"
            :icon="$config.contract.views.overlappingRevisions.icon"
            :text="'Overlapping Revisions'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Rentals'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/rentals"
            :icon="$config.rental.icon"
            :text="'Rentals'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Add Rental...'|pgettext('MainMenu')"
            :icon="$config.rental.addRentalIcon"
            dropdown-item
            @click.prevent="evh_2596033535867385_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpNavItem
            to="/properties"
            :icon="$config.property.icon"
            :text="'Locations'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Add Property...'|pgettext('MainMenu')"
            :icon="$config.property.addPropertyIcon"
            dropdown-item
            @click.prevent="evh_6794737306373342_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Clients'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/clients"
            :icon="$config.client.icon"
            :text="'Clients'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New client...'|pgettext('MainMenu')"
            :icon="$config.client.createClientIcon"
            dropdown-item
            @click.prevent="evh_2660996057968802_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Products'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/products"
            :icon="$config.product.icon"
            :text="'Products'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New product/service...'|pgettext('MainMenu')"
            :icon="$config.product.addProductIcon"
            dropdown-item
            @click.prevent="evh_7567745526909371_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Worksheets'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/worksheets"
            :icon="$config.worksheet.icon"
            :text="'Worksheets'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Worksheet...'|pgettext('MainMenu')"
            :icon="$config.worksheet.createWorksheetIcon"
            dropdown-item
            @click.prevent="evh_7228767045058026_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            to="/worksheets-work-summary"
            :icon="$config.worksheet.icon"
            :text="'Work summary'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav
        class="ml-auto"
      >
        <b-nav-item-dropdown
          right
          class="mx-3"
        >
          <template
            slot="button-content"
          >
            <ItpIcon
              name="fa-user-circle"
              class="mr-1"
            >
            </ItpIcon>
            <span>
              {{ $app.auth.user.fullName || $app.auth.user.userName }}
            </span>
          </template>
          <ItpNavItem
            :href="$app.auth.accountUrl"
            target="_blank"
            icon="fa-user-circle"
            :text="'Profile'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            icon="fa-globe"
          >
            <ItpLocaleSelector
              class="nav-item-content"
            >
            </ItpLocaleSelector>
          </ItpNavItem>
          <b-dropdown-divider>
          </b-dropdown-divider>
          <ItpNavItem
            icon="line-md:confirm-circle"
          >
            <BFormCheckbox
              v-model="$app.confirmPageClose"
              switch
              class="nav-item-content"
              @change="evh_298118714321807_change($event, {})"
            >
              <ItpText
                :text="'Confirm tab close'|pgettext('MainMenu')"
                class="user-select-none"
              >
              </ItpText>
            </BFormCheckbox>
          </ItpNavItem>
          <ItpNavItem
            href="#"
            icon="fa-sign-out-alt"
            :text="'Sign out'|pgettext('MainMenu')"
            dropdown-item
            @click.prevent="evh_7106235911078350_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          right
        >
          <ItpIcon
            name="fa-bars"
            slot="button-content"
          >
          </ItpIcon>
          <ItpNavItem
            dropdown-item
            to="/jobs"
            icon="fa-cogs"
            :text="'Jobs'|pgettext('MainMenu')"
          >
          </ItpNavItem>
          <ItpNavItem
            dropdown-item
            to="/jobs-v2"
            icon="fa-cogs"
            :text="'Jobs (v2)'|pgettext('MainMenu')"
          >
          </ItpNavItem>
          <ItpNavItem
            dropdown-item
            to="/templates"
            icon="fa-file-invoice"
            :text="'Templates'|pgettext('MainMenu')"
          >
          </ItpNavItem>
          <b-dropdown-divider>
          </b-dropdown-divider>
          <ItpNavItem
            dropdown-item
            to="/settings"
            icon="fa-cog"
            :text="'Settings'|pgettext('MainMenu')"
          >
          </ItpNavItem>
          <ItpNavItem
            href="#"
            icon="fa-question-circle"
            :text="'About Integro'|pgettext('MainMenu')"
            dropdown-item
            @click.prevent="evh_5361165673534032_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MainMenu extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get displayTestBadge() {
    if (this.loaded) {

      return _.includes(this.$app.document.location.hostname, 'test')
    }
    return null;
  }

  get displayDevBadge() {
    if (this.loaded) {

      return _.includes(this.$app.document.location.hostname, 'local')
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_5970709076725755_showModal_1228_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5970709076725755_showModal_1228, alias=undefined
    return {
      name: "CreateInvoice",
      props: {
        invoiceKind: "outgoing",
      }
      ,
    }
  }

  async evh_5970709076725755_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5970709076725755_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_4190152961853837_showModal_1230_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_4190152961853837_showModal_1230, alias=undefined
    return {
      name: "CreateInvoice",
      props: {
        invoiceKind: "incoming",
      }
      ,
    }
  }

  async evh_4190152961853837_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4190152961853837_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2424316887484278_showModal_1232_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2424316887484278_showModal_1232, alias=undefined
    return {
      name: "Payment",
      props: {
        transactionType: "deposit",
      }
      ,
    }
  }

  async evh_2424316887484278_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2424316887484278_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7637021470970119_showModal_1234_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7637021470970119_showModal_1234, alias=undefined
    return {
      name: "Payment",
      props: {
        transactionType: "payment",
      }
      ,
    }
  }

  async evh_7637021470970119_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7637021470970119_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2931973773564833_showModal_1236_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2931973773564833_showModal_1236, alias=undefined
    return {
      name: "Payment",
      props: {
        transactionType: "banking",
      }
      ,
    }
  }

  async evh_2931973773564833_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2931973773564833_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2269742420845197_showModal_1238_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2269742420845197_showModal_1238, alias=undefined
    return {
      name: "AccountStatementReport",
    }
  }

  async evh_2269742420845197_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2269742420845197_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_1886107574314164_showModal_1240_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_1886107574314164_showModal_1240, alias=undefined
    return {
      name: "MCreateFixedAsset",
      props: {
        assetType: "general",
      }
      ,
    }
  }

  async evh_1886107574314164_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1886107574314164_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7260361660686930_showModal_1242_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7260361660686930_showModal_1242, alias=undefined
    return {
      name: "MCreateFixedAsset",
      props: {
        assetType: "waste_bin",
      }
      ,
    }
  }

  async evh_7260361660686930_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7260361660686930_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_5449511595610967_showModal_1244_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5449511595610967_showModal_1244, alias=undefined
    return {
      name: "CreateMeter",
    }
  }

  async evh_5449511595610967_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5449511595610967_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_8050584411731670_showModal_1246_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8050584411731670_showModal_1246, alias=undefined
    return {
      name: "MeterDataExchange",
    }
  }

  async evh_8050584411731670_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8050584411731670_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7807097906137782_showModal_1248_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7807097906137782_showModal_1248, alias=undefined
    return {
      name: "ReplaceMeter",
    }
  }

  async evh_7807097906137782_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7807097906137782_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2338624680052783_showModal_1250_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2338624680052783_showModal_1250, alias=undefined
    return {
      name: "CreateContract",
    }
  }

  async evh_2338624680052783_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2338624680052783_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2596033535867385_showModal_1252_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2596033535867385_showModal_1252, alias=undefined
    return {
      name: "CreateRental",
    }
  }

  async evh_2596033535867385_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2596033535867385_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_6794737306373342_showModal_1254_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6794737306373342_showModal_1254, alias=undefined
    return {
      name: "CreateProperty",
    }
  }

  async evh_6794737306373342_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6794737306373342_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2660996057968802_showModal_1256_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2660996057968802_showModal_1256, alias=undefined
    return {
      name: "CreateClient",
    }
  }

  async evh_2660996057968802_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2660996057968802_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7567745526909371_showModal_1258_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7567745526909371_showModal_1258, alias=undefined
    return {
      name: "CreateProduct",
    }
  }

  async evh_7567745526909371_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7567745526909371_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7228767045058026_showModal_1260_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7228767045058026_showModal_1260, alias=undefined
    return {
      name: "Worksheet",
      props: {
        size: "lg",
      }
      ,
    }
  }

  async evh_7228767045058026_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7228767045058026_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_298118714321807_script_1262_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_298118714321807_script_1262, alias=undefined
    this.$app.toggleConfirmPageClose($event)
  }

  async act_298118714321807_script_1262_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_298118714321807_script_1262, alias=undefined
    return {
      value: () => this.act_298118714321807_script_1262_getActionArgs_value($event),
    }
  }

  async evh_298118714321807_change(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_298118714321807_change.executeFromDOM(this, event, scope);
  }

  async act_7106235911078350_logout_1264_getActionArgs($event: actions.ActionEvent): Promise<actions.LogoutActionArgs> {
    // parameterResolver name=act_7106235911078350_logout_1264, alias=undefined
    return {
    }
  }

  async evh_7106235911078350_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7106235911078350_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_5361165673534032_showModal_1266_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5361165673534032_showModal_1266, alias=undefined
    return {
      name: "AboutModal",
    }
  }

  async evh_5361165673534032_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5361165673534032_clickPrevent.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_1226: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_1225: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_5970709076725755_showModal_1228: actions.ShowModalAction;
    evh_5970709076725755_clickPrevent_1227: actions.EventHandlerImpl;
    evh_5970709076725755_clickPrevent: actions.EventHandlerGroup;
    act_4190152961853837_showModal_1230: actions.ShowModalAction;
    evh_4190152961853837_clickPrevent_1229: actions.EventHandlerImpl;
    evh_4190152961853837_clickPrevent: actions.EventHandlerGroup;
    act_2424316887484278_showModal_1232: actions.ShowModalAction;
    evh_2424316887484278_clickPrevent_1231: actions.EventHandlerImpl;
    evh_2424316887484278_clickPrevent: actions.EventHandlerGroup;
    act_7637021470970119_showModal_1234: actions.ShowModalAction;
    evh_7637021470970119_clickPrevent_1233: actions.EventHandlerImpl;
    evh_7637021470970119_clickPrevent: actions.EventHandlerGroup;
    act_2931973773564833_showModal_1236: actions.ShowModalAction;
    evh_2931973773564833_clickPrevent_1235: actions.EventHandlerImpl;
    evh_2931973773564833_clickPrevent: actions.EventHandlerGroup;
    act_2269742420845197_showModal_1238: actions.ShowModalAction;
    evh_2269742420845197_clickPrevent_1237: actions.EventHandlerImpl;
    evh_2269742420845197_clickPrevent: actions.EventHandlerGroup;
    act_1886107574314164_showModal_1240: actions.ShowModalAction;
    evh_1886107574314164_clickPrevent_1239: actions.EventHandlerImpl;
    evh_1886107574314164_clickPrevent: actions.EventHandlerGroup;
    act_7260361660686930_showModal_1242: actions.ShowModalAction;
    evh_7260361660686930_clickPrevent_1241: actions.EventHandlerImpl;
    evh_7260361660686930_clickPrevent: actions.EventHandlerGroup;
    act_5449511595610967_showModal_1244: actions.ShowModalAction;
    evh_5449511595610967_clickPrevent_1243: actions.EventHandlerImpl;
    evh_5449511595610967_clickPrevent: actions.EventHandlerGroup;
    act_8050584411731670_showModal_1246: actions.ShowModalAction;
    evh_8050584411731670_clickPrevent_1245: actions.EventHandlerImpl;
    evh_8050584411731670_clickPrevent: actions.EventHandlerGroup;
    act_7807097906137782_showModal_1248: actions.ShowModalAction;
    evh_7807097906137782_clickPrevent_1247: actions.EventHandlerImpl;
    evh_7807097906137782_clickPrevent: actions.EventHandlerGroup;
    act_2338624680052783_showModal_1250: actions.ShowModalAction;
    evh_2338624680052783_clickPrevent_1249: actions.EventHandlerImpl;
    evh_2338624680052783_clickPrevent: actions.EventHandlerGroup;
    act_2596033535867385_showModal_1252: actions.ShowModalAction;
    evh_2596033535867385_clickPrevent_1251: actions.EventHandlerImpl;
    evh_2596033535867385_clickPrevent: actions.EventHandlerGroup;
    act_6794737306373342_showModal_1254: actions.ShowModalAction;
    evh_6794737306373342_clickPrevent_1253: actions.EventHandlerImpl;
    evh_6794737306373342_clickPrevent: actions.EventHandlerGroup;
    act_2660996057968802_showModal_1256: actions.ShowModalAction;
    evh_2660996057968802_clickPrevent_1255: actions.EventHandlerImpl;
    evh_2660996057968802_clickPrevent: actions.EventHandlerGroup;
    act_7567745526909371_showModal_1258: actions.ShowModalAction;
    evh_7567745526909371_clickPrevent_1257: actions.EventHandlerImpl;
    evh_7567745526909371_clickPrevent: actions.EventHandlerGroup;
    act_7228767045058026_showModal_1260: actions.ShowModalAction;
    evh_7228767045058026_clickPrevent_1259: actions.EventHandlerImpl;
    evh_7228767045058026_clickPrevent: actions.EventHandlerGroup;
    act_298118714321807_script_1262: actions.ScriptAction;
    evh_298118714321807_change_1261: actions.EventHandlerImpl;
    evh_298118714321807_change: actions.EventHandlerGroup;
    act_7106235911078350_logout_1264: actions.LogoutAction;
    evh_7106235911078350_clickPrevent_1263: actions.EventHandlerImpl;
    evh_7106235911078350_clickPrevent: actions.EventHandlerGroup;
    act_5361165673534032_showModal_1266: actions.ShowModalAction;
    evh_5361165673534032_clickPrevent_1265: actions.EventHandlerImpl;
    evh_5361165673534032_clickPrevent: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_1226 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_1225 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_1226,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_1225],
      }
    );
    const act_5970709076725755_showModal_1228 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5970709076725755_showModal_1228_getActionArgs,
        events: [],
      }
    );
    const evh_5970709076725755_clickPrevent_1227 = new actions.EventHandlerImpl(
      {
        action: act_5970709076725755_showModal_1228,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_5970709076725755_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_5970709076725755_clickPrevent_1227],
      }
    );
    const act_4190152961853837_showModal_1230 = new actions.ShowModalAction(
      {
        actionArgs: this.act_4190152961853837_showModal_1230_getActionArgs,
        events: [],
      }
    );
    const evh_4190152961853837_clickPrevent_1229 = new actions.EventHandlerImpl(
      {
        action: act_4190152961853837_showModal_1230,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_4190152961853837_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_4190152961853837_clickPrevent_1229],
      }
    );
    const act_2424316887484278_showModal_1232 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2424316887484278_showModal_1232_getActionArgs,
        events: [],
      }
    );
    const evh_2424316887484278_clickPrevent_1231 = new actions.EventHandlerImpl(
      {
        action: act_2424316887484278_showModal_1232,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2424316887484278_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2424316887484278_clickPrevent_1231],
      }
    );
    const act_7637021470970119_showModal_1234 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7637021470970119_showModal_1234_getActionArgs,
        events: [],
      }
    );
    const evh_7637021470970119_clickPrevent_1233 = new actions.EventHandlerImpl(
      {
        action: act_7637021470970119_showModal_1234,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7637021470970119_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7637021470970119_clickPrevent_1233],
      }
    );
    const act_2931973773564833_showModal_1236 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2931973773564833_showModal_1236_getActionArgs,
        events: [],
      }
    );
    const evh_2931973773564833_clickPrevent_1235 = new actions.EventHandlerImpl(
      {
        action: act_2931973773564833_showModal_1236,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2931973773564833_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2931973773564833_clickPrevent_1235],
      }
    );
    const act_2269742420845197_showModal_1238 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2269742420845197_showModal_1238_getActionArgs,
        events: [],
      }
    );
    const evh_2269742420845197_clickPrevent_1237 = new actions.EventHandlerImpl(
      {
        action: act_2269742420845197_showModal_1238,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2269742420845197_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2269742420845197_clickPrevent_1237],
      }
    );
    const act_1886107574314164_showModal_1240 = new actions.ShowModalAction(
      {
        actionArgs: this.act_1886107574314164_showModal_1240_getActionArgs,
        events: [],
      }
    );
    const evh_1886107574314164_clickPrevent_1239 = new actions.EventHandlerImpl(
      {
        action: act_1886107574314164_showModal_1240,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_1886107574314164_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_1886107574314164_clickPrevent_1239],
      }
    );
    const act_7260361660686930_showModal_1242 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7260361660686930_showModal_1242_getActionArgs,
        events: [],
      }
    );
    const evh_7260361660686930_clickPrevent_1241 = new actions.EventHandlerImpl(
      {
        action: act_7260361660686930_showModal_1242,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7260361660686930_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7260361660686930_clickPrevent_1241],
      }
    );
    const act_5449511595610967_showModal_1244 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5449511595610967_showModal_1244_getActionArgs,
        events: [],
      }
    );
    const evh_5449511595610967_clickPrevent_1243 = new actions.EventHandlerImpl(
      {
        action: act_5449511595610967_showModal_1244,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_5449511595610967_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_5449511595610967_clickPrevent_1243],
      }
    );
    const act_8050584411731670_showModal_1246 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8050584411731670_showModal_1246_getActionArgs,
        events: [],
      }
    );
    const evh_8050584411731670_clickPrevent_1245 = new actions.EventHandlerImpl(
      {
        action: act_8050584411731670_showModal_1246,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_8050584411731670_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8050584411731670_clickPrevent_1245],
      }
    );
    const act_7807097906137782_showModal_1248 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7807097906137782_showModal_1248_getActionArgs,
        events: [],
      }
    );
    const evh_7807097906137782_clickPrevent_1247 = new actions.EventHandlerImpl(
      {
        action: act_7807097906137782_showModal_1248,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7807097906137782_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7807097906137782_clickPrevent_1247],
      }
    );
    const act_2338624680052783_showModal_1250 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2338624680052783_showModal_1250_getActionArgs,
        events: [],
      }
    );
    const evh_2338624680052783_clickPrevent_1249 = new actions.EventHandlerImpl(
      {
        action: act_2338624680052783_showModal_1250,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2338624680052783_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2338624680052783_clickPrevent_1249],
      }
    );
    const act_2596033535867385_showModal_1252 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2596033535867385_showModal_1252_getActionArgs,
        events: [],
      }
    );
    const evh_2596033535867385_clickPrevent_1251 = new actions.EventHandlerImpl(
      {
        action: act_2596033535867385_showModal_1252,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2596033535867385_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2596033535867385_clickPrevent_1251],
      }
    );
    const act_6794737306373342_showModal_1254 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6794737306373342_showModal_1254_getActionArgs,
        events: [],
      }
    );
    const evh_6794737306373342_clickPrevent_1253 = new actions.EventHandlerImpl(
      {
        action: act_6794737306373342_showModal_1254,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_6794737306373342_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_6794737306373342_clickPrevent_1253],
      }
    );
    const act_2660996057968802_showModal_1256 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2660996057968802_showModal_1256_getActionArgs,
        events: [],
      }
    );
    const evh_2660996057968802_clickPrevent_1255 = new actions.EventHandlerImpl(
      {
        action: act_2660996057968802_showModal_1256,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2660996057968802_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2660996057968802_clickPrevent_1255],
      }
    );
    const act_7567745526909371_showModal_1258 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7567745526909371_showModal_1258_getActionArgs,
        events: [],
      }
    );
    const evh_7567745526909371_clickPrevent_1257 = new actions.EventHandlerImpl(
      {
        action: act_7567745526909371_showModal_1258,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7567745526909371_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7567745526909371_clickPrevent_1257],
      }
    );
    const act_7228767045058026_showModal_1260 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7228767045058026_showModal_1260_getActionArgs,
        events: [],
      }
    );
    const evh_7228767045058026_clickPrevent_1259 = new actions.EventHandlerImpl(
      {
        action: act_7228767045058026_showModal_1260,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7228767045058026_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7228767045058026_clickPrevent_1259],
      }
    );
    const act_298118714321807_script_1262 = new actions.ScriptAction(
      {
        actionArgs: this.act_298118714321807_script_1262_getActionArgs,
        events: [],
      }
    );
    const evh_298118714321807_change_1261 = new actions.EventHandlerImpl(
      {
        action: act_298118714321807_script_1262,
        event: "change",
        displayName: "script",
      }
    );
    const evh_298118714321807_change = new actions.EventHandlerGroup(
      {
        handlers: [evh_298118714321807_change_1261],
      }
    );
    const act_7106235911078350_logout_1264 = new actions.LogoutAction(
      {
        actionArgs: this.act_7106235911078350_logout_1264_getActionArgs,
        events: [],
      }
    );
    const evh_7106235911078350_clickPrevent_1263 = new actions.EventHandlerImpl(
      {
        action: act_7106235911078350_logout_1264,
        event: "click.prevent",
        displayName: "logout",
      }
    );
    const evh_7106235911078350_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7106235911078350_clickPrevent_1263],
      }
    );
    const act_5361165673534032_showModal_1266 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5361165673534032_showModal_1266_getActionArgs,
        events: [],
      }
    );
    const evh_5361165673534032_clickPrevent_1265 = new actions.EventHandlerImpl(
      {
        action: act_5361165673534032_showModal_1266,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_5361165673534032_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_5361165673534032_clickPrevent_1265],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_1226,
      evh_7315092382398562_reload_1225,
      evh_7315092382398562_reload,
      act_5970709076725755_showModal_1228,
      evh_5970709076725755_clickPrevent_1227,
      evh_5970709076725755_clickPrevent,
      act_4190152961853837_showModal_1230,
      evh_4190152961853837_clickPrevent_1229,
      evh_4190152961853837_clickPrevent,
      act_2424316887484278_showModal_1232,
      evh_2424316887484278_clickPrevent_1231,
      evh_2424316887484278_clickPrevent,
      act_7637021470970119_showModal_1234,
      evh_7637021470970119_clickPrevent_1233,
      evh_7637021470970119_clickPrevent,
      act_2931973773564833_showModal_1236,
      evh_2931973773564833_clickPrevent_1235,
      evh_2931973773564833_clickPrevent,
      act_2269742420845197_showModal_1238,
      evh_2269742420845197_clickPrevent_1237,
      evh_2269742420845197_clickPrevent,
      act_1886107574314164_showModal_1240,
      evh_1886107574314164_clickPrevent_1239,
      evh_1886107574314164_clickPrevent,
      act_7260361660686930_showModal_1242,
      evh_7260361660686930_clickPrevent_1241,
      evh_7260361660686930_clickPrevent,
      act_5449511595610967_showModal_1244,
      evh_5449511595610967_clickPrevent_1243,
      evh_5449511595610967_clickPrevent,
      act_8050584411731670_showModal_1246,
      evh_8050584411731670_clickPrevent_1245,
      evh_8050584411731670_clickPrevent,
      act_7807097906137782_showModal_1248,
      evh_7807097906137782_clickPrevent_1247,
      evh_7807097906137782_clickPrevent,
      act_2338624680052783_showModal_1250,
      evh_2338624680052783_clickPrevent_1249,
      evh_2338624680052783_clickPrevent,
      act_2596033535867385_showModal_1252,
      evh_2596033535867385_clickPrevent_1251,
      evh_2596033535867385_clickPrevent,
      act_6794737306373342_showModal_1254,
      evh_6794737306373342_clickPrevent_1253,
      evh_6794737306373342_clickPrevent,
      act_2660996057968802_showModal_1256,
      evh_2660996057968802_clickPrevent_1255,
      evh_2660996057968802_clickPrevent,
      act_7567745526909371_showModal_1258,
      evh_7567745526909371_clickPrevent_1257,
      evh_7567745526909371_clickPrevent,
      act_7228767045058026_showModal_1260,
      evh_7228767045058026_clickPrevent_1259,
      evh_7228767045058026_clickPrevent,
      act_298118714321807_script_1262,
      evh_298118714321807_change_1261,
      evh_298118714321807_change,
      act_7106235911078350_logout_1264,
      evh_7106235911078350_clickPrevent_1263,
      evh_7106235911078350_clickPrevent,
      act_5361165673534032_showModal_1266,
      evh_5361165673534032_clickPrevent_1265,
      evh_5361165673534032_clickPrevent,
    }
  }
}

Vue.component("MainMenu", MainMenu);

</script>