<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'General'|pgettext('FixedAsset') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <ItpLabeledListGroupItem
        :label="'Asset Type'|pgettext('FixedAsset')"
      >
        <UcFixedAssetTypeBadge
          :value="fixedAsset.asset_type"
        >
        </UcFixedAssetTypeBadge>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Name'|pgettext('FixedAsset')"
        :content="fixedAsset.name"
        v-if="fixedAsset.name"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Description'|pgettext('FixedAsset')"
        :content="fixedAsset.description"
        v-if="fixedAsset.description"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Inventory Number'|pgettext('FixedAsset')"
        :content="fixedAsset.inventory_number"
        v-if="fixedAsset.inventory_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Rental'|pgettext('FixedAsset')"
      >
        <UcFixedAssetRentalCard
          :fixedAsset="fixedAsset"
          @reload="evh_8352907821006950_reload($event, {})"
        >
        </UcFixedAssetRentalCard>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'SKU'|pgettext('FixedAsset')"
        :content="fixedAsset.sku"
        v-if="fixedAsset.sku"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Type Number'|pgettext('FixedAsset')"
        :content="fixedAsset.type_no"
        v-if="fixedAsset.type_no"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Quantity'|pgettext('FixedAsset')"
        :content="fixedAsset.quantity|number"
        v-if="fixedAsset.quantity"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Cost'|pgettext('FixedAsset')"
        :content="fixedAsset.cost|number"
        v-if="fixedAsset.cost"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'General Ledger Code'|pgettext('FixedAsset')"
        :content="fixedAsset.gl_code"
        v-if="fixedAsset.gl_code"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Year of Production'|pgettext('FixedAsset')"
        :content="fixedAsset.year_of_production"
        v-if="fixedAsset.year_of_production"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Manufacturer'|pgettext('FixedAsset')"
        :content="fixedAsset.manufacturer"
        v-if="fixedAsset.manufacturer"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Serial Number'|pgettext('FixedAsset')"
        :content="fixedAsset.serial_number"
        v-if="fixedAsset.serial_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Category'|pgettext('FixedAsset')"
        :content="fixedAsset.category"
        v-if="fixedAsset.category"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Groups'|pgettext('FixedAsset')"
        :content="fixedAsset.groups"
        v-if="fixedAsset.groups"
      >
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcFixedAssetGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  fixedAsset!: object;

  @Watch('fixedAsset')
  onFixed_asset(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fixedAsset")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_4282_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_4282, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_4281_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_4281, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_4280_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_4280, alias=undefined
    return {
      name: "MFixedAssetEditor",
      props: {
        target: "general",
        fixedAsset: _.cloneDeep(this.fixedAsset),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8352907821006950_emit_4284_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8352907821006950_emit_4284, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8352907821006950_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8352907821006950_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4278: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4277: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_4282: actions.EmitAction;
    evh_8320016629450276_close_4281: actions.EventHandlerImpl;
    act_8320016629450276_showModal_4280: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_4279: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_8352907821006950_emit_4284: actions.EmitAction;
    evh_8352907821006950_reload_4283: actions.EventHandlerImpl;
    evh_8352907821006950_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4278 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4277 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4278,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4277],
      }
    );
    const act_8320016629450276_emit_4282 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_4282_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_4281 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_4282,
        event: "close",
        when: this.evh_8320016629450276_close_4281_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_4280 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_4280_getActionArgs,
        events: [evh_8320016629450276_close_4281],
      }
    );
    const evh_8320016629450276_clickPreventStop_4279 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_4280,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_4279],
      }
    );
    const act_8352907821006950_emit_4284 = new actions.EmitAction(
      {
        actionArgs: this.act_8352907821006950_emit_4284_getActionArgs,
        events: [],
      }
    );
    const evh_8352907821006950_reload_4283 = new actions.EventHandlerImpl(
      {
        action: act_8352907821006950_emit_4284,
        event: "reload",
        displayName: "emit",
      }
    );
    const evh_8352907821006950_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8352907821006950_reload_4283],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4278,
      evh_7315092382398562_reload_4277,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_4282,
      evh_8320016629450276_close_4281,
      act_8320016629450276_showModal_4280,
      evh_8320016629450276_clickPreventStop_4279,
      evh_8320016629450276_clickPreventStop,
      act_8352907821006950_emit_4284,
      evh_8352907821006950_reload_4283,
      evh_8352907821006950_reload,
    }
  }
}

Vue.component("UcFixedAssetGeneral", UcFixedAssetGeneral);

</script>