import FieldsetClientGeneral from './FieldsetClientGeneral.yaml?component';
import UcClientAddresses from './UcClientAddresses.yaml?component';
import UcClientGeneral from './UcClientGeneral.yaml?component';
import UcClientInvoicesPanel from './UcClientInvoicesPanel.yaml?component';

export * from './bank-accounts';

export {
  FieldsetClientGeneral,
  UcClientAddresses,
  UcClientGeneral,
  UcClientInvoicesPanel
};
