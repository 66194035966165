<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    labelColsMd="2"
    contentColsMd="3"
    name="protocol_status"
    :label="'Status'|pgettext('RentalHandover')"
    :labelAlign="labelAlign"
  >
    <UcStatusBadge
      :status="item.protocol_status"
    >
    </UcStatusBadge>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="2"
    contentColsMd="3"
    name="handover_date"
    :label="'Handover date'|pgettext('RentalHandover')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormDatetime
      name="handover_date"
      v-model="item.handover_date"
      required
      :readonly="item.isReadonly"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="2"
    contentColsMd="10"
    name="meter_readings"
    :label="'Paragraphs'|pgettext('RentalHandover')"
    :labelAlign="labelAlign"
    v-if="loaded && item.paragraphs"
  >
    <div
      :key="index"
      v-for="(paragraph, index) in item.paragraphs"
    >
      <ItpFormTextarea
        type="number"
        name="paragraph_text"
        v-model="paragraph.text"
        required
        :readonly="item.isReadonly"
        class="w-100"
      >
      </ItpFormTextarea>
    </div>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="2"
    contentColsMd="10"
    name="meter_readings"
    :label="'Meter reading'|pgettext('RentalHandover')"
    :labelAlign="labelAlign"
    v-if="loaded && item.meter_readings && item.meter_readings.length"
  >
    <table
      class="table table-condensed"
    >
      <tbody>
        <tr
          :key="reading.serial_number"
          v-for="reading in item.meter_readings"
        >
          <td>
            <UcMeterTypeIcon
              :type="reading.meter_type"
            >
            </UcMeterTypeIcon>
            <ItpText
              :text="reading.serial_number"
            >
            </ItpText>
          </td>
          <td>
            <div
              class="input-group"
            >
              <ItpFormInput
                type="number"
                name="reading_value"
                v-model="reading.reading_value"
                :readonly="item.isReadonly"
              >
              </ItpFormInput>
              <DIV
                class="input-group-append w-40"
              >
                <ItpFormSelect
                  name="reading_unit"
                  v-model="reading.reading_unit"
                  :options="units[reading.meter_type]"
                  :readonly="item.isReadonly"
                >
                </ItpFormSelect>
              </DIV>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ItpFormGroup>
  <UcAttachmentsList
    :parent="item"
    :readonly="item.isReadonly"
    v-if="!item.isNew"
  >
  </UcAttachmentsList>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetHandoverProtocol extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    type: Object,
  })
  form!: object;

  @Watch('form')
  onForm(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("form")
  }

  units!: any;

  async $$load_units() {
    return this.$fn.fetch('get_meter_type_units')
  }

  ux!: any;
  dataMembers = ['units', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        units: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6080: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6079: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6080 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6079 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6080,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6079],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6080,
      evh_7315092382398562_reload_6079,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetHandoverProtocol", FieldsetHandoverProtocol);

</script>