"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var CreateContract = (function (_super) {
    __extends(CreateContract, _super);
    function CreateContract() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "page",
        };
        _this.dataMembers = ['contract_type', 'contract', 'contractTypes', 'formName', 'formId', 'labelAlign', 'currentTab', 'categories', 'ux', 'b_7315092382398562_modalBindings'];
        return _this;
    }
    CreateContract.prototype.beforeCreate = function () {
        this.$$cache_contract_type = new core_1.ComponentValueCache(this, "contract_type", "CreateContract.contractType");
    };
    CreateContract.prototype.mapDefaultContract = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var data = args[0];
        return _.extend(data, { counterparty: {}, rental: {} });
    };
    CreateContract.prototype.onRental_id = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rentalId");
    };
    CreateContract.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    CreateContract.prototype.$$load_contract = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('read_contract', {
                        parameters: {
                            id: 'defaults',
                            type: this.$data.contract_type,
                            rental: this.rentalId
                        }
                    })];
            });
        });
    };
    CreateContract.prototype.$$load_contractTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.getEnumValuesFromSchema('ContractTypes')];
            });
        });
    };
    CreateContract.prototype.$$load_formId = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.randomElementId()];
            });
        });
    };
    CreateContract.prototype.$$load_categories = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_contract_category')];
            });
        });
    };
    CreateContract.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            contract_type: "rental",
            contract: null,
            contractTypes: null,
            formName: "contract",
            formId: null,
            labelAlign: "left",
            currentTab: 0,
            categories: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    };
    Object.defineProperty(CreateContract.prototype, "companyTranslation", {
        get: function () {
            if (this.loaded) {
                return ['rental', 'rental_agreement', 'parking'].indexOf(this.contract.contract_type) > -1 ?
                    this.$fn.pgettext('Contract', 'Lessor') :
                    this.$fn.pgettext('Contract', 'Company');
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CreateContract.prototype, "counterpartyTranslation", {
        get: function () {
            if (this.loaded) {
                return ['rental', 'rental_agreement', 'parking'].indexOf(this.contract.contract_type) > -1 ?
                    this.$fn.pgettext('Contract', 'Tenant') :
                    this.$fn.pgettext('Contract', 'Counterparty');
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CreateContract.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.$fn.gettext("Create Contract"),
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    CreateContract.prototype.act_7315092382398562_closeModal_2062_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    CreateContract.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    CreateContract.prototype.evh_2248226175642056_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    CreateContract.prototype.act_7789867021953041_setData_2068_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "contract",
                        value: this.mapDefaultContract($event.data.response.data),
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    CreateContract.prototype.act_7789867021953041_request_2066_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "read_contract",
                        parameters: {
                            id: "defaults",
                            type: $event.data.value,
                        },
                    }];
            });
        });
    };
    CreateContract.prototype.evh_7789867021953041_optionSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7789867021953041_optionSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    CreateContract.prototype.act_2140198529698299_setData_2070_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "currentTab",
                        value: this.currentTab - 1,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    CreateContract.prototype.evh_2140198529698299_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2140198529698299_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    CreateContract.prototype.act_8790374038998522_setData_2072_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "currentTab",
                        value: this.currentTab + 1,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    CreateContract.prototype.evh_8790374038998522_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8790374038998522_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    CreateContract.prototype.act_309864017738714_reloadSlickgrid_2076_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "contracts",
                    }];
            });
        });
    };
    CreateContract.prototype.act_309864017738714_closeComponent_2078_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            close: true,
                        },
                    }];
            });
        });
    };
    CreateContract.prototype.act_309864017738714_navigate_2080_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        location: {
                            name: "app.contract",
                            params: {
                                id: $event.data.id,
                            },
                        },
                    }];
            });
        });
    };
    CreateContract.prototype.act_309864017738714_crud_2074_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        objectType: "contract",
                        op: "upsert",
                        data: this.contract,
                    }];
            });
        });
    };
    CreateContract.prototype.evh_309864017738714_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_309864017738714_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    CreateContract.prototype.evh_1589624209624921_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1589624209624921_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    CreateContract.prototype.getActions = function () {
        var act_7315092382398562_closeModal_2062 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2062_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_2061 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2062,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2061],
        });
        var act_2248226175642056_closeComponent_2064 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_2248226175642056_close_2063 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2064,
            event: "close",
            displayName: "closeComponent",
        });
        var evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2063],
        });
        var act_7789867021953041_setData_2068 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_2068_getActionArgs,
            events: [],
        });
        var evh_7789867021953041_success_2067 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_2068,
            event: "success",
            displayName: "setData",
        });
        var act_7789867021953041_request_2066 = new core_1.actions.RequestAction({
            actionArgs: this.act_7789867021953041_request_2066_getActionArgs,
            events: [evh_7789867021953041_success_2067],
        });
        var evh_7789867021953041_optionSelected_2065 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_request_2066,
            event: "optionSelected",
            displayName: "request",
        });
        var evh_7789867021953041_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_optionSelected_2065],
        });
        var act_2140198529698299_setData_2070 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2140198529698299_setData_2070_getActionArgs,
            events: [],
        });
        var evh_2140198529698299_clickPreventStop_2069 = new core_1.actions.EventHandlerImpl({
            action: act_2140198529698299_setData_2070,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        var evh_2140198529698299_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2140198529698299_clickPreventStop_2069],
        });
        var act_8790374038998522_setData_2072 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8790374038998522_setData_2072_getActionArgs,
            events: [],
        });
        var evh_8790374038998522_clickPreventStop_2071 = new core_1.actions.EventHandlerImpl({
            action: act_8790374038998522_setData_2072,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        var evh_8790374038998522_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8790374038998522_clickPreventStop_2071],
        });
        var act_309864017738714_reloadSlickgrid_2076 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_309864017738714_reloadSlickgrid_2076_getActionArgs,
            events: [],
        });
        var evh_309864017738714_success_2075 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_reloadSlickgrid_2076,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        var act_309864017738714_closeComponent_2078 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_309864017738714_closeComponent_2078_getActionArgs,
            events: [],
        });
        var evh_309864017738714_success_2077 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_closeComponent_2078,
            event: "success",
            displayName: "closeComponent",
        });
        var act_309864017738714_navigate_2080 = new core_1.actions.NavigateAction({
            actionArgs: this.act_309864017738714_navigate_2080_getActionArgs,
            events: [],
        });
        var evh_309864017738714_success_2079 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_navigate_2080,
            event: "success",
            displayName: "navigate",
        });
        var act_309864017738714_crud_2074 = new core_1.actions.CRUDAction({
            actionArgs: this.act_309864017738714_crud_2074_getActionArgs,
            displayName: "save",
            events: [evh_309864017738714_success_2075, evh_309864017738714_success_2077, evh_309864017738714_success_2079],
        });
        var evh_309864017738714_clickPreventStop_2073 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_crud_2074,
            event: "click.prevent.stop",
            displayName: "save",
        });
        var evh_309864017738714_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_309864017738714_clickPreventStop_2073],
        });
        var act_1589624209624921_closeModal_2082 = new core_1.actions.CloseModalAction({
            events: [],
        });
        var evh_1589624209624921_clickPreventStop_2081 = new core_1.actions.EventHandlerImpl({
            action: act_1589624209624921_closeModal_2082,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        var evh_1589624209624921_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1589624209624921_clickPreventStop_2081],
        });
        return {
            act_7315092382398562_closeModal_2062: act_7315092382398562_closeModal_2062,
            evh_7315092382398562_close_2061: evh_7315092382398562_close_2061,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2064: act_2248226175642056_closeComponent_2064,
            evh_2248226175642056_close_2063: evh_2248226175642056_close_2063,
            evh_2248226175642056_close: evh_2248226175642056_close,
            act_7789867021953041_setData_2068: act_7789867021953041_setData_2068,
            evh_7789867021953041_success_2067: evh_7789867021953041_success_2067,
            act_7789867021953041_request_2066: act_7789867021953041_request_2066,
            evh_7789867021953041_optionSelected_2065: evh_7789867021953041_optionSelected_2065,
            evh_7789867021953041_optionSelected: evh_7789867021953041_optionSelected,
            act_2140198529698299_setData_2070: act_2140198529698299_setData_2070,
            evh_2140198529698299_clickPreventStop_2069: evh_2140198529698299_clickPreventStop_2069,
            evh_2140198529698299_clickPreventStop: evh_2140198529698299_clickPreventStop,
            act_8790374038998522_setData_2072: act_8790374038998522_setData_2072,
            evh_8790374038998522_clickPreventStop_2071: evh_8790374038998522_clickPreventStop_2071,
            evh_8790374038998522_clickPreventStop: evh_8790374038998522_clickPreventStop,
            act_309864017738714_reloadSlickgrid_2076: act_309864017738714_reloadSlickgrid_2076,
            evh_309864017738714_success_2075: evh_309864017738714_success_2075,
            act_309864017738714_closeComponent_2078: act_309864017738714_closeComponent_2078,
            evh_309864017738714_success_2077: evh_309864017738714_success_2077,
            act_309864017738714_navigate_2080: act_309864017738714_navigate_2080,
            evh_309864017738714_success_2079: evh_309864017738714_success_2079,
            act_309864017738714_crud_2074: act_309864017738714_crud_2074,
            evh_309864017738714_clickPreventStop_2073: evh_309864017738714_clickPreventStop_2073,
            evh_309864017738714_clickPreventStop: evh_309864017738714_clickPreventStop,
            act_1589624209624921_closeModal_2082: act_1589624209624921_closeModal_2082,
            evh_1589624209624921_clickPreventStop_2081: evh_1589624209624921_clickPreventStop_2081,
            evh_1589624209624921_clickPreventStop: evh_1589624209624921_clickPreventStop,
            save: act_309864017738714_crud_2074,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], CreateContract.prototype, "rentalId", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('rentalId'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], CreateContract.prototype, "onRental_id", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "lg",
        }),
        __metadata("design:type", String)
    ], CreateContract.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], CreateContract.prototype, "onSize", null);
    CreateContract = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], CreateContract);
    return CreateContract;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = CreateContract;
vue_property_decorator_1.Vue.component("CreateContract", CreateContract);
