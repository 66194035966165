"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var UcProductPrices = (function (_super) {
    __extends(UcProductPrices, _super);
    function UcProductPrices() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['items', 'currencies', 'ux'];
        return _this;
    }
    UcProductPrices.prototype.beforeCreate = function () {
    };
    UcProductPrices.prototype.sortItems = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return _.orderBy(args[0], ['valid_from', 'price'], ['desc', 'desc']);
    };
    UcProductPrices.prototype.onProduct = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("product");
    };
    UcProductPrices.prototype.$$load_items = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_product_price', { parameters: { 'product_id': this.product.id } })];
            });
        });
    };
    UcProductPrices.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            currencies: ["HUF",
                "EUR",
                "USD",
            ],
            ux: {
                initialLoadSpinner: true,
            },
        });
    };
    Object.defineProperty(UcProductPrices.prototype, "purchasePrices", {
        get: function () {
            if (this.loaded) {
                return this.sortItems(_.filter(this.items, { is_purchase_price: true }));
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UcProductPrices.prototype, "sellingPrices", {
        get: function () {
            if (this.loaded) {
                return this.sortItems(_.filter(this.items, { is_purchase_price: false }));
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    UcProductPrices.prototype.evh_3343864469950044_close_5247_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data.modalResult.value];
            });
        });
    };
    UcProductPrices.prototype.act_3343864469950044_showModal_5246_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "ProductPrice",
                        props: {
                            product: this.product,
                            is_purchase_price: false,
                        },
                    }];
            });
        });
    };
    UcProductPrices.prototype.evh_3343864469950044_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcProductPrices.prototype.evh_8855792642156263_close_5251_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data.modalResult.ok];
            });
        });
    };
    UcProductPrices.prototype.act_8855792642156263_showModal_5250_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "ProductPrice",
                        props: {
                            oid: $event.scope.item.id,
                            mode: "edit",
                            product: this.product,
                        },
                    }];
            });
        });
    };
    UcProductPrices.prototype.evh_8855792642156263_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8855792642156263_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcProductPrices.prototype.evh_302930856768608_close_5255_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data.modalResult.value];
            });
        });
    };
    UcProductPrices.prototype.act_302930856768608_showModal_5254_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "ProductPrice",
                        props: {
                            product: this.product,
                            is_purchase_price: true,
                        },
                    }];
            });
        });
    };
    UcProductPrices.prototype.evh_302930856768608_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_302930856768608_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcProductPrices.prototype.evh_7972369138378646_close_5259_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data.modalResult.ok];
            });
        });
    };
    UcProductPrices.prototype.act_7972369138378646_showModal_5258_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "ProductPrice",
                        props: {
                            oid: $event.scope.item.id,
                            mode: "edit",
                            product: this.product,
                        },
                    }];
            });
        });
    };
    UcProductPrices.prototype.evh_7972369138378646_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7972369138378646_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcProductPrices.prototype.getActions = function () {
        var act_3343864469950044_reloadComponentData_5248 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_3343864469950044_close_5247 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_reloadComponentData_5248,
            event: "close",
            when: this.evh_3343864469950044_close_5247_getWhen,
            displayName: "reloadComponentData",
        });
        var act_3343864469950044_showModal_5246 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3343864469950044_showModal_5246_getActionArgs,
            events: [evh_3343864469950044_close_5247],
        });
        var evh_3343864469950044_clickPreventStop_5245 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_showModal_5246,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_3343864469950044_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_clickPreventStop_5245],
        });
        var act_8855792642156263_reloadComponentData_5252 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_8855792642156263_close_5251 = new core_1.actions.EventHandlerImpl({
            action: act_8855792642156263_reloadComponentData_5252,
            event: "close",
            when: this.evh_8855792642156263_close_5251_getWhen,
            displayName: "reloadComponentData",
        });
        var act_8855792642156263_showModal_5250 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8855792642156263_showModal_5250_getActionArgs,
            events: [evh_8855792642156263_close_5251],
        });
        var evh_8855792642156263_clickPreventStop_5249 = new core_1.actions.EventHandlerImpl({
            action: act_8855792642156263_showModal_5250,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_8855792642156263_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8855792642156263_clickPreventStop_5249],
        });
        var act_302930856768608_reloadComponentData_5256 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_302930856768608_close_5255 = new core_1.actions.EventHandlerImpl({
            action: act_302930856768608_reloadComponentData_5256,
            event: "close",
            when: this.evh_302930856768608_close_5255_getWhen,
            displayName: "reloadComponentData",
        });
        var act_302930856768608_showModal_5254 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_302930856768608_showModal_5254_getActionArgs,
            events: [evh_302930856768608_close_5255],
        });
        var evh_302930856768608_clickPreventStop_5253 = new core_1.actions.EventHandlerImpl({
            action: act_302930856768608_showModal_5254,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_302930856768608_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_302930856768608_clickPreventStop_5253],
        });
        var act_7972369138378646_reloadComponentData_5260 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_7972369138378646_close_5259 = new core_1.actions.EventHandlerImpl({
            action: act_7972369138378646_reloadComponentData_5260,
            event: "close",
            when: this.evh_7972369138378646_close_5259_getWhen,
            displayName: "reloadComponentData",
        });
        var act_7972369138378646_showModal_5258 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7972369138378646_showModal_5258_getActionArgs,
            events: [evh_7972369138378646_close_5259],
        });
        var evh_7972369138378646_clickPreventStop_5257 = new core_1.actions.EventHandlerImpl({
            action: act_7972369138378646_showModal_5258,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_7972369138378646_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7972369138378646_clickPreventStop_5257],
        });
        return {
            act_3343864469950044_reloadComponentData_5248: act_3343864469950044_reloadComponentData_5248,
            evh_3343864469950044_close_5247: evh_3343864469950044_close_5247,
            act_3343864469950044_showModal_5246: act_3343864469950044_showModal_5246,
            evh_3343864469950044_clickPreventStop_5245: evh_3343864469950044_clickPreventStop_5245,
            evh_3343864469950044_clickPreventStop: evh_3343864469950044_clickPreventStop,
            act_8855792642156263_reloadComponentData_5252: act_8855792642156263_reloadComponentData_5252,
            evh_8855792642156263_close_5251: evh_8855792642156263_close_5251,
            act_8855792642156263_showModal_5250: act_8855792642156263_showModal_5250,
            evh_8855792642156263_clickPreventStop_5249: evh_8855792642156263_clickPreventStop_5249,
            evh_8855792642156263_clickPreventStop: evh_8855792642156263_clickPreventStop,
            act_302930856768608_reloadComponentData_5256: act_302930856768608_reloadComponentData_5256,
            evh_302930856768608_close_5255: evh_302930856768608_close_5255,
            act_302930856768608_showModal_5254: act_302930856768608_showModal_5254,
            evh_302930856768608_clickPreventStop_5253: evh_302930856768608_clickPreventStop_5253,
            evh_302930856768608_clickPreventStop: evh_302930856768608_clickPreventStop,
            act_7972369138378646_reloadComponentData_5260: act_7972369138378646_reloadComponentData_5260,
            evh_7972369138378646_close_5259: evh_7972369138378646_close_5259,
            act_7972369138378646_showModal_5258: act_7972369138378646_showModal_5258,
            evh_7972369138378646_clickPreventStop_5257: evh_7972369138378646_clickPreventStop_5257,
            evh_7972369138378646_clickPreventStop: evh_7972369138378646_clickPreventStop,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], UcProductPrices.prototype, "product", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('product'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcProductPrices.prototype, "onProduct", null);
    UcProductPrices = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], UcProductPrices);
    return UcProductPrices;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = UcProductPrices;
vue_property_decorator_1.Vue.component("UcProductPrices", UcProductPrices);
