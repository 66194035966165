"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var big_js_1 = require("big.js");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var UcWorksheetWorkItems = (function (_super) {
    __extends(UcWorksheetWorkItems, _super);
    function UcWorksheetWorkItems() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['expenseTypes', 'taxCodes', 'units', 'ux', 'b_5962862420439144_rowHeader', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_columns'];
        return _this;
    }
    UcWorksheetWorkItems.prototype.beforeCreate = function () {
    };
    UcWorksheetWorkItems.prototype.onServiceSelected = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 2), row = _a[0], service = _a[1];
        row.service = service;
        row.service_id = service.id;
        row.unit = service.unit;
        row.task = service.name;
        row.tax_code = service.sales_vat_code;
        row.intermediated_service = service.intermediated_service || false;
        row.setFocus('quantity');
    };
    UcWorksheetWorkItems.prototype.onServiceChanged = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.log('onServiceChanged', args);
    };
    UcWorksheetWorkItems.prototype.calculateUnitPrice = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 3), row = _a[0], tax = _a[1], decimals = _a[2];
        row.unit_price = (0, big_js_1.default)((0, big_js_1.default)(row.net_amount) / (0, big_js_1.default)(row.quantity) / (1 - (0, big_js_1.default)(row.discount) / 100)).round(decimals).toNumber();
    };
    UcWorksheetWorkItems.prototype.calculateNetAmount = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 3), row = _a[0], tax = _a[1], decimals = _a[2];
        row.net_amount = (0, big_js_1.default)((0, big_js_1.default)(row.quantity) * (0, big_js_1.default)(row.unit_price) * (1 - (0, big_js_1.default)(row.discount) / 100)).round(decimals).toNumber();
    };
    UcWorksheetWorkItems.prototype.calculateNetAmountFromAmount = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 3), row = _a[0], tax = _a[1], decimals = _a[2];
        row.net_amount = (0, big_js_1.default)((0, big_js_1.default)(row.amount) / (1 + (0, big_js_1.default)(tax.rate) / 100)).round(decimals).toNumber();
    };
    UcWorksheetWorkItems.prototype.calculateAmount = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 3), row = _a[0], tax = _a[1], decimals = _a[2];
        row.amount = (0, big_js_1.default)((0, big_js_1.default)(row.net_amount) * (1 + (0, big_js_1.default)(tax.rate) / 100)).round(decimals).toNumber();
    };
    UcWorksheetWorkItems.prototype.recalculateLineItem = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var data = args[0].data;
        var row = data.row;
        var decimals = 2;
        var tx = _.find(this.taxCodes, { id: row.tax_code });
        if (row.quantity === 0) {
            return;
        }
        switch (data.field) {
            case 'quantity':
            case 'unit_price':
            case 'discount': {
                this.calculateNetAmount(row, tx, decimals);
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'net_amount': {
                this.calculateUnitPrice(row, tx, decimals);
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'tax_code': {
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'amount': {
                this.calculateNetAmountFromAmount(row, tx, decimals);
                this.calculateUnitPrice(row, tx, decimals);
                break;
            }
        }
    };
    UcWorksheetWorkItems.prototype.onWorksheet = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("worksheet");
    };
    UcWorksheetWorkItems.prototype.onIs_readonly = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("isReadonly");
    };
    UcWorksheetWorkItems.prototype.$$load_expenseTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } })];
            });
        });
    };
    UcWorksheetWorkItems.prototype.$$load_taxCodes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_tax_code')];
            });
        });
    };
    UcWorksheetWorkItems.prototype.$$load_units = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_unit')];
            });
        });
    };
    UcWorksheetWorkItems.prototype.$$load_b_5962862420439144_columns = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, [{
                            name: "service",
                            type: "select2",
                            header: this.$fn.gettext("Task"),
                            width: "140px",
                            formatter: {
                                name: "template",
                                value: "<b-link :to=\"{\n\
  name: 'app.product',\n\
  params: { id: row.service_id }\n\
}\">{{ row.task }}</b-link>\n\
",
                            },
                            options: this.$fn.searchDataSource('suggest_product', { parameters: { product_types: 'service' } }),
                            optionValueField: "name",
                            valueField: "id",
                            textField: "name",
                            bindObject: true,
                            validator: ["required",
                            ],
                        },
                        {
                            name: "asset",
                            type: "select2",
                            header: this.$fn.gettext("Asset"),
                            width: "140px",
                            formatter: {
                                name: "template",
                                value: "<b-link :to=\"{\n\
  name: 'app.fixedAsset',\n\
  params: { id: row.asset_id }\n\
}\">{{ row.asset ? row.asset.name : '-' }}</b-link>\n\
",
                            },
                            options: this.$fn.searchDataSource('suggest_fixed_asset'),
                            optionValueField: "name",
                            valueField: "id",
                            textField: "name",
                            bindObject: true,
                        },
                        {
                            name: "date",
                            type: "date",
                            header: this.$fn.gettext("Date"),
                            width: "140px",
                            required: true,
                        },
                        {
                            name: "notes",
                            type: "textarea",
                            width: "200px",
                            header: this.$fn.gettext("Notes"),
                        },
                        {
                            name: "quantity",
                            type: "number",
                            default: 0,
                            header: this.$fn.pgettext("Quantity Abbr", "Qty"),
                            headerTooltip: this.$fn.gettext("Quantity"),
                            width: "60px",
                            textAlign: "right",
                            required: true,
                            editorIsDisabled: function (row) { return !row.service || !row.service.id; },
                        },
                        {
                            name: "unit",
                            header: this.$fn.pgettext("Unit Abbr", "Unit"),
                            headerTooltip: this.$fn.gettext("Unit"),
                            width: "70px",
                            textAlign: "center",
                            required: true,
                            editorIsDisabled: function (row) { return !row.service || !row.service.id; },
                        },
                        {
                            name: "unit_price",
                            type: "number",
                            default: 0,
                            header: this.$fn.gettext("Price"),
                            width: "70px",
                            textAlign: "right",
                            required: true,
                            editorIsDisabled: function (row) { return !row.service || !row.service.id; },
                        },
                        {
                            name: "discount",
                            type: "number",
                            default: 0,
                            header: this.$fn.pgettext("AbbrDiscount", "Disc"),
                            headerTooltip: this.$fn.gettext("Discount"),
                            width: "70px",
                            required: true,
                            editorIsDisabled: function (row) { return !row.service || !row.service.id; },
                        },
                        {
                            name: "net_amount",
                            type: "number",
                            default: 0,
                            header: this.$fn.gettext("Net"),
                            width: "100px",
                            textAlign: "right",
                            required: true,
                            editorIsDisabled: function (row) { return !row.service || !row.service.id; },
                        },
                        {
                            name: "amount",
                            type: "number",
                            default: 0,
                            header: this.$fn.gettext("Amount"),
                            width: "100px",
                            textAlign: "right",
                            required: true,
                            editorIsDisabled: function (row) { return !row.service || !row.service.id; },
                        },
                        {
                            name: "tax_code",
                            header: this.$fn.gettext("VAT"),
                            headerTooltip: this.$fn.gettext("VAT Code"),
                            width: "100px",
                            type: "select",
                            options: this.taxCodes,
                            valueField: "id",
                            textField: "id",
                            editorIsDisabled: function (row) { return !row.service || !row.service.id; },
                        },
                        {
                            name: "intermediated_service",
                            header: this.$fn.pgettext("AbbrIntermediatedService", "IMS"),
                            headerTooltip: this.$fn.gettext("Intermediated Service"),
                            width: "60px",
                            type: "checkbox",
                            editorIsDisabled: function (row) { return !row.service || !row.service.id; },
                        },
                    ]];
            });
        });
    };
    UcWorksheetWorkItems.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            expenseTypes: null,
            taxCodes: null,
            units: null,
            ux: {
                errorHandling: "local",
                initialLoadSpinner: true,
            },
            b_5962862420439144_rowHeader: false,
            b_5962862420439144_paging: false,
            b_5962862420439144_sortable: false,
            b_5962862420439144_columns: null,
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_emit_5524_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_saveCompleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5526_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.worksheet.id
                    ? $event.data.waitFor(this.$fn.crud('work_item').upsert(__assign(__assign({}, $event.data.row), { worksheet_id: this.worksheet.id })))
                    : this.worksheet.work_items.splice(this.worksheet.work_items.length - 1, 0, $event.data.row);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5526_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5526_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_save = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_save.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5528_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.worksheet.id
                    ? $event.data.waitFor(this.$fn.crud('work_item').delete($event.data.row))
                    : _.remove(this.worksheet.work_items, $event.data.row);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5528_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5528_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_delete = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5530_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onServiceSelected($event.data.row, $event.data.option);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5530_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5530_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_serviceSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_serviceSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5532_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onServiceChanged($event);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5532_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5532_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_serviceChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_serviceChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5534_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5534_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5534_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_quantityChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_quantityChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5536_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5536_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5536_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_unitPriceChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_unitPriceChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5538_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5538_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5538_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_discountChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_discountChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5540_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5540_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5540_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_netAmountChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_netAmountChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5542_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5542_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5542_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_amountChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_amountChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5544_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcWorksheetWorkItems.prototype.act_5962862420439144_script_5544_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5544_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcWorksheetWorkItems.prototype.evh_5962862420439144_taxCodeChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_taxCodeChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcWorksheetWorkItems.prototype.getActions = function () {
        var act_5962862420439144_emit_5524 = new core_1.actions.EmitAction({
            actionArgs: this.act_5962862420439144_emit_5524_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_saveCompleted_5523 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_emit_5524,
            event: "save-completed",
            displayName: "emit",
        });
        var evh_5962862420439144_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_saveCompleted_5523],
        });
        var act_5962862420439144_script_5526 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5526_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_save_5525 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5526,
            event: "save",
            displayName: "script",
        });
        var evh_5962862420439144_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_save_5525],
        });
        var act_5962862420439144_script_5528 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5528_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_delete_5527 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5528,
            event: "delete",
            displayName: "script",
        });
        var evh_5962862420439144_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_delete_5527],
        });
        var act_5962862420439144_script_5530 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5530_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_serviceSelected_5529 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5530,
            event: "service:selected",
            displayName: "script",
        });
        var evh_5962862420439144_serviceSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_serviceSelected_5529],
        });
        var act_5962862420439144_script_5532 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5532_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_serviceChange_5531 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5532,
            event: "service:change",
            displayName: "script",
        });
        var evh_5962862420439144_serviceChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_serviceChange_5531],
        });
        var act_5962862420439144_script_5534 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5534_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_quantityChange_5533 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5534,
            event: "quantity:change",
            displayName: "script",
        });
        var evh_5962862420439144_quantityChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_quantityChange_5533],
        });
        var act_5962862420439144_script_5536 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5536_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_unitPriceChange_5535 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5536,
            event: "unit_price:change",
            displayName: "script",
        });
        var evh_5962862420439144_unitPriceChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_unitPriceChange_5535],
        });
        var act_5962862420439144_script_5538 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5538_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_discountChange_5537 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5538,
            event: "discount:change",
            displayName: "script",
        });
        var evh_5962862420439144_discountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_discountChange_5537],
        });
        var act_5962862420439144_script_5540 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5540_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_netAmountChange_5539 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5540,
            event: "net_amount:change",
            displayName: "script",
        });
        var evh_5962862420439144_netAmountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_netAmountChange_5539],
        });
        var act_5962862420439144_script_5542 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5542_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_amountChange_5541 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5542,
            event: "amount:change",
            displayName: "script",
        });
        var evh_5962862420439144_amountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_amountChange_5541],
        });
        var act_5962862420439144_script_5544 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5544_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_taxCodeChange_5543 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5544,
            event: "tax_code:change",
            displayName: "script",
        });
        var evh_5962862420439144_taxCodeChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_taxCodeChange_5543],
        });
        return {
            act_5962862420439144_emit_5524: act_5962862420439144_emit_5524,
            evh_5962862420439144_saveCompleted_5523: evh_5962862420439144_saveCompleted_5523,
            evh_5962862420439144_saveCompleted: evh_5962862420439144_saveCompleted,
            act_5962862420439144_script_5526: act_5962862420439144_script_5526,
            evh_5962862420439144_save_5525: evh_5962862420439144_save_5525,
            evh_5962862420439144_save: evh_5962862420439144_save,
            act_5962862420439144_script_5528: act_5962862420439144_script_5528,
            evh_5962862420439144_delete_5527: evh_5962862420439144_delete_5527,
            evh_5962862420439144_delete: evh_5962862420439144_delete,
            act_5962862420439144_script_5530: act_5962862420439144_script_5530,
            evh_5962862420439144_serviceSelected_5529: evh_5962862420439144_serviceSelected_5529,
            evh_5962862420439144_serviceSelected: evh_5962862420439144_serviceSelected,
            act_5962862420439144_script_5532: act_5962862420439144_script_5532,
            evh_5962862420439144_serviceChange_5531: evh_5962862420439144_serviceChange_5531,
            evh_5962862420439144_serviceChange: evh_5962862420439144_serviceChange,
            act_5962862420439144_script_5534: act_5962862420439144_script_5534,
            evh_5962862420439144_quantityChange_5533: evh_5962862420439144_quantityChange_5533,
            evh_5962862420439144_quantityChange: evh_5962862420439144_quantityChange,
            act_5962862420439144_script_5536: act_5962862420439144_script_5536,
            evh_5962862420439144_unitPriceChange_5535: evh_5962862420439144_unitPriceChange_5535,
            evh_5962862420439144_unitPriceChange: evh_5962862420439144_unitPriceChange,
            act_5962862420439144_script_5538: act_5962862420439144_script_5538,
            evh_5962862420439144_discountChange_5537: evh_5962862420439144_discountChange_5537,
            evh_5962862420439144_discountChange: evh_5962862420439144_discountChange,
            act_5962862420439144_script_5540: act_5962862420439144_script_5540,
            evh_5962862420439144_netAmountChange_5539: evh_5962862420439144_netAmountChange_5539,
            evh_5962862420439144_netAmountChange: evh_5962862420439144_netAmountChange,
            act_5962862420439144_script_5542: act_5962862420439144_script_5542,
            evh_5962862420439144_amountChange_5541: evh_5962862420439144_amountChange_5541,
            evh_5962862420439144_amountChange: evh_5962862420439144_amountChange,
            act_5962862420439144_script_5544: act_5962862420439144_script_5544,
            evh_5962862420439144_taxCodeChange_5543: evh_5962862420439144_taxCodeChange_5543,
            evh_5962862420439144_taxCodeChange: evh_5962862420439144_taxCodeChange,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], UcWorksheetWorkItems.prototype, "worksheet", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('worksheet'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcWorksheetWorkItems.prototype, "onWorksheet", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
        }),
        __metadata("design:type", Boolean)
    ], UcWorksheetWorkItems.prototype, "isReadonly", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('isReadonly'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcWorksheetWorkItems.prototype, "onIs_readonly", null);
    UcWorksheetWorkItems = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], UcWorksheetWorkItems);
    return UcWorksheetWorkItems;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = UcWorksheetWorkItems;
vue_property_decorator_1.Vue.component("UcWorksheetWorkItems", UcWorksheetWorkItems);
