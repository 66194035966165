<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <legend
    v-if="!!noLegend"
  >
    {{ 'Lessor'|pgettext('Contract') }}
  </legend>
  <ItpFormGroup
    name="company"
    :label="'Company'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect2
      name="company"
      v-model="contract.company"
      bind-object
      :dataSource="b_1040167445267876_dataSource"
      textField="name"
      required
      optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
      use-option-template-for-selected-option
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="head_office"
    :label="'Head office'|pgettext('Company')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.company"
  >
    <ItpFormInput
      name="head_office"
      v-model="contract.company.head_office"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="branch_office"
    :label="'Branch office'|pgettext('Company')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.company"
  >
    <ItpFormInput
      name="branch_office"
      v-model="contract.company.branch_office"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="statistical_code"
    :label="'Statistical code'|pgettext('Company')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    v-if="contract.company"
  >
    <ItpFormInput
      name="statistical_code"
      v-model="contract.company.statistical_code"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="registration_number"
    :label="'Registration number'|pgettext('Company')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    v-if="contract.company"
  >
    <ItpFormInput
      name="registration_number"
      v-model="contract.company.registration_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="account_number"
    :label="'Account number'|pgettext('Company')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.company"
  >
    <ItpFormInput
      name="account_number"
      v-model="contract.company.account_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="representative"
    :label="'Representative'|pgettext('Company')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.company"
  >
    <ItpFormTextarea
      name="representative"
      v-model="contract.company.representative"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetContractCompanyData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    type: Boolean,
  })
  noLegend!: boolean;

  @Watch('noLegend')
  onNo_legend(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noLegend")
  }

  ux!: any;
  b_1040167445267876_dataSource!: any;
  dataMembers = ['ux', 'b_1040167445267876_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_1040167445267876_dataSource: {
          name: "suggest_client",
        }
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6050: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6049: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6050 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6049 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6050,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6049],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6050,
      evh_7315092382398562_reload_6049,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetContractCompanyData", FieldsetContractCompanyData);

</script>