<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    alignItems="center"
  >
    <ItpIcon
      :name="$config.invoice.icon"
      spacing="mr-3"
      :title="'Invoice'|pgettext('Invoice')"
      class="mr-2"
      v-if="!noIcon"
    >
    </ItpIcon>
    <UcInvoiceTypeBadge
      :value="item.invoice_type"
      abbreviated
      class="mr-2"
    >
    </UcInvoiceTypeBadge>
    <ItpText
      strong
      :text="item.invoice_number"
      class="mr-2"
    >
    </ItpText>
    <ItpLink
      :icon="$config.externalLinkIcon"
      :to="{
    name: 'app.invoice',
    params: {id: item.id}
  }"
    >
    </ItpLink>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceMiniCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noIcon!: boolean;

  @Watch('noIcon')
  onNo_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noIcon")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4800: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4799: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4800 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4799 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4800,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4799],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4800,
      evh_7315092382398562_reload_4799,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcInvoiceMiniCard", UcInvoiceMiniCard);

</script>