"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
var AddFixedAssetToRentalModal_yaml_component_1 = require("./AddFixedAssetToRentalModal.yaml?component");
var CreateWorksheetsFromWasteBinReadingsModal_yaml_component_1 = require("./CreateWorksheetsFromWasteBinReadingsModal.yaml?component");
var FixedAsset_yaml_component_1 = require("./FixedAsset.yaml?component");
var FixedAssetBrowser_yaml_component_1 = require("./FixedAssetBrowser.yaml?component");
var MCreateFixedAsset_yaml_component_1 = require("./MCreateFixedAsset.yaml?component");
var MFixedAssetEditor_yaml_component_1 = require("./MFixedAssetEditor.yaml?component");
var MFixedAssetEditorFieldset_yaml_component_1 = require("./MFixedAssetEditorFieldset.yaml?component");
var WasteBinReadingModal_yaml_component_1 = require("./WasteBinReadingModal.yaml?component");
var WasteBinReadingsList_yaml_component_1 = require("./WasteBinReadingsList.yaml?component");
var WasteBinsBrowser_yaml_component_1 = require("./WasteBinsBrowser.yaml?component");
__exportStar(require("./form"), exports);
__exportStar(require("./primitives"), exports);
exports.default = [
    AddFixedAssetToRentalModal_yaml_component_1.default,
    CreateWorksheetsFromWasteBinReadingsModal_yaml_component_1.default,
    FixedAsset_yaml_component_1.default,
    FixedAssetBrowser_yaml_component_1.default,
    MCreateFixedAsset_yaml_component_1.default,
    MFixedAssetEditor_yaml_component_1.default,
    MFixedAssetEditorFieldset_yaml_component_1.default,
    WasteBinReadingModal_yaml_component_1.default,
    WasteBinReadingsList_yaml_component_1.default,
    WasteBinsBrowser_yaml_component_1.default
];
