<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="HandoverProtocol"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcContractProtocolHeader
      :protocol="item"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </UcContractProtocolHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      :name="formName"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetHandoverProtocol
        :item="item"
        :form="forms[formName]"
        @reload="evh_5534025912102772_reload($event, {})"
        @close="evh_5534025912102772_close($event, {})"
        @changed="evh_5534025912102772_changed($event, {})"
      >
      </FieldsetHandoverProtocol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="formId"
      variant="warning"
      icon="fa-times"
      :text="'Cancel protocol'|pgettext('HandoverProtocol')"
      :spinning="actions.cancel.isRunning"
      v-if="loaded && !item.isNew && item.protocol_status === 'draft'"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :form="formId"
      variant="success"
      icon="fa-save"
      :text="'Complete'|gettext"
      :spinning="actions.complete.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      class="ml-auto"
      v-if="loaded && !item.isNew && item.protocol_status === 'draft'"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :text="'Save'|gettext"
      :spinning="actions.save.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      v-if="loaded && item.protocol_status === 'draft'"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="default"
      :text="'Close'|gettext"
      :disabled="actions.save.isRunning"
      class="ml-3"
      @click.prevent.stop="evh_5566617329548203_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class HandoverProtocol extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
  })
  contractId!: string;

  @Watch('contractId')
  onContract_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contractId")
  }

  @Prop({
    type: String,
  })
  revisionId!: string;

  @Watch('revisionId')
  onRevision_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("revisionId")
  }

  @Prop({
    type: String,
  })
  handoverType!: string;

  @Watch('handoverType')
  onHandover_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("handoverType")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.oid ?
      this.$fn.fetch('read_contract_protocol', {
        asResource: true,
        parameters: { id: this.oid }
      }) :
      this.$fn.fetch('get_contract_protocol_template', {
        asResource: true,
        parameters: {
          contract_id: this.contractId,
          revision_id: this.revisionId,
          handover_type: this.handoverType,
        }
      })
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  handoverTypes!: any;

  async $$load_handoverTypes() {
    return this.$fn.getEnumValuesFromSchema('HandoverType')
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['item', 'formName', 'formId', 'labelAlign', 'handoverTypes', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        formName: "handoverProtocol",
        formId: null,
        labelAlign: "left",
        handoverTypes: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.$fn.pgettext("Contract", "Handover protocol")
          ,
        this.$fn.pgettext("HandoverType", this.item.handover_type, "Sentence")
          ,
        ]
        ,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6114_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6114, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_6124_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_6124, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1040167445267876_crud_6122_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_6122, alias=save
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_5534025912102772_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_reload.executeFromDOM(this, event, scope);
  }

  async evh_5534025912102772_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_close.executeFromDOM(this, event, scope);
  }

  async act_5534025912102772_emit_6130_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5534025912102772_emit_6130, alias=undefined
    return {
      event: "saved",
      value: $event.data,
    }
  }

  async evh_5534025912102772_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_changed.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeComponent_6134_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1548630417156826_closeComponent_6134, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1548630417156826_crud_6132_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1548630417156826_crud_6132, alias=cancel
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: { ...this.item, protocol_status: 'cancelled' },
    }
  }

  async act_1548630417156826_crud_6132_getConfirm($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1548630417156826_crud_6132, alias=cancel
    return {
      header: "Jegyőkönyv érvénytelenítése",
      message: "A jegyzőkönyv érvénytelenítés után már nem módosítható.",
      btnOk: "Érvénytelenítés",
      severity: "warning",
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeComponent_6138_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1419464017721962_closeComponent_6138, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1419464017721962_crud_6136_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1419464017721962_crud_6136, alias=complete
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: { ...this.item, protocol_status: 'completed' },
    }
  }

  async act_1419464017721962_crud_6136_getConfirm($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1419464017721962_crud_6136, alias=complete
    return {
      header: "Jegyőkönyv véglegesítése",
      message: "A jegyzőkönyv véglegesítés után már nem módosítható.",
      btnOk: "Véglegesítés",
      severity: "warning",
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2186679009640457_closeComponent_6142_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_2186679009640457_closeComponent_6142, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_2186679009640457_crud_6140_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_2186679009640457_crud_6140, alias=save
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_5566617329548203_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6114: actions.CloseModalAction;
    evh_7315092382398562_close_6113: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_6116: actions.CloseModalAction;
    evh_2248226175642056_close_6115: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_6118: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_6117: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeModal_6120: actions.CloseModalAction;
    evh_8564662037462133_deleted_6119: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_6124: actions.CloseComponentAction;
    evh_1040167445267876_success_6123: actions.EventHandlerImpl;
    act_1040167445267876_crud_6122: actions.CRUDAction;
    evh_1040167445267876_submit_6121: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_5534025912102772_reloadComponentData_6126: actions.ReloadComponentDataAction;
    evh_5534025912102772_reload_6125: actions.EventHandlerImpl;
    evh_5534025912102772_reload: actions.EventHandlerGroup;
    act_5534025912102772_closeComponent_6128: actions.CloseComponentAction;
    evh_5534025912102772_close_6127: actions.EventHandlerImpl;
    evh_5534025912102772_close: actions.EventHandlerGroup;
    act_5534025912102772_emit_6130: actions.EmitAction;
    evh_5534025912102772_changed_6129: actions.EventHandlerImpl;
    evh_5534025912102772_changed: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_6134: actions.CloseComponentAction;
    evh_1548630417156826_success_6133: actions.EventHandlerImpl;
    act_1548630417156826_crud_6132: actions.CRUDAction;
    evh_1548630417156826_clickPreventStop_6131: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_6138: actions.CloseComponentAction;
    evh_1419464017721962_success_6137: actions.EventHandlerImpl;
    act_1419464017721962_crud_6136: actions.CRUDAction;
    evh_1419464017721962_clickPreventStop_6135: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
    act_2186679009640457_closeComponent_6142: actions.CloseComponentAction;
    evh_2186679009640457_success_6141: actions.EventHandlerImpl;
    act_2186679009640457_crud_6140: actions.CRUDAction;
    evh_2186679009640457_clickPreventStop_6139: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
    act_5566617329548203_closeModal_6144: actions.CloseModalAction;
    evh_5566617329548203_clickPreventStop_6143: actions.EventHandlerImpl;
    evh_5566617329548203_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6114 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6114_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6113 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6114,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6113],
      }
    );
    const act_2248226175642056_closeModal_6116 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6115 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_6116,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6115],
      }
    );
    const act_8564662037462133_reloadComponentData_6118 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_6117 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_6118,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_6117],
      }
    );
    const act_8564662037462133_closeModal_6120 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_6119 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeModal_6120,
        event: "deleted",
        displayName: "closeModal",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_6119],
      }
    );
    const act_1040167445267876_closeComponent_6124 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_6124_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_6123 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_6124,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_crud_6122 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_6122_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_6123],
      }
    );
    const evh_1040167445267876_submit_6121 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_6122,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_6121],
      }
    );
    const act_5534025912102772_reloadComponentData_6126 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_5534025912102772_reload_6125 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_reloadComponentData_6126,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_5534025912102772_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_reload_6125],
      }
    );
    const act_5534025912102772_closeComponent_6128 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_5534025912102772_close_6127 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_closeComponent_6128,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_5534025912102772_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_close_6127],
      }
    );
    const act_5534025912102772_emit_6130 = new actions.EmitAction(
      {
        actionArgs: this.act_5534025912102772_emit_6130_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_changed_6129 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_emit_6130,
        event: "changed",
        displayName: "emit",
      }
    );
    const evh_5534025912102772_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_changed_6129],
      }
    );
    const act_1548630417156826_closeComponent_6134 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1548630417156826_closeComponent_6134_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_6133 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_6134,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1548630417156826_crud_6132 = new actions.CRUDAction(
      {
        actionArgs: this.act_1548630417156826_crud_6132_getActionArgs,
        confirm: this.act_1548630417156826_crud_6132_getConfirm,
        displayName: "cancel",
        events: [evh_1548630417156826_success_6133],
      }
    );
    const evh_1548630417156826_clickPreventStop_6131 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_crud_6132,
        event: "click.prevent.stop",
        displayName: "cancel",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_6131],
      }
    );
    const act_1419464017721962_closeComponent_6138 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1419464017721962_closeComponent_6138_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_success_6137 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_6138,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1419464017721962_crud_6136 = new actions.CRUDAction(
      {
        actionArgs: this.act_1419464017721962_crud_6136_getActionArgs,
        confirm: this.act_1419464017721962_crud_6136_getConfirm,
        displayName: "complete",
        events: [evh_1419464017721962_success_6137],
      }
    );
    const evh_1419464017721962_clickPreventStop_6135 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_crud_6136,
        event: "click.prevent.stop",
        displayName: "complete",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_6135],
      }
    );
    const act_2186679009640457_closeComponent_6142 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_2186679009640457_closeComponent_6142_getActionArgs,
        events: [],
      }
    );
    const evh_2186679009640457_success_6141 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_closeComponent_6142,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_2186679009640457_crud_6140 = new actions.CRUDAction(
      {
        actionArgs: this.act_2186679009640457_crud_6140_getActionArgs,
        displayName: "save",
        events: [evh_2186679009640457_success_6141],
      }
    );
    const evh_2186679009640457_clickPreventStop_6139 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_crud_6140,
        event: "click.prevent.stop",
        displayName: "save",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_6139],
      }
    );
    const act_5566617329548203_closeModal_6144 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_5566617329548203_clickPreventStop_6143 = new actions.EventHandlerImpl(
      {
        action: act_5566617329548203_closeModal_6144,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_5566617329548203_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5566617329548203_clickPreventStop_6143],
      }
    );
    return {
      act_7315092382398562_closeModal_6114,
      evh_7315092382398562_close_6113,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_6116,
      evh_2248226175642056_close_6115,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_6118,
      evh_8564662037462133_reload_6117,
      evh_8564662037462133_reload,
      act_8564662037462133_closeModal_6120,
      evh_8564662037462133_deleted_6119,
      evh_8564662037462133_deleted,
      act_1040167445267876_closeComponent_6124,
      evh_1040167445267876_success_6123,
      act_1040167445267876_crud_6122,
      evh_1040167445267876_submit_6121,
      evh_1040167445267876_submit,
      act_5534025912102772_reloadComponentData_6126,
      evh_5534025912102772_reload_6125,
      evh_5534025912102772_reload,
      act_5534025912102772_closeComponent_6128,
      evh_5534025912102772_close_6127,
      evh_5534025912102772_close,
      act_5534025912102772_emit_6130,
      evh_5534025912102772_changed_6129,
      evh_5534025912102772_changed,
      act_1548630417156826_closeComponent_6134,
      evh_1548630417156826_success_6133,
      act_1548630417156826_crud_6132,
      evh_1548630417156826_clickPreventStop_6131,
      evh_1548630417156826_clickPreventStop,
      act_1419464017721962_closeComponent_6138,
      evh_1419464017721962_success_6137,
      act_1419464017721962_crud_6136,
      evh_1419464017721962_clickPreventStop_6135,
      evh_1419464017721962_clickPreventStop,
      act_2186679009640457_closeComponent_6142,
      evh_2186679009640457_success_6141,
      act_2186679009640457_crud_6140,
      evh_2186679009640457_clickPreventStop_6139,
      evh_2186679009640457_clickPreventStop,
      act_5566617329548203_closeModal_6144,
      evh_5566617329548203_clickPreventStop_6143,
      evh_5566617329548203_clickPreventStop,
      save: act_2186679009640457_crud_6140,
      cancel: act_1548630417156826_crud_6132,
      complete: act_1419464017721962_crud_6136,
    }
  }
}

Vue.component("HandoverProtocol", HandoverProtocol);

</script>