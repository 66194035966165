<template>
<ItpPage
  :backdrop="b_7315092382398562_backdrop"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="AddInvoiceLine"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="invoiceLine"
      autofocus="first"
      v-if="loaded"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <DIV
        class="form-row"
      >
        <ItpFormGroup
          name="product"
          :label="'Item'|pgettext('InvoiceLine')"
          class="col"
        >
          <ItpFormSelect2
            name="product"
            v-model="item.product"
            required
            :options="searchDataSource('suggest_product')"
            bind-object
            valueField="id"
            textField="name"
            autofocus
            taggable
            :multiple="b_5731695935601903_multiple"
            @optionSelected="evh_5731695935601903_optionSelected($event, {})"
          >
          </ItpFormSelect2>
        </ItpFormGroup>
      </DIV>
      <DIV
        class="form-row"
      >
        <ItpFormGroup
          name="quantity"
          :label="'Quantity'|pgettext('InvoiceLine')"
          class="col-2"
        >
          <ItpFormInput
            type="number"
            name="quantity"
            v-model="item.quantity"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="unit"
          :label="'Unit'|pgettext('InvoiceLine')"
          class="col-2"
        >
          <ItpFormInput
            name="unit"
            v-model="item.unit"
            required
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="price"
          :label="'Price'|pgettext('InvoiceLine')"
          class="col-2"
        >
          <ItpFormInput
            type="number"
            name="price"
            v-model="item.price"
            required
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="price"
          :label="'Discount'|pgettext('InvoiceLine')"
          class="col-2"
        >
          <ItpFormInput
            type="number"
            name="discount"
            v-model="item.discount"
            required
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="tax_code"
          :label="'Tax code'|pgettext('InvoiceLine')"
          class="col-2"
        >
          <ItpFormSelect
            name="tax_code"
            :options="taxCodes"
            v-model="item.tax_code"
            required
          >
          </ItpFormSelect>
        </ItpFormGroup>
      </DIV>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="formId"
      icon="fa-save"
      :text="'Save'|gettext"
      type="submit"
      variant="primary"
      :spinning="actions.submit.isRunning"
      :disabled="!forms.invoiceLine || !forms.invoiceLine.submittable"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class AddInvoiceLine extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.schemaDefaults('CreateInvoiceLine', { invoice_id: this.invoice.id }, true)
  }

  taxCodes!: any;

  async $$load_taxCodes() {
    return this.$fn.fetch('list_tax_code')
  }

  ux!: any;
  b_7315092382398562_backdrop!: any;
  b_7315092382398562_modalBindings!: any;
  b_5731695935601903_multiple!: any;
  dataMembers = ['formId', 'item', 'taxCodes', 'ux', 'b_7315092382398562_backdrop', 'b_7315092382398562_modalBindings', 'b_5731695935601903_multiple'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        item: null,
        taxCodes: null,
        ux: null,
        b_7315092382398562_backdrop: false,
        b_7315092382398562_modalBindings: {
          backdrop: false,
          size: "md",
        }
        ,
        b_5731695935601903_multiple: false,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: "Add invoice line",
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4414_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4414, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_emit_4420_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1040167445267876_emit_4420, alias=undefined
    return {
      event: "created",
      value: $event.data,
    }
  }

  async act_1040167445267876_crud_4418_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_4418, alias=submit
    return {
      objectType: "invoice_line",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_5731695935601903_script_4422_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5731695935601903_script_4422, alias=onProductSelected
    console.log($event)
  }

  async act_5731695935601903_script_4422_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5731695935601903_script_4422, alias=onProductSelected
    return {
      value: () => this.act_5731695935601903_script_4422_getActionArgs_value($event),
    }
  }

  async evh_5731695935601903_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5731695935601903_optionSelected.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4414: actions.CloseModalAction;
    evh_7315092382398562_close_4413: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_4416: actions.CloseModalAction;
    evh_2248226175642056_close_4415: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_emit_4420: actions.EmitAction;
    evh_1040167445267876_success_4419: actions.EventHandlerImpl;
    act_1040167445267876_crud_4418: actions.CRUDAction;
    evh_1040167445267876_submit_4417: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_5731695935601903_script_4422: actions.ScriptAction;
    evh_5731695935601903_optionSelected_4421: actions.EventHandlerImpl;
    evh_5731695935601903_optionSelected: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_4424: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_4423: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4414 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4414_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4413 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4414,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4413],
      }
    );
    const act_2248226175642056_closeModal_4416 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_4415 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_4416,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_4415],
      }
    );
    const act_1040167445267876_emit_4420 = new actions.EmitAction(
      {
        actionArgs: this.act_1040167445267876_emit_4420_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_4419 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_emit_4420,
        event: "success",
        displayName: "emit",
      }
    );
    const act_1040167445267876_crud_4418 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_4418_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_4419],
      }
    );
    const evh_1040167445267876_submit_4417 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_4418,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_4417],
      }
    );
    const act_5731695935601903_script_4422 = new actions.ScriptAction(
      {
        actionArgs: this.act_5731695935601903_script_4422_getActionArgs,
        displayName: "onProductSelected",
        events: [],
      }
    );
    const evh_5731695935601903_optionSelected_4421 = new actions.EventHandlerImpl(
      {
        action: act_5731695935601903_script_4422,
        event: "optionSelected",
        displayName: "onProductSelected",
      }
    );
    const evh_5731695935601903_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_5731695935601903_optionSelected_4421],
      }
    );
    const act_1419464017721962_closeComponent_4424 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_4423 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_4424,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_4423],
      }
    );
    return {
      act_7315092382398562_closeModal_4414,
      evh_7315092382398562_close_4413,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_4416,
      evh_2248226175642056_close_4415,
      evh_2248226175642056_close,
      act_1040167445267876_emit_4420,
      evh_1040167445267876_success_4419,
      act_1040167445267876_crud_4418,
      evh_1040167445267876_submit_4417,
      evh_1040167445267876_submit,
      act_5731695935601903_script_4422,
      evh_5731695935601903_optionSelected_4421,
      evh_5731695935601903_optionSelected,
      act_1419464017721962_closeComponent_4424,
      evh_1419464017721962_clickPreventStop_4423,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_crud_4418,
      onProductSelected: act_5731695935601903_script_4422,
    }
  }
}

Vue.component("AddInvoiceLine", AddInvoiceLine);

</script>