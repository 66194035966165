"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LetterRegisterEntryHeader = exports.LetterRegisterEntry = exports.LetterRegister = exports.FieldsetLetterRegisterEntry = void 0;
var FieldsetLetterRegisterEntry_yaml_component_1 = require("./FieldsetLetterRegisterEntry.yaml?component");
exports.FieldsetLetterRegisterEntry = FieldsetLetterRegisterEntry_yaml_component_1.default;
var LetterRegister_yaml_component_1 = require("./LetterRegister.yaml?component");
exports.LetterRegister = LetterRegister_yaml_component_1.default;
var LetterRegisterEntry_yaml_component_1 = require("./LetterRegisterEntry.yaml?component");
exports.LetterRegisterEntry = LetterRegisterEntry_yaml_component_1.default;
var LetterRegisterEntryHeader_yaml_component_1 = require("./LetterRegisterEntryHeader.yaml?component");
exports.LetterRegisterEntryHeader = LetterRegisterEntryHeader_yaml_component_1.default;
