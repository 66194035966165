<template>
<ItpBox
  direction="column"
  alignItems="stretch"
  v-if="loaded"
>
  <ItpBox
    alignItems="center"
    class="pb-2"
  >
    <strong>
      {{ 'Invoice Lines'|pgettext('Invoice') }}
    </strong>
    <strong
      class="ml-1"
      v-if="invoiceLines.length"
    >
      ({{ invoiceLines.length }})
    </strong>
  </ItpBox>
  <ItpDataTable
    rowKey="id"
    tableId="invoiceLines"
    rowDetailsMode="alwaysVisible"
    :rowHeader="b_5962862420439144_rowHeader"
    :paging="b_5962862420439144_paging"
    :sortable="b_5962862420439144_sortable"
    :editable="!invoice.isReadOnly"
    :value="invoiceLines"
    :groups="invoiceLineGroups"
    groupKeyField="line_group_id"
    variant="details"
    columnHeaderStyle="cellHeader"
    :rowTemplate="b_5962862420439144_rowTemplate"
    :messages="b_5962862420439144_messages"
    :globs="{searchDataSource, messages}"
    :columns="b_5962862420439144_columns"
    class="h-100"
    @save-completed="evh_5962862420439144_saveCompleted($event, {})"
    @save="evh_5962862420439144_save($event, {})"
    @createProduct="evh_5962862420439144_createProduct($event, {})"
    @delete="evh_5962862420439144_delete($event, {})"
    @product:selected="evh_5962862420439144_productSelected($event, {})"
    @product:change="evh_5962862420439144_productChange($event, {})"
    @quantity:change="evh_5962862420439144_quantityChange($event, {})"
    @price:change="evh_5962862420439144_priceChange($event, {})"
    @discount:change="evh_5962862420439144_discountChange($event, {})"
    @net_amount:change="evh_5962862420439144_netAmountChange($event, {})"
    @amount:change="evh_5962862420439144_amountChange($event, {})"
    @tax_code:change="evh_5962862420439144_taxCodeChange($event, {})"
  >
    <template
      v-slot:rowGroupHeader="{ group, rows }"
    >
      <ItpBox>
        <UcInvoiceLineGroup
          :invoice="invoice"
          :group="group"
          @reload="evh_7789867021953041_reload($event, {group, rows})"
        >
        </UcInvoiceLineGroup>
      </ItpBox>
    </template>
    <template
      v-slot:afterLastRow="{ table }"
    >
      <ItpBox>
        <ItpLink
          :icon="$config.addIcon"
          size="sm"
          :text="'Add group...'|pgettext('InvoiceLineGroup')"
          class="ml-3"
          @click.prevent.stop="evh_309515315410323_clickPreventStop($event, {table})"
        >
        </ItpLink>
      </ItpBox>
    </template>
    <template
      v-slot:rowDetails="{ row }"
    >
      <div
        class="container-fluid"
      >
        <ItpRow>
          <ItpCol
            w="2"
          >
            <ItpText
              pre
              :text="row.notes"
              collapsible
              v-if="row.notes && !row.isEditing"
            >
            </ItpText>
            <ItpFormTextarea
              rows="3"
              name="notes"
              :placeholder="'Notes'|gettext"
              v-model="row.notes"
              :disabled="!row.isEditing"
              class="mb-2"
              v-if="row.isEditing"
            >
            </ItpFormTextarea>
            <ItpFormSelect2
              name="reference_invoice_id"
              :placeholder="'Reference invoice'|pgettext('InvoiceLine')"
              v-model="row.reference_invoice_id"
              :initialOptions="row.reference_invoice ? [row.reference_invoice] : []"
              :dataSource="b_1270943406604881_dataSource"
              valueField="id"
              clearable
              optionTemplate="<UcSuggestInvoiceOption :option=option></UcSuggestInvoiceOption>"
              use-option-template-for-selected-option
              v-if="row.isEditing"
            >
            </ItpFormSelect2>
          </ItpCol>
          <ItpCol
            w="10"
          >
            <ItpDataTable
              tableId="invoiceLineMeters"
              variant="basic"
              compact
              :paging="b_4717426290970457_paging"
              :sortable="b_4717426290970457_sortable"
              :rowHeader="b_4717426290970457_rowHeader"
              use-index-as-row-key
              :fillEmptyRowSpace="b_4717426290970457_fillEmptyRowSpace"
              :value="row.meter_accounting ? [row.meter_accounting] : []"
              :editable="!invoice.isReadOnly && !row.locked"
              :noCreateRow="!!row.meter_accounting"
              :globs="{ units, searchDataSource }"
              :messages="b_4717426290970457_messages"
              :rowTemplate="{
    invoice_id: invoice.id,
    invoice_line_id: row.id,
    from_date: null,
    from_amount: null,
    from_unit: null,
    to_date: null,
    to_amount: null,
    to_unit: null,
    billing_multiplier: 1
  }"
              :columns="b_4717426290970457_columns"
              class="bg-transparent datatable-basic mb-1"
              v-if="!row.isNew && row.isEditing || row.meter_accounting"
              @save="evh_4717426290970457_save($event, {row})"
              @save-completed="evh_4717426290970457_saveCompleted($event, {row})"
              @delete="evh_4717426290970457_delete($event, {row})"
              @delete-completed="evh_4717426290970457_deleteCompleted($event, {row})"
            >
            </ItpDataTable>
            <ItpDataTable
              tableId="invoiceLineAccounting"
              variant="basic"
              compact
              :paging="b_1803307708030275_paging"
              :sortable="b_1803307708030275_sortable"
              :rowHeader="b_1803307708030275_rowHeader"
              :fillEmptyRowSpace="b_1803307708030275_fillEmptyRowSpace"
              :value="row.accountings.data"
              :editable="!invoice.isReadOnly && !row.locked"
              :rowTemplate="schemaDefaults('CreateInvoiceLineAccounting', {
    invoice_id: invoice.id,
    invoice_line_id: row.id,
  })"
              :globs="{ searchDataSource }"
              :messages="b_1803307708030275_messages"
              :columns="b_1803307708030275_columns"
              class="bg-transparent datatable-basic mb-1"
              v-if="row.isEditing && !row.isNew || (row.accountings && row.accountings.data && row.accountings.data.length)"
              @save="evh_1803307708030275_save($event, {row})"
              @delete="evh_1803307708030275_delete($event, {row})"
              @save-completed="evh_1803307708030275_saveCompleted($event, {row})"
              @delete-completed="evh_1803307708030275_deleteCompleted($event, {row})"
            >
            </ItpDataTable>
            <ItpBox
              class="mb-3"
            >
              <DIV
                class="ml-auto"
                v-if="hasQuantityMismatch(row)"
              >
                <ItpIcon
                  icon="material-symbols:warning-rounded"
                  class="text-warning"
                >
                </ItpIcon>
                <ItpText
                  strong
                  :text="'Quantity mismatch.'|pgettext('invoiceLineAccounting')"
                >
                </ItpText>
              </DIV>
            </ItpBox>
          </ItpCol>
        </ItpRow>
      </div>
    </template>
  </ItpDataTable>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceLines extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  onProductSelected(...args: any[]) {
    let [row, product] = args;

    if (!product) {
      product = row.product;
    }

    if (_.isObject(product) && product.id == null) {
      product = product.name;
      row.product = product;
    }

    if (_.isString(product)) {
      row.description = product;
      row.tax_code = this.defaultTaxCode;
      const nearestRow = row.$$table.nearestRow(row);
      console.log('PR', nearestRow);
      if (nearestRow) {
        row.unit = nearestRow.unit;
        row.discount = nearestRow.discount;
      }
    }
    else {
      row.product = product;
      row.unit = product.unit;
      row.description = product.name;
      row.tax_code = this.defaultTaxCode || (
        this.invoice.invoice_kind === "outgoing"
          ? product.sales_vat_code
          : product.purchase_vat_code
      );
      row.intermediated_service = product.intermediated_service || false;
    }
    row.setFocus('quantity')
  }

  onProductChanged(...args: any[]) {
    console.log('onProductChanged', args)
  }

  calculateUnitPrice(...args: any[]) {
    const [row, tax, decimals] = args;

    row.price = Big(
      Big(row.net_amount) / Big(row.quantity) / (1 - Big(row.discount) / 100)
    ).round(decimals).toNumber()
  }

  calculateNetAmount(...args: any[]) {
    const [row, tax, decimals] = args;

    row.net_amount = Big(
      Big(row.quantity) * Big(row.price) * (1 - Big(row.discount) / 100)
    ).round(decimals).toNumber()
  }

  calculateNetAmountFromAmount(...args: any[]) {
    const [row, tax, decimals] = args;

    row.net_amount = Big(
      Big(row.amount) / (1 + Big(tax.rate) / 100)
    ).round(decimals).toNumber()
  }

  calculateAmount(...args: any[]) {
    const [row, tax, decimals] = args;

    row.amount = Big(
      Big(row.net_amount) * (1 + Big(tax.rate) / 100)
    ).round(decimals).toNumber()
  }

  recalculateLineItem(...args: any[]) {
    const data = args[0].data;
    const row = data.row;
    const decimals = this.currency.digits;
    const isIncomingInvoice = this.isIncomingInvoice
    const tx = _.find(this.taxCodes, { id: row.tax_code });

    if (row.quantity === 0) {
      return;
    }

    switch (data.field) {
      case 'quantity':
      case 'price':
      case 'discount': {
        this.calculateNetAmount(row, tx, decimals);
        this.calculateAmount(row, tx, decimals);
        break;
      }

      case 'net_amount': {
        this.calculateUnitPrice(row, tx, this.currency.price_digits);
        this.calculateAmount(row, tx, decimals);
        break;
      }

      case 'tax_code': {
        this.calculateAmount(row, tx, decimals);
        break;
      }

      case 'amount': {
        this.calculateNetAmountFromAmount(row, tx, decimals);
        this.calculateUnitPrice(row, tx, this.currency.price_digits);
        break;
      }
    }
  }

  hasQuantityMismatch(...args: any[]) {
    const row = args[0]
    return row.accountings
      && row.accountings.data
      && row.accountings.data.length
      && _.sumBy(row.accountings.data, p => p.quantity) != row.quantity
  }

  getInvoiceLineEditorIsDisabled(...args: any[]) {
    const [row] = args;
    return !row.tax_code
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    required: true,
    type: Object,
  })
  currency!: object;

  @Watch('currency')
  onCurrency(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("currency")
  }

  @Prop({
    type: Number,
  })
  defaultDiscount!: number;

  @Watch('defaultDiscount')
  onDefault_discount(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("defaultDiscount")
  }

  @Prop({
    type: String,
  })
  defaultTaxCode!: string;

  @Watch('defaultTaxCode')
  onDefault_tax_code(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("defaultTaxCode")
  }

  expenseTypes!: any;

  async $$load_expenseTypes() {
    return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } })
  }

  taxCodes!: any;

  async $$load_taxCodes() {
    return this.$fn.fetch('list_tax_code')
  }

  units!: any;

  async $$load_units() {
    return this.$fn.fetch('list_unit')
  }

  displayAddInvoiceLine!: any;
  messages!: any;
  ux!: any;
  b_5962862420439144_rowHeader!: any;
  b_5962862420439144_paging!: any;
  b_5962862420439144_sortable!: any;
  b_5962862420439144_rowTemplate!: any;

  async $$load_b_5962862420439144_rowTemplate() {
    return {
      description: null,
      discount: this.defaultDiscount || 0,
    }
  }

  b_5962862420439144_messages!: any;
  b_5962862420439144_columns!: any;

  async $$load_b_5962862420439144_columns() {
    return [{
      name: "product",
      header: this.$fn.gettext("Product"),
      width: "250px",
      required: true,
      textAlign: "left",
      editor: {
        name: "template",
        value: "<ItpSelect2\n\
  class=\"w-100\"\n\
  v-model=\"currentValue\"\n\
  bindObject\n\
  valueField=\"id\"\n\
  textField=\"name\"\n\
  required\n\
  :placeholder=\"messages.msgSearchProduct\"\n\
  :options=\"globs.searchDataSource('suggest_product')\"\n\
  :autofocus=autofocus\n\
  :form=\"formId\"\n\
  :inputId=\"col.field\"\n\
  :size=\"col.size\"\n\
  :state=\"validationState\"\n\
  :disabled=\"isDisabled\"\n\
  taggable\n\
  @optionSelected=\"col.emit('product:selected', {col, row, ...$event})\"\n\
  @change=\"onChange($event)\"\n\
>\n\
  <template #search=\"{attributes, events}\">\n\
    <input\n\
      class=\"vs__search\"\n\
      v-bind=\"attributes\"\n\
      v-on=\"events\"\n\
    />\n\
  </template>\n\
\n\
  <template #option=\"option\">\n\
    <UcSuggestProductOption :option=\"option\" v-if=\"option.object=='product'\">\n\
    </UcSuggestProductOption>\n\
    <span v-else>{{ option.name }}</span>\n\
  </template>\n\
\n\
  <template #selected-option=\"option\">\n\
    <UcSuggestProductOption :option=\"option\" v-if=\"option.object=='product'\">\n\
    </UcSuggestProductOption>\n\
    <span v-else>{{ option.name }}</span>\n\
  </template>\n\
\n\
  <template #list-footer>\n\
    <hr />\n\
    <ItpButton\n\
      class=\"ml-2\"\n\
      size=sm\n\
      variant=light\n\
      @click=\"col.emitCellEvent('createProduct', row)\">\n\
      {{ messages.msgCreateProduct }}\n\
    </ItpButton>\n\
  </template>\n\
\n\
  <template #footer>\n\
    <b-form-input\n\
      class=\"mt-1\"\n\
      type=text\n\
      v-model=row.description\n\
      id=\"description\"\n\
      name=\"description\"\n\
      autocomplete=\"off\"\n\
      :form=\"formId\"\n\
      :size=col.size\n\
      :disabled=\"isDisabled\"\n\
      :placeholder=\"globs.messages.invoiceLineDescriptionPlaceholder\"\n\
      @change=\"onFieldChange('description', row.description)\"\n\
    ></b-form-input>\n\
  </template>\n\
</ItpSelect2>\n\
",
      }
      ,
      formatter: {
        name: "template",
        value: "<div>\n\
  <b-link v-if=\"row.product\" :to=\"{\n\
    name: 'app.product',\n\
    params: { id: row.product.id }\n\
  }\">{{ row.description }}</b-link>\n\
  <span v-if=\"!row.product\">{{ row.description }}</span>\n\
</div>\n\
",
      }
      ,
    }
      ,
    {
      name: "quantity",
      type: "number",
      maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.quantity_digits,
      default: 0,
      header: this.$fn.pgettext("Quantity Abbr", "Qty"),
      headerTooltip: this.$fn.gettext("Quantity"),
      width: "60px",
      textAlign: "right",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "unit",
      header: this.$fn.pgettext("Unit Abbr", "Unit"),
      headerTooltip: this.$fn.gettext("Unit"),
      width: "70px",
      textAlign: "center",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "price",
      type: "number",
      maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.price_digits,
      default: 0,
      header: this.$fn.gettext("Price"),
      width: "70px",
      textAlign: "right",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "discount",
      type: "number",
      maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.digits,
      default: this.defaultDiscount || 0,
      header: this.$fn.pgettext("AbbrDiscount", "Disc"),
      headerTooltip: this.$fn.gettext("Discount"),
      width: "70px",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "net_amount",
      type: "number",
      maxAllowedDecimals: this.currency.digits,
      default: 0,
      header: this.$fn.gettext("Net"),
      width: "100px",
      textAlign: "right",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "amount",
      type: "number",
      maxAllowedDecimals: this.currency.digits,
      default: 0,
      header: this.$fn.gettext("Amount"),
      width: "100px",
      textAlign: "right",
      required: true,
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "tax_code",
      header: this.$fn.gettext("VAT"),
      headerTooltip: this.$fn.gettext("VAT Code"),
      width: "100px",
      type: "select",
      options: this.taxCodes,
      valueField: "id",
      textField: "id",
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "advance",
      header: this.$fn.pgettext("InvoiceLine|ColHeader", "Adv."),
      headerTooltip: this.$fn.pgettext("invoiceLine|ColHeader|Tooltip", "Advance"),
      width: "40px",
      type: "checkbox",
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    {
      name: "intermediated_service",
      header: this.$fn.pgettext("AbbrIntermediatedService", "IMS"),
      headerTooltip: this.$fn.gettext("Intermediated Service"),
      width: "30px",
      type: "checkbox",
      editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
    }
      ,
    ]
  }

  b_1270943406604881_dataSource!: any;

  async $$load_b_1270943406604881_dataSource() {
    return {
      name: "suggest_invoice",
      parameters: {
        invoice_kind: this.invoice.invoice_kind,
        client_id: this.invoice.client.id,
        company_id: this.invoice.company.id,
        exclude_invoice: this.invoice.id,
      }
      ,
    }
  }

  b_4717426290970457_paging!: any;
  b_4717426290970457_sortable!: any;
  b_4717426290970457_rowHeader!: any;
  b_4717426290970457_fillEmptyRowSpace!: any;
  b_4717426290970457_messages!: any;
  b_4717426290970457_columns!: any;

  async $$load_b_4717426290970457_columns() {
    return [{
      name: "meter",
      header: this.$fn.gettext("Meter"),
      width: "160px",
      required: true,
      type: "select2",
      options: this.$fn.searchDataSource('suggest_meter'),
      bindObject: true,
      valueField: "id",
      textField: "serial_number",
      formatter: {
        name: "template",
        value: "<UcMeterMiniCard :item=\"row.meter\" link-style-underline no-strong></UcMeterMiniCard>\n\
",
      }
      ,
    }
      ,
    {
      name: "from",
      header: this.$fn.gettext("Previous reading"),
      width: "160px",
      formatter: {
        name: "template",
        value: "<div>{{ row.from_date|date }} {{ row.from_amount|number }} {{ row.from_unit }}</div>\n\
",
      }
      ,
      editor: {
        name: "template",
        value: "<div class=\"input-group\">\n\
  <ItpFormDatetime\n\
    v-model=\"row.from_date\"\n\
    name=\"from_date\"\n\
    placeholder=\"date\"\n\
    size=\"sm\"\n\
    style=\"min-width:100px;\"\n\
    required\n\
  ></ItpFormDatetime>\n\
\n\
  <ItpFormInput\n\
    type=\"number\"\n\
    name=\"from_amount\"\n\
    v-model=\"row.from_amount\"\n\
    placeholder=\"reading\"\n\
    size=\"sm\"\n\
    style=\"min-width:60px;\"\n\
    required\n\
  ></ItpFormInput>\n\
\n\
  <ItpFormSelect\n\
    v-model=\"row.from_unit\"\n\
    name=\"from_unit\"\n\
    placeholder=\"unit\"\n\
    size=\"sm\"\n\
    :options=\"globs.units\"\n\
    style=\"min-width:60px;\"\n\
    valueField=\"symbol\"\n\
    textField=\"symbol\"\n\
    required\n\
  ></ItpFormSelect>\n\
</div>\n\
",
      }
      ,
    }
      ,
    {
      name: "to",
      header: this.$fn.gettext("Current reading"),
      width: "240px",
      formatter: {
        name: "template",
        value: "<div>{{ row.to_date|date }} {{ row.to_amount|number }} {{ row.to_unit }}</div>\n\
",
      }
      ,
      editor: {
        name: "template",
        value: "<div class=\"input-group\">\n\
  <ItpFormDatetime\n\
    v-model=\"row.to_date\"\n\
    name=\"to_date\"\n\
    placeholder=\"date\"\n\
    size=\"sm\"\n\
    style=\"min-width:100px;\"\n\
    required\n\
  ></ItpFormDatetime>\n\
\n\
  <ItpFormInput\n\
    type=\"number\"\n\
    name=\"to_amount\"\n\
    v-model=\"row.to_amount\"\n\
    placeholder=\"reading\"\n\
    size=\"sm\"\n\
    style=\"min-width:60px;\"\n\
    required\n\
  ></ItpFormInput>\n\
\n\
  <ItpFormSelect\n\
    v-model=\"row.to_unit\"\n\
    name=\"to_unit\"\n\
    placeholder=\"unit\"\n\
    size=\"sm\"\n\
    :options=\"globs.units\"\n\
    style=\"min-width:60px;\"\n\
    valueField=\"symbol\"\n\
    textField=\"symbol\"\n\
    required\n\
  ></ItpFormSelect>\n\
</div>\n\
",
      }
      ,
    }
      ,
    {
      name: "consumption",
      header: this.$fn.gettext("Consumption"),
      readonly: true,
      formatter: {
        name: "template",
        value: "<div>{{ row.consumption_amount|number }} {{ row.consumption_unit }}</div>\n\
",
      }
      ,
    }
      ,
    {
      name: "billing_multiplier",
      header: this.$fn.gettext("Multiplier"),
      default: 1,
      width: "70px",
      type: "number",
      required: true,
    }
      ,
    ]
  }

  b_1803307708030275_paging!: any;
  b_1803307708030275_sortable!: any;
  b_1803307708030275_rowHeader!: any;
  b_1803307708030275_fillEmptyRowSpace!: any;
  b_1803307708030275_messages!: any;
  b_1803307708030275_columns!: any;

  async $$load_b_1803307708030275_columns() {
    return [{
      name: "expense_type",
      type: "select",
      header: this.$fn.gettext("Expense Type"),
      width: "100px",
      options: this.expenseTypes,
      valueField: "name",
      textField: "name",
    }
      ,
    {
      name: "rental",
      type: "select2",
      header: this.$fn.gettext("Rental"),
      width: "100px",
      path: "name",
      bindObject: true,
      textField: "name",
      options: this.$fn.searchDataSource('suggest_rental'),
      useOptionTemplateForSelectedOption: true,
      optionTemplate: "<UcSuggestRentalOption :option=\"option\"></UcSuggestRentalOption>",
    }
      ,
    {
      name: "property",
      type: "select2",
      header: this.$fn.gettext("Property"),
      width: "100px",
      path: "name",
      bindObject: true,
      textField: "name",
      options: this.$fn.searchDataSource('suggest_property'),
      useOptionTemplateForSelectedOption: true,
      optionTemplate: "<UcSuggestPropertyOption :option=\"option\"></UcSuggestPropertyOption>",
    }
      ,
    {
      name: "contractor",
      type: "select2",
      header: this.$fn.gettext("Contractor"),
      width: "100px",
      path: "name",
      bindObject: true,
      textField: "name",
      options: this.$fn.searchDataSource('suggest_client', { 'is-contractor': true }),
    }
      ,
    {
      name: "quantity",
      type: "number",
      header: this.$fn.gettext("Quantity"),
      width: "100px",
    }
      ,
    ]
  }

  dataMembers = ['expenseTypes', 'taxCodes', 'units', 'displayAddInvoiceLine', 'messages', 'ux', 'b_5962862420439144_rowHeader', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_rowTemplate', 'b_5962862420439144_messages', 'b_5962862420439144_columns', 'b_1270943406604881_dataSource', 'b_4717426290970457_paging', 'b_4717426290970457_sortable', 'b_4717426290970457_rowHeader', 'b_4717426290970457_fillEmptyRowSpace', 'b_4717426290970457_messages', 'b_4717426290970457_columns', 'b_1803307708030275_paging', 'b_1803307708030275_sortable', 'b_1803307708030275_rowHeader', 'b_1803307708030275_fillEmptyRowSpace', 'b_1803307708030275_messages', 'b_1803307708030275_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        expenseTypes: null,
        taxCodes: null,
        units: null,
        displayAddInvoiceLine: null,
        messages: {
          invoiceLineProductSearchPlaceholder: this.$fn.pgettext("InvoiceLines", "Search product/service..."),
          invoiceLineDescriptionPlaceholder: this.$fn.pgettext("InvoiceLines", "Description"),
        }
        ,
        ux: null,
        b_5962862420439144_rowHeader: false,
        b_5962862420439144_paging: false,
        b_5962862420439144_sortable: false,
        b_5962862420439144_rowTemplate: null,
        b_5962862420439144_messages: {
          msgCreateRow: this.$fn.pgettext("InvoiceLines", "Add line"),
          msgCreateProduct: this.$fn.pgettext("InvoiceLines", "Add new product..."),
          msgSearchProduct: this.$fn.pgettext("invoiceLines", "Search for product/service..."),
        }
        ,
        b_5962862420439144_columns: null,
        b_1270943406604881_dataSource: null,
        b_4717426290970457_paging: false,
        b_4717426290970457_sortable: false,
        b_4717426290970457_rowHeader: false,
        b_4717426290970457_fillEmptyRowSpace: false,
        b_4717426290970457_messages: {
          msgCreateRow: this.$fn.pgettext("invoiceLines", "New meter row"),
        }
        ,
        b_4717426290970457_columns: null,
        b_1803307708030275_paging: false,
        b_1803307708030275_sortable: false,
        b_1803307708030275_rowHeader: false,
        b_1803307708030275_fillEmptyRowSpace: false,
        b_1803307708030275_messages: {
          msgCreateRow: this.$fn.pgettext("invoiceLines", "New accounting row"),
        }
        ,
        b_1803307708030275_columns: null,
      },
    }
  }

  get invoiceLines() {
    if (this.loaded) {

      return this.invoice.lines.data
    }
    return null;
  }

  get invoiceLineGroups() {
    if (this.loaded) {

      return this.invoice.line_groups.data
    }
    return null;
  }

  get isIncomingInvoice() {
    if (this.loaded) {

      return this.invoice.invoice_kind == 'incoming'
    }
    return null;
  }

  async act_5962862420439144_emit_4630_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5962862420439144_emit_4630, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_5962862420439144_saveCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4632_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4632, alias=undefined
    this.invoice.id
      ? $event.data.waitFor(this.$fn.crud('invoice_line').upsert({ ...$event.data.row, invoice_id: this.invoice.id }))
      : this.invoiceLines.splice(this.invoiceLines.length - 1, 0, $event.data.row)
  }

  async act_5962862420439144_script_4632_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4632, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4632_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_save.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4636_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4636, alias=undefined
    this.onProductSelected($event.parent.data.row, $event.data.modalResult.value)
  }

  async act_5962862420439144_script_4636_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4636, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4636_getActionArgs_value($event),
    }
  }

  async act_5962862420439144_showModal_4634_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5962862420439144_showModal_4634, alias=undefined
    return {
      name: "CreateProduct",
      props: {
        preventDefaultOnClose: true,
      }
      ,
    }
  }

  async evh_5962862420439144_createProduct(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_createProduct.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4638_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4638, alias=undefined
    $event.data.waitFor(this.$fn.crud('invoice_line').delete($event.data.row))
  }

  async act_5962862420439144_script_4638_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4638, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4638_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4640_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4640, alias=undefined
    this.onProductSelected($event.data.row, $event.data.option)
  }

  async act_5962862420439144_script_4640_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4640, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4640_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_productSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_productSelected.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4642_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4642, alias=undefined
    this.onProductChanged($event)
  }

  async act_5962862420439144_script_4642_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4642, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4642_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_productChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_productChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4644_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4644, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_4644_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4644, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4644_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_quantityChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_quantityChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4646_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4646, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_4646_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4646, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4646_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_priceChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_priceChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4648_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4648, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_4648_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4648, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4648_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_discountChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_discountChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4650_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4650, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_4650_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4650, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4650_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_netAmountChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_netAmountChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4652_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4652, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_4652_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4652, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4652_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_amountChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_amountChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_4654_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_4654, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_4654_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_4654, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_4654_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_taxCodeChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_taxCodeChange.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_emit_4656_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7789867021953041_emit_4656, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7789867021953041_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_reload.executeFromDOM(this, event, scope);
  }

  async act_309515315410323_emit_4660_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_309515315410323_emit_4660, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_309515315410323_close_4659_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_309515315410323_close_4659, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_309515315410323_showModal_4658_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_309515315410323_showModal_4658, alias=undefined
    return {
      name: "InvoiceLineGroup",
      props: {
        invoice: this.invoice,
      }
      ,
    }
  }

  async evh_309515315410323_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_309515315410323_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_4717426290970457_script_4662_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_4717426290970457_script_4662, alias=undefined
    $event.data.waitFor(
      this.$fn.crud('invoice_line')
        .upsert({
          id: $event.scope.row.id,
          etag: $event.scope.row.etag,
          meter_accounting: $event.data.row,
        })
    )
  }

  async act_4717426290970457_script_4662_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_4717426290970457_script_4662, alias=undefined
    return {
      value: () => this.act_4717426290970457_script_4662_getActionArgs_value($event),
    }
  }

  async evh_4717426290970457_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4717426290970457_save.executeFromDOM(this, event, scope);
  }

  async act_4717426290970457_emit_4664_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4717426290970457_emit_4664, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_4717426290970457_saveCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4717426290970457_saveCompleted.executeFromDOM(this, event, scope);
  }

  async act_4717426290970457_script_4666_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_4717426290970457_script_4666, alias=undefined
    $event.data.waitFor(this.$fn.crud('invoice_line_meter_accounting').delete($event.data.row))
  }

  async act_4717426290970457_script_4666_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_4717426290970457_script_4666, alias=undefined
    return {
      value: () => this.act_4717426290970457_script_4666_getActionArgs_value($event),
    }
  }

  async evh_4717426290970457_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4717426290970457_delete.executeFromDOM(this, event, scope);
  }

  async act_4717426290970457_emit_4668_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4717426290970457_emit_4668, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_4717426290970457_deleteCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4717426290970457_deleteCompleted.executeFromDOM(this, event, scope);
  }

  async act_1803307708030275_script_4670_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1803307708030275_script_4670, alias=undefined
    $event.data.waitFor(
      this.$fn.crud('invoice_line_accounting').upsert($event.data.row)
        .then(p => (this.$emit('reload'), p))
    )
  }

  async act_1803307708030275_script_4670_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1803307708030275_script_4670, alias=undefined
    return {
      value: () => this.act_1803307708030275_script_4670_getActionArgs_value($event),
    }
  }

  async evh_1803307708030275_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1803307708030275_save.executeFromDOM(this, event, scope);
  }

  async act_1803307708030275_script_4672_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1803307708030275_script_4672, alias=undefined
    $event.data.waitFor(this.$fn.crud('invoice_line_accounting').delete($event.data.row))
  }

  async act_1803307708030275_script_4672_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1803307708030275_script_4672, alias=undefined
    return {
      value: () => this.act_1803307708030275_script_4672_getActionArgs_value($event),
    }
  }

  async evh_1803307708030275_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1803307708030275_delete.executeFromDOM(this, event, scope);
  }

  async act_1803307708030275_emit_4674_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1803307708030275_emit_4674, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_1803307708030275_saveCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1803307708030275_saveCompleted.executeFromDOM(this, event, scope);
  }

  async act_1803307708030275_emit_4676_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1803307708030275_emit_4676, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_1803307708030275_deleteCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1803307708030275_deleteCompleted.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_5962862420439144_emit_4630: actions.EmitAction;
    evh_5962862420439144_saveCompleted_4629: actions.EventHandlerImpl;
    evh_5962862420439144_saveCompleted: actions.EventHandlerGroup;
    act_5962862420439144_script_4632: actions.ScriptAction;
    evh_5962862420439144_save_4631: actions.EventHandlerImpl;
    evh_5962862420439144_save: actions.EventHandlerGroup;
    act_5962862420439144_script_4636: actions.ScriptAction;
    evh_5962862420439144_close_4635: actions.EventHandlerImpl;
    act_5962862420439144_showModal_4634: actions.ShowModalAction;
    evh_5962862420439144_createProduct_4633: actions.EventHandlerImpl;
    evh_5962862420439144_createProduct: actions.EventHandlerGroup;
    act_5962862420439144_script_4638: actions.ScriptAction;
    evh_5962862420439144_delete_4637: actions.EventHandlerImpl;
    evh_5962862420439144_delete: actions.EventHandlerGroup;
    act_5962862420439144_script_4640: actions.ScriptAction;
    evh_5962862420439144_productSelected_4639: actions.EventHandlerImpl;
    evh_5962862420439144_productSelected: actions.EventHandlerGroup;
    act_5962862420439144_script_4642: actions.ScriptAction;
    evh_5962862420439144_productChange_4641: actions.EventHandlerImpl;
    evh_5962862420439144_productChange: actions.EventHandlerGroup;
    act_5962862420439144_script_4644: actions.ScriptAction;
    evh_5962862420439144_quantityChange_4643: actions.EventHandlerImpl;
    evh_5962862420439144_quantityChange: actions.EventHandlerGroup;
    act_5962862420439144_script_4646: actions.ScriptAction;
    evh_5962862420439144_priceChange_4645: actions.EventHandlerImpl;
    evh_5962862420439144_priceChange: actions.EventHandlerGroup;
    act_5962862420439144_script_4648: actions.ScriptAction;
    evh_5962862420439144_discountChange_4647: actions.EventHandlerImpl;
    evh_5962862420439144_discountChange: actions.EventHandlerGroup;
    act_5962862420439144_script_4650: actions.ScriptAction;
    evh_5962862420439144_netAmountChange_4649: actions.EventHandlerImpl;
    evh_5962862420439144_netAmountChange: actions.EventHandlerGroup;
    act_5962862420439144_script_4652: actions.ScriptAction;
    evh_5962862420439144_amountChange_4651: actions.EventHandlerImpl;
    evh_5962862420439144_amountChange: actions.EventHandlerGroup;
    act_5962862420439144_script_4654: actions.ScriptAction;
    evh_5962862420439144_taxCodeChange_4653: actions.EventHandlerImpl;
    evh_5962862420439144_taxCodeChange: actions.EventHandlerGroup;
    act_7789867021953041_emit_4656: actions.EmitAction;
    evh_7789867021953041_reload_4655: actions.EventHandlerImpl;
    evh_7789867021953041_reload: actions.EventHandlerGroup;
    act_309515315410323_emit_4660: actions.EmitAction;
    evh_309515315410323_close_4659: actions.EventHandlerImpl;
    act_309515315410323_showModal_4658: actions.ShowModalAction;
    evh_309515315410323_clickPreventStop_4657: actions.EventHandlerImpl;
    evh_309515315410323_clickPreventStop: actions.EventHandlerGroup;
    act_4717426290970457_script_4662: actions.ScriptAction;
    evh_4717426290970457_save_4661: actions.EventHandlerImpl;
    evh_4717426290970457_save: actions.EventHandlerGroup;
    act_4717426290970457_emit_4664: actions.EmitAction;
    evh_4717426290970457_saveCompleted_4663: actions.EventHandlerImpl;
    evh_4717426290970457_saveCompleted: actions.EventHandlerGroup;
    act_4717426290970457_script_4666: actions.ScriptAction;
    evh_4717426290970457_delete_4665: actions.EventHandlerImpl;
    evh_4717426290970457_delete: actions.EventHandlerGroup;
    act_4717426290970457_emit_4668: actions.EmitAction;
    evh_4717426290970457_deleteCompleted_4667: actions.EventHandlerImpl;
    evh_4717426290970457_deleteCompleted: actions.EventHandlerGroup;
    act_1803307708030275_script_4670: actions.ScriptAction;
    evh_1803307708030275_save_4669: actions.EventHandlerImpl;
    evh_1803307708030275_save: actions.EventHandlerGroup;
    act_1803307708030275_script_4672: actions.ScriptAction;
    evh_1803307708030275_delete_4671: actions.EventHandlerImpl;
    evh_1803307708030275_delete: actions.EventHandlerGroup;
    act_1803307708030275_emit_4674: actions.EmitAction;
    evh_1803307708030275_saveCompleted_4673: actions.EventHandlerImpl;
    evh_1803307708030275_saveCompleted: actions.EventHandlerGroup;
    act_1803307708030275_emit_4676: actions.EmitAction;
    evh_1803307708030275_deleteCompleted_4675: actions.EventHandlerImpl;
    evh_1803307708030275_deleteCompleted: actions.EventHandlerGroup;
  }

  getActions() {
    const act_5962862420439144_emit_4630 = new actions.EmitAction(
      {
        actionArgs: this.act_5962862420439144_emit_4630_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_saveCompleted_4629 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_emit_4630,
        event: "save-completed",
        displayName: "emit",
      }
    );
    const evh_5962862420439144_saveCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_saveCompleted_4629],
      }
    );
    const act_5962862420439144_script_4632 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4632_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_save_4631 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4632,
        event: "save",
        displayName: "script",
      }
    );
    const evh_5962862420439144_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_save_4631],
      }
    );
    const act_5962862420439144_script_4636 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4636_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_close_4635 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4636,
        event: "close",
        displayName: "script",
      }
    );
    const act_5962862420439144_showModal_4634 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5962862420439144_showModal_4634_getActionArgs,
        events: [evh_5962862420439144_close_4635],
      }
    );
    const evh_5962862420439144_createProduct_4633 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_showModal_4634,
        event: "createProduct",
        displayName: "showModal",
      }
    );
    const evh_5962862420439144_createProduct = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_createProduct_4633],
      }
    );
    const act_5962862420439144_script_4638 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4638_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_delete_4637 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4638,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_5962862420439144_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_delete_4637],
      }
    );
    const act_5962862420439144_script_4640 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4640_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_productSelected_4639 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4640,
        event: "product:selected",
        displayName: "script",
      }
    );
    const evh_5962862420439144_productSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_productSelected_4639],
      }
    );
    const act_5962862420439144_script_4642 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4642_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_productChange_4641 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4642,
        event: "product:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_productChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_productChange_4641],
      }
    );
    const act_5962862420439144_script_4644 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4644_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_quantityChange_4643 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4644,
        event: "quantity:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_quantityChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_quantityChange_4643],
      }
    );
    const act_5962862420439144_script_4646 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4646_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_priceChange_4645 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4646,
        event: "price:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_priceChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_priceChange_4645],
      }
    );
    const act_5962862420439144_script_4648 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4648_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_discountChange_4647 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4648,
        event: "discount:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_discountChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_discountChange_4647],
      }
    );
    const act_5962862420439144_script_4650 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4650_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_netAmountChange_4649 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4650,
        event: "net_amount:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_netAmountChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_netAmountChange_4649],
      }
    );
    const act_5962862420439144_script_4652 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4652_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_amountChange_4651 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4652,
        event: "amount:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_amountChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_amountChange_4651],
      }
    );
    const act_5962862420439144_script_4654 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_4654_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_taxCodeChange_4653 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_4654,
        event: "tax_code:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_taxCodeChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_taxCodeChange_4653],
      }
    );
    const act_7789867021953041_emit_4656 = new actions.EmitAction(
      {
        actionArgs: this.act_7789867021953041_emit_4656_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_reload_4655 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_emit_4656,
        event: "reload",
        displayName: "emit",
      }
    );
    const evh_7789867021953041_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_reload_4655],
      }
    );
    const act_309515315410323_emit_4660 = new actions.EmitAction(
      {
        actionArgs: this.act_309515315410323_emit_4660_getActionArgs,
        events: [],
      }
    );
    const evh_309515315410323_close_4659 = new actions.EventHandlerImpl(
      {
        action: act_309515315410323_emit_4660,
        event: "close",
        when: this.evh_309515315410323_close_4659_getWhen,
        displayName: "emit",
      }
    );
    const act_309515315410323_showModal_4658 = new actions.ShowModalAction(
      {
        actionArgs: this.act_309515315410323_showModal_4658_getActionArgs,
        events: [evh_309515315410323_close_4659],
      }
    );
    const evh_309515315410323_clickPreventStop_4657 = new actions.EventHandlerImpl(
      {
        action: act_309515315410323_showModal_4658,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_309515315410323_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_309515315410323_clickPreventStop_4657],
      }
    );
    const act_4717426290970457_script_4662 = new actions.ScriptAction(
      {
        actionArgs: this.act_4717426290970457_script_4662_getActionArgs,
        events: [],
      }
    );
    const evh_4717426290970457_save_4661 = new actions.EventHandlerImpl(
      {
        action: act_4717426290970457_script_4662,
        event: "save",
        displayName: "script",
      }
    );
    const evh_4717426290970457_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_4717426290970457_save_4661],
      }
    );
    const act_4717426290970457_emit_4664 = new actions.EmitAction(
      {
        actionArgs: this.act_4717426290970457_emit_4664_getActionArgs,
        events: [],
      }
    );
    const evh_4717426290970457_saveCompleted_4663 = new actions.EventHandlerImpl(
      {
        action: act_4717426290970457_emit_4664,
        event: "save-completed",
        displayName: "emit",
      }
    );
    const evh_4717426290970457_saveCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_4717426290970457_saveCompleted_4663],
      }
    );
    const act_4717426290970457_script_4666 = new actions.ScriptAction(
      {
        actionArgs: this.act_4717426290970457_script_4666_getActionArgs,
        events: [],
      }
    );
    const evh_4717426290970457_delete_4665 = new actions.EventHandlerImpl(
      {
        action: act_4717426290970457_script_4666,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_4717426290970457_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_4717426290970457_delete_4665],
      }
    );
    const act_4717426290970457_emit_4668 = new actions.EmitAction(
      {
        actionArgs: this.act_4717426290970457_emit_4668_getActionArgs,
        events: [],
      }
    );
    const evh_4717426290970457_deleteCompleted_4667 = new actions.EventHandlerImpl(
      {
        action: act_4717426290970457_emit_4668,
        event: "delete-completed",
        displayName: "emit",
      }
    );
    const evh_4717426290970457_deleteCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_4717426290970457_deleteCompleted_4667],
      }
    );
    const act_1803307708030275_script_4670 = new actions.ScriptAction(
      {
        actionArgs: this.act_1803307708030275_script_4670_getActionArgs,
        events: [],
      }
    );
    const evh_1803307708030275_save_4669 = new actions.EventHandlerImpl(
      {
        action: act_1803307708030275_script_4670,
        event: "save",
        displayName: "script",
      }
    );
    const evh_1803307708030275_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_1803307708030275_save_4669],
      }
    );
    const act_1803307708030275_script_4672 = new actions.ScriptAction(
      {
        actionArgs: this.act_1803307708030275_script_4672_getActionArgs,
        events: [],
      }
    );
    const evh_1803307708030275_delete_4671 = new actions.EventHandlerImpl(
      {
        action: act_1803307708030275_script_4672,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_1803307708030275_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_1803307708030275_delete_4671],
      }
    );
    const act_1803307708030275_emit_4674 = new actions.EmitAction(
      {
        actionArgs: this.act_1803307708030275_emit_4674_getActionArgs,
        events: [],
      }
    );
    const evh_1803307708030275_saveCompleted_4673 = new actions.EventHandlerImpl(
      {
        action: act_1803307708030275_emit_4674,
        event: "save-completed",
        displayName: "emit",
      }
    );
    const evh_1803307708030275_saveCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_1803307708030275_saveCompleted_4673],
      }
    );
    const act_1803307708030275_emit_4676 = new actions.EmitAction(
      {
        actionArgs: this.act_1803307708030275_emit_4676_getActionArgs,
        events: [],
      }
    );
    const evh_1803307708030275_deleteCompleted_4675 = new actions.EventHandlerImpl(
      {
        action: act_1803307708030275_emit_4676,
        event: "delete-completed",
        displayName: "emit",
      }
    );
    const evh_1803307708030275_deleteCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_1803307708030275_deleteCompleted_4675],
      }
    );
    return {
      act_5962862420439144_emit_4630,
      evh_5962862420439144_saveCompleted_4629,
      evh_5962862420439144_saveCompleted,
      act_5962862420439144_script_4632,
      evh_5962862420439144_save_4631,
      evh_5962862420439144_save,
      act_5962862420439144_script_4636,
      evh_5962862420439144_close_4635,
      act_5962862420439144_showModal_4634,
      evh_5962862420439144_createProduct_4633,
      evh_5962862420439144_createProduct,
      act_5962862420439144_script_4638,
      evh_5962862420439144_delete_4637,
      evh_5962862420439144_delete,
      act_5962862420439144_script_4640,
      evh_5962862420439144_productSelected_4639,
      evh_5962862420439144_productSelected,
      act_5962862420439144_script_4642,
      evh_5962862420439144_productChange_4641,
      evh_5962862420439144_productChange,
      act_5962862420439144_script_4644,
      evh_5962862420439144_quantityChange_4643,
      evh_5962862420439144_quantityChange,
      act_5962862420439144_script_4646,
      evh_5962862420439144_priceChange_4645,
      evh_5962862420439144_priceChange,
      act_5962862420439144_script_4648,
      evh_5962862420439144_discountChange_4647,
      evh_5962862420439144_discountChange,
      act_5962862420439144_script_4650,
      evh_5962862420439144_netAmountChange_4649,
      evh_5962862420439144_netAmountChange,
      act_5962862420439144_script_4652,
      evh_5962862420439144_amountChange_4651,
      evh_5962862420439144_amountChange,
      act_5962862420439144_script_4654,
      evh_5962862420439144_taxCodeChange_4653,
      evh_5962862420439144_taxCodeChange,
      act_7789867021953041_emit_4656,
      evh_7789867021953041_reload_4655,
      evh_7789867021953041_reload,
      act_309515315410323_emit_4660,
      evh_309515315410323_close_4659,
      act_309515315410323_showModal_4658,
      evh_309515315410323_clickPreventStop_4657,
      evh_309515315410323_clickPreventStop,
      act_4717426290970457_script_4662,
      evh_4717426290970457_save_4661,
      evh_4717426290970457_save,
      act_4717426290970457_emit_4664,
      evh_4717426290970457_saveCompleted_4663,
      evh_4717426290970457_saveCompleted,
      act_4717426290970457_script_4666,
      evh_4717426290970457_delete_4665,
      evh_4717426290970457_delete,
      act_4717426290970457_emit_4668,
      evh_4717426290970457_deleteCompleted_4667,
      evh_4717426290970457_deleteCompleted,
      act_1803307708030275_script_4670,
      evh_1803307708030275_save_4669,
      evh_1803307708030275_save,
      act_1803307708030275_script_4672,
      evh_1803307708030275_delete_4671,
      evh_1803307708030275_delete,
      act_1803307708030275_emit_4674,
      evh_1803307708030275_saveCompleted_4673,
      evh_1803307708030275_saveCompleted,
      act_1803307708030275_emit_4676,
      evh_1803307708030275_deleteCompleted_4675,
      evh_1803307708030275_deleteCompleted,
    }
  }
}

Vue.component("UcInvoiceLines", UcInvoiceLines);

</script>