"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var UcPaymentInvoices = (function (_super) {
    __extends(UcPaymentInvoices, _super);
    function UcPaymentInvoices() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['ux', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_columns'];
        return _this;
    }
    UcPaymentInvoices.prototype.beforeCreate = function () {
    };
    UcPaymentInvoices.prototype.createInvoicePaymentData = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 1), data = _a[0];
        return {
            invoice_id: data.id,
            invoice_number: data.invoice_number,
            currency: data.currency,
            amount: data.unpaid,
            exchange_rate: 1,
            client: data.client,
            invoice: data
        };
    };
    UcPaymentInvoices.prototype.onPayment = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payment");
    };
    UcPaymentInvoices.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
            b_5962862420439144_paging: false,
            b_5962862420439144_sortable: false,
            b_5962862420439144_columns: [{
                    name: "invoice_number",
                    width: "210px",
                    header: this.$fn.pgettext("InvoicePayment", "Invoice"),
                    readonly: true,
                    textAlign: "left",
                    type: "template",
                    value: "<DIV>\n\
  <strong>{{ row.invoice.invoice_number }}</strong><br/>\n\
  {{ row.invoice.invoice_date || date }}<br/>\n\
  <!--UcInvoiceKindBadge :value=\"row.invoice_kind\"></UcInvoiceKindBadge><br/-->\n\
  <UcClientMiniCard :item=\"row.invoice.client\" no-link></UcClientMiniCard>\n\
</DIV>\n\
",
                },
                {
                    name: "amount",
                    header: this.$fn.pgettext("InvoicePayment", "Amount"),
                    type: "number",
                    width: "110px",
                    textAlign: "right",
                    required: true,
                },
                {
                    name: "exchange_rate",
                    width: "70px",
                    header: this.$fn.pgettext("InvoicePayment", "Exch. Rate"),
                    type: "number",
                    textAlign: "right",
                    required: true,
                },
                {
                    name: "notes",
                    width: "210px",
                    header: this.$fn.pgettext("InvoicePayment", "Notes"),
                },
            ],
        });
    };
    UcPaymentInvoices.prototype.evh_7315092382398562_reload = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcPaymentInvoices.prototype.act_3343864469950044_script_5296_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                _.chain($event.data.modalResult.value)
                    .map(this.createInvoicePaymentData)
                    .filter(function (p) { return !_.some(_this.payment.invoices.data, { invoice_id: p.invoice_id }); })
                    .each(function (p) { return _this.payment.invoices.data.push(p); })
                    .value();
                return [2];
            });
        });
    };
    UcPaymentInvoices.prototype.act_3343864469950044_script_5296_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_3343864469950044_script_5296_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcPaymentInvoices.prototype.evh_3343864469950044_close_5295_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.modalResult.ok];
            });
        });
    };
    UcPaymentInvoices.prototype.act_3343864469950044_emit_5298_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "recalculate",
                    }];
            });
        });
    };
    UcPaymentInvoices.prototype.evh_3343864469950044_close_5297_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.modalResult.ok];
            });
        });
    };
    UcPaymentInvoices.prototype.act_3343864469950044_showModal_5294_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "InvoiceBrowser",
                        props: {
                            size: "full-screen",
                            company_id: this.payment.account && this.payment.account.owner_id,
                            client_id: this.payment.client && this.payment.client.id,
                            multiselect: true,
                        },
                    }];
            });
        });
    };
    UcPaymentInvoices.prototype.evh_3343864469950044_click = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3343864469950044_click.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcPaymentInvoices.prototype.act_5962862420439144_emit_5300_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "recalculate",
                        value: $event.data.result,
                    }];
            });
        });
    };
    UcPaymentInvoices.prototype.evh_5962862420439144_saveCompleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcPaymentInvoices.prototype.act_5962862420439144_script_5302_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                _.remove(this.payment.invoices.data, { invoice_id: $event.data.row.invoice_id });
                return [2];
            });
        });
    };
    UcPaymentInvoices.prototype.act_5962862420439144_script_5302_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_5302_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcPaymentInvoices.prototype.evh_5962862420439144_delete = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcPaymentInvoices.prototype.act_5962862420439144_emit_5304_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "recalculate",
                    }];
            });
        });
    };
    UcPaymentInvoices.prototype.evh_5962862420439144_deleteCompleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_deleteCompleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcPaymentInvoices.prototype.getActions = function () {
        var act_7315092382398562_reloadComponentData_5292 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_7315092382398562_reload_5291 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5292,
            event: "reload",
            displayName: "reloadComponentData",
        });
        var evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5291],
        });
        var act_3343864469950044_script_5296 = new core_1.actions.ScriptAction({
            actionArgs: this.act_3343864469950044_script_5296_getActionArgs,
            events: [],
        });
        var evh_3343864469950044_close_5295 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_script_5296,
            event: "close",
            when: this.evh_3343864469950044_close_5295_getWhen,
            displayName: "script",
        });
        var act_3343864469950044_emit_5298 = new core_1.actions.EmitAction({
            actionArgs: this.act_3343864469950044_emit_5298_getActionArgs,
            events: [],
        });
        var evh_3343864469950044_close_5297 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_emit_5298,
            event: "close",
            when: this.evh_3343864469950044_close_5297_getWhen,
            displayName: "emit",
        });
        var act_3343864469950044_showModal_5294 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3343864469950044_showModal_5294_getActionArgs,
            displayName: "addInvoice",
            events: [evh_3343864469950044_close_5295, evh_3343864469950044_close_5297],
        });
        var evh_3343864469950044_click_5293 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_showModal_5294,
            event: "click",
            displayName: "addInvoice",
        });
        var evh_3343864469950044_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_click_5293],
        });
        var act_5962862420439144_emit_5300 = new core_1.actions.EmitAction({
            actionArgs: this.act_5962862420439144_emit_5300_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_saveCompleted_5299 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_emit_5300,
            event: "save-completed",
            displayName: "emit",
        });
        var evh_5962862420439144_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_saveCompleted_5299],
        });
        var act_5962862420439144_script_5302 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5302_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_delete_5301 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5302,
            event: "delete",
            displayName: "script",
        });
        var evh_5962862420439144_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_delete_5301],
        });
        var act_5962862420439144_emit_5304 = new core_1.actions.EmitAction({
            actionArgs: this.act_5962862420439144_emit_5304_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_deleteCompleted_5303 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_emit_5304,
            event: "delete-completed",
            displayName: "emit",
        });
        var evh_5962862420439144_deleteCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_deleteCompleted_5303],
        });
        return {
            act_7315092382398562_reloadComponentData_5292: act_7315092382398562_reloadComponentData_5292,
            evh_7315092382398562_reload_5291: evh_7315092382398562_reload_5291,
            evh_7315092382398562_reload: evh_7315092382398562_reload,
            act_3343864469950044_script_5296: act_3343864469950044_script_5296,
            evh_3343864469950044_close_5295: evh_3343864469950044_close_5295,
            act_3343864469950044_emit_5298: act_3343864469950044_emit_5298,
            evh_3343864469950044_close_5297: evh_3343864469950044_close_5297,
            act_3343864469950044_showModal_5294: act_3343864469950044_showModal_5294,
            evh_3343864469950044_click_5293: evh_3343864469950044_click_5293,
            evh_3343864469950044_click: evh_3343864469950044_click,
            act_5962862420439144_emit_5300: act_5962862420439144_emit_5300,
            evh_5962862420439144_saveCompleted_5299: evh_5962862420439144_saveCompleted_5299,
            evh_5962862420439144_saveCompleted: evh_5962862420439144_saveCompleted,
            act_5962862420439144_script_5302: act_5962862420439144_script_5302,
            evh_5962862420439144_delete_5301: evh_5962862420439144_delete_5301,
            evh_5962862420439144_delete: evh_5962862420439144_delete,
            act_5962862420439144_emit_5304: act_5962862420439144_emit_5304,
            evh_5962862420439144_deleteCompleted_5303: evh_5962862420439144_deleteCompleted_5303,
            evh_5962862420439144_deleteCompleted: evh_5962862420439144_deleteCompleted,
            addInvoice: act_3343864469950044_showModal_5294,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], UcPaymentInvoices.prototype, "payment", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('payment'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcPaymentInvoices.prototype, "onPayment", null);
    UcPaymentInvoices = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], UcPaymentInvoices);
    return UcPaymentInvoices;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = UcPaymentInvoices;
vue_property_decorator_1.Vue.component("UcPaymentInvoices", UcPaymentInvoices);
