import { render, staticRenderFns } from "./FieldsetContractClientData.yaml?vue&type=template&id=d0333c50&component"
import script from "./FieldsetContractClientData.yaml?vue&type=script&lang=ts&component"
export * from "./FieldsetContractClientData.yaml?vue&type=script&lang=ts&component"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/buildbot/integro-appbuilder/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d0333c50')) {
      api.createRecord('d0333c50', component.options)
    } else {
      api.reload('d0333c50', component.options)
    }
    module.hot.accept("./FieldsetContractClientData.yaml?vue&type=template&id=d0333c50&component", function () {
      api.rerender('d0333c50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/contracts/form/general/FieldsetContractClientData.yaml"
export default component.exports