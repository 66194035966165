<template>
<ItpModal
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ContractEditor"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="contract"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <ContractEditorFieldset
          :contract="contract"
          :formId="formId"
          :target="target"
        >
        </ContractEditorFieldset>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      :disabled="!forms.contract || !forms.contract.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ContractEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  getPayload(...args: any[]) {
    const [data] = args

    return this.target ? _.pick(data, this.targetConfig[this.target].fieldsToSubmit) : data;
  }

  @Prop({
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: String,
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  defaultTitle!: any;
  targetConfig!: any;
  ux!: any;
  dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        defaultTitle: this.$fn.pgettext("Contract", "Edit General Information"),
        targetConfig: {
          company: {
            title: this.$fn.pgettext("Contract", "Edit company"),
            fieldsToSubmit: ["id"
              ,
              "revision_id"
              ,
              "etag"
              ,
              "company"
              ,
            ]
            ,
          }
          ,
          counterparty: {
            title: this.$fn.pgettext("Contract", "Edit counterparty"),
            fieldsToSubmit: ["id"
              ,
              "revision_id"
              ,
              "etag"
              ,
              "counterparty"
              ,
            ]
            ,
          }
          ,
          invoicing: {
            title: this.$fn.pgettext("Contract", "Edit invoicing information"),
            fieldsToSubmit: ["id"
              ,
              "revision_id"
              ,
              "etag"
              ,
              "invoicing"
              ,
            ]
            ,
          }
          ,
          lifecycle: {
            title: this.$fn.pgettext("Contract", "Edit lifecycle information"),
            fieldsToSubmit: ["id"
              ,
              "revision_id"
              ,
              "etag"
              ,
              "lifecycle"
              ,
            ]
            ,
          }
          ,
          rental: {
            title: this.$fn.pgettext("Contract", "Edit rental"),
            fieldsToSubmit: ["id"
              ,
              "revision_id"
              ,
              "etag"
              ,
              "rental"
              ,
            ]
            ,
          }
          ,
          general: {
            title: this.$fn.pgettext("Contract", "Edit general data"),
            fieldsToSubmit: ["id"
              ,
              "revision_id"
              ,
              "etag"
              ,
              "extras"
              ,
              "revision_number"
              ,
              "status"
              ,
              "contract_type"
              ,
              "contract_number"
              ,
              "external_key"
              ,
              "category_id"
              ,
            ]
            ,
          }
          ,
        }
        ,
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2026_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2026, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_2032_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_2032, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_2030_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_2030, alias=save
    return {
      objectType: "contract",
      op: "upsert",
      data: this.getPayload(this.contract),
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2026: actions.CloseModalAction;
    evh_7315092382398562_close_2025: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2028: actions.CloseComponentAction;
    evh_2248226175642056_close_2027: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_2032: actions.CloseModalAction;
    evh_1040167445267876_success_2031: actions.EventHandlerImpl;
    act_1040167445267876_crud_2030: actions.CRUDAction;
    evh_1040167445267876_submit_2029: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_2034: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_2033: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2026 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2026_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2025 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2026,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2025],
      }
    );
    const act_2248226175642056_closeComponent_2028 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2027 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2028,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2027],
      }
    );
    const act_1040167445267876_closeModal_2032 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_2032_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_2031 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_2032,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_crud_2030 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_2030_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_2031],
      }
    );
    const evh_1040167445267876_submit_2029 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_2030,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_2029],
      }
    );
    const act_1419464017721962_closeComponent_2034 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2033 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_2034,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2033],
      }
    );
    return {
      act_7315092382398562_closeModal_2026,
      evh_7315092382398562_close_2025,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2028,
      evh_2248226175642056_close_2027,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_2032,
      evh_1040167445267876_success_2031,
      act_1040167445267876_crud_2030,
      evh_1040167445267876_submit_2029,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_2034,
      evh_1419464017721962_clickPreventStop_2033,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_crud_2030,
    }
  }
}

Vue.component("ContractEditor", ContractEditor);

</script>