<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Account"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcAccountHeader
      :account="item"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </UcAccountHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="account"
      :readonly="item.locked"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <FieldsetAccountGeneral
          :item="item"
          :formId="formId"
          no-submit-button
          @saved="evh_7789867021953041_saved($event, {})"
        >
        </FieldsetAccountGeneral>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      type="submit"
      :text="'Save'|gettext"
      :form="forms.account"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Account extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.oid == null ? this.$fn.schemaDefaults('CreateAccountCommand') : this.$fn.fetch('read_account', { asResource: true, parameters: { id: this.oid } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formId', 'item', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.oid == null ?
          this.$fn.gettext("New Cash or Bank Account") :
          [this.item.snapshot("name"), this.$fn.gettext("Account")],
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1310_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1310, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_1318_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_1318, alias=undefined
    return {
      grid: "payments",
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_1322_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_1322, alias=undefined
    return {
      path: "item",
      value: $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1040167445267876_setData_1322_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_1322, alias=undefined
    return !!$event.data
  }

  async act_1040167445267876_emit_1324_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1040167445267876_emit_1324, alias=undefined
    return {
      event: "saved",
      value: $event.data,
    }
  }

  async act_1040167445267876_closeModal_1326_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_1326, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_1328_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_1328, alias=undefined
    return {
      grid: "accounts",
    }
  }

  async act_1040167445267876_crud_1320_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_1320, alias=save
    return {
      objectType: "account",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_reloadSlickgrid_1332_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_7789867021953041_reloadSlickgrid_1332, alias=undefined
    return {
      grid: "accounts",
    }
  }

  async evh_7789867021953041_saved(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_saved.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_1334_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_1334, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1310: actions.CloseModalAction;
    evh_7315092382398562_close_1309: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1312: actions.CloseModalAction;
    evh_2248226175642056_close_1311: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_1314: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_1313: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeComponent_1316: actions.CloseComponentAction;
    evh_8564662037462133_deleted_1315: actions.EventHandlerImpl;
    act_8564662037462133_reloadSlickgrid_1318: actions.ReloadSlickgridAction;
    evh_8564662037462133_deleted_1317: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_setData_1322: actions.SetDataAction;
    evh_1040167445267876_success_1321: actions.EventHandlerImpl;
    act_1040167445267876_emit_1324: actions.EmitAction;
    evh_1040167445267876_success_1323: actions.EventHandlerImpl;
    act_1040167445267876_closeModal_1326: actions.CloseModalAction;
    evh_1040167445267876_success_1325: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_1328: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_1327: actions.EventHandlerImpl;
    act_1040167445267876_crud_1320: actions.CRUDAction;
    evh_1040167445267876_submit_1319: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_7789867021953041_closeModal_1330: actions.CloseModalAction;
    evh_7789867021953041_saved_1329: actions.EventHandlerImpl;
    act_7789867021953041_reloadSlickgrid_1332: actions.ReloadSlickgridAction;
    evh_7789867021953041_saved_1331: actions.EventHandlerImpl;
    evh_7789867021953041_saved: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_1334: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_1333: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1310 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1310_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1309 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1310,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1309],
      }
    );
    const act_2248226175642056_closeModal_1312 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1311 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1312,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1311],
      }
    );
    const act_8564662037462133_reloadComponentData_1314 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_1313 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_1314,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_1313],
      }
    );
    const act_8564662037462133_closeComponent_1316 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_1315 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeComponent_1316,
        event: "deleted",
        displayName: "closeComponent",
      }
    );
    const act_8564662037462133_reloadSlickgrid_1318 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_1318_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_1317 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_1318,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_1315, evh_8564662037462133_deleted_1317],
      }
    );
    const act_1040167445267876_setData_1322 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_1322_getActionArgs,
        when: this.act_1040167445267876_setData_1322_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_1321 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_1322,
        event: "success",
        displayName: "setData",
      }
    );
    const act_1040167445267876_emit_1324 = new actions.EmitAction(
      {
        actionArgs: this.act_1040167445267876_emit_1324_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1323 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_emit_1324,
        event: "success",
        displayName: "emit",
      }
    );
    const act_1040167445267876_closeModal_1326 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_1326_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1325 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_1326,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_reloadSlickgrid_1328 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_1328_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1327 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_1328,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_crud_1320 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_1320_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_1321, evh_1040167445267876_success_1323, evh_1040167445267876_success_1325, evh_1040167445267876_success_1327],
      }
    );
    const evh_1040167445267876_submit_1319 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_1320,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1319],
      }
    );
    const act_7789867021953041_closeModal_1330 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_7789867021953041_saved_1329 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_closeModal_1330,
        event: "saved",
        displayName: "closeModal",
      }
    );
    const act_7789867021953041_reloadSlickgrid_1332 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_7789867021953041_reloadSlickgrid_1332_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_saved_1331 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_reloadSlickgrid_1332,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_7789867021953041_saved = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_saved_1329, evh_7789867021953041_saved_1331],
      }
    );
    const act_1419464017721962_closeModal_1334 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_1334_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1333 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_1334,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1333],
      }
    );
    return {
      act_7315092382398562_closeModal_1310,
      evh_7315092382398562_close_1309,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1312,
      evh_2248226175642056_close_1311,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_1314,
      evh_8564662037462133_reload_1313,
      evh_8564662037462133_reload,
      act_8564662037462133_closeComponent_1316,
      evh_8564662037462133_deleted_1315,
      act_8564662037462133_reloadSlickgrid_1318,
      evh_8564662037462133_deleted_1317,
      evh_8564662037462133_deleted,
      act_1040167445267876_setData_1322,
      evh_1040167445267876_success_1321,
      act_1040167445267876_emit_1324,
      evh_1040167445267876_success_1323,
      act_1040167445267876_closeModal_1326,
      evh_1040167445267876_success_1325,
      act_1040167445267876_reloadSlickgrid_1328,
      evh_1040167445267876_success_1327,
      act_1040167445267876_crud_1320,
      evh_1040167445267876_submit_1319,
      evh_1040167445267876_submit,
      act_7789867021953041_closeModal_1330,
      evh_7789867021953041_saved_1329,
      act_7789867021953041_reloadSlickgrid_1332,
      evh_7789867021953041_saved_1331,
      evh_7789867021953041_saved,
      act_1419464017721962_closeModal_1334,
      evh_1419464017721962_clickPreventStop_1333,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_crud_1320,
    }
  }
}

Vue.component("Account", Account);

</script>