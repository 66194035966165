var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "ItpBox",
            [
              _c(
                "DIV",
                { staticClass: "mr-2", style: _vm.b_8564662037462133_style },
                [
                  _c("ItpFormSelect2", {
                    attrs: {
                      name: "accounts",
                      "bind-object": "",
                      textField: "name,currency,account_number",
                      options: _vm.accounts,
                      "use-option-template-for-selected-option": "",
                      optionTemplate:
                        "<UcAccountSelectOption :payload=option></UcAccountSelectOption>"
                    },
                    on: {
                      optionSelected: function($event) {
                        return _vm.evh_6466705138206980_optionSelected(
                          $event,
                          {}
                        )
                      }
                    },
                    model: {
                      value: _vm.item.account,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "account", $$v)
                      },
                      expression: "item.account"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "DIV",
                { staticClass: "mr-3" },
                [
                  _vm.selectedAccountType &&
                  _vm.selectedAccountType == "cash_book"
                    ? _c(
                        "b-dropdown",
                        {
                          attrs: {
                            text: _vm._f("pgettext")(
                              "New transaction",
                              "Payments"
                            ),
                            "toggle-class": "border",
                            variant: "light"
                          }
                        },
                        [
                          _c("ItpButton", {
                            attrs: {
                              text: _vm._f("pgettext")(
                                "Add deposit",
                                "Payments"
                              ),
                              icon: _vm.$config.payment.icon,
                              "dropdown-item": "",
                              variant: "secondary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.evh_8169383091859921_clickPrevent(
                                  $event,
                                  {}
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpButton", {
                            attrs: {
                              text: _vm._f("pgettext")(
                                "New payment",
                                "Payments"
                              ),
                              icon: _vm.$config.payment.icon,
                              "dropdown-item": "",
                              variant: "secondary"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.evh_7640643585580915_clickPrevent(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedAccountType &&
                  _vm.selectedAccountType !== "cash_book"
                    ? _c("ItpButton", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "New transaction...",
                            "Payments"
                          ),
                          icon: _vm.$config.payment.icon,
                          variant: "light"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_2857579216270778_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("DateRangeSelector", {
                staticClass: "mr-2",
                style: _vm.b_1889980785424776_style,
                attrs: {
                  name: "paymentFilters",
                  from: _vm.item.date_from,
                  to: _vm.item.date_to
                },
                on: {
                  changed: function($event) {
                    return _vm.evh_1889980785424776_changed($event, {})
                  }
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  variant: "light",
                  icon: _vm.$config.reloadIcon,
                  title: _vm._f("pgettext")("Load data", "PaymentsViewFilters"),
                  spinning:
                    _vm.actions &&
                    _vm.actions.load &&
                    _vm.actions.load.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_4892266742011185_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }