"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcSuggestClientOption = exports.UcCompactClientDisplay = exports.UcClientTypeBadge = exports.UcClientMiniCard = void 0;
var UcClientMiniCard_yaml_component_1 = require("./UcClientMiniCard.yaml?component");
exports.UcClientMiniCard = UcClientMiniCard_yaml_component_1.default;
var UcClientTypeBadge_yaml_component_1 = require("./UcClientTypeBadge.yaml?component");
exports.UcClientTypeBadge = UcClientTypeBadge_yaml_component_1.default;
var UcCompactClientDisplay_yaml_component_1 = require("./UcCompactClientDisplay.yaml?component");
exports.UcCompactClientDisplay = UcCompactClientDisplay_yaml_component_1.default;
var UcSuggestClientOption_yaml_component_1 = require("./UcSuggestClientOption.yaml?component");
exports.UcSuggestClientOption = UcSuggestClientOption_yaml_component_1.default;
