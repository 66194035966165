<template>
<ItpBox
  direction="column"
>
  <ItpBox>
    <div
      class="col"
    >
      <ItpText
        strong
        :text="'Cost allocators'|pgettext('RentalServiceCostAllocators')"
      >
      </ItpText>
    </div>
  </ItpBox>
  <ItpBox
    direction="column"
    v-if="service.cost_allocators && service.cost_allocators.data.length"
  >
    <ItpBox
      :key="item.id"
      v-for="item in service.cost_allocators.data"
    >
      <UcMeterMiniCard
        :item="item.meter"
        class="col-6"
      >
      </UcMeterMiniCard>
      <ItpText
        :text="item.factor"
        class="col-3"
      >
      </ItpText>
      <ItpText
        :text="item.notes"
        class="col-3"
      >
      </ItpText>
      <b-dropdown
        size="sm"
        toggle-class="btn-default-borderless"
        no-caret
        class="ml-auto mb-auto"
        v-if="!item.isReadonly && !service.isReadonly"
      >
        <ItpIcon
          fa="ellipsis-h"
          class="mx-1"
          slot="button-content"
        >
        </ItpIcon>
        <b-dropdown-item-button
          @click.prevent.stop="evh_2576027262358435_clickPreventStop($event, {item})"
        >
          <ItpText
            :text="'Edit'|pgettext('Command')"
          >
          </ItpText>
        </b-dropdown-item-button>
        <b-dropdown-item-button
          @click.prevent.stop="evh_7593494570053535_clickPreventStop($event, {item})"
        >
          <ItpText
            :text="'Delete'|pgettext('Command')"
          >
          </ItpText>
        </b-dropdown-item-button>
      </b-dropdown>
    </ItpBox>
  </ItpBox>
  <ItpBox>
    <div
      class="col"
    >
      <ItpLink
        :text="'Add cost allocator...'|pgettext('RentalServiceCostAllocators')"
        class="ml-auto"
        v-if="!service.isReadonly"
        @click.prevent.stop="evh_2140198529698299_clickPreventStop($event, {})"
      >
      </ItpLink>
    </div>
  </ItpBox>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcRentalServiceCostAllocators extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  service!: object;

  @Watch('service')
  onService(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("service")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async act_2576027262358435_emit_6790_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2576027262358435_emit_6790, alias=undefined
    return {
      event: "reload",
    }
  }

  async act_2576027262358435_showModal_6788_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2576027262358435_showModal_6788, alias=undefined
    return {
      name: "RentalServiceCostAllocator",
      props: {
        oid: $event.scope.item.id,
        service: this.service,
      }
      ,
    }
  }

  async evh_2576027262358435_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2576027262358435_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7593494570053535_emit_6794_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7593494570053535_emit_6794, alias=undefined
    return {
      event: "reload",
    }
  }

  async act_7593494570053535_request_6792_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7593494570053535_request_6792, alias=undefined
    return {
      operation: "delete_rental_service_cost_allocator",
      data: $event.scope.item,
    }
  }

  async act_7593494570053535_request_6792_getConfirm($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7593494570053535_request_6792, alias=undefined
    return {
      message: this.$fn.gettext("Please confirm delete."),
      btnOk: this.$fn.gettext("Delete"),
    }
  }

  async evh_7593494570053535_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7593494570053535_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2140198529698299_showModal_6796_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2140198529698299_showModal_6796, alias=undefined
    return {
      name: "RentalServiceCostAllocator",
      props: {
        service: this.service,
      }
      ,
    }
  }

  async evh_2140198529698299_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2140198529698299_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_2576027262358435_emit_6790: actions.EmitAction;
    evh_2576027262358435_close_6789: actions.EventHandlerImpl;
    act_2576027262358435_showModal_6788: actions.ShowModalAction;
    evh_2576027262358435_clickPreventStop_6787: actions.EventHandlerImpl;
    evh_2576027262358435_clickPreventStop: actions.EventHandlerGroup;
    act_7593494570053535_emit_6794: actions.EmitAction;
    evh_7593494570053535_success_6793: actions.EventHandlerImpl;
    act_7593494570053535_request_6792: actions.RequestAction;
    evh_7593494570053535_clickPreventStop_6791: actions.EventHandlerImpl;
    evh_7593494570053535_clickPreventStop: actions.EventHandlerGroup;
    act_2140198529698299_showModal_6796: actions.ShowModalAction;
    evh_2140198529698299_clickPreventStop_6795: actions.EventHandlerImpl;
    evh_2140198529698299_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_2576027262358435_emit_6790 = new actions.EmitAction(
      {
        actionArgs: this.act_2576027262358435_emit_6790_getActionArgs,
        events: [],
      }
    );
    const evh_2576027262358435_close_6789 = new actions.EventHandlerImpl(
      {
        action: act_2576027262358435_emit_6790,
        event: "close",
        displayName: "emit",
      }
    );
    const act_2576027262358435_showModal_6788 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2576027262358435_showModal_6788_getActionArgs,
        events: [evh_2576027262358435_close_6789],
      }
    );
    const evh_2576027262358435_clickPreventStop_6787 = new actions.EventHandlerImpl(
      {
        action: act_2576027262358435_showModal_6788,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2576027262358435_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2576027262358435_clickPreventStop_6787],
      }
    );
    const act_7593494570053535_emit_6794 = new actions.EmitAction(
      {
        actionArgs: this.act_7593494570053535_emit_6794_getActionArgs,
        events: [],
      }
    );
    const evh_7593494570053535_success_6793 = new actions.EventHandlerImpl(
      {
        action: act_7593494570053535_emit_6794,
        event: "success",
        displayName: "emit",
      }
    );
    const act_7593494570053535_request_6792 = new actions.RequestAction(
      {
        actionArgs: this.act_7593494570053535_request_6792_getActionArgs,
        confirm: this.act_7593494570053535_request_6792_getConfirm,
        events: [evh_7593494570053535_success_6793],
      }
    );
    const evh_7593494570053535_clickPreventStop_6791 = new actions.EventHandlerImpl(
      {
        action: act_7593494570053535_request_6792,
        event: "click.prevent.stop",
        displayName: "request",
      }
    );
    const evh_7593494570053535_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7593494570053535_clickPreventStop_6791],
      }
    );
    const act_2140198529698299_showModal_6796 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2140198529698299_showModal_6796_getActionArgs,
        events: [],
      }
    );
    const evh_2140198529698299_clickPreventStop_6795 = new actions.EventHandlerImpl(
      {
        action: act_2140198529698299_showModal_6796,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2140198529698299_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2140198529698299_clickPreventStop_6795],
      }
    );
    return {
      act_2576027262358435_emit_6790,
      evh_2576027262358435_close_6789,
      act_2576027262358435_showModal_6788,
      evh_2576027262358435_clickPreventStop_6787,
      evh_2576027262358435_clickPreventStop,
      act_7593494570053535_emit_6794,
      evh_7593494570053535_success_6793,
      act_7593494570053535_request_6792,
      evh_7593494570053535_clickPreventStop_6791,
      evh_7593494570053535_clickPreventStop,
      act_2140198529698299_showModal_6796,
      evh_2140198529698299_clickPreventStop_6795,
      evh_2140198529698299_clickPreventStop,
    }
  }
}

Vue.component("UcRentalServiceCostAllocators", UcRentalServiceCostAllocators);

</script>