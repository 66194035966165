<template>
<ItpDataTable
  tableId="RentalFormFixedAssets"
  :editable="editable"
  :isRowEditable="p => !p.locked"
  :value="_.orderBy(items, ['created_at', 'valid_from', 'valid_to'], ['desc', 'desc', 'desc'])"
  :rowTemplate="schemaDefaults('CreateRentalAsset', {
    rental_id: rentalId,
    valid_from: new Date()
  })"
  :columns="b_7315092382398562_columns"
  class="h-100"
  v-if="loaded"
  @save="evh_7315092382398562_save($event, {})"
  @delete="evh_7315092382398562_delete($event, {})"
>
</ItpDataTable>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcRentalFormFixedAssets extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Boolean,
    default: true,
  })
  editable!: boolean;

  @Watch('editable')
  onEditable(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("editable")
  }

  @Prop({
    required: true,
    type: String,
  })
  rentalId!: string;

  @Watch('rentalId')
  onRental_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rentalId")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_rental_asset', { parameters: { rental_id: this.rentalId } })
  }

  ux!: any;
  b_7315092382398562_columns!: any;

  async $$load_b_7315092382398562_columns() {
    return [{
      name: "locked",
      type: "lock",
    }
      ,
    {
      name: "inactive",
      header: this.$fn.gettext("Inactive"),
      width: "30px",
      textAlign: "center",
      formatter: {
        name: "checkbox",
      }
      ,
      editor: {
        name: "checkbox",
      }
      ,
    }
      ,
    {
      name: "version",
      type: "number",
      header: this.$fn.gettext("Version"),
      width: "110px",
      validator: {
        required: true,
        numeric: true,
        minValue: 0,
      }
      ,
    }
      ,
    {
      name: "valid_from",
      type: "date",
      header: this.$fn.gettext("Valid From"),
      width: "120px",
    }
      ,
    {
      name: "valid_to",
      type: "date",
      header: this.$fn.gettext("Valid To"),
      width: "120px",
    }
      ,
    {
      name: "asset",
      type: "select2",
      header: this.$fn.gettext("Fixed Asset"),
      width: "250px",
      required: true,
      path: "name",
      bindObject: true,
      textField: "name",
      options: this.$fn.searchDataSource('suggest_fixed_asset', { t: 'fixed_asset' }),
    }
      ,
    {
      name: "notes",
      header: this.$fn.gettext("Notes"),
      width: "320px",
    }
      ,
    ]
  }

  dataMembers = ['items', 'ux', 'b_7315092382398562_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_columns: null,
      },
    }
  }

  async act_7315092382398562_script_6024_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_6024, alias=undefined
    $event.data.waitFor(
      this.$fn.crud('rental_asset').upsert({
        ...$event.data.row,
        asset_id: $event.data.row.asset.id
      })
    )
  }

  async act_7315092382398562_script_6024_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_6024, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_6024_getActionArgs_value($event),
    }
  }

  async evh_7315092382398562_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_save.executeFromDOM(this, event, scope);
  }

  async act_7315092382398562_script_6026_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_6026, alias=undefined
    $event.data.waitFor(this.$fn.crud('rental_asset').delete($event.data.row))
  }

  async act_7315092382398562_script_6026_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_6026, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_6026_getActionArgs_value($event),
    }
  }

  async evh_7315092382398562_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_delete.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_script_6024: actions.ScriptAction;
    evh_7315092382398562_save_6023: actions.EventHandlerImpl;
    evh_7315092382398562_save: actions.EventHandlerGroup;
    act_7315092382398562_script_6026: actions.ScriptAction;
    evh_7315092382398562_delete_6025: actions.EventHandlerImpl;
    evh_7315092382398562_delete: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_script_6024 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_6024_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_save_6023 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_6024,
        event: "save",
        displayName: "script",
      }
    );
    const evh_7315092382398562_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_save_6023],
      }
    );
    const act_7315092382398562_script_6026 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_6026_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_delete_6025 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_6026,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_7315092382398562_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_delete_6025],
      }
    );
    return {
      act_7315092382398562_script_6024,
      evh_7315092382398562_save_6023,
      evh_7315092382398562_save,
      act_7315092382398562_script_6026,
      evh_7315092382398562_delete_6025,
      evh_7315092382398562_delete,
    }
  }
}

Vue.component("UcRentalFormFixedAssets", UcRentalFormFixedAssets);

</script>