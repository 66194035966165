var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "BListGroup",
        { attrs: { flush: "" } },
        _vm._l(_vm.filterItems(_vm.services), function(service) {
          return _c(
            "BListGroupItem",
            { key: service.id },
            [
              _c(
                "ItpBox",
                { attrs: { direction: "column" } },
                [
                  _c(
                    "ItpBox",
                    [
                      _c("ItpText", {
                        attrs: { strong: "", text: service.service.name }
                      }),
                      _vm._v(" "),
                      service.service.invoice_group_id
                        ? _c("ItpText", {
                            staticClass: "px-3",
                            attrs: { text: service.service.invoice_group_id }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ItpText",
                        { staticClass: "px-3" },
                        [
                          _c("ItpText", [
                            _vm._v(_vm._s(_vm._f("date")(service.valid_from)))
                          ]),
                          _vm._v(" "),
                          service.valid_to
                            ? _c("ItpText", [
                                _vm._v(
                                  " - " +
                                    _vm._s(_vm._f("date")(service.valid_to))
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ItpBox",
                    [
                      service.notes
                        ? _c("ItpText", [
                            _vm._v(
                              "\n            " +
                                _vm._s(service.notes) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  service.meter
                    ? _c(
                        "ItpBox",
                        [
                          _c("ItpText", {
                            staticClass: "pr-3",
                            attrs: {
                              muted: "",
                              text: _vm._f("gettext")("Meter")
                            }
                          }),
                          _vm._v(" "),
                          _c("UcCompactMeterDisplay", {
                            attrs: { payload: service.meter }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }