"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var big_js_1 = require("big.js");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var UcInvoiceLines = (function (_super) {
    __extends(UcInvoiceLines, _super);
    function UcInvoiceLines() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['expenseTypes', 'taxCodes', 'units', 'displayAddInvoiceLine', 'messages', 'ux', 'b_5962862420439144_rowHeader', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_rowTemplate', 'b_5962862420439144_messages', 'b_5962862420439144_columns', 'b_1270943406604881_dataSource', 'b_4717426290970457_paging', 'b_4717426290970457_sortable', 'b_4717426290970457_rowHeader', 'b_4717426290970457_fillEmptyRowSpace', 'b_4717426290970457_messages', 'b_4717426290970457_columns', 'b_1803307708030275_paging', 'b_1803307708030275_sortable', 'b_1803307708030275_rowHeader', 'b_1803307708030275_fillEmptyRowSpace', 'b_1803307708030275_messages', 'b_1803307708030275_columns'];
        return _this;
    }
    UcInvoiceLines.prototype.beforeCreate = function () {
    };
    UcInvoiceLines.prototype.onProductSelected = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 2), row = _a[0], product = _a[1];
        if (!product) {
            product = row.product;
        }
        if (_.isObject(product) && product.id == null) {
            product = product.name;
            row.product = product;
        }
        if (_.isString(product)) {
            row.description = product;
            row.tax_code = this.defaultTaxCode;
            var nearestRow = row.$$table.nearestRow(row);
            console.log('PR', nearestRow);
            if (nearestRow) {
                row.unit = nearestRow.unit;
                row.discount = nearestRow.discount;
            }
        }
        else {
            row.product = product;
            row.unit = product.unit;
            row.description = product.name;
            row.tax_code = this.defaultTaxCode || (this.invoice.invoice_kind === "outgoing"
                ? product.sales_vat_code
                : product.purchase_vat_code);
            row.intermediated_service = product.intermediated_service || false;
        }
        row.setFocus('quantity');
    };
    UcInvoiceLines.prototype.onProductChanged = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.log('onProductChanged', args);
    };
    UcInvoiceLines.prototype.calculateUnitPrice = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 3), row = _a[0], tax = _a[1], decimals = _a[2];
        row.price = (0, big_js_1.default)((0, big_js_1.default)(row.net_amount) / (0, big_js_1.default)(row.quantity) / (1 - (0, big_js_1.default)(row.discount) / 100)).round(decimals).toNumber();
    };
    UcInvoiceLines.prototype.calculateNetAmount = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 3), row = _a[0], tax = _a[1], decimals = _a[2];
        row.net_amount = (0, big_js_1.default)((0, big_js_1.default)(row.quantity) * (0, big_js_1.default)(row.price) * (1 - (0, big_js_1.default)(row.discount) / 100)).round(decimals).toNumber();
    };
    UcInvoiceLines.prototype.calculateNetAmountFromAmount = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 3), row = _a[0], tax = _a[1], decimals = _a[2];
        row.net_amount = (0, big_js_1.default)((0, big_js_1.default)(row.amount) / (1 + (0, big_js_1.default)(tax.rate) / 100)).round(decimals).toNumber();
    };
    UcInvoiceLines.prototype.calculateAmount = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 3), row = _a[0], tax = _a[1], decimals = _a[2];
        row.amount = (0, big_js_1.default)((0, big_js_1.default)(row.net_amount) * (1 + (0, big_js_1.default)(tax.rate) / 100)).round(decimals).toNumber();
    };
    UcInvoiceLines.prototype.recalculateLineItem = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var data = args[0].data;
        var row = data.row;
        var decimals = this.currency.digits;
        var isIncomingInvoice = this.isIncomingInvoice;
        var tx = _.find(this.taxCodes, { id: row.tax_code });
        if (row.quantity === 0) {
            return;
        }
        switch (data.field) {
            case 'quantity':
            case 'price':
            case 'discount': {
                this.calculateNetAmount(row, tx, decimals);
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'net_amount': {
                this.calculateUnitPrice(row, tx, this.currency.price_digits);
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'tax_code': {
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'amount': {
                this.calculateNetAmountFromAmount(row, tx, decimals);
                this.calculateUnitPrice(row, tx, this.currency.price_digits);
                break;
            }
        }
    };
    UcInvoiceLines.prototype.hasQuantityMismatch = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var row = args[0];
        return row.accountings
            && row.accountings.data
            && row.accountings.data.length
            && _.sumBy(row.accountings.data, function (p) { return p.quantity; }) != row.quantity;
    };
    UcInvoiceLines.prototype.getInvoiceLineEditorIsDisabled = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 1), row = _a[0];
        return !row.tax_code;
    };
    UcInvoiceLines.prototype.onInvoice = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    };
    UcInvoiceLines.prototype.onCurrency = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("currency");
    };
    UcInvoiceLines.prototype.onDefault_discount = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("defaultDiscount");
    };
    UcInvoiceLines.prototype.onDefault_tax_code = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("defaultTaxCode");
    };
    UcInvoiceLines.prototype.$$load_expenseTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } })];
            });
        });
    };
    UcInvoiceLines.prototype.$$load_taxCodes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_tax_code')];
            });
        });
    };
    UcInvoiceLines.prototype.$$load_units = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_unit')];
            });
        });
    };
    UcInvoiceLines.prototype.$$load_b_5962862420439144_rowTemplate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, {
                        description: null,
                        discount: this.defaultDiscount || 0,
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.$$load_b_5962862420439144_columns = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, [{
                            name: "product",
                            header: this.$fn.gettext("Product"),
                            width: "250px",
                            required: true,
                            textAlign: "left",
                            editor: {
                                name: "template",
                                value: "<ItpSelect2\n\
  class=\"w-100\"\n\
  v-model=\"currentValue\"\n\
  bindObject\n\
  valueField=\"id\"\n\
  textField=\"name\"\n\
  required\n\
  :placeholder=\"messages.msgSearchProduct\"\n\
  :options=\"globs.searchDataSource('suggest_product')\"\n\
  :autofocus=autofocus\n\
  :form=\"formId\"\n\
  :inputId=\"col.field\"\n\
  :size=\"col.size\"\n\
  :state=\"validationState\"\n\
  :disabled=\"isDisabled\"\n\
  taggable\n\
  @optionSelected=\"col.emit('product:selected', {col, row, ...$event})\"\n\
  @change=\"onChange($event)\"\n\
>\n\
  <template #search=\"{attributes, events}\">\n\
    <input\n\
      class=\"vs__search\"\n\
      v-bind=\"attributes\"\n\
      v-on=\"events\"\n\
    />\n\
  </template>\n\
\n\
  <template #option=\"option\">\n\
    <UcSuggestProductOption :option=\"option\" v-if=\"option.object=='product'\">\n\
    </UcSuggestProductOption>\n\
    <span v-else>{{ option.name }}</span>\n\
  </template>\n\
\n\
  <template #selected-option=\"option\">\n\
    <UcSuggestProductOption :option=\"option\" v-if=\"option.object=='product'\">\n\
    </UcSuggestProductOption>\n\
    <span v-else>{{ option.name }}</span>\n\
  </template>\n\
\n\
  <template #list-footer>\n\
    <hr />\n\
    <ItpButton\n\
      class=\"ml-2\"\n\
      size=sm\n\
      variant=light\n\
      @click=\"col.emitCellEvent('createProduct', row)\">\n\
      {{ messages.msgCreateProduct }}\n\
    </ItpButton>\n\
  </template>\n\
\n\
  <template #footer>\n\
    <b-form-input\n\
      class=\"mt-1\"\n\
      type=text\n\
      v-model=row.description\n\
      id=\"description\"\n\
      name=\"description\"\n\
      autocomplete=\"off\"\n\
      :form=\"formId\"\n\
      :size=col.size\n\
      :disabled=\"isDisabled\"\n\
      :placeholder=\"globs.messages.invoiceLineDescriptionPlaceholder\"\n\
      @change=\"onFieldChange('description', row.description)\"\n\
    ></b-form-input>\n\
  </template>\n\
</ItpSelect2>\n\
",
                            },
                            formatter: {
                                name: "template",
                                value: "<div>\n\
  <b-link v-if=\"row.product\" :to=\"{\n\
    name: 'app.product',\n\
    params: { id: row.product.id }\n\
  }\">{{ row.description }}</b-link>\n\
  <span v-if=\"!row.product\">{{ row.description }}</span>\n\
</div>\n\
",
                            },
                        },
                        {
                            name: "quantity",
                            type: "number",
                            maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.quantity_digits,
                            default: 0,
                            header: this.$fn.pgettext("Quantity Abbr", "Qty"),
                            headerTooltip: this.$fn.gettext("Quantity"),
                            width: "60px",
                            textAlign: "right",
                            required: true,
                            editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                        },
                        {
                            name: "unit",
                            header: this.$fn.pgettext("Unit Abbr", "Unit"),
                            headerTooltip: this.$fn.gettext("Unit"),
                            width: "70px",
                            textAlign: "center",
                            required: true,
                            editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                        },
                        {
                            name: "price",
                            type: "number",
                            maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.price_digits,
                            default: 0,
                            header: this.$fn.gettext("Price"),
                            width: "70px",
                            textAlign: "right",
                            required: true,
                            editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                        },
                        {
                            name: "discount",
                            type: "number",
                            maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.digits,
                            default: this.defaultDiscount || 0,
                            header: this.$fn.pgettext("AbbrDiscount", "Disc"),
                            headerTooltip: this.$fn.gettext("Discount"),
                            width: "70px",
                            required: true,
                            editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                        },
                        {
                            name: "net_amount",
                            type: "number",
                            maxAllowedDecimals: this.currency.digits,
                            default: 0,
                            header: this.$fn.gettext("Net"),
                            width: "100px",
                            textAlign: "right",
                            required: true,
                            editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                        },
                        {
                            name: "amount",
                            type: "number",
                            maxAllowedDecimals: this.currency.digits,
                            default: 0,
                            header: this.$fn.gettext("Amount"),
                            width: "100px",
                            textAlign: "right",
                            required: true,
                            editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                        },
                        {
                            name: "tax_code",
                            header: this.$fn.gettext("VAT"),
                            headerTooltip: this.$fn.gettext("VAT Code"),
                            width: "100px",
                            type: "select",
                            options: this.taxCodes,
                            valueField: "id",
                            textField: "id",
                            editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                        },
                        {
                            name: "advance",
                            header: this.$fn.pgettext("InvoiceLine|ColHeader", "Adv."),
                            headerTooltip: this.$fn.pgettext("invoiceLine|ColHeader|Tooltip", "Advance"),
                            width: "40px",
                            type: "checkbox",
                            editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                        },
                        {
                            name: "intermediated_service",
                            header: this.$fn.pgettext("AbbrIntermediatedService", "IMS"),
                            headerTooltip: this.$fn.gettext("Intermediated Service"),
                            width: "30px",
                            type: "checkbox",
                            editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                        },
                    ]];
            });
        });
    };
    UcInvoiceLines.prototype.$$load_b_1270943406604881_dataSource = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "suggest_invoice",
                        parameters: {
                            invoice_kind: this.invoice.invoice_kind,
                            client_id: this.invoice.client.id,
                            company_id: this.invoice.company.id,
                            exclude_invoice: this.invoice.id,
                        },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.$$load_b_4717426290970457_columns = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, [{
                            name: "meter",
                            header: this.$fn.gettext("Meter"),
                            width: "160px",
                            required: true,
                            type: "select2",
                            options: this.$fn.searchDataSource('suggest_meter'),
                            bindObject: true,
                            valueField: "id",
                            textField: "serial_number",
                            formatter: {
                                name: "template",
                                value: "<UcMeterMiniCard :item=\"row.meter\" link-style-underline no-strong></UcMeterMiniCard>\n\
",
                            },
                        },
                        {
                            name: "from",
                            header: this.$fn.gettext("Previous reading"),
                            width: "160px",
                            formatter: {
                                name: "template",
                                value: "<div>{{ row.from_date|date }} {{ row.from_amount|number }} {{ row.from_unit }}</div>\n\
",
                            },
                            editor: {
                                name: "template",
                                value: "<div class=\"input-group\">\n\
  <ItpFormDatetime\n\
    v-model=\"row.from_date\"\n\
    name=\"from_date\"\n\
    placeholder=\"date\"\n\
    size=\"sm\"\n\
    style=\"min-width:100px;\"\n\
    required\n\
  ></ItpFormDatetime>\n\
\n\
  <ItpFormInput\n\
    type=\"number\"\n\
    name=\"from_amount\"\n\
    v-model=\"row.from_amount\"\n\
    placeholder=\"reading\"\n\
    size=\"sm\"\n\
    style=\"min-width:60px;\"\n\
    required\n\
  ></ItpFormInput>\n\
\n\
  <ItpFormSelect\n\
    v-model=\"row.from_unit\"\n\
    name=\"from_unit\"\n\
    placeholder=\"unit\"\n\
    size=\"sm\"\n\
    :options=\"globs.units\"\n\
    style=\"min-width:60px;\"\n\
    valueField=\"symbol\"\n\
    textField=\"symbol\"\n\
    required\n\
  ></ItpFormSelect>\n\
</div>\n\
",
                            },
                        },
                        {
                            name: "to",
                            header: this.$fn.gettext("Current reading"),
                            width: "240px",
                            formatter: {
                                name: "template",
                                value: "<div>{{ row.to_date|date }} {{ row.to_amount|number }} {{ row.to_unit }}</div>\n\
",
                            },
                            editor: {
                                name: "template",
                                value: "<div class=\"input-group\">\n\
  <ItpFormDatetime\n\
    v-model=\"row.to_date\"\n\
    name=\"to_date\"\n\
    placeholder=\"date\"\n\
    size=\"sm\"\n\
    style=\"min-width:100px;\"\n\
    required\n\
  ></ItpFormDatetime>\n\
\n\
  <ItpFormInput\n\
    type=\"number\"\n\
    name=\"to_amount\"\n\
    v-model=\"row.to_amount\"\n\
    placeholder=\"reading\"\n\
    size=\"sm\"\n\
    style=\"min-width:60px;\"\n\
    required\n\
  ></ItpFormInput>\n\
\n\
  <ItpFormSelect\n\
    v-model=\"row.to_unit\"\n\
    name=\"to_unit\"\n\
    placeholder=\"unit\"\n\
    size=\"sm\"\n\
    :options=\"globs.units\"\n\
    style=\"min-width:60px;\"\n\
    valueField=\"symbol\"\n\
    textField=\"symbol\"\n\
    required\n\
  ></ItpFormSelect>\n\
</div>\n\
",
                            },
                        },
                        {
                            name: "consumption",
                            header: this.$fn.gettext("Consumption"),
                            readonly: true,
                            formatter: {
                                name: "template",
                                value: "<div>{{ row.consumption_amount|number }} {{ row.consumption_unit }}</div>\n\
",
                            },
                        },
                        {
                            name: "billing_multiplier",
                            header: this.$fn.gettext("Multiplier"),
                            default: 1,
                            width: "70px",
                            type: "number",
                            required: true,
                        },
                    ]];
            });
        });
    };
    UcInvoiceLines.prototype.$$load_b_1803307708030275_columns = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, [{
                            name: "expense_type",
                            type: "select",
                            header: this.$fn.gettext("Expense Type"),
                            width: "100px",
                            options: this.expenseTypes,
                            valueField: "name",
                            textField: "name",
                        },
                        {
                            name: "rental",
                            type: "select2",
                            header: this.$fn.gettext("Rental"),
                            width: "100px",
                            path: "name",
                            bindObject: true,
                            textField: "name",
                            options: this.$fn.searchDataSource('suggest_rental'),
                            useOptionTemplateForSelectedOption: true,
                            optionTemplate: "<UcSuggestRentalOption :option=\"option\"></UcSuggestRentalOption>",
                        },
                        {
                            name: "property",
                            type: "select2",
                            header: this.$fn.gettext("Property"),
                            width: "100px",
                            path: "name",
                            bindObject: true,
                            textField: "name",
                            options: this.$fn.searchDataSource('suggest_property'),
                            useOptionTemplateForSelectedOption: true,
                            optionTemplate: "<UcSuggestPropertyOption :option=\"option\"></UcSuggestPropertyOption>",
                        },
                        {
                            name: "contractor",
                            type: "select2",
                            header: this.$fn.gettext("Contractor"),
                            width: "100px",
                            path: "name",
                            bindObject: true,
                            textField: "name",
                            options: this.$fn.searchDataSource('suggest_client', { 'is-contractor': true }),
                        },
                        {
                            name: "quantity",
                            type: "number",
                            header: this.$fn.gettext("Quantity"),
                            width: "100px",
                        },
                    ]];
            });
        });
    };
    UcInvoiceLines.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            expenseTypes: null,
            taxCodes: null,
            units: null,
            displayAddInvoiceLine: null,
            messages: {
                invoiceLineProductSearchPlaceholder: this.$fn.pgettext("InvoiceLines", "Search product/service..."),
                invoiceLineDescriptionPlaceholder: this.$fn.pgettext("InvoiceLines", "Description"),
            },
            ux: null,
            b_5962862420439144_rowHeader: false,
            b_5962862420439144_paging: false,
            b_5962862420439144_sortable: false,
            b_5962862420439144_rowTemplate: null,
            b_5962862420439144_messages: {
                msgCreateRow: this.$fn.pgettext("InvoiceLines", "Add line"),
                msgCreateProduct: this.$fn.pgettext("InvoiceLines", "Add new product..."),
                msgSearchProduct: this.$fn.pgettext("invoiceLines", "Search for product/service..."),
            },
            b_5962862420439144_columns: null,
            b_1270943406604881_dataSource: null,
            b_4717426290970457_paging: false,
            b_4717426290970457_sortable: false,
            b_4717426290970457_rowHeader: false,
            b_4717426290970457_fillEmptyRowSpace: false,
            b_4717426290970457_messages: {
                msgCreateRow: this.$fn.pgettext("invoiceLines", "New meter row"),
            },
            b_4717426290970457_columns: null,
            b_1803307708030275_paging: false,
            b_1803307708030275_sortable: false,
            b_1803307708030275_rowHeader: false,
            b_1803307708030275_fillEmptyRowSpace: false,
            b_1803307708030275_messages: {
                msgCreateRow: this.$fn.pgettext("invoiceLines", "New accounting row"),
            },
            b_1803307708030275_columns: null,
        });
    };
    Object.defineProperty(UcInvoiceLines.prototype, "invoiceLines", {
        get: function () {
            if (this.loaded) {
                return this.invoice.lines.data;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UcInvoiceLines.prototype, "invoiceLineGroups", {
        get: function () {
            if (this.loaded) {
                return this.invoice.line_groups.data;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UcInvoiceLines.prototype, "isIncomingInvoice", {
        get: function () {
            if (this.loaded) {
                return this.invoice.invoice_kind == 'incoming';
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    UcInvoiceLines.prototype.act_5962862420439144_emit_4630_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_saveCompleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4632_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.invoice.id
                    ? $event.data.waitFor(this.$fn.crud('invoice_line').upsert(__assign(__assign({}, $event.data.row), { invoice_id: this.invoice.id })))
                    : this.invoiceLines.splice(this.invoiceLines.length - 1, 0, $event.data.row);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4632_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4632_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_save = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_save.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4636_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onProductSelected($event.parent.data.row, $event.data.modalResult.value);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4636_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4636_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_showModal_4634_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateProduct",
                        props: {
                            preventDefaultOnClose: true,
                        },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_createProduct = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_createProduct.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4638_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $event.data.waitFor(this.$fn.crud('invoice_line').delete($event.data.row));
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4638_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4638_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_delete = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4640_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onProductSelected($event.data.row, $event.data.option);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4640_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4640_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_productSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_productSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4642_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onProductChanged($event);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4642_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4642_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_productChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_productChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4644_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4644_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4644_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_quantityChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_quantityChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4646_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4646_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4646_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_priceChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_priceChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4648_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4648_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4648_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_discountChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_discountChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4650_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4650_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4650_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_netAmountChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_netAmountChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4652_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4652_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4652_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_amountChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_amountChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4654_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateLineItem($event);
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_5962862420439144_script_4654_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_5962862420439144_script_4654_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_5962862420439144_taxCodeChange = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_taxCodeChange.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_7789867021953041_emit_4656_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_7789867021953041_reload = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7789867021953041_reload.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_309515315410323_emit_4660_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_309515315410323_close_4659_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data.modalResult.value];
            });
        });
    };
    UcInvoiceLines.prototype.act_309515315410323_showModal_4658_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "InvoiceLineGroup",
                        props: {
                            invoice: this.invoice,
                        },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_309515315410323_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_309515315410323_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_4717426290970457_script_4662_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $event.data.waitFor(this.$fn.crud('invoice_line')
                    .upsert({
                    id: $event.scope.row.id,
                    etag: $event.scope.row.etag,
                    meter_accounting: $event.data.row,
                }));
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_4717426290970457_script_4662_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_4717426290970457_script_4662_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_4717426290970457_save = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4717426290970457_save.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_4717426290970457_emit_4664_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_4717426290970457_saveCompleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4717426290970457_saveCompleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_4717426290970457_script_4666_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $event.data.waitFor(this.$fn.crud('invoice_line_meter_accounting').delete($event.data.row));
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_4717426290970457_script_4666_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_4717426290970457_script_4666_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_4717426290970457_delete = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4717426290970457_delete.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_4717426290970457_emit_4668_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_4717426290970457_deleteCompleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_4717426290970457_deleteCompleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_1803307708030275_script_4670_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                $event.data.waitFor(this.$fn.crud('invoice_line_accounting').upsert($event.data.row)
                    .then(function (p) { return (_this.$emit('reload'), p); }));
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_1803307708030275_script_4670_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_1803307708030275_script_4670_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_1803307708030275_save = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1803307708030275_save.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_1803307708030275_script_4672_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $event.data.waitFor(this.$fn.crud('invoice_line_accounting').delete($event.data.row));
                return [2];
            });
        });
    };
    UcInvoiceLines.prototype.act_1803307708030275_script_4672_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_1803307708030275_script_4672_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_1803307708030275_delete = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1803307708030275_delete.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_1803307708030275_emit_4674_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_1803307708030275_saveCompleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1803307708030275_saveCompleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.act_1803307708030275_emit_4676_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "reload",
                    }];
            });
        });
    };
    UcInvoiceLines.prototype.evh_1803307708030275_deleteCompleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1803307708030275_deleteCompleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcInvoiceLines.prototype.getActions = function () {
        var act_5962862420439144_emit_4630 = new core_1.actions.EmitAction({
            actionArgs: this.act_5962862420439144_emit_4630_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_saveCompleted_4629 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_emit_4630,
            event: "save-completed",
            displayName: "emit",
        });
        var evh_5962862420439144_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_saveCompleted_4629],
        });
        var act_5962862420439144_script_4632 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4632_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_save_4631 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4632,
            event: "save",
            displayName: "script",
        });
        var evh_5962862420439144_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_save_4631],
        });
        var act_5962862420439144_script_4636 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4636_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_close_4635 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4636,
            event: "close",
            displayName: "script",
        });
        var act_5962862420439144_showModal_4634 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5962862420439144_showModal_4634_getActionArgs,
            events: [evh_5962862420439144_close_4635],
        });
        var evh_5962862420439144_createProduct_4633 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_showModal_4634,
            event: "createProduct",
            displayName: "showModal",
        });
        var evh_5962862420439144_createProduct = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_createProduct_4633],
        });
        var act_5962862420439144_script_4638 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4638_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_delete_4637 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4638,
            event: "delete",
            displayName: "script",
        });
        var evh_5962862420439144_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_delete_4637],
        });
        var act_5962862420439144_script_4640 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4640_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_productSelected_4639 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4640,
            event: "product:selected",
            displayName: "script",
        });
        var evh_5962862420439144_productSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_productSelected_4639],
        });
        var act_5962862420439144_script_4642 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4642_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_productChange_4641 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4642,
            event: "product:change",
            displayName: "script",
        });
        var evh_5962862420439144_productChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_productChange_4641],
        });
        var act_5962862420439144_script_4644 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4644_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_quantityChange_4643 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4644,
            event: "quantity:change",
            displayName: "script",
        });
        var evh_5962862420439144_quantityChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_quantityChange_4643],
        });
        var act_5962862420439144_script_4646 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4646_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_priceChange_4645 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4646,
            event: "price:change",
            displayName: "script",
        });
        var evh_5962862420439144_priceChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_priceChange_4645],
        });
        var act_5962862420439144_script_4648 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4648_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_discountChange_4647 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4648,
            event: "discount:change",
            displayName: "script",
        });
        var evh_5962862420439144_discountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_discountChange_4647],
        });
        var act_5962862420439144_script_4650 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4650_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_netAmountChange_4649 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4650,
            event: "net_amount:change",
            displayName: "script",
        });
        var evh_5962862420439144_netAmountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_netAmountChange_4649],
        });
        var act_5962862420439144_script_4652 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4652_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_amountChange_4651 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4652,
            event: "amount:change",
            displayName: "script",
        });
        var evh_5962862420439144_amountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_amountChange_4651],
        });
        var act_5962862420439144_script_4654 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_4654_getActionArgs,
            events: [],
        });
        var evh_5962862420439144_taxCodeChange_4653 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_4654,
            event: "tax_code:change",
            displayName: "script",
        });
        var evh_5962862420439144_taxCodeChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_taxCodeChange_4653],
        });
        var act_7789867021953041_emit_4656 = new core_1.actions.EmitAction({
            actionArgs: this.act_7789867021953041_emit_4656_getActionArgs,
            events: [],
        });
        var evh_7789867021953041_reload_4655 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_emit_4656,
            event: "reload",
            displayName: "emit",
        });
        var evh_7789867021953041_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_reload_4655],
        });
        var act_309515315410323_emit_4660 = new core_1.actions.EmitAction({
            actionArgs: this.act_309515315410323_emit_4660_getActionArgs,
            events: [],
        });
        var evh_309515315410323_close_4659 = new core_1.actions.EventHandlerImpl({
            action: act_309515315410323_emit_4660,
            event: "close",
            when: this.evh_309515315410323_close_4659_getWhen,
            displayName: "emit",
        });
        var act_309515315410323_showModal_4658 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_309515315410323_showModal_4658_getActionArgs,
            events: [evh_309515315410323_close_4659],
        });
        var evh_309515315410323_clickPreventStop_4657 = new core_1.actions.EventHandlerImpl({
            action: act_309515315410323_showModal_4658,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_309515315410323_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_309515315410323_clickPreventStop_4657],
        });
        var act_4717426290970457_script_4662 = new core_1.actions.ScriptAction({
            actionArgs: this.act_4717426290970457_script_4662_getActionArgs,
            events: [],
        });
        var evh_4717426290970457_save_4661 = new core_1.actions.EventHandlerImpl({
            action: act_4717426290970457_script_4662,
            event: "save",
            displayName: "script",
        });
        var evh_4717426290970457_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4717426290970457_save_4661],
        });
        var act_4717426290970457_emit_4664 = new core_1.actions.EmitAction({
            actionArgs: this.act_4717426290970457_emit_4664_getActionArgs,
            events: [],
        });
        var evh_4717426290970457_saveCompleted_4663 = new core_1.actions.EventHandlerImpl({
            action: act_4717426290970457_emit_4664,
            event: "save-completed",
            displayName: "emit",
        });
        var evh_4717426290970457_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4717426290970457_saveCompleted_4663],
        });
        var act_4717426290970457_script_4666 = new core_1.actions.ScriptAction({
            actionArgs: this.act_4717426290970457_script_4666_getActionArgs,
            events: [],
        });
        var evh_4717426290970457_delete_4665 = new core_1.actions.EventHandlerImpl({
            action: act_4717426290970457_script_4666,
            event: "delete",
            displayName: "script",
        });
        var evh_4717426290970457_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4717426290970457_delete_4665],
        });
        var act_4717426290970457_emit_4668 = new core_1.actions.EmitAction({
            actionArgs: this.act_4717426290970457_emit_4668_getActionArgs,
            events: [],
        });
        var evh_4717426290970457_deleteCompleted_4667 = new core_1.actions.EventHandlerImpl({
            action: act_4717426290970457_emit_4668,
            event: "delete-completed",
            displayName: "emit",
        });
        var evh_4717426290970457_deleteCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4717426290970457_deleteCompleted_4667],
        });
        var act_1803307708030275_script_4670 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1803307708030275_script_4670_getActionArgs,
            events: [],
        });
        var evh_1803307708030275_save_4669 = new core_1.actions.EventHandlerImpl({
            action: act_1803307708030275_script_4670,
            event: "save",
            displayName: "script",
        });
        var evh_1803307708030275_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1803307708030275_save_4669],
        });
        var act_1803307708030275_script_4672 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1803307708030275_script_4672_getActionArgs,
            events: [],
        });
        var evh_1803307708030275_delete_4671 = new core_1.actions.EventHandlerImpl({
            action: act_1803307708030275_script_4672,
            event: "delete",
            displayName: "script",
        });
        var evh_1803307708030275_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1803307708030275_delete_4671],
        });
        var act_1803307708030275_emit_4674 = new core_1.actions.EmitAction({
            actionArgs: this.act_1803307708030275_emit_4674_getActionArgs,
            events: [],
        });
        var evh_1803307708030275_saveCompleted_4673 = new core_1.actions.EventHandlerImpl({
            action: act_1803307708030275_emit_4674,
            event: "save-completed",
            displayName: "emit",
        });
        var evh_1803307708030275_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1803307708030275_saveCompleted_4673],
        });
        var act_1803307708030275_emit_4676 = new core_1.actions.EmitAction({
            actionArgs: this.act_1803307708030275_emit_4676_getActionArgs,
            events: [],
        });
        var evh_1803307708030275_deleteCompleted_4675 = new core_1.actions.EventHandlerImpl({
            action: act_1803307708030275_emit_4676,
            event: "delete-completed",
            displayName: "emit",
        });
        var evh_1803307708030275_deleteCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1803307708030275_deleteCompleted_4675],
        });
        return {
            act_5962862420439144_emit_4630: act_5962862420439144_emit_4630,
            evh_5962862420439144_saveCompleted_4629: evh_5962862420439144_saveCompleted_4629,
            evh_5962862420439144_saveCompleted: evh_5962862420439144_saveCompleted,
            act_5962862420439144_script_4632: act_5962862420439144_script_4632,
            evh_5962862420439144_save_4631: evh_5962862420439144_save_4631,
            evh_5962862420439144_save: evh_5962862420439144_save,
            act_5962862420439144_script_4636: act_5962862420439144_script_4636,
            evh_5962862420439144_close_4635: evh_5962862420439144_close_4635,
            act_5962862420439144_showModal_4634: act_5962862420439144_showModal_4634,
            evh_5962862420439144_createProduct_4633: evh_5962862420439144_createProduct_4633,
            evh_5962862420439144_createProduct: evh_5962862420439144_createProduct,
            act_5962862420439144_script_4638: act_5962862420439144_script_4638,
            evh_5962862420439144_delete_4637: evh_5962862420439144_delete_4637,
            evh_5962862420439144_delete: evh_5962862420439144_delete,
            act_5962862420439144_script_4640: act_5962862420439144_script_4640,
            evh_5962862420439144_productSelected_4639: evh_5962862420439144_productSelected_4639,
            evh_5962862420439144_productSelected: evh_5962862420439144_productSelected,
            act_5962862420439144_script_4642: act_5962862420439144_script_4642,
            evh_5962862420439144_productChange_4641: evh_5962862420439144_productChange_4641,
            evh_5962862420439144_productChange: evh_5962862420439144_productChange,
            act_5962862420439144_script_4644: act_5962862420439144_script_4644,
            evh_5962862420439144_quantityChange_4643: evh_5962862420439144_quantityChange_4643,
            evh_5962862420439144_quantityChange: evh_5962862420439144_quantityChange,
            act_5962862420439144_script_4646: act_5962862420439144_script_4646,
            evh_5962862420439144_priceChange_4645: evh_5962862420439144_priceChange_4645,
            evh_5962862420439144_priceChange: evh_5962862420439144_priceChange,
            act_5962862420439144_script_4648: act_5962862420439144_script_4648,
            evh_5962862420439144_discountChange_4647: evh_5962862420439144_discountChange_4647,
            evh_5962862420439144_discountChange: evh_5962862420439144_discountChange,
            act_5962862420439144_script_4650: act_5962862420439144_script_4650,
            evh_5962862420439144_netAmountChange_4649: evh_5962862420439144_netAmountChange_4649,
            evh_5962862420439144_netAmountChange: evh_5962862420439144_netAmountChange,
            act_5962862420439144_script_4652: act_5962862420439144_script_4652,
            evh_5962862420439144_amountChange_4651: evh_5962862420439144_amountChange_4651,
            evh_5962862420439144_amountChange: evh_5962862420439144_amountChange,
            act_5962862420439144_script_4654: act_5962862420439144_script_4654,
            evh_5962862420439144_taxCodeChange_4653: evh_5962862420439144_taxCodeChange_4653,
            evh_5962862420439144_taxCodeChange: evh_5962862420439144_taxCodeChange,
            act_7789867021953041_emit_4656: act_7789867021953041_emit_4656,
            evh_7789867021953041_reload_4655: evh_7789867021953041_reload_4655,
            evh_7789867021953041_reload: evh_7789867021953041_reload,
            act_309515315410323_emit_4660: act_309515315410323_emit_4660,
            evh_309515315410323_close_4659: evh_309515315410323_close_4659,
            act_309515315410323_showModal_4658: act_309515315410323_showModal_4658,
            evh_309515315410323_clickPreventStop_4657: evh_309515315410323_clickPreventStop_4657,
            evh_309515315410323_clickPreventStop: evh_309515315410323_clickPreventStop,
            act_4717426290970457_script_4662: act_4717426290970457_script_4662,
            evh_4717426290970457_save_4661: evh_4717426290970457_save_4661,
            evh_4717426290970457_save: evh_4717426290970457_save,
            act_4717426290970457_emit_4664: act_4717426290970457_emit_4664,
            evh_4717426290970457_saveCompleted_4663: evh_4717426290970457_saveCompleted_4663,
            evh_4717426290970457_saveCompleted: evh_4717426290970457_saveCompleted,
            act_4717426290970457_script_4666: act_4717426290970457_script_4666,
            evh_4717426290970457_delete_4665: evh_4717426290970457_delete_4665,
            evh_4717426290970457_delete: evh_4717426290970457_delete,
            act_4717426290970457_emit_4668: act_4717426290970457_emit_4668,
            evh_4717426290970457_deleteCompleted_4667: evh_4717426290970457_deleteCompleted_4667,
            evh_4717426290970457_deleteCompleted: evh_4717426290970457_deleteCompleted,
            act_1803307708030275_script_4670: act_1803307708030275_script_4670,
            evh_1803307708030275_save_4669: evh_1803307708030275_save_4669,
            evh_1803307708030275_save: evh_1803307708030275_save,
            act_1803307708030275_script_4672: act_1803307708030275_script_4672,
            evh_1803307708030275_delete_4671: evh_1803307708030275_delete_4671,
            evh_1803307708030275_delete: evh_1803307708030275_delete,
            act_1803307708030275_emit_4674: act_1803307708030275_emit_4674,
            evh_1803307708030275_saveCompleted_4673: evh_1803307708030275_saveCompleted_4673,
            evh_1803307708030275_saveCompleted: evh_1803307708030275_saveCompleted,
            act_1803307708030275_emit_4676: act_1803307708030275_emit_4676,
            evh_1803307708030275_deleteCompleted_4675: evh_1803307708030275_deleteCompleted_4675,
            evh_1803307708030275_deleteCompleted: evh_1803307708030275_deleteCompleted,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], UcInvoiceLines.prototype, "invoice", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('invoice'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcInvoiceLines.prototype, "onInvoice", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], UcInvoiceLines.prototype, "currency", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('currency'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcInvoiceLines.prototype, "onCurrency", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Number,
        }),
        __metadata("design:type", Number)
    ], UcInvoiceLines.prototype, "defaultDiscount", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('defaultDiscount'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcInvoiceLines.prototype, "onDefault_discount", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], UcInvoiceLines.prototype, "defaultTaxCode", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('defaultTaxCode'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcInvoiceLines.prototype, "onDefault_tax_code", null);
    UcInvoiceLines = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], UcInvoiceLines);
    return UcInvoiceLines;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = UcInvoiceLines;
vue_property_decorator_1.Vue.component("UcInvoiceLines", UcInvoiceLines);
