"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var FieldsetInvoiceRegisterEntry = (function (_super) {
    __extends(FieldsetInvoiceRegisterEntry, _super);
    function FieldsetInvoiceRegisterEntry() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['invoiceTypes', 'paymentMethods', 'categories', 'statuses', 'currencies', 'ux', 'b_1548630417156826_dataSource', 'b_8680084583475136_dataSource'];
        return _this;
    }
    FieldsetInvoiceRegisterEntry.prototype.beforeCreate = function () {
    };
    FieldsetInvoiceRegisterEntry.prototype.onItem = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("item");
    };
    FieldsetInvoiceRegisterEntry.prototype.onLabel_align = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("labelAlign");
    };
    FieldsetInvoiceRegisterEntry.prototype.onForm = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("form");
    };
    FieldsetInvoiceRegisterEntry.prototype.$$load_invoiceTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.getEnumValuesFromSchema('InvoiceType')];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.$$load_paymentMethods = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_payment_method')
                        .then(function (data) { return _.map(data, function (p) { return ({
                        value: p.id,
                        text: _this.$fn.pgettext('PaymentMethod', p.name)
                    }); }); })];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.$$load_categories = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice' } }).then(function (data) { return _.map(data, function (p) { return p.name; }); })];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.$$load_statuses = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice-register-status' } }).then(function (data) { return _.map(data, function (p) { return p.name; }); })];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.$$load_b_1548630417156826_dataSource = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "suggest_invoice_register_entry",
                        parameters: {
                            source: this.item.id,
                        },
                    }];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            invoiceTypes: null,
            paymentMethods: null,
            categories: null,
            statuses: null,
            currencies: ["HUF",
                "EUR",
                "USD",
            ],
            ux: null,
            b_1548630417156826_dataSource: null,
            b_8680084583475136_dataSource: {
                name: "suggest_client",
            },
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.evh_7315092382398562_reload = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.act_2524784394460522_emit_2452_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "close",
                    }];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.evh_2524784394460522_click = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2524784394460522_click.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.act_7445225937215608_emit_2458_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "changed",
                        value: $event.data.response.data,
                    }];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.act_7445225937215608_request_2456_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "update_invoice_register_entry",
                        data: {
                            id: this.item.id,
                            etag: this.item.etag,
                            invoice_id: $event.data.modalResult.value.id
                        },
                    }];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.evh_7445225937215608_close_2455_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.modalResult.ok];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.act_7445225937215608_showModal_2454_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "InvoiceBrowser",
                        props: {
                            invoice_kind: this.item.invoice_kind,
                            company_id: this.item.company_id,
                            client_id: this.item.client_id,
                            invoice_number: this.item.invoice_number,
                            invoice_date: this.item.fulfillment_date,
                        },
                    }];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.evh_7445225937215608_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7445225937215608_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.act_3283186227685836_emit_2460_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        event: "close",
                    }];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.act_3283186227685836_showModal_2462_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateInvoice",
                        props: {
                            invoiceKind: this.item.invoice_kind,
                            invoiceType: this.item.invoice_type,
                            invoiceRegisterEntry: this.item.id,
                        },
                    }];
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.evh_3283186227685836_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3283186227685836_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    FieldsetInvoiceRegisterEntry.prototype.getActions = function () {
        var act_7315092382398562_reloadComponentData_2450 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_7315092382398562_reload_2449 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_2450,
            event: "reload",
            displayName: "reloadComponentData",
        });
        var evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_2449],
        });
        var act_2524784394460522_emit_2452 = new core_1.actions.EmitAction({
            actionArgs: this.act_2524784394460522_emit_2452_getActionArgs,
            events: [],
        });
        var evh_2524784394460522_click_2451 = new core_1.actions.EventHandlerImpl({
            action: act_2524784394460522_emit_2452,
            event: "click",
            displayName: "emit",
        });
        var evh_2524784394460522_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2524784394460522_click_2451],
        });
        var act_7445225937215608_emit_2458 = new core_1.actions.EmitAction({
            actionArgs: this.act_7445225937215608_emit_2458_getActionArgs,
            events: [],
        });
        var evh_7445225937215608_success_2457 = new core_1.actions.EventHandlerImpl({
            action: act_7445225937215608_emit_2458,
            event: "success",
            displayName: "emit",
        });
        var act_7445225937215608_request_2456 = new core_1.actions.RequestAction({
            actionArgs: this.act_7445225937215608_request_2456_getActionArgs,
            displayName: "onInvoiceSelectedAction",
            events: [evh_7445225937215608_success_2457],
        });
        var evh_7445225937215608_close_2455 = new core_1.actions.EventHandlerImpl({
            action: act_7445225937215608_request_2456,
            event: "close",
            when: this.evh_7445225937215608_close_2455_getWhen,
            displayName: "onInvoiceSelectedAction",
        });
        var act_7445225937215608_showModal_2454 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7445225937215608_showModal_2454_getActionArgs,
            events: [evh_7445225937215608_close_2455],
        });
        var evh_7445225937215608_clickPreventStop_2453 = new core_1.actions.EventHandlerImpl({
            action: act_7445225937215608_showModal_2454,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_7445225937215608_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7445225937215608_clickPreventStop_2453],
        });
        var act_3283186227685836_emit_2460 = new core_1.actions.EmitAction({
            actionArgs: this.act_3283186227685836_emit_2460_getActionArgs,
            events: [],
        });
        var evh_3283186227685836_clickPreventStop_2459 = new core_1.actions.EventHandlerImpl({
            action: act_3283186227685836_emit_2460,
            event: "click.prevent.stop",
            displayName: "emit",
        });
        var act_3283186227685836_showModal_2462 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3283186227685836_showModal_2462_getActionArgs,
            events: [],
        });
        var evh_3283186227685836_clickPreventStop_2461 = new core_1.actions.EventHandlerImpl({
            action: act_3283186227685836_showModal_2462,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        var evh_3283186227685836_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3283186227685836_clickPreventStop_2459, evh_3283186227685836_clickPreventStop_2461],
        });
        return {
            act_7315092382398562_reloadComponentData_2450: act_7315092382398562_reloadComponentData_2450,
            evh_7315092382398562_reload_2449: evh_7315092382398562_reload_2449,
            evh_7315092382398562_reload: evh_7315092382398562_reload,
            act_2524784394460522_emit_2452: act_2524784394460522_emit_2452,
            evh_2524784394460522_click_2451: evh_2524784394460522_click_2451,
            evh_2524784394460522_click: evh_2524784394460522_click,
            act_7445225937215608_emit_2458: act_7445225937215608_emit_2458,
            evh_7445225937215608_success_2457: evh_7445225937215608_success_2457,
            act_7445225937215608_request_2456: act_7445225937215608_request_2456,
            evh_7445225937215608_close_2455: evh_7445225937215608_close_2455,
            act_7445225937215608_showModal_2454: act_7445225937215608_showModal_2454,
            evh_7445225937215608_clickPreventStop_2453: evh_7445225937215608_clickPreventStop_2453,
            evh_7445225937215608_clickPreventStop: evh_7445225937215608_clickPreventStop,
            act_3283186227685836_emit_2460: act_3283186227685836_emit_2460,
            evh_3283186227685836_clickPreventStop_2459: evh_3283186227685836_clickPreventStop_2459,
            act_3283186227685836_showModal_2462: act_3283186227685836_showModal_2462,
            evh_3283186227685836_clickPreventStop_2461: evh_3283186227685836_clickPreventStop_2461,
            evh_3283186227685836_clickPreventStop: evh_3283186227685836_clickPreventStop,
            onInvoiceSelectedAction: act_7445225937215608_request_2456,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], FieldsetInvoiceRegisterEntry.prototype, "item", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('item'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], FieldsetInvoiceRegisterEntry.prototype, "onItem", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "right",
        }),
        __metadata("design:type", String)
    ], FieldsetInvoiceRegisterEntry.prototype, "labelAlign", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('labelAlign'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], FieldsetInvoiceRegisterEntry.prototype, "onLabel_align", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Object,
        }),
        __metadata("design:type", Object)
    ], FieldsetInvoiceRegisterEntry.prototype, "form", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('form'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], FieldsetInvoiceRegisterEntry.prototype, "onForm", null);
    FieldsetInvoiceRegisterEntry = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], FieldsetInvoiceRegisterEntry);
    return FieldsetInvoiceRegisterEntry;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = FieldsetInvoiceRegisterEntry;
vue_property_decorator_1.Vue.component("FieldsetInvoiceRegisterEntry", FieldsetInvoiceRegisterEntry);
