<template>
<DIV
  :class="[
    {
      'modal-header': modal,
      'shadow-sm': modal,
      'card-header': card,
      'bg-gray-100': modal || card,
    },
    borderClass
  ]"
>
  <ItpBox
    alignItems="center"
    :class="b_2248226175642056_class"
  >
    <ItpText
      tag="h5"
      capitalize
      muted
      class="mb-0"
      v-if="dialogName"
    >
      {{ dialogName }}
    </ItpText>
    <span
      class="m-0 p-0"
      v-if="dialogName && title"
    >
      &middot;
    </span>
    <ItpText
      tag="h4"
      :text="title"
      class="mb-0"
      v-if="title"
    >
    </ItpText>
    <slot>
    </slot>
  </ItpBox>
  <button
    data-dismiss="modal"
    aria-label="Close"
    class="close ml-auto mr-1"
    v-if="modal"
    @click="evh_5962862420439144_click($event, {})"
  >
    <span aria-hidden="true">&times;</span>

  </button>
</DIV>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Boolean,
  })
  modal!: boolean;

  @Watch('modal')
  onModal(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("modal")
  }

  @Prop({
    type: Boolean,
  })
  card!: boolean;

  @Watch('card')
  onCard(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("card")
  }

  @Prop({
    type: String,
  })
  dialogName!: string;

  @Watch('dialogName')
  onDialog_name(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("dialogName")
  }

  @Prop({
    type: String,
  })
  title!: string;

  @Watch('title')
  onTitle(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("title")
  }

  @Prop({
    type: String,
  })
  color!: string;

  @Watch('color')
  onColor(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("color")
  }

  ux!: any;
  b_2248226175642056_class!: any;
  dataMembers = ['ux', 'b_2248226175642056_class'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_2248226175642056_class: {
          "items-spacing": true,
          "w-100": true,
          "mr-3": true,
        }
        ,
      },
    }
  }

  get borderClass() {
    if (this.loaded) {

      return this.color ? 'uc-border border-top-' + this.color : ''
    }
    return null;
  }

  async act_5962862420439144_emit_3342_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5962862420439144_emit_3342, alias=undefined
    return {
      event: "close",
    }
  }

  async evh_5962862420439144_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_5962862420439144_emit_3342: actions.EmitAction;
    evh_5962862420439144_click_3341: actions.EventHandlerImpl;
    evh_5962862420439144_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_5962862420439144_emit_3342 = new actions.EmitAction(
      {
        actionArgs: this.act_5962862420439144_emit_3342_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_click_3341 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_emit_3342,
        event: "click",
        displayName: "emit",
      }
    );
    const evh_5962862420439144_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_click_3341],
      }
    );
    return {
      act_5962862420439144_emit_3342,
      evh_5962862420439144_click_3341,
      evh_5962862420439144_click,
    }
  }
}

Vue.component("UcHeader", UcHeader);

</script>