<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="3"
    name="received_date"
    :label="'Entry date'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormDatetime
      name="received_date"
      v-model="item.received_date"
      required
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="8"
    name="description"
    :label="'Subject'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="description"
      v-model="item.description"
      required
      autofocus
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="ref_entry_id"
    :label="'Reference entry'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
    labelColsMd="3"
    contentColsMd="8"
  >
    <ItpFormSelect2
      name="ref_entry_id"
      v-model="item.ref_entry_id"
      :dataSource="b_1548630417156826_dataSource"
      :initialOptions="item.ref_entry ? [item.ref_entry] : []"
      valueField="id"
      clearable
      optionTemplate="<UcSuggestInvoiceRegisterEntryOption :option=option></UcSuggestInvoiceRegisterEntryOption>"
      use-option-template-for-selected-option
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <hr>
  </hr>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="8"
    name="invoice_number"
    :label="'Invoice number'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="invoice_number"
      v-model="item.invoice_number"
      required
      class="mb-2"
    >
    </ItpFormInput>
    <ItpBox
      direction="column"
    >
      <ItpBox
        v-if="item.invoice_id"
      >
        <ItpLink
          :to="{
    name: 'app.invoice',
    params: { id: item.invoice_id }
  }"
          class="mb-2"
          @click="evh_2524784394460522_click($event, {})"
        >
          <span>
            {{ item.reference_invoice_number }}
          </span>
          <Icon
            :icon="$config.externalLinkIcon"
          >
          </Icon>
        </ItpLink>
        <DIV
          :title="'Invoice number mismatch'|pgettext('InvoiceRegisterEntry')"
          class="ml-3"
          v-if="item.reference_invoice_number != item.invoice_number"
        >
          <Icon
            icon="emojione-v1:warning"
          >
          </Icon>
        </DIV>
      </ItpBox>
      <ItpBox>
        <ItpButton
          :text="'Assign...'|pgettext('InvoiceRegisterEntry')"
          size="sm"
          variant="light"
          :tooltip="'Assign invoice.'|pgettext('InvoiceRegisterEntry')"
          :icon="$config.assignIcon"
          class="mr-2"
          @click.prevent.stop="evh_7445225937215608_clickPreventStop($event, {})"
        >
        </ItpButton>
        <ItpButton
          :text="'Record...'|pgettext('InvoiceRegisterEntry')"
          size="sm"
          variant="light"
          :tooltip="'Record a new invoice.'|pgettext('InvoiceRegisterEntry')"
          :icon="$config.addIcon"
          v-if="item.id"
          @click.prevent.stop="evh_3283186227685836_clickPreventStop($event, {})"
        >
        </ItpButton>
      </ItpBox>
    </ItpBox>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="3"
    name="invoice_type"
    :label="'Invoice type'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect2
      name="invoice_type"
      v-model="item.invoice_type"
      :options="invoiceTypes"
      valueField="value"
      textField="text"
      required
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="8"
    name="status"
    :label="'Status'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="status"
      taggable
      :options="statuses"
      v-model="item.status"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="8"
    name="categories"
    :label="'Categories'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="categories"
      multiple
      taggable
      :options="categories"
      v-model="item.categories"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="8"
    name="client"
    :labelAlign="labelAlign"
    :label="'Client'|pgettext('InvoiceRegisterEntry')"
    required
  >
    <ItpFormSelect2
      name="contractor"
      v-model="item.client_id"
      :dataSource="b_8680084583475136_dataSource"
      valueField="id"
      required
      optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
      use-option-template-for-selected-option
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="3"
    name="fulfillment_date"
    :label="'Fulfillment date'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormDatetime
      name="fulfillment_date"
      v-model="item.fulfillment_date"
      required
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="3"
    name="due_date"
    :label="'Due date'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormDatetime
      name="due_date"
      v-model="item.due_date"
      required
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="3"
    name="payment_method"
    :label="'Payment method'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
    required
    class="col"
  >
    <ItpFormSelect
      name="payment_method"
      v-model="item.payment_method"
      :options="paymentMethods"
      required
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="5"
    name="amount"
    :label="'Amount'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
    required
    contentClass="input-group"
    class="col"
  >
    <ItpFormInput
      type="number"
      name="amount_amount"
      v-model="item.amount.amount"
      required
    >
    </ItpFormInput>
    <DIV
      class="input-group-append w-40"
    >
      <ItpFormSelect
        name="amount_currency"
        v-model="item.amount.currency"
        :options="currencies"
        required
      >
      </ItpFormSelect>
    </DIV>
  </ItpFormGroup>
  <hr>
  </hr>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="8"
    name="notes"
    :label="'Notes'|pgettext('InvoiceRegisterEntry')"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="notes"
      v-model="item.notes"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="8"
    v-if="item.isNew"
  >
    <ItpButton
      :form="form"
      :text="'Create entry'|pgettext('InvoiceRegisterEntry/Button')"
      type="submit"
      class="mr-2"
    >
    </ItpButton>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="3"
    contentColsMd="8"
    v-if="!item.isNew && !item.isReadOnly"
  >
    <ItpButton
      :form="form"
      :text="'Save changes'|pgettext('Button')"
      type="submit"
      class="mr-2"
    >
    </ItpButton>
  </ItpFormGroup>
  <hr
    v-if="item.id"
  >
  </hr>
  <UcAttachmentsList
    :parent="item"
    v-if="item.id"
  >
  </UcAttachmentsList>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetInvoiceRegisterEntry extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    type: Object,
  })
  form!: object;

  @Watch('form')
  onForm(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("form")
  }

  invoiceTypes!: any;

  async $$load_invoiceTypes() {
    return this.$fn.getEnumValuesFromSchema('InvoiceType')
  }

  paymentMethods!: any;

  async $$load_paymentMethods() {
    return this.$fn.fetch('list_payment_method')
      .then(data => _.map(data, p => ({
        value: p.id,
        text: this.$fn.pgettext('PaymentMethod', p.name)
      })))
  }

  categories!: any;

  async $$load_categories() {
    return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice' } }).then(data => _.map(data, p => p.name))
  }

  statuses!: any;

  async $$load_statuses() {
    return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice-register-status' } }).then(data => _.map(data, p => p.name))
  }

  currencies!: any;
  ux!: any;
  b_1548630417156826_dataSource!: any;

  async $$load_b_1548630417156826_dataSource() {
    return {
      name: "suggest_invoice_register_entry",
      parameters: {
        source: this.item.id,
      }
      ,
    }
  }

  b_8680084583475136_dataSource!: any;
  dataMembers = ['invoiceTypes', 'paymentMethods', 'categories', 'statuses', 'currencies', 'ux', 'b_1548630417156826_dataSource', 'b_8680084583475136_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        invoiceTypes: null,
        paymentMethods: null,
        categories: null,
        statuses: null,
        currencies: ["HUF"
          ,
          "EUR"
          ,
          "USD"
          ,
        ]
        ,
        ux: null,
        b_1548630417156826_dataSource: null,
        b_8680084583475136_dataSource: {
          name: "suggest_client",
        }
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_2524784394460522_emit_2452_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2524784394460522_emit_2452, alias=undefined
    return {
      event: "close",
    }
  }

  async evh_2524784394460522_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2524784394460522_click.executeFromDOM(this, event, scope);
  }

  async act_7445225937215608_emit_2458_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7445225937215608_emit_2458, alias=undefined
    return {
      event: "changed",
      value: $event.data.response.data,
    }
  }

  async act_7445225937215608_request_2456_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7445225937215608_request_2456, alias=onInvoiceSelectedAction
    return {
      operation: "update_invoice_register_entry",
      data: {
        id: this.item.id,
        etag: this.item.etag,
        invoice_id: $event.data.modalResult.value.id
      },
    }
  }

  async evh_7445225937215608_close_2455_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7445225937215608_close_2455, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_7445225937215608_showModal_2454_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7445225937215608_showModal_2454, alias=undefined
    return {
      name: "InvoiceBrowser",
      props: {
        invoice_kind: this.item.invoice_kind,
        company_id: this.item.company_id,
        client_id: this.item.client_id,
        invoice_number: this.item.invoice_number,
        invoice_date: this.item.fulfillment_date,
      }
      ,
    }
  }

  async evh_7445225937215608_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7445225937215608_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_3283186227685836_emit_2460_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3283186227685836_emit_2460, alias=undefined
    return {
      event: "close",
    }
  }

  async act_3283186227685836_showModal_2462_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3283186227685836_showModal_2462, alias=undefined
    return {
      name: "CreateInvoice",
      props: {
        invoiceKind: this.item.invoice_kind,
        invoiceType: this.item.invoice_type,
        invoiceRegisterEntry: this.item.id,
      }
      ,
    }
  }

  async evh_3283186227685836_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3283186227685836_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2450: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2449: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_2524784394460522_emit_2452: actions.EmitAction;
    evh_2524784394460522_click_2451: actions.EventHandlerImpl;
    evh_2524784394460522_click: actions.EventHandlerGroup;
    act_7445225937215608_emit_2458: actions.EmitAction;
    evh_7445225937215608_success_2457: actions.EventHandlerImpl;
    act_7445225937215608_request_2456: actions.RequestAction;
    evh_7445225937215608_close_2455: actions.EventHandlerImpl;
    act_7445225937215608_showModal_2454: actions.ShowModalAction;
    evh_7445225937215608_clickPreventStop_2453: actions.EventHandlerImpl;
    evh_7445225937215608_clickPreventStop: actions.EventHandlerGroup;
    act_3283186227685836_emit_2460: actions.EmitAction;
    evh_3283186227685836_clickPreventStop_2459: actions.EventHandlerImpl;
    act_3283186227685836_showModal_2462: actions.ShowModalAction;
    evh_3283186227685836_clickPreventStop_2461: actions.EventHandlerImpl;
    evh_3283186227685836_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2450 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2449 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2450,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2449],
      }
    );
    const act_2524784394460522_emit_2452 = new actions.EmitAction(
      {
        actionArgs: this.act_2524784394460522_emit_2452_getActionArgs,
        events: [],
      }
    );
    const evh_2524784394460522_click_2451 = new actions.EventHandlerImpl(
      {
        action: act_2524784394460522_emit_2452,
        event: "click",
        displayName: "emit",
      }
    );
    const evh_2524784394460522_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_2524784394460522_click_2451],
      }
    );
    const act_7445225937215608_emit_2458 = new actions.EmitAction(
      {
        actionArgs: this.act_7445225937215608_emit_2458_getActionArgs,
        events: [],
      }
    );
    const evh_7445225937215608_success_2457 = new actions.EventHandlerImpl(
      {
        action: act_7445225937215608_emit_2458,
        event: "success",
        displayName: "emit",
      }
    );
    const act_7445225937215608_request_2456 = new actions.RequestAction(
      {
        actionArgs: this.act_7445225937215608_request_2456_getActionArgs,
        displayName: "onInvoiceSelectedAction",
        events: [evh_7445225937215608_success_2457],
      }
    );
    const evh_7445225937215608_close_2455 = new actions.EventHandlerImpl(
      {
        action: act_7445225937215608_request_2456,
        event: "close",
        when: this.evh_7445225937215608_close_2455_getWhen,
        displayName: "onInvoiceSelectedAction",
      }
    );
    const act_7445225937215608_showModal_2454 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7445225937215608_showModal_2454_getActionArgs,
        events: [evh_7445225937215608_close_2455],
      }
    );
    const evh_7445225937215608_clickPreventStop_2453 = new actions.EventHandlerImpl(
      {
        action: act_7445225937215608_showModal_2454,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_7445225937215608_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7445225937215608_clickPreventStop_2453],
      }
    );
    const act_3283186227685836_emit_2460 = new actions.EmitAction(
      {
        actionArgs: this.act_3283186227685836_emit_2460_getActionArgs,
        events: [],
      }
    );
    const evh_3283186227685836_clickPreventStop_2459 = new actions.EventHandlerImpl(
      {
        action: act_3283186227685836_emit_2460,
        event: "click.prevent.stop",
        displayName: "emit",
      }
    );
    const act_3283186227685836_showModal_2462 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3283186227685836_showModal_2462_getActionArgs,
        events: [],
      }
    );
    const evh_3283186227685836_clickPreventStop_2461 = new actions.EventHandlerImpl(
      {
        action: act_3283186227685836_showModal_2462,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_3283186227685836_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3283186227685836_clickPreventStop_2459, evh_3283186227685836_clickPreventStop_2461],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2450,
      evh_7315092382398562_reload_2449,
      evh_7315092382398562_reload,
      act_2524784394460522_emit_2452,
      evh_2524784394460522_click_2451,
      evh_2524784394460522_click,
      act_7445225937215608_emit_2458,
      evh_7445225937215608_success_2457,
      act_7445225937215608_request_2456,
      evh_7445225937215608_close_2455,
      act_7445225937215608_showModal_2454,
      evh_7445225937215608_clickPreventStop_2453,
      evh_7445225937215608_clickPreventStop,
      act_3283186227685836_emit_2460,
      evh_3283186227685836_clickPreventStop_2459,
      act_3283186227685836_showModal_2462,
      evh_3283186227685836_clickPreventStop_2461,
      evh_3283186227685836_clickPreventStop,
      onInvoiceSelectedAction: act_7445225937215608_request_2456,
    }
  }
}

Vue.component("FieldsetInvoiceRegisterEntry", FieldsetInvoiceRegisterEntry);

</script>