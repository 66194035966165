<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="MCreateFixedAsset"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="currentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      schema="CreateFixedAsset"
      :id="formId"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetFixedAssetGeneral
        :fixedAsset="item"
      >
      </FieldsetFixedAssetGeneral>
      <hr
        v-if="assetType === 'waste_bin'"
      >
      </hr>
      <FieldsetFixedAssetWasteBin
        :fixedAsset="item"
        v-if="assetType === 'waste_bin'"
      >
      </FieldsetFixedAssetWasteBin>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.create && actions.create.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.create && actions.create.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MCreateFixedAsset extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "general",
  })
  assetType!: string;

  @Watch('assetType')
  onAsset_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("assetType")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.schemaDefaults('CreateFixedAsset', {
      asset_type: this.assetType,
      waste_bin: {
        bin_type: null,
        bin_number: null,
        capacity: {
          amount: null,
          unit: 'liter'
        }
      }
    })
  }

  fixedAssetTypes!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formName', 'formId', 'item', 'fixedAssetTypes', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formName: "rental",
        formId: null,
        item: null,
        fixedAssetTypes: [{
          id: "general",
          name: this.$fn.pgettext("FixedAssetTypes", "General"),
        }
          ,
        {
          id: "waste_bin",
          name: this.$fn.pgettext("FixedAssetTypes", "Waste Bin"),
        }
          ,
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Create Fixed Asset"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1480_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1480, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_1486_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_1486, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1040167445267876_navigate_1488_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_1040167445267876_navigate_1488, alias=undefined
    return {
      location: {
        name: "app.fixedAsset",
        params: {
          id: $event.data.response.data.id,
        }
        ,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_1490_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_1490, alias=undefined
    return {
      grid: "fixedAssets",
    }
  }

  async act_1040167445267876_reloadSlickgrid_1492_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_1492, alias=undefined
    return {
      grid: "wasteBins",
    }
  }

  async act_1040167445267876_request_1484_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_1484, alias=create
    return {
      operation: "create_fixed_asset",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1480: actions.CloseModalAction;
    evh_7315092382398562_close_1479: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1482: actions.CloseModalAction;
    evh_2248226175642056_close_1481: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_1486: actions.CloseModalAction;
    evh_1040167445267876_success_1485: actions.EventHandlerImpl;
    act_1040167445267876_navigate_1488: actions.NavigateAction;
    evh_1040167445267876_success_1487: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_1490: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_1489: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_1492: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_1491: actions.EventHandlerImpl;
    act_1040167445267876_request_1484: actions.RequestAction;
    evh_1040167445267876_submit_1483: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_1494: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_1493: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1480 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1480_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1479 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1480,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1479],
      }
    );
    const act_2248226175642056_closeModal_1482 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1481 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1482,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1481],
      }
    );
    const act_1040167445267876_closeModal_1486 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_1486_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1485 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_1486,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_navigate_1488 = new actions.NavigateAction(
      {
        actionArgs: this.act_1040167445267876_navigate_1488_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1487 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_navigate_1488,
        event: "success",
        displayName: "navigate",
      }
    );
    const act_1040167445267876_reloadSlickgrid_1490 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_1490_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1489 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_1490,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_reloadSlickgrid_1492 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_1492_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1491 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_1492,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_request_1484 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_1484_getActionArgs,
        displayName: "create",
        events: [evh_1040167445267876_success_1485, evh_1040167445267876_success_1487, evh_1040167445267876_success_1489, evh_1040167445267876_success_1491],
      }
    );
    const evh_1040167445267876_submit_1483 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_1484,
        event: "submit",
        displayName: "create",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1483],
      }
    );
    const act_1419464017721962_closeComponent_1494 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1493 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_1494,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1493],
      }
    );
    return {
      act_7315092382398562_closeModal_1480,
      evh_7315092382398562_close_1479,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1482,
      evh_2248226175642056_close_1481,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_1486,
      evh_1040167445267876_success_1485,
      act_1040167445267876_navigate_1488,
      evh_1040167445267876_success_1487,
      act_1040167445267876_reloadSlickgrid_1490,
      evh_1040167445267876_success_1489,
      act_1040167445267876_reloadSlickgrid_1492,
      evh_1040167445267876_success_1491,
      act_1040167445267876_request_1484,
      evh_1040167445267876_submit_1483,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_1494,
      evh_1419464017721962_clickPreventStop_1493,
      evh_1419464017721962_clickPreventStop,
      create: act_1040167445267876_request_1484,
    }
  }
}

Vue.component("MCreateFixedAsset", MCreateFixedAsset);

</script>