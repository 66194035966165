"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var UcSuggestInvoiceOption = (function (_super) {
    __extends(UcSuggestInvoiceOption, _super);
    function UcSuggestInvoiceOption() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['ux'];
        return _this;
    }
    UcSuggestInvoiceOption.prototype.beforeCreate = function () {
    };
    UcSuggestInvoiceOption.prototype.onOption = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("option");
    };
    UcSuggestInvoiceOption.prototype.onHide_invoice_kind = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("hideInvoiceKind");
    };
    UcSuggestInvoiceOption.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    };
    UcSuggestInvoiceOption.prototype.getActions = function () {
        return {};
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Object,
        }),
        __metadata("design:type", Object)
    ], UcSuggestInvoiceOption.prototype, "option", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('option'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcSuggestInvoiceOption.prototype, "onOption", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
        }),
        __metadata("design:type", Boolean)
    ], UcSuggestInvoiceOption.prototype, "hideInvoiceKind", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('hideInvoiceKind'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcSuggestInvoiceOption.prototype, "onHide_invoice_kind", null);
    UcSuggestInvoiceOption = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], UcSuggestInvoiceOption);
    return UcSuggestInvoiceOption;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = UcSuggestInvoiceOption;
vue_property_decorator_1.Vue.component("UcSuggestInvoiceOption", UcSuggestInvoiceOption);
