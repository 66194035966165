<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    alignItems="center"
    v-if="loaded"
  >
    <ItpFormSelect
      name="date_type"
      v-model="item.date_type"
      :placeholder="'date filter type'|pgettext('Invoices')"
      :options="b_8564662037462133_options"
      class="mx-3 w-auto"
    >
    </ItpFormSelect>
    <DateRangeSelector
      name="invoiceFilters"
      :from="item.date_from"
      :to="item.date_to"
      @changed="evh_3343864469950044_changed($event, {})"
    >
      <ItpText
        :text="'Unpaid'|pgettext('Invoices')"
      >
      </ItpText>
    </DateRangeSelector>
    <ItpFormCheckbox
      name="unpaid"
      inline
      v-model="item.unpaid"
      class="mx-3"
    >
      <ItpText
        :text="'Unpaid'|pgettext('Invoices')"
      >
      </ItpText>
    </ItpFormCheckbox>
    <ItpButton
      :icon="$config.reloadIcon"
      :text="'Load'|gettext"
      variant="light"
      class="mx-2"
      @click.prevent.stop="evh_4892266742011185_clickPreventStop($event, {})"
    >
    </ItpButton>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoicesViewFilters extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "InvoicesViewFilters");
  }

  item!: any;

  async $$load_item() {
    return {
      date_from: null,
      date_to: null,
      unpaid: false,
      kind: this.kind,
      date_type: "invoice_date",
    }
  }

  ux!: any;
  b_8564662037462133_options!: any;
  dataMembers = ['item', 'ux', 'b_8564662037462133_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        ux: null,
        b_8564662037462133_options: [{
          value: "invoice_date",
          text: this.$fn.pgettext("Invoice", "Invoice date"),
        }
          ,
        {
          value: "fulfillment_date",
          text: this.$fn.pgettext("Invoice", "Fulfillment date"),
        }
          ,
        {
          value: "due_date",
          text: this.$fn.pgettext("Invoice", "Due date"),
        }
          ,
        ]
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_3343864469950044_setData_5040_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_3343864469950044_setData_5040, alias=undefined
    return {
      path: "item.date_from",
      value: $event.data[0],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_3343864469950044_setData_5042_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_3343864469950044_setData_5042, alias=undefined
    return {
      path: "item.date_to",
      value: $event.data[1],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_3343864469950044_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_changed.executeFromDOM(this, event, scope);
  }

  async act_4892266742011185_emit_5044_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4892266742011185_emit_5044, alias=undefined
    return {
      event: "submit",
      value: { ...this.item, kind: this.kind },
    }
  }

  async evh_4892266742011185_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4892266742011185_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5038: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5037: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_3343864469950044_setData_5040: actions.SetDataAction;
    evh_3343864469950044_changed_5039: actions.EventHandlerImpl;
    act_3343864469950044_setData_5042: actions.SetDataAction;
    evh_3343864469950044_changed_5041: actions.EventHandlerImpl;
    evh_3343864469950044_changed: actions.EventHandlerGroup;
    act_4892266742011185_emit_5044: actions.EmitAction;
    evh_4892266742011185_clickPreventStop_5043: actions.EventHandlerImpl;
    evh_4892266742011185_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5038 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5037 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5038,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5037],
      }
    );
    const act_3343864469950044_setData_5040 = new actions.SetDataAction(
      {
        actionArgs: this.act_3343864469950044_setData_5040_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_changed_5039 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_setData_5040,
        event: "changed",
        displayName: "setData",
      }
    );
    const act_3343864469950044_setData_5042 = new actions.SetDataAction(
      {
        actionArgs: this.act_3343864469950044_setData_5042_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_changed_5041 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_setData_5042,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_3343864469950044_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_changed_5039, evh_3343864469950044_changed_5041],
      }
    );
    const act_4892266742011185_emit_5044 = new actions.EmitAction(
      {
        actionArgs: this.act_4892266742011185_emit_5044_getActionArgs,
        events: [],
      }
    );
    const evh_4892266742011185_clickPreventStop_5043 = new actions.EventHandlerImpl(
      {
        action: act_4892266742011185_emit_5044,
        event: "click.prevent.stop",
        displayName: "emit",
      }
    );
    const evh_4892266742011185_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_4892266742011185_clickPreventStop_5043],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5038,
      evh_7315092382398562_reload_5037,
      evh_7315092382398562_reload,
      act_3343864469950044_setData_5040,
      evh_3343864469950044_changed_5039,
      act_3343864469950044_setData_5042,
      evh_3343864469950044_changed_5041,
      evh_3343864469950044_changed,
      act_4892266742011185_emit_5044,
      evh_4892266742011185_clickPreventStop_5043,
      evh_4892266742011185_clickPreventStop,
    }
  }
}

Vue.component("InvoicesViewFilters", InvoicesViewFilters);

</script>