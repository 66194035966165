"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var UcMeterReadings = (function (_super) {
    __extends(UcMeterReadings, _super);
    function UcMeterReadings() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['items', 'ux', 'b_7315092382398562_paging', 'b_7315092382398562_columns'];
        return _this;
    }
    UcMeterReadings.prototype.beforeCreate = function () {
    };
    UcMeterReadings.prototype.onMeter = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("meter");
    };
    UcMeterReadings.prototype.$$load_items = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_meter_reading', { parameters: { 'meter-id': this.meter.id } })];
            });
        });
    };
    UcMeterReadings.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: {
                initialLoadSpinner: true,
            },
            b_7315092382398562_paging: false,
            b_7315092382398562_columns: [{
                    name: "locked",
                    type: "lock",
                },
                {
                    name: "inactive",
                    header: this.$fn.gettext("Inactive"),
                    width: "30px",
                    textAlign: "center",
                    formatter: {
                        name: "checkbox",
                    },
                    editor: {
                        name: "checkbox",
                    },
                },
                {
                    name: "value",
                    type: "number",
                    header: this.$fn.gettext("Value"),
                    width: "110px",
                    validator: ["required",
                        "numeric",
                    ],
                },
                {
                    name: "unit",
                    header: this.$fn.gettext("Unit"),
                    width: "110px",
                    required: true,
                },
                {
                    name: "reading_type",
                    header: this.$fn.gettext("Reading Type"),
                    width: "120px",
                    required: true,
                    default: "reading",
                },
                {
                    name: "reading_date",
                    type: "date",
                    header: this.$fn.gettext("Reading Date"),
                    required: true,
                    width: "120px",
                },
                {
                    name: "reading_time",
                    type: "time",
                    header: this.$fn.gettext("Reading Time"),
                    required: true,
                    width: "120px",
                },
                {
                    name: "notes",
                    type: "textarea",
                    header: this.$fn.gettext("Notes"),
                    width: "200px",
                },
            ],
        });
    };
    UcMeterReadings.prototype.act_7315092382398562_script_6806_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $event.data.waitFor(this.$fn.crud('meter_reading').upsert($event.data.row));
                return [2];
            });
        });
    };
    UcMeterReadings.prototype.act_7315092382398562_script_6806_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_7315092382398562_script_6806_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcMeterReadings.prototype.evh_7315092382398562_save = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_save.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcMeterReadings.prototype.act_7315092382398562_script_6808_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $event.data.waitFor(this.$fn.crud('meter_reading').delete($event.data.row));
                return [2];
            });
        });
    };
    UcMeterReadings.prototype.act_7315092382398562_script_6808_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_7315092382398562_script_6808_getActionArgs_value($event); },
                    }];
            });
        });
    };
    UcMeterReadings.prototype.evh_7315092382398562_delete = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_delete.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    UcMeterReadings.prototype.getActions = function () {
        var act_7315092382398562_script_6806 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_6806_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_save_6805 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_6806,
            event: "save",
            displayName: "script",
        });
        var evh_7315092382398562_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_save_6805],
        });
        var act_7315092382398562_script_6808 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_6808_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_delete_6807 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_6808,
            event: "delete",
            displayName: "script",
        });
        var evh_7315092382398562_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_delete_6807],
        });
        return {
            act_7315092382398562_script_6806: act_7315092382398562_script_6806,
            evh_7315092382398562_save_6805: evh_7315092382398562_save_6805,
            evh_7315092382398562_save: evh_7315092382398562_save,
            act_7315092382398562_script_6808: act_7315092382398562_script_6808,
            evh_7315092382398562_delete_6807: evh_7315092382398562_delete_6807,
            evh_7315092382398562_delete: evh_7315092382398562_delete,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], UcMeterReadings.prototype, "meter", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('meter'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], UcMeterReadings.prototype, "onMeter", null);
    UcMeterReadings = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], UcMeterReadings);
    return UcMeterReadings;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = UcMeterReadings;
vue_property_decorator_1.Vue.component("UcMeterReadings", UcMeterReadings);
