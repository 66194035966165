<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    v-if="loaded"
  >
    <DateRangeSelector
      name="paymentFilters"
      :from="item.date_from"
      :to="item.date_to"
      class="mr-2"
      :style="b_8564662037462133_style"
      @changed="evh_8564662037462133_changed($event, {})"
    >
    </DateRangeSelector>
    <DIV>
      <ItpButton
        variant="light"
        :icon="$config.reloadIcon"
        :title="'Load data'|pgettext('WorkSummaryViewFilters')"
        :spinning="actions && actions.load && actions.load.isRunning"
        @click.prevent.stop="evh_3943151158818434_clickPreventStop($event, {})"
      >
      </ItpButton>
    </DIV>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class WorkSummaryViewFilters extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "WorkSummaryViewFilters");
  }

  item!: any;
  ux!: any;
  b_8564662037462133_style!: any;
  dataMembers = ['item', 'ux', 'b_8564662037462133_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: {
          date_from: null,
          date_to: null,
        }
        ,
        ux: null,
        b_8564662037462133_style: {
          width: "500px",
        }
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_setData_5564_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8564662037462133_setData_5564, alias=undefined
    return {
      path: "item.date_from",
      value: $event.data[0],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_8564662037462133_setData_5566_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8564662037462133_setData_5566, alias=undefined
    return {
      path: "item.date_to",
      value: $event.data[1],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_8564662037462133_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_changed.executeFromDOM(this, event, scope);
  }

  async act_3943151158818434_emit_5568_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3943151158818434_emit_5568, alias=load
    return {
      event: "submit",
      value: this.item,
    }
  }

  async evh_3943151158818434_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3943151158818434_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5562: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5561: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8564662037462133_setData_5564: actions.SetDataAction;
    evh_8564662037462133_changed_5563: actions.EventHandlerImpl;
    act_8564662037462133_setData_5566: actions.SetDataAction;
    evh_8564662037462133_changed_5565: actions.EventHandlerImpl;
    evh_8564662037462133_changed: actions.EventHandlerGroup;
    act_3943151158818434_emit_5568: actions.EmitAction;
    evh_3943151158818434_clickPreventStop_5567: actions.EventHandlerImpl;
    evh_3943151158818434_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5562 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5561 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5562,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5561],
      }
    );
    const act_8564662037462133_setData_5564 = new actions.SetDataAction(
      {
        actionArgs: this.act_8564662037462133_setData_5564_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_changed_5563 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_setData_5564,
        event: "changed",
        displayName: "setData",
      }
    );
    const act_8564662037462133_setData_5566 = new actions.SetDataAction(
      {
        actionArgs: this.act_8564662037462133_setData_5566_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_changed_5565 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_setData_5566,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_8564662037462133_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_changed_5563, evh_8564662037462133_changed_5565],
      }
    );
    const act_3943151158818434_emit_5568 = new actions.EmitAction(
      {
        actionArgs: this.act_3943151158818434_emit_5568_getActionArgs,
        displayName: "load",
        events: [],
      }
    );
    const evh_3943151158818434_clickPreventStop_5567 = new actions.EventHandlerImpl(
      {
        action: act_3943151158818434_emit_5568,
        event: "click.prevent.stop",
        displayName: "load",
      }
    );
    const evh_3943151158818434_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3943151158818434_clickPreventStop_5567],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5562,
      evh_7315092382398562_reload_5561,
      evh_7315092382398562_reload,
      act_8564662037462133_setData_5564,
      evh_8564662037462133_changed_5563,
      act_8564662037462133_setData_5566,
      evh_8564662037462133_changed_5565,
      evh_8564662037462133_changed,
      act_3943151158818434_emit_5568,
      evh_3943151158818434_clickPreventStop_5567,
      evh_3943151158818434_clickPreventStop,
      load: act_3943151158818434_emit_5568,
    }
  }
}

Vue.component("WorkSummaryViewFilters", WorkSummaryViewFilters);

</script>