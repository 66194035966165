"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var InvoiceBrowser = (function (_super) {
    __extends(InvoiceBrowser, _super);
    function InvoiceBrowser() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "page",
        };
        _this.dataMembers = ['formId', 'selectedItems', 'invoices', 'selectedInvoices', 'filters', 'invoiceKinds', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_dataSource', 'b_3054425420812800_dataSource', 'b_2070266589801014_editable', 'b_2070266589801014_columnDefaults', 'b_2070266589801014_columns'];
        return _this;
    }
    InvoiceBrowser.prototype.beforeCreate = function () {
    };
    InvoiceBrowser.prototype.invoiceTypeBadgeClass = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 1), row = _a[0];
        var cc = this.$config.invoice.types.colorCodes[row.invoice_type];
        return "bg-".concat(cc.bg, " text-").concat(cc.text);
    };
    InvoiceBrowser.prototype.onMultiselect = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("multiselect");
    };
    InvoiceBrowser.prototype.onInvoice_kind = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice_kind");
    };
    InvoiceBrowser.prototype.onCompany_id = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("company_id");
    };
    InvoiceBrowser.prototype.onClient_id = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("client_id");
    };
    InvoiceBrowser.prototype.onInvoice_number = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice_number");
    };
    InvoiceBrowser.prototype.onInvoice_date = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice_date");
    };
    InvoiceBrowser.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    InvoiceBrowser.prototype.$$load_formId = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.randomElementId()];
            });
        });
    };
    InvoiceBrowser.prototype.$$load_filters = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, {
                        date_from: this.invoice_date,
                        date_to: null,
                        invoice_kind: this.invoice_kind ? [this.invoice_kind] : [],
                        company_id: this.company_id,
                        client_id: this.client_id,
                        unpaid: true
                    }];
            });
        });
    };
    InvoiceBrowser.prototype.$$load_b_2070266589801014_columns = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, [{
                            field: "invoice_kind",
                            name: this.$fn.pgettext("InvoicesView", "Kind"),
                            formatter: {
                                name: "mapvalue",
                                mapping: {
                                    incoming: this.$fn.pgettext("InvoiceKind", "In"),
                                    outgoing: this.$fn.pgettext("InvoiceKind", "Out"),
                                },
                            },
                        },
                        {
                            field: "invoice_number",
                            name: this.$fn.pgettext("InvoicesView", "Invoice nr"),
                            formatter: {
                                name: "cellclass",
                                value: function (row) { return ({
                                    'font-weight-bold': true,
                                    'bg-yellow-200': !row.locked,
                                    'bg-yellow-500 text-yellow-800': row.is_corrected,
                                    'bg-red-300 text-red-800': row.is_reversed,
                                }); },
                            },
                        },
                        {
                            field: "invoice_type",
                            name: this.$fn.pgettext("InvoicesView", "Type"),
                            formatter: {
                                name: "chain",
                                formatters: [{
                                        name: "gettext",
                                        context: "InvoiceType",
                                        conversion: "StartCase",
                                        filterInput: true,
                                    },
                                    {
                                        name: "badge",
                                        dot: true,
                                        pill: true,
                                        badgeClass: this.invoiceTypeBadgeClass,
                                    },
                                ],
                            },
                        },
                        {
                            field: "invoice_date",
                            name: this.$fn.pgettext("InvoicesView", "Date"),
                        },
                        {
                            field: "fulfillment_date",
                            name: this.$fn.pgettext("InvoicesView", "Fulfillment date"),
                            type: "date",
                        },
                        {
                            field: "due_date",
                            name: this.$fn.pgettext("InvoicesView", "Due date"),
                            type: "date",
                        },
                        {
                            field: "invoice_status",
                            name: this.$fn.pgettext("InvoicesView", "Status"),
                            formatter: {
                                name: "gettext",
                                context: "Status",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                        },
                        {
                            field: "client_name",
                            name: this.$fn.pgettext("InvoicesView", "Client"),
                            formatter: {
                                name: "routeLink",
                                to: "client",
                                navigateOnIconClick: true,
                                params: {
                                    id: "client_id",
                                },
                            },
                        },
                        {
                            field: "net_total",
                            name: this.$fn.pgettext("InvoicesView", "Net"),
                            type: "number",
                            formatter: {
                                name: "localestring",
                                locale: "hu-HU",
                            },
                        },
                        {
                            field: "total",
                            name: this.$fn.pgettext("InvoicesView", "Amount"),
                            type: "number",
                            formatter: {
                                name: "localestring",
                                locale: "hu-HU",
                            },
                        },
                        {
                            field: "paid",
                            name: this.$fn.pgettext("InvoicesView", "Paid"),
                            type: "number",
                            formatter: {
                                name: "localestring",
                                locale: "hu-HU",
                            },
                        },
                        {
                            field: "unpaid",
                            name: this.$fn.pgettext("InvoicesView", "Claim"),
                            type: "number",
                            formatter: {
                                name: "chain",
                                formatters: [{
                                        name: "localestring",
                                        locale: "hu-HU",
                                    },
                                    {
                                        name: "cellclass",
                                        value: function (row) { return ({
                                            'text-red-500': row.unpaid != null && row.unpaid > 0,
                                            'text-green-500': row.unpaid != null && row.unpaid <= 0,
                                            'font-weight-bold': row.unpaid != null
                                        }); },
                                    },
                                ],
                            },
                        },
                        {
                            field: "currency",
                            name: this.$fn.pgettext("InvoicesView", "Currency"),
                        },
                        {
                            field: "tags",
                            name: this.$fn.pgettext("InvoicesView", "Tags"),
                        },
                        {
                            field: "rentals",
                            name: this.$fn.pgettext("InvoicesView", "Rentals"),
                        },
                        {
                            field: "contracts",
                            name: this.$fn.pgettext("InvoicesView", "Contracts"),
                        },]];
            });
        });
    };
    InvoiceBrowser.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            selectedItems: null,
            invoices: [],
            selectedInvoices: [],
            filters: null,
            invoiceKinds: [{
                    id: "incoming",
                    text: this.$fn.pgettext("InvoiceKind", "Incoming"),
                },
                {
                    id: "outgoing",
                    text: this.$fn.pgettext("InvoiceKind", "Outgoing"),
                },
            ],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "full-screen",
            },
            b_5731695935601903_dataSource: {
                name: "suggest_company",
                parameters: {
                    owned: true,
                },
            },
            b_3054425420812800_dataSource: {
                name: "suggest_client",
            },
            b_2070266589801014_editable: false,
            b_2070266589801014_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2070266589801014_columns: null,
        });
    };
    Object.defineProperty(InvoiceBrowser.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.$fn.pgettext("InvoiceBrowser", "Browse Invoices"),
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    InvoiceBrowser.prototype.act_7315092382398562_closeModal_3358_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    InvoiceBrowser.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    InvoiceBrowser.prototype.evh_2248226175642056_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    InvoiceBrowser.prototype.act_5534025912102772_setData_3364_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "invoices",
                        value: $event.data.response.data,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    InvoiceBrowser.prototype.act_5534025912102772_cache_3366_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "filters",
                    }];
            });
        });
    };
    InvoiceBrowser.prototype.act_5534025912102772_request_3362_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "list_invoice",
                        parameters: _.pickBy(this.filters, function (v) { return v || !_.isEmpty(v); }),
                    }];
            });
        });
    };
    InvoiceBrowser.prototype.evh_5534025912102772_submit = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5534025912102772_submit.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    InvoiceBrowser.prototype.act_2070266589801014_setData_3368_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "selectedItems",
                        value: $event.data.sender.getSelectedItems(),
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    InvoiceBrowser.prototype.evh_2070266589801014_selectedRowsChanged = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2070266589801014_selectedRowsChanged.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    InvoiceBrowser.prototype.act_1548630417156826_closeComponent_3370_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            ok: true,
                            value: this.multiselect ? this.selectedItems : this.selectedItems[0],
                        },
                    }];
            });
        });
    };
    InvoiceBrowser.prototype.evh_1548630417156826_click = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    InvoiceBrowser.prototype.getActions = function () {
        var act_7315092382398562_closeModal_3358 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3358_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_3357 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3358,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3357],
        });
        var act_2248226175642056_closeComponent_3360 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_2248226175642056_close_3359 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_3360,
            event: "close",
            displayName: "closeComponent",
        });
        var evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_3359],
        });
        var act_5534025912102772_setData_3364 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5534025912102772_setData_3364_getActionArgs,
            events: [],
        });
        var evh_5534025912102772_success_3363 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_setData_3364,
            event: "success",
            displayName: "setData",
        });
        var act_5534025912102772_cache_3366 = new core_1.actions.CacheAction({
            actionArgs: this.act_5534025912102772_cache_3366_getActionArgs,
            events: [],
        });
        var evh_5534025912102772_success_3365 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_cache_3366,
            event: "success",
            displayName: "cache",
        });
        var act_5534025912102772_request_3362 = new core_1.actions.RequestAction({
            actionArgs: this.act_5534025912102772_request_3362_getActionArgs,
            displayName: "search",
            events: [evh_5534025912102772_success_3363, evh_5534025912102772_success_3365],
        });
        var evh_5534025912102772_submit_3361 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_request_3362,
            event: "submit",
            displayName: "search",
        });
        var evh_5534025912102772_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_submit_3361],
        });
        var act_2070266589801014_setData_3368 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2070266589801014_setData_3368_getActionArgs,
            events: [],
        });
        var evh_2070266589801014_selectedRowsChanged_3367 = new core_1.actions.EventHandlerImpl({
            action: act_2070266589801014_setData_3368,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        var evh_2070266589801014_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2070266589801014_selectedRowsChanged_3367],
        });
        var act_1548630417156826_closeComponent_3370 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1548630417156826_closeComponent_3370_getActionArgs,
            events: [],
        });
        var evh_1548630417156826_click_3369 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_3370,
            event: "click",
            displayName: "closeComponent",
        });
        var evh_1548630417156826_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_click_3369],
        });
        return {
            act_7315092382398562_closeModal_3358: act_7315092382398562_closeModal_3358,
            evh_7315092382398562_close_3357: evh_7315092382398562_close_3357,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_closeComponent_3360: act_2248226175642056_closeComponent_3360,
            evh_2248226175642056_close_3359: evh_2248226175642056_close_3359,
            evh_2248226175642056_close: evh_2248226175642056_close,
            act_5534025912102772_setData_3364: act_5534025912102772_setData_3364,
            evh_5534025912102772_success_3363: evh_5534025912102772_success_3363,
            act_5534025912102772_cache_3366: act_5534025912102772_cache_3366,
            evh_5534025912102772_success_3365: evh_5534025912102772_success_3365,
            act_5534025912102772_request_3362: act_5534025912102772_request_3362,
            evh_5534025912102772_submit_3361: evh_5534025912102772_submit_3361,
            evh_5534025912102772_submit: evh_5534025912102772_submit,
            act_2070266589801014_setData_3368: act_2070266589801014_setData_3368,
            evh_2070266589801014_selectedRowsChanged_3367: evh_2070266589801014_selectedRowsChanged_3367,
            evh_2070266589801014_selectedRowsChanged: evh_2070266589801014_selectedRowsChanged,
            act_1548630417156826_closeComponent_3370: act_1548630417156826_closeComponent_3370,
            evh_1548630417156826_click_3369: evh_1548630417156826_click_3369,
            evh_1548630417156826_click: evh_1548630417156826_click,
            search: act_5534025912102772_request_3362,
        };
    };
    var _a;
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Boolean,
        }),
        __metadata("design:type", Boolean)
    ], InvoiceBrowser.prototype, "multiselect", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('multiselect'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], InvoiceBrowser.prototype, "onMultiselect", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], InvoiceBrowser.prototype, "invoice_kind", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('invoice_kind'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], InvoiceBrowser.prototype, "onInvoice_kind", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], InvoiceBrowser.prototype, "company_id", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('company_id'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], InvoiceBrowser.prototype, "onCompany_id", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], InvoiceBrowser.prototype, "client_id", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('client_id'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], InvoiceBrowser.prototype, "onClient_id", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], InvoiceBrowser.prototype, "invoice_number", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('invoice_number'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], InvoiceBrowser.prototype, "onInvoice_number", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({}),
        __metadata("design:type", typeof (_a = typeof date !== "undefined" && date) === "function" ? _a : Object)
    ], InvoiceBrowser.prototype, "invoice_date", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('invoice_date'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], InvoiceBrowser.prototype, "onInvoice_date", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "full-screen",
        }),
        __metadata("design:type", String)
    ], InvoiceBrowser.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], InvoiceBrowser.prototype, "onSize", null);
    InvoiceBrowser = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], InvoiceBrowser);
    return InvoiceBrowser;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = InvoiceBrowser;
vue_property_decorator_1.Vue.component("InvoiceBrowser", InvoiceBrowser);
