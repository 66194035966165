"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var ContractServiceEditor = (function (_super) {
    __extends(ContractServiceEditor, _super);
    function ContractServiceEditor() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "page",
        };
        _this.dataMembers = ['formId', 'labelAlign', 'isEditable', 'isDeletable', 'isServiceReadonly', 'isServiceFeeReadonly', 'messages', 'item', 'feeItem', 'currencies', 'services', 'ux', 'b_7315092382398562_modalBindings'];
        return _this;
    }
    ContractServiceEditor.prototype.beforeCreate = function () {
    };
    ContractServiceEditor.prototype.getConfiguration = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        switch (this.mode) {
            case 'addService':
                return {
                    isEditable: true,
                    isDeletable: false,
                    isServiceReadonly: false,
                };
            case 'editService':
                return {
                    isEditable: !this.service.locked,
                    isDeletable: !this.service.locked,
                    isServiceReadonly: this.service.locked,
                };
            case 'addServiceFee':
                return {
                    isEditable: true,
                    isDeletable: true,
                    isServiceReadonly: true,
                    isServiceFeeReadonly: false
                };
            case 'editServiceFee':
                return {
                    isEditable: !this.fee.locked,
                    isDeletable: !this.fee.locked,
                    isServiceReadonly: true,
                    isServiceFeeReadonly: this.fee.locked
                };
        }
    };
    ContractServiceEditor.prototype.onContract = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    };
    ContractServiceEditor.prototype.onService = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("service");
    };
    ContractServiceEditor.prototype.onFee = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("fee");
    };
    ContractServiceEditor.prototype.onMode = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("mode");
    };
    ContractServiceEditor.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    ContractServiceEditor.prototype.$$load_formId = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.randomElementId()];
            });
        });
    };
    ContractServiceEditor.prototype.$$load_isEditable = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.getConfiguration().isEditable];
            });
        });
    };
    ContractServiceEditor.prototype.$$load_isDeletable = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.getConfiguration().isDeletable];
            });
        });
    };
    ContractServiceEditor.prototype.$$load_isServiceReadonly = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.getConfiguration().isServiceReadonly];
            });
        });
    };
    ContractServiceEditor.prototype.$$load_isServiceFeeReadonly = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.getConfiguration().isServiceFeeReadonly];
            });
        });
    };
    ContractServiceEditor.prototype.$$load_item = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.service || this.$fn.schemaDefaults('CreateContractService', {
                        valid_from: this.$fn.today(),
                        contract_id: this.contract.id
                    })];
            });
        });
    };
    ContractServiceEditor.prototype.$$load_feeItem = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.fee ||
                        this.$fn.schemaDefaults('CreateContractServiceFee', {
                            unit: _.get(this.item, 'service.unit'),
                            contract_id: this.contract.id,
                            service_id: _.get(this.item, 'id'),
                        })];
            });
        });
    };
    ContractServiceEditor.prototype.$$load_currencies = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('list_currency')];
            });
        });
    };
    ContractServiceEditor.prototype.$$load_services = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.fetch('suggest_product', {
                        parameters: { query: '*', top: 50, skip: 0, product_types: 'service', product_invoice_groups: 'all' }
                    }).then(function (data) { return _.orderBy(data, [function (p) { return p.name.toLowerCase(); }]); })];
            });
        });
    };
    ContractServiceEditor.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            labelAlign: "right",
            isEditable: null,
            isDeletable: null,
            isServiceReadonly: null,
            isServiceFeeReadonly: null,
            messages: {
                addService: {
                    title: this.$fn.pgettext("Contract", "Add Service"),
                },
                editService: {
                    title: this.$fn.pgettext("Contract", "Edit Service"),
                },
                addServiceFee: {
                    title: this.$fn.pgettext("Contract", "Add Service Fee"),
                },
                editServiceFee: {
                    title: this.$fn.pgettext("Contract", "Edit Service Fee"),
                },
            },
            item: null,
            feeItem: null,
            currencies: null,
            services: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                noPrimaryButton: true,
                closeButtonText: this.$fn.gettext("Close"),
                size: "md",
            },
        });
    };
    Object.defineProperty(ContractServiceEditor.prototype, "currentObjectType", {
        get: function () {
            if (this.loaded) {
                return this.mode.indexOf('Fee') > -1 ? 'contract_service_fee' : 'contract_service';
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContractServiceEditor.prototype, "displayFee", {
        get: function () {
            if (this.loaded) {
                return this.mode !== 'editService';
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContractServiceEditor.prototype, "displayFeeDates", {
        get: function () {
            if (this.loaded) {
                return this.displayFee && this.mode !== 'addService';
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContractServiceEditor.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.messages[this.mode].title,
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    ContractServiceEditor.prototype.act_7315092382398562_closeModal_6320_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ContractServiceEditor.prototype.evh_2248226175642056_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ContractServiceEditor.prototype.act_1040167445267876_closeModal_6326_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            ok: true,
                            value: $event.data,
                        },
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.act_1040167445267876_crud_6324_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        objectType: this.currentObjectType,
                        op: "upsert",
                        data: this.mode === 'addService'
                            ? _.extend({}, this.item, { fees: [_.extend(this.feeItem, { valid_from: this.item.valid_from, valid_to: this.item.valid_to })] })
                            : (this.currentObjectType == 'contract_service' ? this.item : this.feeItem),
                        options: {
                            parameters: {
                                rev: this.contract.revision_number,
                            },
                        },
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.evh_1040167445267876_submit = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ContractServiceEditor.prototype.act_3054425420812800_setData_6328_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.name",
                        value: $event.data.option.name,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.act_3054425420812800_setData_6330_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item.service_id",
                        value: $event.data.option.id,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.act_3054425420812800_setData_6332_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "feeItem.unit",
                        value: $event.data.option.unit,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.evh_3054425420812800_optionSelected = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3054425420812800_optionSelected.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ContractServiceEditor.prototype.act_1478395556975758_closeModal_6336_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            ok: true,
                        },
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.act_1478395556975758_request_6334_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "update_contract_service",
                        data: {
                            id: this.item.id,
                            etag: this.item.etag,
                            locked: true
                        },
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.evh_1478395556975758_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1478395556975758_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ContractServiceEditor.prototype.act_2712422394771578_closeModal_6340_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            ok: true,
                        },
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.act_2712422394771578_request_6338_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "update_contract_service_fee",
                        data: {
                            id: this.feeItem.id,
                            etag: this.feeItem.etag,
                            locked: true
                        },
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.evh_2712422394771578_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2712422394771578_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ContractServiceEditor.prototype.act_1548630417156826_closeModal_6344_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            ok: true,
                        },
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.act_1548630417156826_request_6342_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "delete_contract_service",
                        data: this.item,
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.evh_1548630417156826_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ContractServiceEditor.prototype.act_1419464017721962_closeModal_6348_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        result: {
                            ok: true,
                        },
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.act_1419464017721962_request_6346_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "delete_contract_service_fee",
                        data: this.feeItem,
                    }];
            });
        });
    };
    ContractServiceEditor.prototype.evh_1419464017721962_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ContractServiceEditor.prototype.evh_5566617329548203_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ContractServiceEditor.prototype.getActions = function () {
        var act_7315092382398562_closeModal_6320 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6320_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_6319 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6320,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6319],
        });
        var act_2248226175642056_closeComponent_6322 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_2248226175642056_close_6321 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6322,
            event: "close",
            displayName: "closeComponent",
        });
        var evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6321],
        });
        var act_1040167445267876_closeModal_6326 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_6326_getActionArgs,
            events: [],
        });
        var evh_1040167445267876_success_6325 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_6326,
            event: "success",
            displayName: "closeModal",
        });
        var act_1040167445267876_crud_6324 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_6324_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_6325],
        });
        var evh_1040167445267876_submit_6323 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_6324,
            event: "submit",
            displayName: "save",
        });
        var evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6323],
        });
        var act_3054425420812800_setData_6328 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3054425420812800_setData_6328_getActionArgs,
            events: [],
        });
        var evh_3054425420812800_optionSelected_6327 = new core_1.actions.EventHandlerImpl({
            action: act_3054425420812800_setData_6328,
            event: "optionSelected",
            displayName: "setData",
        });
        var act_3054425420812800_setData_6330 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3054425420812800_setData_6330_getActionArgs,
            events: [],
        });
        var evh_3054425420812800_optionSelected_6329 = new core_1.actions.EventHandlerImpl({
            action: act_3054425420812800_setData_6330,
            event: "optionSelected",
            displayName: "setData",
        });
        var act_3054425420812800_setData_6332 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3054425420812800_setData_6332_getActionArgs,
            events: [],
        });
        var evh_3054425420812800_optionSelected_6331 = new core_1.actions.EventHandlerImpl({
            action: act_3054425420812800_setData_6332,
            event: "optionSelected",
            displayName: "setData",
        });
        var evh_3054425420812800_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3054425420812800_optionSelected_6327, evh_3054425420812800_optionSelected_6329, evh_3054425420812800_optionSelected_6331],
        });
        var act_1478395556975758_closeModal_6336 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1478395556975758_closeModal_6336_getActionArgs,
            events: [],
        });
        var evh_1478395556975758_success_6335 = new core_1.actions.EventHandlerImpl({
            action: act_1478395556975758_closeModal_6336,
            event: "success",
            displayName: "closeModal",
        });
        var act_1478395556975758_request_6334 = new core_1.actions.RequestAction({
            actionArgs: this.act_1478395556975758_request_6334_getActionArgs,
            displayName: "lockService",
            events: [evh_1478395556975758_success_6335],
        });
        var evh_1478395556975758_clickPreventStop_6333 = new core_1.actions.EventHandlerImpl({
            action: act_1478395556975758_request_6334,
            event: "click.prevent.stop",
            displayName: "lockService",
        });
        var evh_1478395556975758_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1478395556975758_clickPreventStop_6333],
        });
        var act_2712422394771578_closeModal_6340 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2712422394771578_closeModal_6340_getActionArgs,
            events: [],
        });
        var evh_2712422394771578_success_6339 = new core_1.actions.EventHandlerImpl({
            action: act_2712422394771578_closeModal_6340,
            event: "success",
            displayName: "closeModal",
        });
        var act_2712422394771578_request_6338 = new core_1.actions.RequestAction({
            actionArgs: this.act_2712422394771578_request_6338_getActionArgs,
            displayName: "lockServiceFee",
            events: [evh_2712422394771578_success_6339],
        });
        var evh_2712422394771578_clickPreventStop_6337 = new core_1.actions.EventHandlerImpl({
            action: act_2712422394771578_request_6338,
            event: "click.prevent.stop",
            displayName: "lockServiceFee",
        });
        var evh_2712422394771578_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2712422394771578_clickPreventStop_6337],
        });
        var act_1548630417156826_closeModal_6344 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1548630417156826_closeModal_6344_getActionArgs,
            events: [],
        });
        var evh_1548630417156826_success_6343 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeModal_6344,
            event: "success",
            displayName: "closeModal",
        });
        var act_1548630417156826_request_6342 = new core_1.actions.RequestAction({
            actionArgs: this.act_1548630417156826_request_6342_getActionArgs,
            displayName: "deleteService",
            events: [evh_1548630417156826_success_6343],
        });
        var evh_1548630417156826_clickPreventStop_6341 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_request_6342,
            event: "click.prevent.stop",
            displayName: "deleteService",
        });
        var evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_6341],
        });
        var act_1419464017721962_closeModal_6348 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1419464017721962_closeModal_6348_getActionArgs,
            events: [],
        });
        var evh_1419464017721962_success_6347 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_6348,
            event: "success",
            displayName: "closeModal",
        });
        var act_1419464017721962_request_6346 = new core_1.actions.RequestAction({
            actionArgs: this.act_1419464017721962_request_6346_getActionArgs,
            displayName: "deleteServiceFee",
            events: [evh_1419464017721962_success_6347],
        });
        var evh_1419464017721962_clickPreventStop_6345 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_request_6346,
            event: "click.prevent.stop",
            displayName: "deleteServiceFee",
        });
        var evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_6345],
        });
        var act_5566617329548203_closeComponent_6350 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_5566617329548203_clickPreventStop_6349 = new core_1.actions.EventHandlerImpl({
            action: act_5566617329548203_closeComponent_6350,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        var evh_5566617329548203_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5566617329548203_clickPreventStop_6349],
        });
        return {
            act_7315092382398562_closeModal_6320: act_7315092382398562_closeModal_6320,
            evh_7315092382398562_close_6319: evh_7315092382398562_close_6319,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6322: act_2248226175642056_closeComponent_6322,
            evh_2248226175642056_close_6321: evh_2248226175642056_close_6321,
            evh_2248226175642056_close: evh_2248226175642056_close,
            act_1040167445267876_closeModal_6326: act_1040167445267876_closeModal_6326,
            evh_1040167445267876_success_6325: evh_1040167445267876_success_6325,
            act_1040167445267876_crud_6324: act_1040167445267876_crud_6324,
            evh_1040167445267876_submit_6323: evh_1040167445267876_submit_6323,
            evh_1040167445267876_submit: evh_1040167445267876_submit,
            act_3054425420812800_setData_6328: act_3054425420812800_setData_6328,
            evh_3054425420812800_optionSelected_6327: evh_3054425420812800_optionSelected_6327,
            act_3054425420812800_setData_6330: act_3054425420812800_setData_6330,
            evh_3054425420812800_optionSelected_6329: evh_3054425420812800_optionSelected_6329,
            act_3054425420812800_setData_6332: act_3054425420812800_setData_6332,
            evh_3054425420812800_optionSelected_6331: evh_3054425420812800_optionSelected_6331,
            evh_3054425420812800_optionSelected: evh_3054425420812800_optionSelected,
            act_1478395556975758_closeModal_6336: act_1478395556975758_closeModal_6336,
            evh_1478395556975758_success_6335: evh_1478395556975758_success_6335,
            act_1478395556975758_request_6334: act_1478395556975758_request_6334,
            evh_1478395556975758_clickPreventStop_6333: evh_1478395556975758_clickPreventStop_6333,
            evh_1478395556975758_clickPreventStop: evh_1478395556975758_clickPreventStop,
            act_2712422394771578_closeModal_6340: act_2712422394771578_closeModal_6340,
            evh_2712422394771578_success_6339: evh_2712422394771578_success_6339,
            act_2712422394771578_request_6338: act_2712422394771578_request_6338,
            evh_2712422394771578_clickPreventStop_6337: evh_2712422394771578_clickPreventStop_6337,
            evh_2712422394771578_clickPreventStop: evh_2712422394771578_clickPreventStop,
            act_1548630417156826_closeModal_6344: act_1548630417156826_closeModal_6344,
            evh_1548630417156826_success_6343: evh_1548630417156826_success_6343,
            act_1548630417156826_request_6342: act_1548630417156826_request_6342,
            evh_1548630417156826_clickPreventStop_6341: evh_1548630417156826_clickPreventStop_6341,
            evh_1548630417156826_clickPreventStop: evh_1548630417156826_clickPreventStop,
            act_1419464017721962_closeModal_6348: act_1419464017721962_closeModal_6348,
            evh_1419464017721962_success_6347: evh_1419464017721962_success_6347,
            act_1419464017721962_request_6346: act_1419464017721962_request_6346,
            evh_1419464017721962_clickPreventStop_6345: evh_1419464017721962_clickPreventStop_6345,
            evh_1419464017721962_clickPreventStop: evh_1419464017721962_clickPreventStop,
            act_5566617329548203_closeComponent_6350: act_5566617329548203_closeComponent_6350,
            evh_5566617329548203_clickPreventStop_6349: evh_5566617329548203_clickPreventStop_6349,
            evh_5566617329548203_clickPreventStop: evh_5566617329548203_clickPreventStop,
            save: act_1040167445267876_crud_6324,
            lockService: act_1478395556975758_request_6334,
            lockServiceFee: act_2712422394771578_request_6338,
            deleteService: act_1548630417156826_request_6342,
            deleteServiceFee: act_1419464017721962_request_6346,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], ContractServiceEditor.prototype, "contract", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('contract'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], ContractServiceEditor.prototype, "onContract", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Object,
        }),
        __metadata("design:type", Object)
    ], ContractServiceEditor.prototype, "service", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('service'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], ContractServiceEditor.prototype, "onService", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Object,
        }),
        __metadata("design:type", Object)
    ], ContractServiceEditor.prototype, "fee", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('fee'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], ContractServiceEditor.prototype, "onFee", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: String,
        }),
        __metadata("design:type", String)
    ], ContractServiceEditor.prototype, "mode", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('mode'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], ContractServiceEditor.prototype, "onMode", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "md",
        }),
        __metadata("design:type", String)
    ], ContractServiceEditor.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], ContractServiceEditor.prototype, "onSize", null);
    ContractServiceEditor = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], ContractServiceEditor);
    return ContractServiceEditor;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = ContractServiceEditor;
vue_property_decorator_1.Vue.component("ContractServiceEditor", ContractServiceEditor);
