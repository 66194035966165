"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LazyTabManager = void 0;
var LazyTabManager = (function () {
    function LazyTabManager() {
        this.uiSkipComponentReload = true;
        this._visited = [];
    }
    LazyTabManager.prototype.visited = function (index, setValue) {
        var contains = this._visited.includes(index);
        if (!setValue) {
            return contains;
        }
        if (!contains) {
            this._visited.push(index);
        }
    };
    return LazyTabManager;
}());
exports.LazyTabManager = LazyTabManager;
