"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPrice = exports.ProductBrowser = exports.Product = exports.ProductEditor = exports.CreateProduct = void 0;
var CreateProduct_yaml_component_1 = require("./CreateProduct.yaml?component");
exports.CreateProduct = CreateProduct_yaml_component_1.default;
var Product_yaml_component_1 = require("./Product.yaml?component");
exports.Product = Product_yaml_component_1.default;
var ProductBrowser_yaml_component_1 = require("./ProductBrowser.yaml?component");
exports.ProductBrowser = ProductBrowser_yaml_component_1.default;
var ProductEditor_yaml_component_1 = require("./ProductEditor.yaml?component");
exports.ProductEditor = ProductEditor_yaml_component_1.default;
var ProductPrice_yaml_component_1 = require("./ProductPrice.yaml?component");
exports.ProductPrice = ProductPrice_yaml_component_1.default;
__exportStar(require("./form"), exports);
__exportStar(require("./primitives"), exports);
