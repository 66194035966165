"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var BtnSyncInvoice = (function (_super) {
    __extends(BtnSyncInvoice, _super);
    function BtnSyncInvoice() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "usercontrol",
        };
        _this.dataMembers = ['jobData', 'invoiceSyncPoller', 'messages', 'statuses', 'ux'];
        return _this;
    }
    BtnSyncInvoice.prototype.beforeCreate = function () {
    };
    BtnSyncInvoice.prototype.beforeDestroy = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        {
            {
                this.invoiceSyncPoller && this.invoiceSyncPoller.stop();
            }
        }
    };
    BtnSyncInvoice.prototype.checkStatus = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!this.jobData) {
            console.log('JobData is null, stop polling.');
            this.invoiceSyncPoller.stop();
            return;
        }
        var status = this.jobData.status;
        if (status !== 'pending' && status !== 'running') {
            console.log("JobStatus is ".concat(status, ", stop polling."), this.invoiceSyncPoller);
            this.invoiceSyncPoller.stop();
            this.$emit('success');
            return;
        }
        this.$fn
            .fetch('read_a_job', { parameters: { id: this.jobData.id } })
            .then(function (data) { return _this.jobData = data; })
            .catch(function (err) {
            console.error(err);
            _this.invoiceSyncPoller.stop();
        });
    };
    BtnSyncInvoice.prototype.onInvoice = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    };
    BtnSyncInvoice.prototype.$$load_invoiceSyncPoller = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.poller({ id: 'InvoiceSync', callback: this.checkStatus })];
            });
        });
    };
    BtnSyncInvoice.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            jobData: null,
            invoiceSyncPoller: null,
            messages: {
                createButtonText: "Számla kiállítása",
                createButtonTitle: "A számla kiállítása a számlázó szolgáltatónál",
                syncButtonTooltip: "Szinkronizálás",
                confirmSyncDialog: {
                    header: "Számla szinkronizálása",
                    message: "A számlát csak egyszer lehet feltölteni a szamlazz.hu rendszerébe, feltöltés után a számlát már nem lehet módosítani.",
                    btnOk: "Szinkronizálás",
                    btnCancel: "Mégsem",
                },
                cancelSyncDialog: {
                    header: "Szinkronizálás megszakítása",
                    message: "Megszakítás esetén a számla nem lesz szinkronizálva.\n\
Tartalma módosítható, szerkesztés után az új adattartalommal újra szinkronizálható.\n\
A sor feldolgozása a megaszakított elemnél szünetel.\n\
",
                    btnOk: "Megszakítás",
                    btnCancel: "Mégsem",
                },
                showLogMessagesTooltip: "Naplófile megtekintése.",
            },
            statuses: {
                default: {
                    variant: "primary",
                    text: "Számla kiállítása",
                    tooltip: "A számla kiállítása a számlázó szolgáltatónál",
                },
                pending: {
                    icon: "material-symbols:lock-clock-outline-sharp",
                    color: "bg-yellow-200",
                    variant: "warning",
                    text: "Számla kiállítása folyamatban",
                    tooltip: "A számla szinkronizálásra vár, ebben az állapotában nem módosítható.",
                },
                retry: {
                    icon: "material-symbols:lock-clock-outline-sharp",
                    color: "bg-yellow-200",
                    variant: "warning",
                    text: "Számla kiállítása folyamatban",
                    tooltip: "A számla szinkronizálás alatt.",
                },
                processing: {
                    icon: "material-symbols:cloud-sync-rounded",
                    color: "bg-blue-200",
                    variant: "warning",
                    text: "Számla kiállítása folyamatban",
                    tooltip: "A számla szinkronizálás alatt.",
                },
                failed: {
                    icon: "material-symbols:sync-problem-outline-rounded",
                    color: "bg-red-200",
                    variant: "danger",
                    text: "Számla kiállítás hiba",
                    tooltip: "Hiba történt a szinkronizálás során.",
                },
                cancelled: {
                    icon: "material-symbols:sync-disabled",
                    color: "bg-gray-200",
                    variant: "secondary",
                    text: "Számla kiállítás megszakítva",
                    tooltip: "A számla törölve lett a szinkronizálási sorból.",
                },
            },
            ux: null,
        });
    };
    Object.defineProperty(BtnSyncInvoice.prototype, "status", {
        get: function () {
            if (this.loaded) {
                return this.jobData ? this.jobData.status : (this.invoice.sync_job ? this.invoice.sync_job.status : null);
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BtnSyncInvoice.prototype, "context", {
        get: function () {
            if (this.loaded) {
                return this.statuses[this.status || 'default'];
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BtnSyncInvoice.prototype, "isJobRunning", {
        get: function () {
            if (this.loaded) {
                return this.status === 'running' || this.status === 'pending';
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    BtnSyncInvoice.prototype.act_2248226175642056_setData_4496_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "jobData",
                        value: $event.data.modalResult && $event.data.modalResult.value,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    BtnSyncInvoice.prototype.evh_2248226175642056_close_4495_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.modalResult && $event.data.modalResult.ok];
            });
        });
    };
    BtnSyncInvoice.prototype.act_2248226175642056_script_4498_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.invoiceSyncPoller.start();
                return [2];
            });
        });
    };
    BtnSyncInvoice.prototype.act_2248226175642056_script_4498_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_2248226175642056_script_4498_getActionArgs_value($event); },
                    }];
            });
        });
    };
    BtnSyncInvoice.prototype.evh_2248226175642056_close_4497_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.modalResult && $event.data.modalResult.ok];
            });
        });
    };
    BtnSyncInvoice.prototype.act_2248226175642056_showModal_4494_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "ConfirmSyncInvoice",
                        props: {
                            invoice: this.invoice,
                        },
                    }];
            });
        });
    };
    BtnSyncInvoice.prototype.evh_2248226175642056_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    BtnSyncInvoice.prototype.act_5962862420439144_showModal_4500_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "Job",
                        props: {
                            oid: this.invoice.sync_job.id,
                        },
                    }];
            });
        });
    };
    BtnSyncInvoice.prototype.evh_5962862420439144_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5962862420439144_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    BtnSyncInvoice.prototype.getActions = function () {
        var act_2248226175642056_setData_4496 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_4496_getActionArgs,
            events: [],
        });
        var evh_2248226175642056_close_4495 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_4496,
            event: "close",
            when: this.evh_2248226175642056_close_4495_getWhen,
            displayName: "setData",
        });
        var act_2248226175642056_script_4498 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2248226175642056_script_4498_getActionArgs,
            events: [],
        });
        var evh_2248226175642056_close_4497 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_script_4498,
            event: "close",
            when: this.evh_2248226175642056_close_4497_getWhen,
            displayName: "script",
        });
        var act_2248226175642056_showModal_4494 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_4494_getActionArgs,
            displayName: "sync",
            events: [evh_2248226175642056_close_4495, evh_2248226175642056_close_4497],
        });
        var evh_2248226175642056_clickPreventStop_4493 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_4494,
            event: "click.prevent.stop",
            displayName: "sync",
        });
        var evh_2248226175642056_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_clickPreventStop_4493],
        });
        var act_5962862420439144_showModal_4500 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5962862420439144_showModal_4500_getActionArgs,
            displayName: "showJob",
            events: [],
        });
        var evh_5962862420439144_clickPreventStop_4499 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_showModal_4500,
            event: "click.prevent.stop",
            displayName: "showJob",
        });
        var evh_5962862420439144_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_clickPreventStop_4499],
        });
        return {
            act_2248226175642056_setData_4496: act_2248226175642056_setData_4496,
            evh_2248226175642056_close_4495: evh_2248226175642056_close_4495,
            act_2248226175642056_script_4498: act_2248226175642056_script_4498,
            evh_2248226175642056_close_4497: evh_2248226175642056_close_4497,
            act_2248226175642056_showModal_4494: act_2248226175642056_showModal_4494,
            evh_2248226175642056_clickPreventStop_4493: evh_2248226175642056_clickPreventStop_4493,
            evh_2248226175642056_clickPreventStop: evh_2248226175642056_clickPreventStop,
            act_5962862420439144_showModal_4500: act_5962862420439144_showModal_4500,
            evh_5962862420439144_clickPreventStop_4499: evh_5962862420439144_clickPreventStop_4499,
            evh_5962862420439144_clickPreventStop: evh_5962862420439144_clickPreventStop,
            sync: act_2248226175642056_showModal_4494,
            showJob: act_5962862420439144_showModal_4500,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: Object,
        }),
        __metadata("design:type", Object)
    ], BtnSyncInvoice.prototype, "invoice", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('invoice'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], BtnSyncInvoice.prototype, "onInvoice", null);
    BtnSyncInvoice = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], BtnSyncInvoice);
    return BtnSyncInvoice;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = BtnSyncInvoice;
vue_property_decorator_1.Vue.component("BtnSyncInvoice", BtnSyncInvoice);
