<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="AccountsDailyFlow"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="accountsDailyFlow"
    :headerTitle="'Daily Flow'|pgettext('AccountsDailyFlowView')"
    :items="getItems"
    checkbox-row-selection
    export-to-csv
    :exportToCsvFilename="'accounts-daily-flow'|pgettext('ExportFilename')"
    footer-totals
    no-column-reorder
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
    @command="evh_2248226175642056_command($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpFormSelect2
        name="accounts"
        v-model="parameters.account"
        bind-object
        textField="name,currency,account_number"
        :options="accounts"
        use-option-template-for-selected-option
        optionTemplate="<UcAccountSelectOption :payload=option></UcAccountSelectOption>"
        class="mr-2"
        @optionSelected="evh_6466705138206980_optionSelected($event, {})"
      >
      </ItpFormSelect2>
      <DateRangeSelector
        name="paymentFilters"
        :from="parameters.date_from"
        :to="parameters.date_to"
        class="mr-2"
        @changed="evh_8320016629450276_changed($event, {})"
      >
      </DateRangeSelector>
      <ItpButton
        :icon="$config.reloadIcon"
        :text="'Load'|pgettext('Command')"
        size="sm"
        @click.prevent.stop="evh_6705964765214521_clickPreventStop($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/finance/accounts-daily-flow.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "accountsDailyFlow",
    path: "/accounts-daily-flow",
    component: "AccountsDailyFlow"
  }
)

@Component()
export default class AccountsDailyFlow extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_parameters = new ComponentValueCache(this, "parameters", "AccountsDailyFlowViewFilters");
  }

  getItems(...args: any[]) {
    const parameters = this.parameters;
    return this.$fn.fetch('browse_accounts_daily_flow', {
      parameters: {
        ...parameters,
        account: parameters.account.id
      }
    })
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  accounts!: any;

  async $$load_accounts() {
    return this.$fn.fetch('list_account')
      .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']))
  }

  parameters!: any;

  async $$load_parameters() {
    return {
      date_from: null,
      date_to: null,
      account: this.accounts ? this.accounts[0] : null,
    }
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'accounts', 'parameters', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        accounts: null,
        parameters: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "date",
          name: this.$fn.pgettext("AccountsDailyFlowView", "Date"),
        }
          ,
        {
          field: "open",
          name: this.$fn.pgettext("AccountsDailyFlowView", "Opening balance"),
          type: "number",
          noFooterTotals: true,
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "pay_in",
          name: this.$fn.pgettext("AccountsDailyFlowView", "Pay in"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "pay_out",
          name: this.$fn.pgettext("AccountsDailyFlowView", "Pay out"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "close",
          name: this.$fn.pgettext("AccountsDailyFlowView", "Closing balance"),
          type: "number",
          noFooterTotals: true,
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("AccountsDailyFlowView", "Daily Flow"),
        icon: this.$config.accountsDailyFlow.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5348_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5348, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_5350_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5350, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_showModal_5352_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_5352, alias=undefined
    return {
      name: "Account",
      props: {
        oid: $event.data.data.account_id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_5351_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_5351, alias=undefined
    return $event.data.column.id === 'account_name'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_reloadSlickgrid_5354_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_6466705138206980_reloadSlickgrid_5354, alias=undefined
    return {
      grid: "accountsDailyFlow",
    }
  }

  async evh_6466705138206980_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_setData_5356_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8320016629450276_setData_5356, alias=undefined
    return {
      path: "parameters.date_from",
      value: $event.data[0],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_8320016629450276_setData_5358_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8320016629450276_setData_5358, alias=undefined
    return {
      path: "parameters.date_to",
      value: $event.data[1],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_8320016629450276_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_changed.executeFromDOM(this, event, scope);
  }

  async act_6705964765214521_reloadSlickgrid_5360_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_6705964765214521_reloadSlickgrid_5360, alias=undefined
    return {
      grid: "accountsDailyFlow",
    }
  }

  async evh_6705964765214521_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6705964765214521_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5348: actions.CloseModalAction;
    evh_7315092382398562_close_5347: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_5350: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_5349: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_2248226175642056_showModal_5352: actions.ShowModalAction;
    evh_2248226175642056_command_5351: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_6466705138206980_reloadSlickgrid_5354: actions.ReloadSlickgridAction;
    evh_6466705138206980_optionSelected_5353: actions.EventHandlerImpl;
    evh_6466705138206980_optionSelected: actions.EventHandlerGroup;
    act_8320016629450276_setData_5356: actions.SetDataAction;
    evh_8320016629450276_changed_5355: actions.EventHandlerImpl;
    act_8320016629450276_setData_5358: actions.SetDataAction;
    evh_8320016629450276_changed_5357: actions.EventHandlerImpl;
    evh_8320016629450276_changed: actions.EventHandlerGroup;
    act_6705964765214521_reloadSlickgrid_5360: actions.ReloadSlickgridAction;
    evh_6705964765214521_clickPreventStop_5359: actions.EventHandlerImpl;
    evh_6705964765214521_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5348 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5348_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5347 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5348,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5347],
      }
    );
    const act_2248226175642056_setData_5350 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5350_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_5349 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5350,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_5349],
      }
    );
    const act_2248226175642056_showModal_5352 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_5352_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_command_5351 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_5352,
        event: "command",
        when: this.evh_2248226175642056_command_5351_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_5351],
      }
    );
    const act_6466705138206980_reloadSlickgrid_5354 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_6466705138206980_reloadSlickgrid_5354_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_optionSelected_5353 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_reloadSlickgrid_5354,
        event: "optionSelected",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_6466705138206980_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_optionSelected_5353],
      }
    );
    const act_8320016629450276_setData_5356 = new actions.SetDataAction(
      {
        actionArgs: this.act_8320016629450276_setData_5356_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_changed_5355 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_setData_5356,
        event: "changed",
        displayName: "setData",
      }
    );
    const act_8320016629450276_setData_5358 = new actions.SetDataAction(
      {
        actionArgs: this.act_8320016629450276_setData_5358_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_changed_5357 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_setData_5358,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_8320016629450276_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_changed_5355, evh_8320016629450276_changed_5357],
      }
    );
    const act_6705964765214521_reloadSlickgrid_5360 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_6705964765214521_reloadSlickgrid_5360_getActionArgs,
        events: [],
      }
    );
    const evh_6705964765214521_clickPreventStop_5359 = new actions.EventHandlerImpl(
      {
        action: act_6705964765214521_reloadSlickgrid_5360,
        event: "click.prevent.stop",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_6705964765214521_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6705964765214521_clickPreventStop_5359],
      }
    );
    return {
      act_7315092382398562_closeModal_5348,
      evh_7315092382398562_close_5347,
      evh_7315092382398562_close,
      act_2248226175642056_setData_5350,
      evh_2248226175642056_selectedRowsChanged_5349,
      evh_2248226175642056_selectedRowsChanged,
      act_2248226175642056_showModal_5352,
      evh_2248226175642056_command_5351,
      evh_2248226175642056_command,
      act_6466705138206980_reloadSlickgrid_5354,
      evh_6466705138206980_optionSelected_5353,
      evh_6466705138206980_optionSelected,
      act_8320016629450276_setData_5356,
      evh_8320016629450276_changed_5355,
      act_8320016629450276_setData_5358,
      evh_8320016629450276_changed_5357,
      evh_8320016629450276_changed,
      act_6705964765214521_reloadSlickgrid_5360,
      evh_6705964765214521_clickPreventStop_5359,
      evh_6705964765214521_clickPreventStop,
    }
  }
}

Vue.component("AccountsDailyFlow", AccountsDailyFlow);

</script>