"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Home = void 0;
require("./styles.scss");
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var core_1 = require("@integro/core");
var connections_1 = require("./connections");
var ui = require("@integro/ui");
var components = require("./components");
var app = require("./app.yaml");
var msg_hu = require("./messages/hu.po");
var config = require("./config.yaml?plugin");
var scripts = require("./scripts");
var utils = require("./utils");
var template = "\n<div class=\"app d-flex flex-column flex-nowrap overflow-hidden h-100\">\n  <MainMenu class=\"app__navbar\"></MainMenu>\n  <div class=\"app__content flex-grow-1 flex-shrink-1 overflow-hidden bg-light pt-2\">\n    <router-tab :keep-last-tab=\"false\" restore>\n      <template #default=\"tab\">\n        <ItpIcon v-if=\"tab.icon\" :name=\"tab.icon\" class=\"router-tab__item-icon\" />\n        <span class=\"router-tab__item-title\" :title=\"tab.tips\">{{\n          tab.title\n        }}</span>\n        <i\n          v-if=\"tab.closable\"\n          class=\"router-tab__item-close\"\n          @click.prevent=\"tab.close\"\n        />\n      </template>\n    </router-tab>\n  </div>\n</div>";
var Home = (function (_super) {
    __extends(Home, _super);
    function Home() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Home.prototype.data = function () {
        return {
            mode: 'development'
        };
    };
    Home = __decorate([
        (0, vue_property_decorator_1.Component)({ template: template, components: components })
    ], Home);
    return Home;
}(vue_property_decorator_1.Vue));
exports.Home = Home;
vue_property_decorator_1.Vue.component('Home', Home);
var application = new core_1.Application(__assign(__assign({}, app), { connections: connections_1.connections, config: config, translations: [msg_hu], home: 'Home' }));
ui.initialize(application);
scripts.initialize(application);
_.set(application, 'utils', utils);
application.mount();
