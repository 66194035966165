"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var Payment = (function (_super) {
    __extends(Payment, _super);
    function Payment() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "page",
        };
        _this.dataMembers = ['formId', 'item', 'ux', 'b_7315092382398562_modalBindings'];
        return _this;
    }
    Payment.prototype.beforeCreate = function () {
    };
    Payment.prototype.recalculateAmountFromLines = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var item = this.item;
        var _a = __read(args, 1), invoiceData = _a[0];
        console.log('recalculateAmountFromLines', args, invoiceData);
        if (invoiceData) {
            var idx = _.findIndex(item.invoices.data, { invoice_id: invoiceData.invoice_id });
            if (idx > -1) {
                _.extend(item.invoices.data[idx], invoiceData);
            }
        }
        item.amount = _.sumBy(item.invoices.data, function (p) { return _.toNumber(p.amount); });
        if (this.isPettyCashPayment) {
            item.amount = Math.abs(item.amount);
        }
    };
    Payment.prototype.onOid = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    };
    Payment.prototype.onInvoices = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoices");
    };
    Payment.prototype.onAccount_id = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("accountId");
    };
    Payment.prototype.onTransaction_type = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("transactionType");
    };
    Payment.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    Payment.prototype.$$load_formId = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$fn.randomElementId()];
            });
        });
    };
    Payment.prototype.$$load_item = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.oid == null
                        ? this.$fn.fetch('get_payment_template', {
                            asResource: true,
                            parameters: {
                                TransactionType: this.transactionType,
                                invoices: this.invoices ? this.invoices.join(',') : null,
                                account_id: this.accountId
                            }
                        })
                        : this.$fn.fetch('read_payment', { asResource: true, parameters: { id: this.oid } })];
            });
        });
    };
    Payment.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            item: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    };
    Object.defineProperty(Payment.prototype, "isDeletable", {
        get: function () {
            if (this.loaded) {
                return this.oid != null;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Payment.prototype, "isNew", {
        get: function () {
            if (this.loaded) {
                return this.item && this.item.id == null;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Payment.prototype, "isPettyCashPayment", {
        get: function () {
            if (this.loaded) {
                return this.item.account && this.item.account.account_type === 'cash_book';
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Payment.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Payment.prototype.act_7315092382398562_closeModal_2946_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    Payment.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    Payment.prototype.evh_2248226175642056_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    Payment.prototype.evh_8564662037462133_reload = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    Payment.prototype.act_8564662037462133_reloadSlickgrid_2954_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "payments",
                    }];
            });
        });
    };
    Payment.prototype.evh_8564662037462133_deleted = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    Payment.prototype.act_1040167445267876_setData_2958_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item",
                        value: $event && $event.data,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    Payment.prototype.act_1040167445267876_setData_2958_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data];
            });
        });
    };
    Payment.prototype.act_1040167445267876_reloadSlickgrid_2960_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "payments",
                    }];
            });
        });
    };
    Payment.prototype.act_1040167445267876_reloadSlickgrid_2960_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data];
            });
        });
    };
    Payment.prototype.act_1040167445267876_crud_2956_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        objectType: "payment",
                        op: "upsert",
                        data: this.item,
                    }];
            });
        });
    };
    Payment.prototype.evh_1040167445267876_submit = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    Payment.prototype.act_3083451129821185_script_2962_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.recalculateAmountFromLines($event.data);
                return [2];
            });
        });
    };
    Payment.prototype.act_3083451129821185_script_2962_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_3083451129821185_script_2962_getActionArgs_value($event); },
                    }];
            });
        });
    };
    Payment.prototype.evh_3083451129821185_recalculate = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_3083451129821185_recalculate.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    Payment.prototype.act_1419464017721962_setData_2966_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item",
                        value: $event && $event.data,
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    Payment.prototype.evh_1419464017721962_success_2965_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data];
            });
        });
    };
    Payment.prototype.act_1419464017721962_reloadSlickgrid_2968_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "payments",
                    }];
            });
        });
    };
    Payment.prototype.evh_1419464017721962_success_2967_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !!$event.data];
            });
        });
    };
    Payment.prototype.act_1419464017721962_rest_2964_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        method: "save",
                        container: {
                            self: this.item.links.parent,
                            object: 'list',
                            data: []
                        },
                        resource: this.item,
                        data: {
                            etag: this.item.etag,
                            complete: true,
                        },
                    }];
            });
        });
    };
    Payment.prototype.act_1419464017721962_rest_2964_getConfirm = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        header: this.dialogHeader,
                        message: this.$fn.gettext("Completing prevents further modifications. Please confirm this operation."),
                        btnOk: this.$fn.gettext("Complete"),
                        severity: "warning",
                    }];
            });
        });
    };
    Payment.prototype.evh_1419464017721962_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    Payment.prototype.act_5566617329548203_setData_2970_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "item",
                        value: this.item.clone({
                            id: null,
                            rev: null,
                            payment_number: this.isPettyCashPayment ? null : this.item.payment_number,
                            amount: 0,
                            links: null,
                            etag: null,
                            invoices: { data: [] },
                            preview_url: null,
                            locked: false,
                            notes: null,
                            internal_notes: null,
                        }),
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    Payment.prototype.evh_5566617329548203_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    Payment.prototype.getActions = function () {
        var act_7315092382398562_closeModal_2946 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2946_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_2945 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2946,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2945],
        });
        var act_2248226175642056_closeModal_2948 = new core_1.actions.CloseModalAction({
            events: [],
        });
        var evh_2248226175642056_close_2947 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_2948,
            event: "close",
            displayName: "closeModal",
        });
        var evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2947],
        });
        var act_8564662037462133_reloadComponentData_2950 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        var evh_8564662037462133_reload_2949 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_2950,
            event: "reload",
            displayName: "reloadComponentData",
        });
        var evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_2949],
        });
        var act_8564662037462133_closeModal_2952 = new core_1.actions.CloseModalAction({
            events: [],
        });
        var evh_8564662037462133_deleted_2951 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeModal_2952,
            event: "deleted",
            displayName: "closeModal",
        });
        var act_8564662037462133_reloadSlickgrid_2954 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_2954_getActionArgs,
            events: [],
        });
        var evh_8564662037462133_deleted_2953 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_2954,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        var evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_2951, evh_8564662037462133_deleted_2953],
        });
        var act_1040167445267876_setData_2958 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_2958_getActionArgs,
            when: this.act_1040167445267876_setData_2958_getWhen,
            events: [],
        });
        var evh_1040167445267876_success_2957 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_2958,
            event: "success",
            displayName: "setData",
        });
        var act_1040167445267876_reloadSlickgrid_2960 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_2960_getActionArgs,
            when: this.act_1040167445267876_reloadSlickgrid_2960_getWhen,
            events: [],
        });
        var evh_1040167445267876_success_2959 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_2960,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        var act_1040167445267876_crud_2956 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_2956_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_2957, evh_1040167445267876_success_2959],
        });
        var evh_1040167445267876_submit_2955 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_2956,
            event: "submit",
            displayName: "save",
        });
        var evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_2955],
        });
        var act_3083451129821185_script_2962 = new core_1.actions.ScriptAction({
            actionArgs: this.act_3083451129821185_script_2962_getActionArgs,
            events: [],
        });
        var evh_3083451129821185_recalculate_2961 = new core_1.actions.EventHandlerImpl({
            action: act_3083451129821185_script_2962,
            event: "recalculate",
            displayName: "script",
        });
        var evh_3083451129821185_recalculate = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3083451129821185_recalculate_2961],
        });
        var act_1419464017721962_setData_2966 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1419464017721962_setData_2966_getActionArgs,
            events: [],
        });
        var evh_1419464017721962_success_2965 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_setData_2966,
            event: "success",
            when: this.evh_1419464017721962_success_2965_getWhen,
            displayName: "setData",
        });
        var act_1419464017721962_reloadSlickgrid_2968 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1419464017721962_reloadSlickgrid_2968_getActionArgs,
            events: [],
        });
        var evh_1419464017721962_success_2967 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_reloadSlickgrid_2968,
            event: "success",
            when: this.evh_1419464017721962_success_2967_getWhen,
            displayName: "reloadSlickgrid",
        });
        var act_1419464017721962_rest_2964 = new core_1.actions.RestAction({
            actionArgs: this.act_1419464017721962_rest_2964_getActionArgs,
            confirm: this.act_1419464017721962_rest_2964_getConfirm,
            displayName: "complete",
            events: [evh_1419464017721962_success_2965, evh_1419464017721962_success_2967],
        });
        var evh_1419464017721962_clickPreventStop_2963 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_rest_2964,
            event: "click.prevent.stop",
            displayName: "complete",
        });
        var evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_2963],
        });
        var act_5566617329548203_setData_2970 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5566617329548203_setData_2970_getActionArgs,
            events: [],
        });
        var evh_5566617329548203_clickPreventStop_2969 = new core_1.actions.EventHandlerImpl({
            action: act_5566617329548203_setData_2970,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        var evh_5566617329548203_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5566617329548203_clickPreventStop_2969],
        });
        return {
            act_7315092382398562_closeModal_2946: act_7315092382398562_closeModal_2946,
            evh_7315092382398562_close_2945: evh_7315092382398562_close_2945,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_closeModal_2948: act_2248226175642056_closeModal_2948,
            evh_2248226175642056_close_2947: evh_2248226175642056_close_2947,
            evh_2248226175642056_close: evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_2950: act_8564662037462133_reloadComponentData_2950,
            evh_8564662037462133_reload_2949: evh_8564662037462133_reload_2949,
            evh_8564662037462133_reload: evh_8564662037462133_reload,
            act_8564662037462133_closeModal_2952: act_8564662037462133_closeModal_2952,
            evh_8564662037462133_deleted_2951: evh_8564662037462133_deleted_2951,
            act_8564662037462133_reloadSlickgrid_2954: act_8564662037462133_reloadSlickgrid_2954,
            evh_8564662037462133_deleted_2953: evh_8564662037462133_deleted_2953,
            evh_8564662037462133_deleted: evh_8564662037462133_deleted,
            act_1040167445267876_setData_2958: act_1040167445267876_setData_2958,
            evh_1040167445267876_success_2957: evh_1040167445267876_success_2957,
            act_1040167445267876_reloadSlickgrid_2960: act_1040167445267876_reloadSlickgrid_2960,
            evh_1040167445267876_success_2959: evh_1040167445267876_success_2959,
            act_1040167445267876_crud_2956: act_1040167445267876_crud_2956,
            evh_1040167445267876_submit_2955: evh_1040167445267876_submit_2955,
            evh_1040167445267876_submit: evh_1040167445267876_submit,
            act_3083451129821185_script_2962: act_3083451129821185_script_2962,
            evh_3083451129821185_recalculate_2961: evh_3083451129821185_recalculate_2961,
            evh_3083451129821185_recalculate: evh_3083451129821185_recalculate,
            act_1419464017721962_setData_2966: act_1419464017721962_setData_2966,
            evh_1419464017721962_success_2965: evh_1419464017721962_success_2965,
            act_1419464017721962_reloadSlickgrid_2968: act_1419464017721962_reloadSlickgrid_2968,
            evh_1419464017721962_success_2967: evh_1419464017721962_success_2967,
            act_1419464017721962_rest_2964: act_1419464017721962_rest_2964,
            evh_1419464017721962_clickPreventStop_2963: evh_1419464017721962_clickPreventStop_2963,
            evh_1419464017721962_clickPreventStop: evh_1419464017721962_clickPreventStop,
            act_5566617329548203_setData_2970: act_5566617329548203_setData_2970,
            evh_5566617329548203_clickPreventStop_2969: evh_5566617329548203_clickPreventStop_2969,
            evh_5566617329548203_clickPreventStop: evh_5566617329548203_clickPreventStop,
            save: act_1040167445267876_crud_2956,
            complete: act_1419464017721962_rest_2964,
        };
    };
    var _a;
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: null,
        }),
        __metadata("design:type", String)
    ], Payment.prototype, "oid", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('oid'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], Payment.prototype, "onOid", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: Array,
            default: null,
        }),
        __metadata("design:type", typeof (_a = typeof array !== "undefined" && array) === "function" ? _a : Object)
    ], Payment.prototype, "invoices", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('invoices'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], Payment.prototype, "onInvoices", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
        }),
        __metadata("design:type", String)
    ], Payment.prototype, "accountId", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('accountId'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], Payment.prototype, "onAccount_id", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: null,
        }),
        __metadata("design:type", String)
    ], Payment.prototype, "transactionType", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('transactionType'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], Payment.prototype, "onTransaction_type", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "lg",
        }),
        __metadata("design:type", String)
    ], Payment.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], Payment.prototype, "onSize", null);
    Payment = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], Payment);
    return Payment;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = Payment;
vue_property_decorator_1.Vue.component("Payment", Payment);
