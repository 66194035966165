<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ExpenseTypes"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="overflow-auto h-100 p-3"
  >
    <ItpDataTable
      tableId="Settings.ExpenseTypes"
      editable
      :isRowEditable="p => !p.locked"
      :value="items"
      :columns="b_8564662037462133_columns"
      class="h-100"
      ref="table"
      v-if="loaded"
      @save="evh_8564662037462133_save($event, {})"
      @delete="evh_8564662037462133_delete($event, {})"
    >
    </ItpDataTable>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "settings.expenseTypes",
    path: "expense-types",
    component: "ExpenseTypes"
  }
)

@Component()
export default class ExpenseTypes extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  objectType!: any;
  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_object_by_type', { parameters: { object_type: this.objectType } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_8564662037462133_columns!: any;
  dataMembers = ['objectType', 'items', 'ux', 'b_7315092382398562_modalBindings', 'b_8564662037462133_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        objectType: "expense-type",
        items: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_8564662037462133_columns: [{
          name: "code",
          header: this.$fn.gettext("Code"),
          width: "160px",
          required: true,
        }
          ,
        {
          name: "name",
          header: this.$fn.gettext("Name"),
          width: "140px",
          required: true,
        }
          ,
        {
          name: "description",
          header: this.$fn.gettext("Description"),
          width: "320px",
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async act_7315092382398562_closeModal_3286_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3286, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_script_3288_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8564662037462133_script_3288, alias=undefined
    $event.data.waitFor(this.$fn.crud('object_by_type').upsert({ ...$event.data.row, object_type: this.objectType }))
  }

  async act_8564662037462133_script_3288_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8564662037462133_script_3288, alias=undefined
    return {
      value: () => this.act_8564662037462133_script_3288_getActionArgs_value($event),
    }
  }

  async evh_8564662037462133_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_save.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_script_3290_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8564662037462133_script_3290, alias=undefined
    $event.data.waitFor(this.$fn.crud('object_by_type').delete({ ...$event.data.row, object_type: this.objectType }))
  }

  async act_8564662037462133_script_3290_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8564662037462133_script_3290, alias=undefined
    return {
      value: () => this.act_8564662037462133_script_3290_getActionArgs_value($event),
    }
  }

  async evh_8564662037462133_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_delete.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3286: actions.CloseModalAction;
    evh_7315092382398562_close_3285: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_8564662037462133_script_3288: actions.ScriptAction;
    evh_8564662037462133_save_3287: actions.EventHandlerImpl;
    evh_8564662037462133_save: actions.EventHandlerGroup;
    act_8564662037462133_script_3290: actions.ScriptAction;
    evh_8564662037462133_delete_3289: actions.EventHandlerImpl;
    evh_8564662037462133_delete: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3286 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3286_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3285 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3286,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3285],
      }
    );
    const act_8564662037462133_script_3288 = new actions.ScriptAction(
      {
        actionArgs: this.act_8564662037462133_script_3288_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_save_3287 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_script_3288,
        event: "save",
        displayName: "script",
      }
    );
    const evh_8564662037462133_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_save_3287],
      }
    );
    const act_8564662037462133_script_3290 = new actions.ScriptAction(
      {
        actionArgs: this.act_8564662037462133_script_3290_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_delete_3289 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_script_3290,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_8564662037462133_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_delete_3289],
      }
    );
    return {
      act_7315092382398562_closeModal_3286,
      evh_7315092382398562_close_3285,
      evh_7315092382398562_close,
      act_8564662037462133_script_3288,
      evh_8564662037462133_save_3287,
      evh_8564662037462133_save,
      act_8564662037462133_script_3290,
      evh_8564662037462133_delete_3289,
      evh_8564662037462133_delete,
    }
  }
}

Vue.component("ExpenseTypes", ExpenseTypes);

</script>