var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        backdrop: _vm.b_7315092382398562_backdrop,
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "AddInvoiceLine",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c("UcHeader", {
        attrs: {
          slot: "header",
          card: !_vm.modal,
          modal: _vm.modal,
          title: _vm.documentTitle
        },
        on: {
          close: function($event) {
            return _vm.evh_2248226175642056_close($event, {})
          }
        },
        slot: "header"
      }),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _vm.loaded
                ? _c(
                    "ItpForm",
                    {
                      attrs: {
                        id: _vm.formId,
                        name: "invoiceLine",
                        autofocus: "first"
                      },
                      on: {
                        submit: function($event) {
                          return _vm.evh_1040167445267876_submit($event, {})
                        }
                      }
                    },
                    [
                      _c(
                        "DIV",
                        { staticClass: "form-row" },
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              staticClass: "col",
                              attrs: {
                                name: "product",
                                label: _vm._f("pgettext")("Item", "InvoiceLine")
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "product",
                                  required: "",
                                  options: _vm.searchDataSource(
                                    "suggest_product"
                                  ),
                                  "bind-object": "",
                                  valueField: "id",
                                  textField: "name",
                                  autofocus: "",
                                  taggable: "",
                                  multiple: _vm.b_5731695935601903_multiple
                                },
                                on: {
                                  optionSelected: function($event) {
                                    return _vm.evh_5731695935601903_optionSelected(
                                      $event,
                                      {}
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.item.product,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "product", $$v)
                                  },
                                  expression: "item.product"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "DIV",
                        { staticClass: "form-row" },
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              staticClass: "col-2",
                              attrs: {
                                name: "quantity",
                                label: _vm._f("pgettext")(
                                  "Quantity",
                                  "InvoiceLine"
                                )
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: { type: "number", name: "quantity" },
                                model: {
                                  value: _vm.item.quantity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "quantity", $$v)
                                  },
                                  expression: "item.quantity"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              staticClass: "col-2",
                              attrs: {
                                name: "unit",
                                label: _vm._f("pgettext")("Unit", "InvoiceLine")
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: { name: "unit", required: "" },
                                model: {
                                  value: _vm.item.unit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "unit", $$v)
                                  },
                                  expression: "item.unit"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              staticClass: "col-2",
                              attrs: {
                                name: "price",
                                label: _vm._f("pgettext")(
                                  "Price",
                                  "InvoiceLine"
                                )
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "number",
                                  name: "price",
                                  required: ""
                                },
                                model: {
                                  value: _vm.item.price,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "price", $$v)
                                  },
                                  expression: "item.price"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              staticClass: "col-2",
                              attrs: {
                                name: "price",
                                label: _vm._f("pgettext")(
                                  "Discount",
                                  "InvoiceLine"
                                )
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "number",
                                  name: "discount",
                                  required: ""
                                },
                                model: {
                                  value: _vm.item.discount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "discount", $$v)
                                  },
                                  expression: "item.discount"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              staticClass: "col-2",
                              attrs: {
                                name: "tax_code",
                                label: _vm._f("pgettext")(
                                  "Tax code",
                                  "InvoiceLine"
                                )
                              }
                            },
                            [
                              _c("ItpFormSelect", {
                                attrs: {
                                  name: "tax_code",
                                  options: _vm.taxCodes,
                                  required: ""
                                },
                                model: {
                                  value: _vm.item.tax_code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "tax_code", $$v)
                                  },
                                  expression: "item.tax_code"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: _vm.formId,
                  icon: "fa-save",
                  text: _vm._f("gettext")("Save"),
                  type: "submit",
                  variant: "primary",
                  spinning: _vm.actions.submit.isRunning,
                  disabled:
                    !_vm.forms.invoiceLine || !_vm.forms.invoiceLine.submittable
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }