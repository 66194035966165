var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { attrs: { direction: "column", alignItems: "stretch" } },
        [
          _c(
            "ItpBox",
            { staticClass: "pb-2", attrs: { alignItems: "center" } },
            [
              _c("strong", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("pgettext")("Invoice Lines", "Invoice")) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _vm.invoiceLines.length
                ? _c("strong", { staticClass: "ml-1" }, [
                    _vm._v(
                      "\n      (" + _vm._s(_vm.invoiceLines.length) + ")\n    "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("ItpDataTable", {
            staticClass: "h-100",
            attrs: {
              rowKey: "id",
              tableId: "invoiceLines",
              rowDetailsMode: "alwaysVisible",
              rowHeader: _vm.b_5962862420439144_rowHeader,
              paging: _vm.b_5962862420439144_paging,
              sortable: _vm.b_5962862420439144_sortable,
              editable: !_vm.invoice.isReadOnly,
              value: _vm.invoiceLines,
              groups: _vm.invoiceLineGroups,
              groupKeyField: "line_group_id",
              variant: "details",
              columnHeaderStyle: "cellHeader",
              rowTemplate: _vm.b_5962862420439144_rowTemplate,
              messages: _vm.b_5962862420439144_messages,
              globs: {
                searchDataSource: _vm.searchDataSource,
                messages: _vm.messages
              },
              columns: _vm.b_5962862420439144_columns
            },
            on: {
              "save-completed": function($event) {
                return _vm.evh_5962862420439144_saveCompleted($event, {})
              },
              save: function($event) {
                return _vm.evh_5962862420439144_save($event, {})
              },
              createProduct: function($event) {
                return _vm.evh_5962862420439144_createProduct($event, {})
              },
              delete: function($event) {
                return _vm.evh_5962862420439144_delete($event, {})
              },
              "product:selected": function($event) {
                return _vm.evh_5962862420439144_productSelected($event, {})
              },
              "product:change": function($event) {
                return _vm.evh_5962862420439144_productChange($event, {})
              },
              "quantity:change": function($event) {
                return _vm.evh_5962862420439144_quantityChange($event, {})
              },
              "price:change": function($event) {
                return _vm.evh_5962862420439144_priceChange($event, {})
              },
              "discount:change": function($event) {
                return _vm.evh_5962862420439144_discountChange($event, {})
              },
              "net_amount:change": function($event) {
                return _vm.evh_5962862420439144_netAmountChange($event, {})
              },
              "amount:change": function($event) {
                return _vm.evh_5962862420439144_amountChange($event, {})
              },
              "tax_code:change": function($event) {
                return _vm.evh_5962862420439144_taxCodeChange($event, {})
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "rowGroupHeader",
                  fn: function(ref) {
                    var group = ref.group
                    var rows = ref.rows
                    return [
                      _c(
                        "ItpBox",
                        [
                          _c("UcInvoiceLineGroup", {
                            attrs: { invoice: _vm.invoice, group: group },
                            on: {
                              reload: function($event) {
                                return _vm.evh_7789867021953041_reload($event, {
                                  group: group,
                                  rows: rows
                                })
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "afterLastRow",
                  fn: function(ref) {
                    var table = ref.table
                    return [
                      _c(
                        "ItpBox",
                        [
                          _c("ItpLink", {
                            staticClass: "ml-3",
                            attrs: {
                              icon: _vm.$config.addIcon,
                              size: "sm",
                              text: _vm._f("pgettext")(
                                "Add group...",
                                "InvoiceLineGroup"
                              )
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.evh_309515315410323_clickPreventStop(
                                  $event,
                                  { table: table }
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "rowDetails",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "div",
                        { staticClass: "container-fluid" },
                        [
                          _c(
                            "ItpRow",
                            [
                              _c(
                                "ItpCol",
                                { attrs: { w: "2" } },
                                [
                                  row.notes && !row.isEditing
                                    ? _c("ItpText", {
                                        attrs: {
                                          pre: "",
                                          text: row.notes,
                                          collapsible: ""
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.isEditing
                                    ? _c("ItpFormTextarea", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          rows: "3",
                                          name: "notes",
                                          placeholder: _vm._f("gettext")(
                                            "Notes"
                                          ),
                                          disabled: !row.isEditing
                                        },
                                        model: {
                                          value: row.notes,
                                          callback: function($$v) {
                                            _vm.$set(row, "notes", $$v)
                                          },
                                          expression: "row.notes"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.isEditing
                                    ? _c("ItpFormSelect2", {
                                        attrs: {
                                          name: "reference_invoice_id",
                                          placeholder: _vm._f("pgettext")(
                                            "Reference invoice",
                                            "InvoiceLine"
                                          ),
                                          initialOptions: row.reference_invoice
                                            ? [row.reference_invoice]
                                            : [],
                                          dataSource:
                                            _vm.b_1270943406604881_dataSource,
                                          valueField: "id",
                                          clearable: "",
                                          optionTemplate:
                                            "<UcSuggestInvoiceOption :option=option></UcSuggestInvoiceOption>",
                                          "use-option-template-for-selected-option":
                                            ""
                                        },
                                        model: {
                                          value: row.reference_invoice_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              row,
                                              "reference_invoice_id",
                                              $$v
                                            )
                                          },
                                          expression: "row.reference_invoice_id"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "ItpCol",
                                { attrs: { w: "10" } },
                                [
                                  (!row.isNew && row.isEditing) ||
                                  row.meter_accounting
                                    ? _c("ItpDataTable", {
                                        staticClass:
                                          "bg-transparent datatable-basic mb-1",
                                        attrs: {
                                          tableId: "invoiceLineMeters",
                                          variant: "basic",
                                          compact: "",
                                          paging: _vm.b_4717426290970457_paging,
                                          sortable:
                                            _vm.b_4717426290970457_sortable,
                                          rowHeader:
                                            _vm.b_4717426290970457_rowHeader,
                                          "use-index-as-row-key": "",
                                          fillEmptyRowSpace:
                                            _vm.b_4717426290970457_fillEmptyRowSpace,
                                          value: row.meter_accounting
                                            ? [row.meter_accounting]
                                            : [],
                                          editable:
                                            !_vm.invoice.isReadOnly &&
                                            !row.locked,
                                          noCreateRow: !!row.meter_accounting,
                                          globs: {
                                            units: _vm.units,
                                            searchDataSource:
                                              _vm.searchDataSource
                                          },
                                          messages:
                                            _vm.b_4717426290970457_messages,
                                          rowTemplate: {
                                            invoice_id: _vm.invoice.id,
                                            invoice_line_id: row.id,
                                            from_date: null,
                                            from_amount: null,
                                            from_unit: null,
                                            to_date: null,
                                            to_amount: null,
                                            to_unit: null,
                                            billing_multiplier: 1
                                          },
                                          columns:
                                            _vm.b_4717426290970457_columns
                                        },
                                        on: {
                                          save: function($event) {
                                            return _vm.evh_4717426290970457_save(
                                              $event,
                                              { row: row }
                                            )
                                          },
                                          "save-completed": function($event) {
                                            return _vm.evh_4717426290970457_saveCompleted(
                                              $event,
                                              { row: row }
                                            )
                                          },
                                          delete: function($event) {
                                            return _vm.evh_4717426290970457_delete(
                                              $event,
                                              { row: row }
                                            )
                                          },
                                          "delete-completed": function($event) {
                                            return _vm.evh_4717426290970457_deleteCompleted(
                                              $event,
                                              { row: row }
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  (row.isEditing && !row.isNew) ||
                                  (row.accountings &&
                                    row.accountings.data &&
                                    row.accountings.data.length)
                                    ? _c("ItpDataTable", {
                                        staticClass:
                                          "bg-transparent datatable-basic mb-1",
                                        attrs: {
                                          tableId: "invoiceLineAccounting",
                                          variant: "basic",
                                          compact: "",
                                          paging: _vm.b_1803307708030275_paging,
                                          sortable:
                                            _vm.b_1803307708030275_sortable,
                                          rowHeader:
                                            _vm.b_1803307708030275_rowHeader,
                                          fillEmptyRowSpace:
                                            _vm.b_1803307708030275_fillEmptyRowSpace,
                                          value: row.accountings.data,
                                          editable:
                                            !_vm.invoice.isReadOnly &&
                                            !row.locked,
                                          rowTemplate: _vm.schemaDefaults(
                                            "CreateInvoiceLineAccounting",
                                            {
                                              invoice_id: _vm.invoice.id,
                                              invoice_line_id: row.id
                                            }
                                          ),
                                          globs: {
                                            searchDataSource:
                                              _vm.searchDataSource
                                          },
                                          messages:
                                            _vm.b_1803307708030275_messages,
                                          columns:
                                            _vm.b_1803307708030275_columns
                                        },
                                        on: {
                                          save: function($event) {
                                            return _vm.evh_1803307708030275_save(
                                              $event,
                                              { row: row }
                                            )
                                          },
                                          delete: function($event) {
                                            return _vm.evh_1803307708030275_delete(
                                              $event,
                                              { row: row }
                                            )
                                          },
                                          "save-completed": function($event) {
                                            return _vm.evh_1803307708030275_saveCompleted(
                                              $event,
                                              { row: row }
                                            )
                                          },
                                          "delete-completed": function($event) {
                                            return _vm.evh_1803307708030275_deleteCompleted(
                                              $event,
                                              { row: row }
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "ItpBox",
                                    { staticClass: "mb-3" },
                                    [
                                      _vm.hasQuantityMismatch(row)
                                        ? _c(
                                            "DIV",
                                            { staticClass: "ml-auto" },
                                            [
                                              _c("ItpIcon", {
                                                staticClass: "text-warning",
                                                attrs: {
                                                  icon:
                                                    "material-symbols:warning-rounded"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("ItpText", {
                                                attrs: {
                                                  strong: "",
                                                  text: _vm._f("pgettext")(
                                                    "Quantity mismatch.",
                                                    "invoiceLineAccounting"
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3135665919
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }