<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Tasks"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <H3
    class="m-3"
  >
    {{ 'Tasks'|gettext }}
  </H3>
  <DIV
    class="h-100 overflow-auto px-3"
  >
    <DIV
      class="card my-3"
    >
      <DIV
        class="card-body"
      >
        <H5>
          {{ 'Expired contracts'|gettext }}
        </H5>
        <P
          class="card-text"
        >
          {{ 'This task searches for contracts expired within X days and creates notifications if founds any.'|gettext }}
        </P>
        <JobHandlerControl
          jobType="contracts:check_expiring_contracts"
          load-current
          :payload="() => ({})"
          :text="'Check expiring contracts'|pgettext('Tasks|Command')"
          icon="mdi:cog"
        >
        </JobHandlerControl>
      </DIV>
    </DIV>
    <DIV
      class="card my-3"
    >
      <DIV
        class="card-body"
      >
        <H5>
          {{ 'Inflation indexed fees'|gettext }}
        </H5>
        <P
          class="card-text"
        >
          {{ 'This task searches for fees subject to inflation indexed price change and creates notifications if founds any.'|gettext }}
        </P>
        <JobHandlerControl
          jobType="contracts:inflation_indexing"
          load-current
          :payload="() => ({})"
          :text="'Check inflation indexed fees.'|pgettext('Tasks|Command')"
          icon="mdi:cog"
        >
        </JobHandlerControl>
      </DIV>
    </DIV>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "settings.tasks",
    path: "sync-jobs",
    component: "Tasks"
  }
)

@Component()
export default class Tasks extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async act_7315092382398562_closeModal_3310_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3310, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3310: actions.CloseModalAction;
    evh_7315092382398562_close_3309: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3310 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3310_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3309 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3310,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3309],
      }
    );
    return {
      act_7315092382398562_closeModal_3310,
      evh_7315092382398562_close_3309,
      evh_7315092382398562_close,
    }
  }
}

Vue.component("Tasks", Tasks);

</script>