<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Protocols and events'|pgettext('Contract') }}
      </strong>
      <b-dropdown
        size="sm"
        toggle-class="btn-light border"
        :text="'New event'|pgettext('Contract')"
        class="ml-auto"
        v-if="!readonly"
      >
        <b-dropdown-item-button
          @click.prevent.stop="evh_4440454779651433_clickPreventStop($event, {})"
        >
          <ItpText
            :text="'New handover protocol (moving in)...'|pgettext('Contract')"
          >
          </ItpText>
        </b-dropdown-item-button>
        <b-dropdown-item-button
          @click.prevent.stop="evh_8643180786190723_clickPreventStop($event, {})"
        >
          <ItpText
            :text="'New handover protocol (moving out)...'|pgettext('Contract')"
          >
          </ItpText>
        </b-dropdown-item-button>
      </b-dropdown>
    </ItpBox>
    <BListGroup>
      <BListGroupItem
        v-if="!items || !items.length"
      >
        <ItpText>
          {{ 'No events have been added yet.'|pgettext('Contract') }}
        </ItpText>
      </BListGroupItem>
      <BListGroupItem
        :key="item.id"
        v-for="item in items"
      >
        <ItpBox
          alignItems="center"
        >
          <DIV
            class="pr-3"
          >
            <ItpIcon
              name="fluent:calendar-12-regular"
              class="align-middle"
            >
            </ItpIcon>
            <span
              class="align-middle"
            >
              {{ item.handover_date|date }}
            </span>
          </DIV>
          <ItpText
            :text="item.handover_type|pgettext('HandoverType', 'Sentence')"
            :strikethrough="item.protocol_status == 'cancelled'"
            class="font-weight-bold px-3"
          >
          </ItpText>
          <UcStatusBadge
            :status="item.protocol_status"
            class="px-3"
          >
          </UcStatusBadge>
          <ItpIcon
            :title="'Locked'|gettext"
            name="fa-lock"
            class="px-3"
            v-if="item.locked"
          >
          </ItpIcon>
          <b-dropdown
            size="sm"
            toggle-class="btn-default-borderless"
            no-caret
            class="ml-auto mb-auto"
          >
            <ItpIcon
              fa="ellipsis-v"
              class="mx-1"
              slot="button-content"
            >
            </ItpIcon>
            <b-dropdown-item-button
              @click.prevent.stop="evh_2002045350107722_clickPreventStop($event, {item})"
            >
              <ItpText
                :text="'Edit'|pgettext('Command')"
              >
              </ItpText>
            </b-dropdown-item-button>
          </b-dropdown>
        </ItpBox>
        <table
          class="table table-condensed mt-2 mb-0 pb-0 mx-2"
          v-if="item.protocol_status == 'completed' && item.meter_readings"
        >
          <tr
            :key="reading.serial_number"
            v-for="reading in item.meter_readings"
          >
            <td>
              <UcMeterMiniCard
                :item="reading"
                no-strong
              >
              </UcMeterMiniCard>
            </td>
            <td>
              {{ reading.reading_value|number }} {{ reading.reading_unit }}

            </td>
          </tr>
        </table>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractEvents extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_contract_protocol', {
      parameters: {
        contract_id: this.contract.id,
        contract_revision_id: this.contract.revision_id
      }
    })
      .then(d => _.orderBy(d, ['handover_date'], ['desc']))
  }

  ux!: any;
  dataMembers = ['items', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_4440454779651433_emit_6164_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4440454779651433_emit_6164, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_4440454779651433_close_6163_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_4440454779651433_close_6163, alias=undefined
    return $event.data.modalResult && $event.data.modalResult.ok
  }

  async act_4440454779651433_showModal_6162_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_4440454779651433_showModal_6162, alias=undefined
    return {
      name: "HandoverProtocol",
      props: {
        contractId: this.contract.id,
        revisionId: this.contract.revision_id,
        handoverType: "moving_in",
      }
      ,
    }
  }

  async evh_4440454779651433_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4440454779651433_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8643180786190723_emit_6168_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8643180786190723_emit_6168, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_8643180786190723_close_6167_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8643180786190723_close_6167, alias=undefined
    return $event.data.modalResult && $event.data.modalResult.ok
  }

  async act_8643180786190723_showModal_6166_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8643180786190723_showModal_6166, alias=undefined
    return {
      name: "HandoverProtocol",
      props: {
        contractId: this.contract.id,
        revisionId: this.contract.revision_id,
        handoverType: "moving_out",
      }
      ,
    }
  }

  async evh_8643180786190723_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8643180786190723_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_2002045350107722_close_6171_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2002045350107722_close_6171, alias=undefined
    return $event.data.modalResult && $event.data.modalResult.ok
  }

  async act_2002045350107722_showModal_6170_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2002045350107722_showModal_6170, alias=undefined
    return {
      name: "HandoverProtocol",
      props: {
        oid: $event.scope.item.id,
      }
      ,
    }
  }

  async evh_2002045350107722_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2002045350107722_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6160: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6159: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_4440454779651433_emit_6164: actions.EmitAction;
    evh_4440454779651433_close_6163: actions.EventHandlerImpl;
    act_4440454779651433_showModal_6162: actions.ShowModalAction;
    evh_4440454779651433_clickPreventStop_6161: actions.EventHandlerImpl;
    evh_4440454779651433_clickPreventStop: actions.EventHandlerGroup;
    act_8643180786190723_emit_6168: actions.EmitAction;
    evh_8643180786190723_close_6167: actions.EventHandlerImpl;
    act_8643180786190723_showModal_6166: actions.ShowModalAction;
    evh_8643180786190723_clickPreventStop_6165: actions.EventHandlerImpl;
    evh_8643180786190723_clickPreventStop: actions.EventHandlerGroup;
    act_2002045350107722_reloadComponentData_6172: actions.ReloadComponentDataAction;
    evh_2002045350107722_close_6171: actions.EventHandlerImpl;
    act_2002045350107722_showModal_6170: actions.ShowModalAction;
    evh_2002045350107722_clickPreventStop_6169: actions.EventHandlerImpl;
    evh_2002045350107722_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6160 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6159 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6160,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6159],
      }
    );
    const act_4440454779651433_emit_6164 = new actions.EmitAction(
      {
        actionArgs: this.act_4440454779651433_emit_6164_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_4440454779651433_close_6163 = new actions.EventHandlerImpl(
      {
        action: act_4440454779651433_emit_6164,
        event: "close",
        when: this.evh_4440454779651433_close_6163_getWhen,
        displayName: "emitReload",
      }
    );
    const act_4440454779651433_showModal_6162 = new actions.ShowModalAction(
      {
        actionArgs: this.act_4440454779651433_showModal_6162_getActionArgs,
        events: [evh_4440454779651433_close_6163],
      }
    );
    const evh_4440454779651433_clickPreventStop_6161 = new actions.EventHandlerImpl(
      {
        action: act_4440454779651433_showModal_6162,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_4440454779651433_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_4440454779651433_clickPreventStop_6161],
      }
    );
    const act_8643180786190723_emit_6168 = new actions.EmitAction(
      {
        actionArgs: this.act_8643180786190723_emit_6168_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_8643180786190723_close_6167 = new actions.EventHandlerImpl(
      {
        action: act_8643180786190723_emit_6168,
        event: "close",
        when: this.evh_8643180786190723_close_6167_getWhen,
        displayName: "emitReload",
      }
    );
    const act_8643180786190723_showModal_6166 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8643180786190723_showModal_6166_getActionArgs,
        events: [evh_8643180786190723_close_6167],
      }
    );
    const evh_8643180786190723_clickPreventStop_6165 = new actions.EventHandlerImpl(
      {
        action: act_8643180786190723_showModal_6166,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8643180786190723_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8643180786190723_clickPreventStop_6165],
      }
    );
    const act_2002045350107722_reloadComponentData_6172 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2002045350107722_close_6171 = new actions.EventHandlerImpl(
      {
        action: act_2002045350107722_reloadComponentData_6172,
        event: "close",
        when: this.evh_2002045350107722_close_6171_getWhen,
        displayName: "reloadComponentData",
      }
    );
    const act_2002045350107722_showModal_6170 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2002045350107722_showModal_6170_getActionArgs,
        events: [evh_2002045350107722_close_6171],
      }
    );
    const evh_2002045350107722_clickPreventStop_6169 = new actions.EventHandlerImpl(
      {
        action: act_2002045350107722_showModal_6170,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2002045350107722_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2002045350107722_clickPreventStop_6169],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6160,
      evh_7315092382398562_reload_6159,
      evh_7315092382398562_reload,
      act_4440454779651433_emit_6164,
      evh_4440454779651433_close_6163,
      act_4440454779651433_showModal_6162,
      evh_4440454779651433_clickPreventStop_6161,
      evh_4440454779651433_clickPreventStop,
      act_8643180786190723_emit_6168,
      evh_8643180786190723_close_6167,
      act_8643180786190723_showModal_6166,
      evh_8643180786190723_clickPreventStop_6165,
      evh_8643180786190723_clickPreventStop,
      act_2002045350107722_reloadComponentData_6172,
      evh_2002045350107722_close_6171,
      act_2002045350107722_showModal_6170,
      evh_2002045350107722_clickPreventStop_6169,
      evh_2002045350107722_clickPreventStop,
      emitReload: act_8643180786190723_emit_6168,
    }
  }
}

Vue.component("UcContractEvents", UcContractEvents);

</script>