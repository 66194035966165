import { render, staticRenderFns } from "./UcInvoiceHeader.yaml?vue&type=template&id=5d34fbf8&component"
import script from "./UcInvoiceHeader.yaml?vue&type=script&lang=ts&component"
export * from "./UcInvoiceHeader.yaml?vue&type=script&lang=ts&component"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/buildbot/integro-appbuilder/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d34fbf8')) {
      api.createRecord('5d34fbf8', component.options)
    } else {
      api.reload('5d34fbf8', component.options)
    }
    module.hot.accept("./UcInvoiceHeader.yaml?vue&type=template&id=5d34fbf8&component", function () {
      api.rerender('5d34fbf8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/invoice/form/UcInvoiceHeader.yaml"
export default component.exports