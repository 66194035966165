"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
var ClientDataImportModal = (function (_super) {
    __extends(ClientDataImportModal, _super);
    function ClientDataImportModal() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "modal",
        };
        _this.dataMembers = ['file', 'headered', 'colnum_number', 'colnum_name', 'colnum_email', 'ux'];
        return _this;
    }
    ClientDataImportModal.prototype.beforeCreate = function () {
    };
    ClientDataImportModal.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    ClientDataImportModal.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            file: null,
            headered: true,
            colnum_number: 1,
            colnum_name: 2,
            colnum_email: 3,
            ux: null,
        });
    };
    Object.defineProperty(ClientDataImportModal.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.$fn.gettext("Import client data"),
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    ClientDataImportModal.prototype.act_7315092382398562_closeModal_1654_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    ClientDataImportModal.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ClientDataImportModal.prototype.evh_2248226175642056_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ClientDataImportModal.prototype.act_5534025912102772_register_1660_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "resultLength",
                        value: $event.data.response.data ? $event.data.response.data.updated_records : 0,
                    }];
            });
        });
    };
    ClientDataImportModal.prototype.act_5534025912102772_notification_1662_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        message: this.$fn.ngettext("One record imported.", "${n} records imported.", $event.data.resultLength),
                    }];
            });
        });
    };
    ClientDataImportModal.prototype.act_5534025912102772_notification_1662_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, $event.data.resultLength > 0];
            });
        });
    };
    ClientDataImportModal.prototype.act_5534025912102772_notification_1664_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        message: this.$fn.gettext("Nothing has been imported."),
                    }];
            });
        });
    };
    ClientDataImportModal.prototype.act_5534025912102772_notification_1664_getWhen = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, !$event.data.resultLength];
            });
        });
    };
    ClientDataImportModal.prototype.act_5534025912102772_closeModal_1666_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            value: $event.data.response.data,
                        },
                    }];
            });
        });
    };
    ClientDataImportModal.prototype.act_5534025912102772_reloadSlickgrid_1668_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        grid: "all",
                    }];
            });
        });
    };
    ClientDataImportModal.prototype.act_5534025912102772_request_1658_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        operation: "import_clients",
                        data: { header: this.headered, file: this.file, colnum_number: this.colnum_number, colnum_name: this.colnum_name, colnum_email: this.colnum_email },
                    }];
            });
        });
    };
    ClientDataImportModal.prototype.evh_5534025912102772_submit = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_5534025912102772_submit.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ClientDataImportModal.prototype.act_1419464017721962_closeModal_1670_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                        },
                    }];
            });
        });
    };
    ClientDataImportModal.prototype.evh_1419464017721962_clickPreventStop = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    ClientDataImportModal.prototype.getActions = function () {
        var act_7315092382398562_closeModal_1654 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1654_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_1653 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1654,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1653],
        });
        var act_2248226175642056_closeComponent_1656 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        var evh_2248226175642056_close_1655 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_1656,
            event: "close",
            displayName: "closeComponent",
        });
        var evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1655],
        });
        var act_5534025912102772_register_1660 = new core_1.actions.RegisterAction({
            actionArgs: this.act_5534025912102772_register_1660_getActionArgs,
            events: [],
        });
        var evh_5534025912102772_success_1659 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_register_1660,
            event: "success",
            displayName: "register",
        });
        var act_5534025912102772_notification_1662 = new core_1.actions.NotificationAction({
            actionArgs: this.act_5534025912102772_notification_1662_getActionArgs,
            when: this.act_5534025912102772_notification_1662_getWhen,
            events: [],
        });
        var evh_5534025912102772_success_1661 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_notification_1662,
            event: "success",
            displayName: "notification",
        });
        var act_5534025912102772_notification_1664 = new core_1.actions.NotificationAction({
            actionArgs: this.act_5534025912102772_notification_1664_getActionArgs,
            when: this.act_5534025912102772_notification_1664_getWhen,
            events: [],
        });
        var evh_5534025912102772_success_1663 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_notification_1664,
            event: "success",
            displayName: "notification",
        });
        var act_5534025912102772_closeModal_1666 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_5534025912102772_closeModal_1666_getActionArgs,
            events: [],
        });
        var evh_5534025912102772_success_1665 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_closeModal_1666,
            event: "success",
            displayName: "closeModal",
        });
        var act_5534025912102772_reloadSlickgrid_1668 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_5534025912102772_reloadSlickgrid_1668_getActionArgs,
            events: [],
        });
        var evh_5534025912102772_success_1667 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_reloadSlickgrid_1668,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        var act_5534025912102772_request_1658 = new core_1.actions.RequestAction({
            actionArgs: this.act_5534025912102772_request_1658_getActionArgs,
            displayName: "submit",
            events: [evh_5534025912102772_success_1659, evh_5534025912102772_success_1661, evh_5534025912102772_success_1663, evh_5534025912102772_success_1665, evh_5534025912102772_success_1667],
        });
        var evh_5534025912102772_submit_1657 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_request_1658,
            event: "submit",
            displayName: "submit",
        });
        var evh_5534025912102772_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_submit_1657],
        });
        var act_1419464017721962_closeModal_1670 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1419464017721962_closeModal_1670_getActionArgs,
            events: [],
        });
        var evh_1419464017721962_clickPreventStop_1669 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_1670,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        var evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_1669],
        });
        return {
            act_7315092382398562_closeModal_1654: act_7315092382398562_closeModal_1654,
            evh_7315092382398562_close_1653: evh_7315092382398562_close_1653,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_closeComponent_1656: act_2248226175642056_closeComponent_1656,
            evh_2248226175642056_close_1655: evh_2248226175642056_close_1655,
            evh_2248226175642056_close: evh_2248226175642056_close,
            act_5534025912102772_register_1660: act_5534025912102772_register_1660,
            evh_5534025912102772_success_1659: evh_5534025912102772_success_1659,
            act_5534025912102772_notification_1662: act_5534025912102772_notification_1662,
            evh_5534025912102772_success_1661: evh_5534025912102772_success_1661,
            act_5534025912102772_notification_1664: act_5534025912102772_notification_1664,
            evh_5534025912102772_success_1663: evh_5534025912102772_success_1663,
            act_5534025912102772_closeModal_1666: act_5534025912102772_closeModal_1666,
            evh_5534025912102772_success_1665: evh_5534025912102772_success_1665,
            act_5534025912102772_reloadSlickgrid_1668: act_5534025912102772_reloadSlickgrid_1668,
            evh_5534025912102772_success_1667: evh_5534025912102772_success_1667,
            act_5534025912102772_request_1658: act_5534025912102772_request_1658,
            evh_5534025912102772_submit_1657: evh_5534025912102772_submit_1657,
            evh_5534025912102772_submit: evh_5534025912102772_submit,
            act_1419464017721962_closeModal_1670: act_1419464017721962_closeModal_1670,
            evh_1419464017721962_clickPreventStop_1669: evh_1419464017721962_clickPreventStop_1669,
            evh_1419464017721962_clickPreventStop: evh_1419464017721962_clickPreventStop,
            submit: act_5534025912102772_request_1658,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "md",
        }),
        __metadata("design:type", String)
    ], ClientDataImportModal.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], ClientDataImportModal.prototype, "onSize", null);
    ClientDataImportModal = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], ClientDataImportModal);
    return ClientDataImportModal;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = ClientDataImportModal;
vue_property_decorator_1.Vue.component("ClientDataImportModal", ClientDataImportModal);
