"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.connections = void 0;
var integroApiSchema = require("./integro-api.json");
var metersSchema = require("./meters.json");
var meterStatsSchema = require("./meter-stats.json");
var wasteBinsSchema = require("./waste-bins.json");
exports.connections = [
    {
        name: 'integro_api',
        kind: 'openapi',
        url: '<%= integro_api_openapi_json_url %>',
        baseUrl: '<%= integro_api_base_url %>',
        schema: integroApiSchema
    },
    {
        name: 'meters',
        kind: 'openapi',
        url: '<%= meters_api_openapi_json_url %>',
        baseUrl: '<%= meters_api_base_url %>',
        schema: metersSchema
    },
    {
        name: 'meter_stats',
        kind: 'openapi',
        url: '<%= meter_stats_api_openapi_json_url %>',
        baseUrl: '<%= meter_stats_api_base_url %>',
        schema: meterStatsSchema
    },
    {
        name: 'waste_bins',
        kind: 'openapi',
        url: '<%= waste_bins_api_openapi_json_url %>',
        baseUrl: '<%= waste_bins_api_base_url %>',
        schema: wasteBinsSchema
    }
];
