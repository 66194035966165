"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcAddressList = exports.UcAddressTypeBadge = exports.UcAddressCard = exports.FieldsetAddress = exports.Address = void 0;
var Address_yaml_component_1 = require("./Address.yaml?component");
exports.Address = Address_yaml_component_1.default;
var FieldsetAddress_yaml_component_1 = require("./FieldsetAddress.yaml?component");
exports.FieldsetAddress = FieldsetAddress_yaml_component_1.default;
var UcAddressCard_yaml_component_1 = require("./UcAddressCard.yaml?component");
exports.UcAddressCard = UcAddressCard_yaml_component_1.default;
var UcAddressTypeBadge_yaml_component_1 = require("./UcAddressTypeBadge.yaml?component");
exports.UcAddressTypeBadge = UcAddressTypeBadge_yaml_component_1.default;
var UcAddressList_yaml_component_1 = require("./UcAddressList.yaml?component");
exports.UcAddressList = UcAddressList_yaml_component_1.default;
