<template>
<ItpPage
  card
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="LetterRegisterEntry"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.letterRegister.color"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <LetterRegisterEntryHeader
      :register="resource.register || register"
      :entry="resource"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </LetterRegisterEntryHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      :name="formName"
      :readonly="resource.isReadOnly"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <FieldsetLetterRegisterEntry
          :form="forms[formName]"
          :item="editor"
        >
        </FieldsetLetterRegisterEntry>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    :color="$config.letterRegister.color"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Create another entry'|pgettext('LetterRegisterEntry/Command')"
      :disabled="!forms[formName] || !forms[formName].submittable"
      variant="light"
      icon="mdi:forward"
      :spinning="actions.save.isRunning"
      class="mr-2"
      v-if="!resource.isNew"
      @click="evh_1548630417156826_click($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class LetterRegisterEntry extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: Object,
  })
  register!: object;

  @Watch('register')
  onRegister(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("register")
  }

  @Prop({
    type: String,
  })
  companyId!: string;

  @Watch('companyId')
  onCompany_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("companyId")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  container!: any;

  async $$load_container() {
    return {
      self: this.$fn.urlFor('create_letter_register_entry'),
      object: 'list',
      data: []
    }
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  formName!: any;
  resource!: any;

  async $$load_resource() {
    return this.oid
      ? this.$fn.fetch('read_letter_register_entry', { parameters: { id: this.oid }, asResource: true })
      : this.$fn.schemaDefaults('CreateLetterRegisterEntryCommand', {
        id: null,
        register_name: this.register.id,
        company_id: this.companyId,
        entry_date: this.$fn.today()
      })
  }

  editor!: any;

  async $$load_editor() {
    return this.resource.editor()
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['container', 'formId', 'formName', 'resource', 'editor', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        container: null,
        formId: null,
        formName: "letterRegisterEntry",
        resource: null,
        editor: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get isDeletable() {
    if (this.loaded) {

      return this.oid != null
    }
    return null;
  }

  get creatingTitle() {
    if (this.loaded) {

      return this.$fn.pgettext("LetterRegisterEntry", "Create new entry")
    }
    return null;
  }

  get editingTitle() {
    if (this.loaded) {

      return '#' + this.resource.entry_number
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.resource.isNew ? this.creatingTitle : this.editingTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2660_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2660, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_2668_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_2668, alias=undefined
    return {
      grid: "letterRegister",
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_emit_2672_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1040167445267876_emit_2672, alias=undefined
    return {
      event: "saved",
      value: $event.data,
    }
  }

  async act_1040167445267876_reloadSlickgrid_2674_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_2674, alias=undefined
    return {
      grid: "letterRegister",
    }
  }

  async act_1040167445267876_rest_2670_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_2670, alias=save
    return {
      method: "save",
      container: this.container,
      resource: this.editor,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_script_2676_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1548630417156826_script_2676, alias=undefined
    (
      this.setData('resource', this.resource.clone({
        id: null,
        rev: null,
        entry_number: null,
        subject: null,
        links: null,
        etag: null,
        locked: null,
      })),
      this.setData('editor', this.resource.editor())
    )
  }

  async act_1548630417156826_script_2676_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1548630417156826_script_2676, alias=undefined
    return {
      value: () => this.act_1548630417156826_script_2676_getActionArgs_value($event),
    }
  }

  async evh_1548630417156826_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2660: actions.CloseModalAction;
    evh_7315092382398562_close_2659: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2662: actions.CloseComponentAction;
    evh_2248226175642056_close_2661: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_2664: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_2663: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeModal_2666: actions.CloseModalAction;
    evh_8564662037462133_deleted_2665: actions.EventHandlerImpl;
    act_8564662037462133_reloadSlickgrid_2668: actions.ReloadSlickgridAction;
    evh_8564662037462133_deleted_2667: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_emit_2672: actions.EmitAction;
    evh_1040167445267876_saved_2671: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_2674: actions.ReloadSlickgridAction;
    evh_1040167445267876_saved_2673: actions.EventHandlerImpl;
    act_1040167445267876_rest_2670: actions.RestAction;
    evh_1040167445267876_submit_2669: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1548630417156826_script_2676: actions.ScriptAction;
    evh_1548630417156826_click_2675: actions.EventHandlerImpl;
    evh_1548630417156826_click: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_2678: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_2677: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2660 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2660_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2659 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2660,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2659],
      }
    );
    const act_2248226175642056_closeComponent_2662 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2661 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2662,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2661],
      }
    );
    const act_8564662037462133_reloadComponentData_2664 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_2663 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_2664,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_2663],
      }
    );
    const act_8564662037462133_closeModal_2666 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_2665 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeModal_2666,
        event: "deleted",
        displayName: "closeModal",
      }
    );
    const act_8564662037462133_reloadSlickgrid_2668 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_2668_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_2667 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_2668,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_2665, evh_8564662037462133_deleted_2667],
      }
    );
    const act_1040167445267876_emit_2672 = new actions.EmitAction(
      {
        actionArgs: this.act_1040167445267876_emit_2672_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_2671 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_emit_2672,
        event: "saved",
        displayName: "emit",
      }
    );
    const act_1040167445267876_reloadSlickgrid_2674 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_2674_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_2673 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_2674,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_rest_2670 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_2670_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_saved_2671, evh_1040167445267876_saved_2673],
      }
    );
    const evh_1040167445267876_submit_2669 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_2670,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_2669],
      }
    );
    const act_1548630417156826_script_2676 = new actions.ScriptAction(
      {
        actionArgs: this.act_1548630417156826_script_2676_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_click_2675 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_script_2676,
        event: "click",
        displayName: "script",
      }
    );
    const evh_1548630417156826_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_click_2675],
      }
    );
    const act_1419464017721962_closeComponent_2678 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2677 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_2678,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2677],
      }
    );
    return {
      act_7315092382398562_closeModal_2660,
      evh_7315092382398562_close_2659,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2662,
      evh_2248226175642056_close_2661,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_2664,
      evh_8564662037462133_reload_2663,
      evh_8564662037462133_reload,
      act_8564662037462133_closeModal_2666,
      evh_8564662037462133_deleted_2665,
      act_8564662037462133_reloadSlickgrid_2668,
      evh_8564662037462133_deleted_2667,
      evh_8564662037462133_deleted,
      act_1040167445267876_emit_2672,
      evh_1040167445267876_saved_2671,
      act_1040167445267876_reloadSlickgrid_2674,
      evh_1040167445267876_saved_2673,
      act_1040167445267876_rest_2670,
      evh_1040167445267876_submit_2669,
      evh_1040167445267876_submit,
      act_1548630417156826_script_2676,
      evh_1548630417156826_click_2675,
      evh_1548630417156826_click,
      act_1419464017721962_closeComponent_2678,
      evh_1419464017721962_clickPreventStop_2677,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_rest_2670,
    }
  }
}

Vue.component("LetterRegisterEntry", LetterRegisterEntry);

</script>