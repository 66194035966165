"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "invoices",
    path: "/invoices",
    component: "InvoicesView",
    params: [{
            name: "kind",
            prop: "kind",
        },
    ]
});
var InvoicesView = (function (_super) {
    __extends(InvoicesView, _super);
    function InvoicesView() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.componentMetadata = {
            kind: "page",
        };
        _this.dataMembers = ['titles', 'selectedItems', 'params', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_headerShowReloadButton', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
        return _this;
    }
    InvoicesView.prototype.beforeCreate = function () {
    };
    InvoicesView.prototype.getItems = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var parameters = this.params;
        return parameters ? this.$fn.fetch('list_invoice', { parameters: parameters }) : [];
    };
    InvoicesView.prototype.invoiceTypeBadgeClass = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = __read(args, 1), row = _a[0];
        var cc = this.$config.invoice.types.colorCodes[row.invoice_type];
        return "bg-".concat(cc.bg, " text-").concat(cc.text);
    };
    InvoicesView.prototype.onPrintInvoicesPayload = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return this.$imodal
            .show({ name: 'CompleteInvoicesAlt' })
            .then(function (result) {
            if (!result || result.cancel || !result.value) {
                return;
            }
            return _this.selectedItems.map(function (p) { return (__assign(__assign({}, result.value), { invoice_id: p.id })); });
        });
    };
    InvoicesView.prototype.onKind = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("kind");
    };
    InvoicesView.prototype.onSize = function (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    };
    InvoicesView.prototype.$$load_b_2248226175642056_columns = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, [{
                            field: "invoice_number",
                            name: this.$fn.pgettext("InvoicesView", "Invoice nr"),
                            type: "command",
                            formatter: [{
                                    name: "chain",
                                    formatters: [{
                                            name: "routeLink",
                                            to: "invoice",
                                            params: {
                                                id: "id",
                                            },
                                        },
                                        {
                                            name: "cellclass",
                                            value: function (row) { return ({
                                                'font-weight-bold': true,
                                                'bg-yellow-200': !row.locked,
                                                'bg-yellow-500 text-yellow-800': row.is_corrected,
                                                'bg-red-300 text-red-800': row.is_reversed,
                                            }); },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            field: "invoice_type",
                            name: this.$fn.pgettext("InvoicesView", "Type"),
                            formatter: {
                                name: "chain",
                                formatters: [{
                                        name: "gettext",
                                        context: "InvoiceType",
                                        conversion: "StartCase",
                                        filterInput: true,
                                    },
                                    {
                                        name: "badge",
                                        dot: true,
                                        pill: true,
                                        badgeClass: this.invoiceTypeBadgeClass,
                                    },
                                ],
                            },
                        },
                        {
                            field: "invoice_date",
                            name: this.$fn.pgettext("InvoicesView", "Date"),
                        },
                        {
                            field: "fulfillment_date",
                            name: this.$fn.pgettext("InvoicesView", "Fulfillment date"),
                            type: "date",
                        },
                        {
                            field: "due_date",
                            name: this.$fn.pgettext("InvoicesView", "Due date"),
                            type: "date",
                        },
                        {
                            field: "client_name",
                            name: this.$fn.pgettext("InvoicesView", "Client"),
                            formatter: {
                                name: "routeLink",
                                to: "client",
                                navigateOnIconClick: true,
                                params: {
                                    id: "client_id",
                                },
                            },
                        },
                        {
                            field: "net_total",
                            name: this.$fn.pgettext("InvoicesView", "Net"),
                            type: "number",
                            formatter: {
                                name: "localestring",
                                locale: "hu-HU",
                            },
                        },
                        {
                            field: "vat_total",
                            name: this.$fn.pgettext("InvoicesView", "VAT"),
                            type: "number",
                            formatter: {
                                name: "localestring",
                                locale: "hu-HU",
                            },
                        },
                        {
                            field: "total",
                            name: this.$fn.pgettext("InvoicesView", "Total"),
                            type: "number",
                            formatter: {
                                name: "localestring",
                                locale: "hu-HU",
                            },
                        },
                        {
                            field: "paid",
                            name: this.$fn.pgettext("InvoicesView", "Paid"),
                            type: "number",
                            formatter: {
                                name: "localestring",
                                locale: "hu-HU",
                            },
                        },
                        {
                            field: "unpaid",
                            name: this.$fn.pgettext("InvoicesView", "Claim"),
                            type: "number",
                            formatter: {
                                name: "chain",
                                formatters: [{
                                        name: "localestring",
                                        locale: "hu-HU",
                                    },
                                    {
                                        name: "cellclass",
                                        value: function (row) { return ({
                                            'text-red-500': row.unpaid != null && row.unpaid > 0,
                                            'text-green-500': row.unpaid != null && row.unpaid <= 0,
                                            'font-weight-bold': row.unpaid != null
                                        }); },
                                    },
                                ],
                            },
                        },
                        {
                            field: "currency",
                            name: this.$fn.pgettext("InvoicesView", "Currency"),
                        },
                        {
                            field: "tags",
                            name: this.$fn.pgettext("InvoicesView", "Tags"),
                        },
                        {
                            field: "rentals",
                            name: this.$fn.pgettext("InvoicesView", "Rentals"),
                        },
                        {
                            field: "contracts",
                            name: this.$fn.pgettext("InvoicesView", "Contracts"),
                        },]];
            });
        });
    };
    InvoicesView.prototype.data = function () {
        return __assign(__assign(__assign({}, this.mixinData()), { actions: this.getActions() }), {
            titles: {
                incoming: this.$fn.pgettext("InvoicesView", "Vendor invoices"),
                outgoing: this.$fn.pgettext("InvoicesView", "Customer invoices"),
            },
            selectedItems: [],
            params: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_headerShowReloadButton: false,
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: null,
        });
    };
    Object.defineProperty(InvoicesView.prototype, "document", {
        get: function () {
            if (this.loaded) {
                return {
                    title: this.titles[this.kind],
                    icon: this.$config.invoice.icon,
                };
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    InvoicesView.prototype.act_7315092382398562_closeModal_4972_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: this.modalName,
                        result: {
                            close: true,
                            cancel: true,
                        },
                    }];
            });
        });
    };
    InvoicesView.prototype.evh_7315092382398562_close = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    InvoicesView.prototype.act_2248226175642056_setData_4974_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "selectedItems",
                        value: this.$refs.grid.getSelectedItems(),
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    InvoicesView.prototype.evh_2248226175642056_selectedRowsChanged = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    InvoicesView.prototype.act_6466705138206980_showModal_4976_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        name: "CreateInvoice",
                        props: {
                            invoiceKind: this.kind,
                        },
                    }];
            });
        });
    };
    InvoicesView.prototype.evh_6466705138206980_click = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    InvoicesView.prototype.act_6705964765214521_setData_4978_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, {
                        path: "params",
                        value: __assign(__assign({}, $event.data), { kind: this.kind }),
                        resolve: false,
                        noTouchLastLoaded: false,
                        noTakeSnapshot: false,
                    }];
            });
        });
    };
    InvoicesView.prototype.act_6705964765214521_script_4980_getActionArgs_value = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.$refs.grid.reload();
                return [2];
            });
        });
    };
    InvoicesView.prototype.act_6705964765214521_script_4980_getActionArgs = function ($event) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, {
                        value: function () { return _this.act_6705964765214521_script_4980_getActionArgs_value($event); },
                    }];
            });
        });
    };
    InvoicesView.prototype.evh_6705964765214521_submit = function (event, scope) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.actions.evh_6705964765214521_submit.executeFromDOM(this, event, scope)];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    InvoicesView.prototype.getActions = function () {
        var act_7315092382398562_closeModal_4972 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4972_getActionArgs,
            events: [],
        });
        var evh_7315092382398562_close_4971 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4972,
            event: "close",
            displayName: "closeModal",
        });
        var evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4971],
        });
        var act_2248226175642056_setData_4974 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_4974_getActionArgs,
            events: [],
        });
        var evh_2248226175642056_selectedRowsChanged_4973 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_4974,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        var evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_4973],
        });
        var act_6466705138206980_showModal_4976 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6466705138206980_showModal_4976_getActionArgs,
            events: [],
        });
        var evh_6466705138206980_click_4975 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_showModal_4976,
            event: "click",
            displayName: "showModal",
        });
        var evh_6466705138206980_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_click_4975],
        });
        var act_6705964765214521_setData_4978 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6705964765214521_setData_4978_getActionArgs,
            events: [],
        });
        var evh_6705964765214521_submit_4977 = new core_1.actions.EventHandlerImpl({
            action: act_6705964765214521_setData_4978,
            event: "submit",
            displayName: "setData",
        });
        var act_6705964765214521_script_4980 = new core_1.actions.ScriptAction({
            actionArgs: this.act_6705964765214521_script_4980_getActionArgs,
            events: [],
        });
        var evh_6705964765214521_submit_4979 = new core_1.actions.EventHandlerImpl({
            action: act_6705964765214521_script_4980,
            event: "submit",
            displayName: "script",
        });
        var evh_6705964765214521_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6705964765214521_submit_4977, evh_6705964765214521_submit_4979],
        });
        return {
            act_7315092382398562_closeModal_4972: act_7315092382398562_closeModal_4972,
            evh_7315092382398562_close_4971: evh_7315092382398562_close_4971,
            evh_7315092382398562_close: evh_7315092382398562_close,
            act_2248226175642056_setData_4974: act_2248226175642056_setData_4974,
            evh_2248226175642056_selectedRowsChanged_4973: evh_2248226175642056_selectedRowsChanged_4973,
            evh_2248226175642056_selectedRowsChanged: evh_2248226175642056_selectedRowsChanged,
            act_6466705138206980_showModal_4976: act_6466705138206980_showModal_4976,
            evh_6466705138206980_click_4975: evh_6466705138206980_click_4975,
            evh_6466705138206980_click: evh_6466705138206980_click,
            act_6705964765214521_setData_4978: act_6705964765214521_setData_4978,
            evh_6705964765214521_submit_4977: evh_6705964765214521_submit_4977,
            act_6705964765214521_script_4980: act_6705964765214521_script_4980,
            evh_6705964765214521_submit_4979: evh_6705964765214521_submit_4979,
            evh_6705964765214521_submit: evh_6705964765214521_submit,
        };
    };
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            required: true,
            type: String,
        }),
        __metadata("design:type", String)
    ], InvoicesView.prototype, "kind", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('kind'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], InvoicesView.prototype, "onKind", null);
    __decorate([
        (0, vue_property_decorator_1.Prop)({
            type: String,
            default: "md",
        }),
        __metadata("design:type", String)
    ], InvoicesView.prototype, "size", void 0);
    __decorate([
        (0, vue_property_decorator_1.Watch)('size'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], InvoicesView.prototype, "onSize", null);
    InvoicesView = __decorate([
        (0, vue_property_decorator_1.Component)()
    ], InvoicesView);
    return InvoicesView;
}((0, vue_class_component_1.mixins)(core_1.UserComponentMixin)));
exports.default = InvoicesView;
vue_property_decorator_1.Vue.component("InvoicesView", InvoicesView);
