<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="RentalProperty"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      schema="RentalProperty"
      :id="formId"
      class="container"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        name="property_id"
        :label="'Real Property'|gettext"
      >
        <ItpFormSelect2
          name="property_id"
          v-model="item.property_unit_id"
          :dataSource="b_7789867021953041_dataSource"
          :initialOptions="[item.property]"
          valueField="id"
          textField="name"
          :readonly="item.locked"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="valid_from"
        :label="'Valid from'|gettext"
        class="w-auto"
      >
        <ItpFormDatetime
          type="date"
          name="valid_from"
          v-model="item.valid_from"
          :readonly="item.locked"
          :defaultValue="$fn.today()"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="valid_to"
        :label="'Valid to'|gettext"
        class="w-auto"
      >
        <ItpFormDatetime
          type="date"
          name="valid_to"
          v-model="item.valid_to"
          :readonly="item.locked"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="inactive"
      >
        <ItpFormCheckbox
          name="inactive"
          :label="'Inactive'|gettext"
          inline
          v-model="item.inactive"
          :readonly="item.locked"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        name="notes"
        :label="'Notes'|gettext"
      >
        <ItpFormTextarea
          name="notes"
          v-model="item.notes"
          :readonly="item.locked"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Lock'|gettext"
      variant="default"
      :spinning="actions.lock.isRunning"
      class="mr-auto"
      v-if="resourceId && !item.locked"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :form="formId"
      :text="'Save'|gettext"
      type="submit"
      variant="primary"
      :spinning="actions.submit.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      v-if="!item.locked"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class RentalProperty extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  rentalId!: string;

  @Watch('rentalId')
  onRental_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rentalId")
  }

  @Prop({
    type: String,
    default: null,
  })
  resourceId!: string;

  @Watch('resourceId')
  onResource_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("resourceId")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.resourceId
      ? this.$fn.fetch('read_rental_property', { parameters: { id: this.resourceId, rental_id: this.rentalId } })
      : this.$fn.schemaDefaults('CreateRentalProperty', { valid_from: this.$fn.today() })
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  formName!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_7789867021953041_dataSource!: any;
  dataMembers = ['item', 'formId', 'formName', 'ux', 'b_7315092382398562_modalBindings', 'b_7789867021953041_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        formId: null,
        formName: "rentalProperyt",
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_7789867021953041_dataSource: {
          name: "suggest_property",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.resourceId ? this.$fn.gettext('Edit Rental Property') : this.$fn.gettext('Add Rental Property'),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6738_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6738, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_6744_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_6744, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_request_6742_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_6742, alias=submit
    return {
      operation: this.item.id ? 'update_rental_property' : 'create_rental_property',
      data: { ...this.item, rental_id: this.rentalId },
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeModal_6748_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1548630417156826_closeModal_6748, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1548630417156826_request_6746_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1548630417156826_request_6746, alias=lock
    return {
      operation: "update_rental_property",
      data: {
        id: this.item.id,
        rental_id: this.rentalId,
        locked: true,
        etag: this.item.etag
      },
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2186679009640457_closeModal_6750_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_2186679009640457_closeModal_6750, alias=undefined
    return {
      name: this.modalName,
      result: {
        cancel: true,
      }
      ,
    }
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6738: actions.CloseModalAction;
    evh_7315092382398562_close_6737: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_6740: actions.CloseComponentAction;
    evh_2248226175642056_close_6739: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_6744: actions.CloseModalAction;
    evh_1040167445267876_success_6743: actions.EventHandlerImpl;
    act_1040167445267876_request_6742: actions.RequestAction;
    evh_1040167445267876_submit_6741: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1548630417156826_closeModal_6748: actions.CloseModalAction;
    evh_1548630417156826_success_6747: actions.EventHandlerImpl;
    act_1548630417156826_request_6746: actions.RequestAction;
    evh_1548630417156826_clickPreventStop_6745: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
    act_2186679009640457_closeModal_6750: actions.CloseModalAction;
    evh_2186679009640457_clickPreventStop_6749: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6738 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6738_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6737 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6738,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6737],
      }
    );
    const act_2248226175642056_closeComponent_6740 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6739 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_6740,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6739],
      }
    );
    const act_1040167445267876_closeModal_6744 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_6744_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_6743 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_6744,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_request_6742 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_6742_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_6743],
      }
    );
    const evh_1040167445267876_submit_6741 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_6742,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_6741],
      }
    );
    const act_1548630417156826_closeModal_6748 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1548630417156826_closeModal_6748_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_6747 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeModal_6748,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1548630417156826_request_6746 = new actions.RequestAction(
      {
        actionArgs: this.act_1548630417156826_request_6746_getActionArgs,
        displayName: "lock",
        events: [evh_1548630417156826_success_6747],
      }
    );
    const evh_1548630417156826_clickPreventStop_6745 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_request_6746,
        event: "click.prevent.stop",
        displayName: "lock",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_6745],
      }
    );
    const act_2186679009640457_closeModal_6750 = new actions.CloseModalAction(
      {
        actionArgs: this.act_2186679009640457_closeModal_6750_getActionArgs,
        events: [],
      }
    );
    const evh_2186679009640457_clickPreventStop_6749 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_closeModal_6750,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_6749],
      }
    );
    return {
      act_7315092382398562_closeModal_6738,
      evh_7315092382398562_close_6737,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_6740,
      evh_2248226175642056_close_6739,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_6744,
      evh_1040167445267876_success_6743,
      act_1040167445267876_request_6742,
      evh_1040167445267876_submit_6741,
      evh_1040167445267876_submit,
      act_1548630417156826_closeModal_6748,
      evh_1548630417156826_success_6747,
      act_1548630417156826_request_6746,
      evh_1548630417156826_clickPreventStop_6745,
      evh_1548630417156826_clickPreventStop,
      act_2186679009640457_closeModal_6750,
      evh_2186679009640457_clickPreventStop_6749,
      evh_2186679009640457_clickPreventStop,
      submit: act_1040167445267876_request_6742,
      lock: act_1548630417156826_request_6746,
    }
  }
}

Vue.component("RentalProperty", RentalProperty);

</script>