<template>
<ItpDataTable
  tableId="ProductPrices"
  editable
  paging
  :value="_.orderBy(items, ['valid_from', 'valid_to', 'created_at'], ['desc', 'desc', 'desc'])"
  :rowTemplate="schemaDefaults('CreateClientAddress', {
    part_id: product.id,
    valid_from: new Date().toLocaleDateString(),
  })"
  :columns="b_7315092382398562_columns"
  @save="evh_7315092382398562_save($event, {})"
  @delete="evh_7315092382398562_delete($event, {})"
>
</ItpDataTable>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ClientAddresses extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  owner!: object;

  @Watch('owner')
  onOwner(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("owner")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_client_address', { parameters: { 'client_id': this.owner.id } })
  }

  ux!: any;
  b_7315092382398562_columns!: any;

  async $$load_b_7315092382398562_columns() {
    return [{
      name: "locked",
      type: "lock",
    }
      ,
    {
      name: "inactive",
      header: this.$fn.gettext("Inactive"),
      width: "30px",
      textAlign: "center",
      formatter: {
        name: "checkbox",
      }
      ,
      editor: {
        name: "checkbox",
      }
      ,
    }
      ,
    {
      name: "valid_from",
      type: "date",
      header: this.$fn.gettext("Valid From"),
      required: true,
      width: "120px",
    }
      ,
    {
      name: "valid_to",
      type: "date",
      header: this.$fn.gettext("Valid To"),
      width: "120px",
    }
      ,
    {
      name: "quantity_from",
      type: "number",
      header: this.$fn.gettext("Quantity from"),
      width: "110px",
      validator: ["numeric"
        ,
      ]
      ,
    }
      ,
    {
      name: "price",
      type: "number",
      header: this.$fn.gettext("Price"),
      width: "110px",
      validator: ["required"
        ,
        "numeric"
        ,
      ]
      ,
    }
      ,
    {
      name: "currency",
      header: this.$fn.gettext("Currency"),
      width: "110px",
      required: true,
      type: "select",
      options: this.currencies,
    }
      ,
    {
      name: "is_discount_price",
      type: "checkbox",
      header: this.$fn.gettext("Discount price"),
    }
      ,
    {
      name: "is_purchase_price",
      type: "checkbox",
      header: this.$fn.gettext("Purchase price"),
    }
      ,
    {
      name: "note",
      type: "textarea",
      header: this.$fn.gettext("Note"),
      width: "200px",
    }
      ,
    ]
  }

  dataMembers = ['items', 'ux', 'b_7315092382398562_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_columns: null,
      },
    }
  }

  async act_7315092382398562_script_4346_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_4346, alias=undefined
    $event.data.waitFor(this.$fn.crud('part_price').upsert($event.data.row))
  }

  async act_7315092382398562_script_4346_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_4346, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_4346_getActionArgs_value($event),
    }
  }

  async evh_7315092382398562_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_save.executeFromDOM(this, event, scope);
  }

  async act_7315092382398562_script_4348_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_4348, alias=undefined
    $event.data.waitFor(this.$fn.crud('part_price').delete($event.data.row))
  }

  async act_7315092382398562_script_4348_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_4348, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_4348_getActionArgs_value($event),
    }
  }

  async evh_7315092382398562_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_delete.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_script_4346: actions.ScriptAction;
    evh_7315092382398562_save_4345: actions.EventHandlerImpl;
    evh_7315092382398562_save: actions.EventHandlerGroup;
    act_7315092382398562_script_4348: actions.ScriptAction;
    evh_7315092382398562_delete_4347: actions.EventHandlerImpl;
    evh_7315092382398562_delete: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_script_4346 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_4346_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_save_4345 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_4346,
        event: "save",
        displayName: "script",
      }
    );
    const evh_7315092382398562_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_save_4345],
      }
    );
    const act_7315092382398562_script_4348 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_4348_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_delete_4347 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_4348,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_7315092382398562_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_delete_4347],
      }
    );
    return {
      act_7315092382398562_script_4346,
      evh_7315092382398562_save_4345,
      evh_7315092382398562_save,
      act_7315092382398562_script_4348,
      evh_7315092382398562_delete_4347,
      evh_7315092382398562_delete,
    }
  }
}

Vue.component("ClientAddresses", ClientAddresses);

</script>