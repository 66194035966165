<template>
<ItpBox
  direction="row"
  class="w-100"
>
  <ItpText
    strong
  >
    <ItpIcon
      icon="material-symbols:ad-group-sharp"
    >
    </ItpIcon>
    <ItpText
      spacing="mx-1"
      :text="group.data.description"
    >
    </ItpText>
  </ItpText>
  <ItpBadge
    strong
    no-box-shadow
    class="mx-2 bg-gray-100 border"
    v-if="group.data.expense_type"
  >
    <ItpText
      strong
      :text="group.data.expense_type"
    >
    </ItpText>
  </ItpBadge>
  <UcRentalMiniCard
    badge
    :item="group.data.rental"
    class="mx-2"
    v-if="group.data.rental"
  >
  </UcRentalMiniCard>
  <UcPropertyMiniCard
    badge
    :item="group.data.property"
    class="mx-2"
    v-if="group.data.property"
  >
  </UcPropertyMiniCard>
  <UcWorksheetMiniCard
    badge
    :item="group.data.worksheet"
    class="mx-2"
    v-if="group.data.worksheet"
  >
  </UcWorksheetMiniCard>
  <UcClientMiniCard
    badge
    :item="group.data.contractor"
    class="mx-2"
    v-if="group.data.contractor"
  >
  </UcClientMiniCard>
  <ItpLink
    :icon="$config.editIcon"
    :text="'Edit group...'|pgettext('InvoiceLineGroup')"
    class="mx-2"
    @click.prevent.stop="evh_4280711203779102_clickPreventStop($event, {})"
  >
  </ItpLink>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceLineGroup extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    required: true,
    type: Object,
  })
  group!: object;

  @Watch('group')
  onGroup(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("group")
  }

  editing!: any;
  expenseTypes!: any;

  async $$load_expenseTypes() {
    return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } })
  }

  ux!: any;
  dataMembers = ['editing', 'expenseTypes', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        editing: false,
        expenseTypes: null,
        ux: null,
      },
    }
  }

  async act_4280711203779102_emit_4580_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4280711203779102_emit_4580, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_4280711203779102_close_4579_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_4280711203779102_close_4579, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_4280711203779102_showModal_4578_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_4280711203779102_showModal_4578, alias=undefined
    return {
      name: "InvoiceLineGroup",
      props: {
        invoice: this.invoice,
        groupId: this.group.data.id || null,
        isDefaultGroup: !this.group.data.id,
      }
      ,
    }
  }

  async evh_4280711203779102_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4280711203779102_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_4280711203779102_emit_4580: actions.EmitAction;
    evh_4280711203779102_close_4579: actions.EventHandlerImpl;
    act_4280711203779102_showModal_4578: actions.ShowModalAction;
    evh_4280711203779102_clickPreventStop_4577: actions.EventHandlerImpl;
    evh_4280711203779102_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_4280711203779102_emit_4580 = new actions.EmitAction(
      {
        actionArgs: this.act_4280711203779102_emit_4580_getActionArgs,
        events: [],
      }
    );
    const evh_4280711203779102_close_4579 = new actions.EventHandlerImpl(
      {
        action: act_4280711203779102_emit_4580,
        event: "close",
        when: this.evh_4280711203779102_close_4579_getWhen,
        displayName: "emit",
      }
    );
    const act_4280711203779102_showModal_4578 = new actions.ShowModalAction(
      {
        actionArgs: this.act_4280711203779102_showModal_4578_getActionArgs,
        events: [evh_4280711203779102_close_4579],
      }
    );
    const evh_4280711203779102_clickPreventStop_4577 = new actions.EventHandlerImpl(
      {
        action: act_4280711203779102_showModal_4578,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_4280711203779102_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_4280711203779102_clickPreventStop_4577],
      }
    );
    return {
      act_4280711203779102_emit_4580,
      evh_4280711203779102_close_4579,
      act_4280711203779102_showModal_4578,
      evh_4280711203779102_clickPreventStop_4577,
      evh_4280711203779102_clickPreventStop,
    }
  }
}

Vue.component("UcInvoiceLineGroup", UcInvoiceLineGroup);

</script>